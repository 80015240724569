<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Next Steps
          </h3>
        </div>
        <!-- end title header -->
        
        <!-- First Line Approval -->
        <div>
        <div class="col-span-6">
          <p>Who will be providing first line approval for this ATR? *</p>
          <user-search 
            group="HM" 
            v-model:selectedEmail="model.firstLineApproval"
            :read-only="isFirstLineSelected"
            :errors="v$.formData.firstLineApproval.$errors"
            @updated-user="handleUser($event)" />
        </div>

          <!-- End First Line Approval -->

          <p class="mt-1 text-sm text-gray-500">
            *You can either type their name (surname then space then first name)
            e.g. Bloggs Joe and select the person icon or you can open the address
            book and search for their name (this is linked to the email
            directory).
          </p>
          
          <!-- Save for later button -->
          <div v-if="!showApprovalSteps" class="my-4">
            <button
              :disabled="v$.$invalid"
              type="submit"
              class="
                bg-green-500
                border border-transparent
                rounded-md
                shadow-sm
                py-2
                px-4
                inline-flex
                justify-center
                text-sm
                font-medium
                text-white
                hover:bg-green-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                disabled:cursor-not-allowed
                disabled:bg-gray-200
                disabled:text-gray-400
              "
              @click="submitForm"
            >
              Save
            </button>
            <p class="my-4">
              * Once all mandatory fields (*) have been completed, you will be able to see a Submit button to allow you to
              submit the ATR to the next stage in the process. If you cannot see a Submit button, then you will need to
              review the form and complete any missing information.
            </p>
          </div>
          <!-- End Save for later button -->
        </div>
      </div>
    </div>
    <!-- Save button -->
    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        v-if="!showApprovalSteps"
        :disabled="!atrReadyToSubmit"
        type="submit"
        class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        "
        @click="handleCompleteAtr"
      >
        Submit ATR
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import UserSearch from '@/components/userDropdownSearch.vue'
export default {
  name: "FormTwelve",
  props: ['formData', 'atrReadyToSubmit', 'showApprovalSteps'],
  components: {
    UserSearch
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
    };
  },
  computed: {
    isFirstLineSelected(){
      return this.model?.firstLineApproval ? true : false;
    }
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formTwelve",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleRequestMoreInfo(event) {
      event.preventDefault();
      this.store.dispatch('requestMoreInfo');
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTwelve', formData: this.model });
    },
    handleUser() {
      this.$emit('update:formData', { formName: 'formTwelve', formData: this.model});
    },
    handleCompleteAtr(event) {
      event.preventDefault();
      this.$emit('submit-atr');
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  validations() {
    return {
      formData: {
        firstLineApproval: {
          required,
        },
      },
    };
  },
};
</script>

<style>
</style>
<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Hiring Manager & Secondary Contact Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <!-- hiring manager name -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="hiring_name"
              class="block text-sm font-medium text-gray-700"
              >Hiring manager name *</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="hiring_name"
                id="hiring_name"
                :value="formData?.hiringManagerName"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
            <p class="text-sm text-blue-700">*You can either type your name (surname then space then first
                name) e.g. Bloggs Joe and select the person icon or you can
                open the address book and search for your name (this is linked
                to the email directory)</p>
          </div>
        </div>
        <!-- End hiring manager name -->

        <!-- hiring manager email -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="hiring_email"
              class="block text-sm font-medium text-gray-700"
              >Hiring manager email *</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="hiring_email"
                id="hiring_email"
                :value="formData?.hiringManagerEmail"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
            <p class="text-sm text-red-700"> Please be sure to provide the correct email address as further
            communications, including copy contracts, will be issued to this
            email account. If an incorrect email address is provided, there
            could be a resulting Data Protection breach therefore it is
            essential that the correct information is provided </p>
          </div>
        </div>
        <!-- End hiring manager email -->

        <!-- employee name -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="employee_name"
              class="block text-sm font-medium text-gray-700"
              >What is the employee name for your contact *</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="employee_name"
                id="employee_name"
                :value="formData?.employeeName"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
            <p class="text-sm text-red-700">
                *You can either type their name (surname then space then first
                name) e.g. Bloggs Joe and select the person icon or you can
                open the address book and search for their name (this is linked
                to the email directory)
            </p>
          </div>
        </div>
        <!-- End employee name -->

         <!-- employee email -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="employee_email"
              class="block text-sm font-medium text-gray-700"
              >What is the their email? *</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="employee_email"
                id="employee_email"
                :value="formData?.employeeEmail"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
            <p class="text-sm text-red-700">
                Please be sure to provide the correct email address as further
                communications, including copy contracts, will be issued to
                this email account. If an incorrect email address is provided,
                there could be a resulting Data Protection breach therefore it
                is essential that the correct information is provided

            </p>
          </div>
        </div>
        <!-- End employee email -->

        <!-- End of form -->
        </div>
        </div>
        </form>
</template>

<script>
export default {
    name: 'hiringManager',
    props: ['formData', 'disableForm'],

}
</script>

<template>
  <div>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">

        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Required Documentation
          </h3>

          <p v-if="!showApprovalSteps" class="text-blue-500 mt-4">
            * Please ensure that all documents are uploaded including a valid and complete Job Description.
          </p>
        </div>
        <!-- end title header -->

        <!-- Document upload -->
        <FileUploader 
          name="form-eleven"
          :disabled="showApprovalSteps"
          :files="formData.files"
          :documentShortId="atr?.shortId"
          @updateFiles="handleUpdateFiles"
          :uploading="uploading"
          @fileUploading="uploading = $event"
        />
        <!-- End Document upload -->

      </div>
      <!-- Save button -->
      <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-600
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:bg-gray-200
            disabled:text-gray-500
            disabled:cursor-not-allowed
          "
          :disabled="disableNext || uploading"
          @click="handleSave"
        >
          Next
        </button>
      </div>
      <!-- End Save button -->
    </div>
  </div>
</template>
<script>

import { Auth } from 'aws-amplify'
import FileUploader from '@/components/FileUploader.vue'
import { useStore } from 'vuex'
import { getStorage } from '@/storageSave'

export default {
  props: ['formData', 'showApprovalSteps'],
  components: {
    FileUploader
  },
  data() {
    return {
      store: useStore(),
      dropzoneActive: false,
      model: this.formData,
      uploading: false
    }
  },
  methods: {
    handleUpdateFiles({ model }) {
      this.model.files = model;
      this.$emit('update:formData', { formName: 'formEleven', formData: this.model });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formEleven');
    },
    handleSave(event) {
      event.preventDefault();
      this.$emit('submit-form', { name: "formEleven", formData: this.formData });
    }
  },
  computed: {
    atr() {
      const formDataLS = getStorage('formData')
      if (formDataLS) {
        return JSON.parse(formDataLS)
      }
      return this.formData;
    },
    shortId() {
      return this.atr.shortId ?? '';
    },
    usersEmail() {
      return Auth?.user.signInUserSession.idToken.payload.email
    },
    atrId() {
      return this.atr.docId || '';
    },
    isNewAtr() {
      if(this.atr.status === 'DRAFT') {
        return true
      } else {
        return false
      }
    },
    disableNext() {
      const uploaded = this.model.files.filter((file) => file.uploaded);
      if (uploaded.length) return false;
      return true;
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  mounted() {
    this.files = this.atr.formEleven?.files ?? [];
  }
}
</script>

<template>
  <div>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Your Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <p class="text-red-700 text-sm">This person will receive the updated letters, contract and / or terms and condtions for the employee.</p>
        <div class="grid grid-cols-3 gap-6">
          <div v-if="!formData?.name" class="col-span-6 mb-4">
            <p>Hiring Manager details: *</p>
            <user-search group="HM" @updated-user="handleUser($event)"></user-search>
            <span v-if="v$.formData.name.$error" class="text-red-500">
              * {{ v$.formData.name.$errors[0].$message }}
            </span>
          </div>

          <div
            v-else
            class="col-span-6 p-4 border border-gray-300 rounded-md"
          >
            <div v-if="!disableForm" class="flex w-full justify-end">
              <button @click.prevent="selectAnotherUser">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" />
              </button>
            </div>
            
            <div class="col-span-6">
              <label 
                for="name"
                class="block text-sm font-medium text-gray-700"
              >Name *</label>
              <input
                :value="formData.name"
                type="text"
                name="name"
                id="name-input"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                disabled
                @input="handleInput"
                @blur="v$.formData.name.$touch()"
              >
            </div>
            <div class="col-span-6 mt-4">
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >Email address: *</label>
              <input
                :value="formData.email"
                type="email"
                name="email"
                id="email-input"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                disabled
                @input="handleInput"
                @blur="v$.formData.email.$touch()"
              >
            </div>
          </div>
        </div>

        <!-- Hiring manger phone -->
        <div class="col-span-6">
              <label 
                for="phone"
                class="block text-sm font-medium text-gray-700"
              >Telephone number *</label>
              <input
                :value="formData.phone"
                :disabled="disableForm"
                type="text"
                name="phone"
                id="phone-input"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                @input="handleInput"
                @blur="v$.formData.phone.$touch()"
              >
              <span v-if="v$.formData.phone.$error" class="text-red-500">
                * {{ v$.formData.phone.$errors[0].$message }}
              </span>
        </div>
        <!-- Hiring manager phone end -->
        <p class="text-sm text-red-700">Please be sure to provide the correct email address as further communications, including letters, will be issued to this email account
            If an incorrect email address is provided, there could be a resulting Data Protection breach therefore it is essential that the correct
            information is provided
        </p>

        <!-- Secondary Contact -->
        <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Would you like to add a secondary contact to this form? *
              </legend>
              <span v-if="v$.formData.secondaryContact.$error" class="text-red-500">
                * {{ v$.formData.secondaryContact.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="Yes"
                        id="radio-secondary-contact-yes"
                        name="secondaryContact"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:cursor-not-allowed disabled:bg-gray-200
                        "
                        :disabled="disableForm"
                        :checked="formData.secondaryContact === 'Yes'"
                        @change="handleRadioChange"
                        @blur="v$.formData.secondaryContact.$touch()"
                    />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="secondaryContact" class="font-medium text-gray-700"
                        >Yes</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="No"
                        id="radio-secondary-contact-no"
                        name="secondaryContact"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:cursor-not-allowed disabled:bg-gray-200
                        "
                        :disabled="disableForm"
                        :checked="formData.secondaryContact === 'No'"
                        @change="handleRadioChange"
                        @blur="v$.formData.secondaryContact.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="secondaryContact" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
        </div>

        <!-- Secondary Contact info -->
        <div v-if="formData.secondaryContact === 'Yes'" class="grid grid-cols-3 gap-6">
          <div v-if="!formData.secondaryName" class="col-span-6 mb-4">
            <p>Secondary contact details *</p>
            <user-search group="HM" @updated-user="handleSecondaryUser($event)"></user-search>
            <span v-if="v$.formData.secondaryName.$error" class="text-red-500">
              * {{ v$.formData.secondaryName.$errors[0].$message }}
            </span>
          </div>

          <div
            v-else
            class="col-span-6 p-4 border border-gray-300 rounded-md"
          >
            <div v-if="!disableForm" class="flex w-full justify-end">
              <button @click.prevent="selectAnotherSecondaryUser">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" />
              </button>
            </div>
            
            <div class="col-span-6">
              <label 
                for="secondaryName"
                class="block text-sm font-medium text-gray-700"
              >Secondary contact name: *</label>
              <input
                :value="formData.secondaryName"
                type="text"
                name="secondaryName"
                id="secondary-name-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                disabled
                @input="handleInput"
              >
            </div>
            <div class="col-span-6 mt-4">
              <label
                for="secondaryEmail"
                class="block text-sm font-medium text-gray-700"
              >Secondary contact email address: *</label>
              <input
                :value="formData.secondaryEmail"
                type="email"
                name="secondaryEmail"
                id="secondary-email-input"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                disabled
                @input="handleInput"
              >
            </div>
          </div>
          <div class="col-span-6 mt-4">
              <label
                for="secondaryPhone"
                class="block text-sm font-medium text-gray-700"
              >Secondary contact telephone number: *</label>
              <input
                :value="formData.secondaryPhone"
                :disabled="disableForm"
                type="text"
                name="secondaryPhone"
                id="secondary-hiring-manager-phone-input"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                @input="handleInput"
                @blur="v$.formData.secondaryPhone.$touch()"
              >
              <span v-if="v$.formData.secondaryPhone.$error" class="text-red-500">
                * {{ v$.formData.secondaryPhone.$errors[0].$message }}
              </span>
            </div>
        </div>
        <!-- Secondary Contact info end -->
        
        <!-- Secondary Contact end -->

        <p class="text-sm text-red-700">This person will have access to the online employee amendment form and will receive update emails. The secondary contact will not
            receive any letters, contract and/or terms and conditions for the employee as these will be issued to the primary contact only.
        </p>

        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSaveForm"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserSearch from '@/components/userDropdownSearch.vue'
import { XIcon } from '@heroicons/vue/outline'
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf, email } from '@vuelidate/validators'

export default {
    props: ['formData', 'disableForm'],
    components: {
        UserSearch,
        XIcon
    },
    data() {
        return {
            model: this.formData,
            hiddenInputs: true,
            v$: useVuelidate()
        }
    },
    methods: {
      handleSecondaryUser(event) {
        this.model.secondaryName = event.Title;
        this.model.secondaryEmail = event.email.trim();
        this.v$.secondaryName.$touch();
        this.v$.secondaryEmail.$touch();
        this.$emit('update:formData', { formName: 'formOne', formData: this.model });
      },
      handleUser(event) {
        this.model.name = event.Title;
        this.model.email = event.email.trim();
        this.hiddenInputs = false,
        this.v$.formData.name.$touch();
        this.v$.formData.email.$touch();
        this.$emit('update:formData', { formName: 'formOne', formData: this.model });
      },
      selectAnotherUser() {
        this.model.name = null;
        this.model.email = null;
        this.$emit('update:formData', { formName: 'formOne', formData: this.model });
      },
      selectAnotherSecondaryUser() {
        this.model.secondaryName = null;
        this.model.secondaryEmail = null;
        this.$emit('update:formData', { formName: 'formOne', formData: this.model });
      },
      handleInput(event) {
        const { name, value } = event.currentTarget;
        this.model[name] = value;
        this.$emit('update:formData', { formName: 'formOne', formData: this.model});
      },
      handleRadioChange(event) {
        const { name, value } = event.target;
        this.model[name] = value;
        this.$emit('update:formData', { formName: 'formOne', formData: this.model });
      },
      handleSaveForm() {
        this.$emit('submit-form', { name: "formOne", formData: this.formData })
      }
    },
    validations() {
      return {
        formData: {
          name: { required },
          email: { required, email },
          phone: { required },
          secondaryContact: { required },
          secondaryName: {
            requiredIf: helpers.withMessage(
              "Please specify secondary contact's name",
              requiredIf(this.formData.secondaryContact === 'Yes')
            )
          },
          secondaryEmail: {
            requiredIf: helpers.withMessage(
              "Please specify secondary contact's email address",
              requiredIf(this.formData.secondaryContact === 'Yes')
            ),
            email
          },
          secondaryPhone: {
            requiredIf: helpers.withMessage(
              "Please specify secondary contact's telephone number",
              requiredIf(this.formData.secondaryContact === 'Yes')
            )
          }
        }
      }
    }
};
</script>

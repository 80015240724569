<template>
<form-layout
  v-if="formData"
  :navItemConfig="navItemConfig"
  :currentNavItem="currentNavItem"
  :formPoint="formPoint"
  :formData="formData"
  formName="leaverForm"
  :displayHeader="true"
  :displayTimeline="
      formData.currentStageName === 'offer.notifyMakeOffer' ||
      formData.currentStageName === 'offer.notifyOfferSent' ||
      formData.currentStageName === 'offer.complete'
    "
  @set-current-nav-item="setCurrentNavItem"
>
  <template #pageTitle>Offer</template>
  <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Name of candidate offered:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.candidateName }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentStage?.displayName }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.atr?.formOne?.hiringManagerEmail ?? formData.currentAssigneeEmail }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Status:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.status}}</dd>
      </div>
      <div class="flex justify-end">
        <div v-if="(profile.ADMIN || formData.requestedBy === profile.userID) && formData.status !== 'CANCELLED'">
          <button
            type="button"
            class="
              inline-flex
              items-center
              justify-center
              rounded-md
              border
              border-transparent
              bg-red-700
              hover:bg-red-800
              hover:underline
              px-4
              py-2
              text-sm
              font-medium
              text-white
              shadow-sm
              hover:bg-green-800
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
              sm:w-auto
            " 
            @click="cancelOffer"
          >Cancel Offer</button>
        </div>
        <div v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING" class="ml-2">
          <button
            type="button"
            class="
              inline-flex
              items-center
              justify-center
              rounded-md
              border
              border-transparent
              bg-green-700
              hover:bg-green-800
              hover:underline
              px-4
              py-2
              text-sm
              font-medium
              text-white
              shadow-sm
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
              sm:w-auto
            " 
            @click="hanldeReassign"
          >Re-assign</button>
        </div>
      </div>
  </template>
  <template #main>
    <component
        :is="currentNavItem?.component"
        :atr="formData"
        :data="formData"
        :document="formData"
        :formData="formData[currentNavItem?.name] || formData.atr[currentNavItem?.name]"
        :showApprovalSteps="disableForm"
        :disableForm="disableForm"
        :configItems="configItems"
        :configData="configItems"
        @update:formData="handleUpdateFormData"
        @submit-form="handleSubmitForm"
    />
  </template>
  <template #timeline>
      <activity-feed v-if="formData.activityList" :activityList="formData?.activityList"></activity-feed>
    </template>
</form-layout>
</template>

<script setup>
import { computed, ref, onBeforeMount, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import httpClient from '@/httpClient'
import { saveStorage } from '@/storageSave'
import activityFeed from '@/components/activityFeed.vue'
import AmendContractDetails from '@/components/approvalWorkflowForms/AmendContractDetails.vue'
import formLayout from '@/layouts/formLayout.vue'
import formOne from '@/components/create-starter/formOne.vue';
import formTwo from '@/components/create-starter/formTwo.vue';
import formThree from '@/components/create-starter/formThree.vue';
import formFour from '@/components/create-starter/formFour.vue';
import formFive from '@/components/create-starter/formFive.vue';
import formSix from '@/components/create-starter/formSix.vue';
import formSeven from '@/components/create-starter/formSeven.vue';
import formEight from '@/components/create-starter/formEight.vue';
import formNine from '@/components/create-starter/formNine.vue';
import formTen from '@/components/create-starter/formTen.vue';
import formEleven from '@/components/create-starter/formEleven.vue';
import formTwelve from '@/components/approvalWorkflowForms/firstSecondApproval.vue';
import formfifteen from '@/components/approvalWorkflowForms/preContractual.vue'
import candidateDetails from '@/components/approvalWorkflowForms/candidateDetails.vue'
import salaryOvertime from '@/components/approvalWorkflowForms/salaryOvertimeForm.vue'
import ConfirmSapDetails from '../approvalWorkflowForms/ConfirmSapDetails.vue'
const store = useStore();
const router = useRouter();
const formPoint = ref(0);
const navItemConfig = [
  {
    formPoint: 0,
    name: "sap",
    text: "Confirm Employee Details",
    component: ConfirmSapDetails,
    icon: "UserIcon"
  },
  {
    formPoint: 1,
    name: "checks",
    text: "Pre-Contractual Checks",
    component: formfifteen,
    icon: "UserIcon"
  },
  {
    formPoint: 2,
    name: "candidate",
    text: "Candidate Details",
    component: candidateDetails,
    icon: "UserIcon"
  },
  {
    formPoint: 3,
    name: "contractAmend",
    text: "Confirm Contract Details",
    component: AmendContractDetails,
    icon: "UserIcon"
  },
  {
    formPoint: 4,
    name: "salary",
    text: "Salary and Overtime Details",
    component: salaryOvertime,
    icon: "UserIcon"
  },
  {
    formPoint: 5,
    name: "formOne",
    text: "Hiring Manager & Secondary Contact Details",
    component: formOne,
    icon: "UserIcon"
  },
  {
    formPoint: 6,
    name: "formTwo",
    text: "Recruitment Reason",
    component: formTwo,
    icon: "LogoutIcon"
  },
  {
    formPoint: 7,
    name: "formThree",
    text: "Job Details",
    component: formThree,
    icon: "EmojiHappyIcon"
  },
  {
    formPoint: 8,
    name: "formFour",
    text: "Organisation Details",
    component: formFour,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 9,
    name: "formFive",
    text: "Working Hours",
    component: formFive,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 10,
    name: "formSix",
    text: "Contract Details",
    component: formSix,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 11,
    name: "formSeven",
    text: "Advertising Requirements",
    component: formSeven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 12,
    name: "formEight",
    text: "Pre Employment Checks",
    component: formEight,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 13,
    name: "formNine",
    text: "Security",
    component: formNine,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 14,
    name: "formTen",
    text: "Specialist Roles",
    component: formTen,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 15,
    name: "formEleven",
    text: "Attachments",
    component: formEleven,
    icon: "UserCircleIcon"
  },
  {
    formPoint: 16,
    name: "formTwelve",
    text: "First & Second Line Approval",
    component: formTwelve,
    icon: "DocumentTextIcon"
  },
];

const formData = ref({
  sap: {},
  checks: {},
  adminOwner: {},
  candidate: {},
  contractAmend: {},
  salary: {},
  formOne: {},
  formTwo: {},
  formThree: {},
  formFour: {},
  formFive: {},
  formSix: {},
  formSeven: {},
  formEight: {},
  formNine: {},
  formTen: {},
  formEleven: {},
  formTwelve: {},
});
const configItems = ref({});
const stages = inject('stages');

const disableForm = computed(() => currentNavItem.value?.name !== 'sap');
const profile = computed(() => store.getters.getProfile);
const currentStage = computed(() => stages.find((stage) => stage.stageName === formData.value.currentStageName))

const currentNavItem = computed(() => navItemConfig.find((item) => item.formPoint === formPoint.value));
const setCurrentNavItem = (value) => formPoint.value = value;
const handleSubmitForm = async ({ name, data, completed=false }) => {
  try {
    store.dispatch('setIsLoading', true);
    formData.value[name].saved = true;
    const payload = {
        formData: { ...data },
        requestedBy: docOwner.value,
        id: docId.value,
        completed: completed || undefined,
        stage: formData.value.currentStageName
    }
    const res = await httpClient.post(`bff/starter/${name}`, payload);
    formData.value = res.data.body.Item;
    saveStorage('formData', JSON.stringify(formData.value));
    store.dispatch('setNotificationAll', {
        visible: true,
        type: 'success',
        title: 'SAVED!',
        message: 'The form has been saved.'
    });
    if (completed) router.push('/');
    store.dispatch('setIsLoading', false);
} catch (err) {
    console.log(err)
    formData.value[name].saved = false;
    store.dispatch('setNotificationAll', {
        visible: true,
        type: 'error',
        title: 'Error!',
        message: 'There was an error submitting the form'
    });
    store.dispatch('setIsLoading', false);
}
};

const hanldeReassign = () => {
  const id = router.currentRoute.value.params.id;
  const docOwner = formData.value.requestedBy;
  router.push(`/re-assign/${id}/${docOwner}`);
};

const handleUpdateFormData = (event) => {
  formData.value[event.formName] = event.formData;
  saveStorage('formData', JSON.stringify(formData.value));
};

const docId = computed(() => router.currentRoute.value.params.id);
const docOwner = computed(() => router.currentRoute.value.params.docOwner);

const fetchFormData = async () => {
  const response = await httpClient.post('/bff/get-document', { id: docId.value, requestedBy: docOwner.value })
  formData.value = response.data.body.Item;
  saveStorage('formData', JSON.stringify(formData.value));
};
const fetchConfigItems = async () => {
  const response = await httpClient.post('/config/all', { requestedBy: 'something' });
  configItems.value = response.data.body;
}

const cancelOffer = async () => {
  store.dispatch('setIsLoading', true);
  await httpClient.post('/bff/cancel-offer', {
    requestedBy: profile.value.userID,
    id: formData.value.docId,
    docOwner: formData.value.requestedBy
  });
  router.push('/');
  store.dispatch('setIsLoading', false);
}

onBeforeMount(async () => {
  await fetchFormData();
  await fetchConfigItems();
});

</script>
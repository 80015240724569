<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Organisation Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div v-html="business_contract"></div>
        <div v-html="employing_entity"></div>
        <p class="text-sm text-red-700">*The Employing Entity will be the EQUANS company that Cost
            Centre or Cost Object (Internal Order/WBS code) relates to.
            Please check with your local Finance support if you are unsure of
            this
        </p>
        <div v-html="division"></div>
        <div v-html="cost_center"></div>
        <p class="text-sm text-red-700">*Please ensure this Cost Code has been validated by your local
            Finance support before submitting this form
        </p>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { Input } from "@/form";
import { defineProps } from "vue";

const props = defineProps(['formData', 'disableForm']);

const business_contract = Input(
  "What is the name of the Business Contract that this role will be working on? *",
  "business_contract",
  "text",
  props.disableForm,
  props.formData.businesscontact
);

const employing_entity = Input(
  "Which employing entity will this role be employed in? *",
  "employing_entity",
  "text",
  props.disableForm,
  props.formData.employedin
);

const division = Input(
  "Which division will this role be employed in?  *",
  "division",
  "text",
  props.disableForm,
  props.formData.division
);

const cost_center = Input(
  "Which cost centre will this role be assigned to? *",
  "cost_center",
  "text",
  props.disableForm,
  props.formData.assigned
);

// const sector = Input(
//   "Which sector will this role be employed in? *",
//   "sector",
//   "text",
//   props.disableForm
// );

// const cost_object = Input(
//   "If required, which Cost Object (WBS Code or Internal Order Number) will this role be assigned to? *",
//   "cost_object",
//   "text",
//   props.disableForm
// );
</script>
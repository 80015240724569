<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Security
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div v-html="security_role"></div>
            <div v-html="sia"></div>
            <div v-html="security_check"></div>
            <div v-html="preliminary_check"></div>
            <p class="text-sm text-red-700">Please note a start date cannot be confirmed unless the preliminary checks are complete and we may need to return the
                ATR to you whilst the checks are being completed.
            </p>
            <div v-html="edf_only"></div>
            <div v-html="clearance_required"></div>
            <div v-html="dwp_only"></div>
            <div v-html="mobile_patrol"></div>
            <div v-html="start_before"></div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { Input } from "@/form";
import { defineProps } from 'vue'

const props = defineProps(['formData', 'disableForm']);

const security_role = Input(
  "Is this a Security Role?",
  "security_role",
  "text",
  props.disableForm,
  props.formData.securityRole
);

const sia = Input(
  "Is a Security Industry Authority Licence (SIA) Required?",
  "sia",
  "text",
  props.disableForm,
  props.formData.securitySIA
);

const security_check = Input(
  "Is a Security Check (BS7858) Required?",
  "security_check",
  "text",
  props.disableForm,
  props.formData.securityCheck
);

const preliminary_check = Input(
  "Have the preliminary checks been carried out for BS7858 Security Clearance?",
  "preliminary_check",
  "text",
  props.disableForm,
  props.formData.securityPreliminary
);

const edf_only = Input(
  "Is a Security Baseline Standard Verification Record (BSVR) Check Required? (EDF Only)?",
  "edf_only",
  "text",
  props.disableForm,
  props.formData.securityBSVR
);

const clearance_required = Input(
  "Is Government Level Clearance Required?",
  "clearance_required",
  "text",
  props.disableForm,
  props.formData.securityGovernment
);

const dwp_only = Input(
  "Is a specific DWP Baseline Security Standard (BPSS) Check Required? (DWP Only)?",
  "dwp_only",
  "text",
  props.disableForm,
  props.formData.securityDWP
);

const mobile_patrol = Input(
  "Is this a Security Role Carrying Out Mobile Patrols?",
  "mobile_patrol",
  "text",
  props.disableForm,
  props.formData.securityMobile
);

const start_before = Input(
  "Can Employee Start Before Checks Complete?",
  "mobile_patrol",
  "text",
  props.disableForm,
  props.formData.securityBefore
);

</script>

<template>
    <!--        OutstandingWork.vue component - Displays outstanding Offers, Amends and Leavers in full detail per user      -->
    <h1 class="text-2xl font-semibold text-gray-900 hidden md:block py-4">Outstanding Work</h1>
    <section>
        <div class="table-border-padding">
        <div class="border rounded-lg min-w-[900px]">
            <table class="min-w-full text-center text-sm">

            <thead>
                <tr class="divide-x divide-gray-200">
                    <th scope="col" colspan="6" rowspan="2" class="border-b py-2 font-normal text-sm">
                        Employee Email
                    </th>
                    <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                        Outstanding Offers
                    </th>
                    <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                        Outstanding Amends
                    </th>
                    <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                        Outstanding Leavers
                    </th>
                </tr>
                <tr class="divide-x border-b divide-gray-200">
                    <th class="whitespace-nowrap px-3 py-2 border-l bg-green-100 bg-opacity-50">1</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">2</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">3</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">4</th>
                    <th class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">5+</th>
                    <th class="whitespace-nowrap py-2 px-2">Total</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">1</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">2</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">3</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">4</th>
                    <th class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">5+</th>
                    <th class="whitespace-nowrap py-2 px-2">Total</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">1</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">2</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">3</th>
                    <th class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">4</th>
                    <th class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">5+</th>
                    <th class="whitespace-nowrap py-2 px-2">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-b">
                    <td class="py-1"></td>
                </tr>
                <tr 
                    v-for="(user, index) in Object.keys(users)"
                    :key="index"
                    class="divide-x divide-y border-b divide-gray-200">
                    <td colspan="6" class="font-light">
                        <p>{{ user }}</p> 
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].OFFER[1] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].OFFER[2] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].OFFER[3] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].OFFER[4] }}</td>
                    <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].OFFER[5] }}</td>
                    <td class="whitespace-nowrap py-2">{{ sum(users[user].OFFER) }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].AMEND[1] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].AMEND[2] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].AMEND[3] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].AMEND[4] }}</td>
                    <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].AMEND[5] }}</td>
                    <td class="whitespace-nowrap py-2">{{ sum(users[user].AMEND) }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].LEAVER[1] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].LEAVER[2] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].LEAVER[3] }}</td>
                    <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].LEAVER[4] }}</td>
                    <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].LEAVER[5] }}</td>
                    <td class="whitespace-nowrap py-2">{{ sum(users[user].LEAVER) }}</td>
                </tr>
                <tr class="border-b">
                    <td class="py-1"></td>
                </tr>
                <tr class="divide-x divide-y divide-gray-200">
                    <td colspan="6" class="font-normal">
                        <a href="#">Totals</a> 
                    </td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-green-100 bg-opacity-50">{{ totals(1, 'OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-lime-100 bg-opacity-50">{{ totals(2, 'OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-yellow-100 bg-opacity-50">{{ totals(3, 'OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-orange-100 bg-opacity-50">{{ totals(4, 'OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold px-2 py-2 bg-red-100 bg-opacity-50">{{ totals(5, 'OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold py-2">{{ overallTotals('OFFER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-green-100 bg-opacity-50">{{ totals(1, 'AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-lime-100 bg-opacity-50">{{ totals(2, 'AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-yellow-100 bg-opacity-50">{{ totals(3, 'AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-orange-100 bg-opacity-50">{{ totals(4, 'AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold px-2 py-2 bg-red-100 bg-opacity-50">{{ totals(5, 'AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold py-2">{{ overallTotals('AMEND') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-green-100 bg-opacity-50">{{ totals(1, 'LEAVER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-lime-100 bg-opacity-50">{{ totals(2, 'LEAVER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-yellow-100 bg-opacity-50">{{ totals(3, 'LEAVER') }}</td>
                    <td class="whitespace-nowrap font-bold px-3 py-2 bg-orange-100 bg-opacity-50">{{ totals(4, 'LEAVER') }}</td>
                    <td class="whitespace-nowrap font-bold px-2 py-2 bg-red-100 bg-opacity-50">{{ totals(5, 'LEAVER') }}</td>
                    <td class="whitespace-nowrap font-bold py-2">{{ overallTotals('LEAVER') }}</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
    </section>
</template>

<script>
export default {
    props: ['users'],
    methods: {
        sum(data = []) {
            return data?.reduce((accumulator, currentValue) => {  return accumulator + currentValue},0) ?? 0;
        },
        totals(sla=1, type='OFFER') {
            let total = 0;
            for (const user of Object.keys(this.users)){
                total += this.users[user][type][sla]
            }
            return total
        },
        overallTotals(type='OFFER') {
            let total = 0;
            for (const user of Object.keys(this.users)){
                total += this.sum(this.users[user][type])
            }
            return total
        }
    },
}
</script>
<style scoped>
@media only screen and (max-width: 1300px) {
    .table-border-padding {
        width: 950px;
        padding-right: 50px;
    }
}
</style>
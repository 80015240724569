<template>
  <div class="p-12">
    <!-- Page title-->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Complete Leaver
      </h3>
    </div>
    <!-- End of page title-->

    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="p-8">
            <p>You will not be able to change this request once it has been submitted. Are you sure you would like to complete this leaver request?</p>
              <div class="mt-4 flex justify-end">
                <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click="handleCompleteUser">Complete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import httpClient from '@/httpClient';
export default {
  data() {
    return {
      formData: {}
    }
  },
  methods: {
    async handleCompleteUser() {
      try {
        const docId = this.$route.params.docId;
        const docOwner = this.$route.params.docOwner;
        this.$store.dispatch('setIsLoading', true);
        await httpClient.post('bff/complete-request', { docId, docOwner, state: this.formData.currentStageName })
        this.$store.dispatch('setIsLoading', false);
        this.$router.push('/');
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Successfully complete leaver request'
        });
      } catch (err) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was an error completing the leaver request.'
        });
      }
      
    },
    async fetchSingleAtr(id) {
      this.$store.dispatch('setIsLoading', true);
      const requestedBy = this.$route.params.docOwner;
      const payload = { requestedBy, id }
      const response = await httpClient.post('/bff/get-document', payload);
      const body = response.data.body;
      this.formData = body.Item;
      this.$store.dispatch('setIsLoading', false);
    },
    async completeDoc () {
      const docId = this.$route.params.docId;
      const docOwner = this.$route.params.docOwner;
      this.$store.dispatch('setIsLoading', true);
      await httpClient.post('/bff/complete-request', {
        docOwner: docOwner,
        docId: docId,
        state: this.formData.currentStageName,
      });
      this.$router.push('/');
      this.$store.dispatch('setIsLoading', false);
    }
  },
  computed: {
    docId() {
      return this.$route.params.docId;
    }
  },
  async mounted() {
    await this.fetchSingleAtr(this.docId)
  }
}
</script>
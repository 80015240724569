<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Additional Work Requirements
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div class="mt-4">
          <CheckList
            :options="additionalWorkRequirementsOptions"
            :modelValue="formData?.additionalRequirements || []"
            label="Based on the role and location select the relevant options below:"
            name="additionalRequirements"
            @update:modelValue="handleChecklistChange"
          />
        </div>

        <div class="grid sm:border-t sm:border-gray-200 sm:pt-5 mt-4" v-if="atr?.formEight?.disclosureCheckRequired === 'Yes'">
            <fieldset>              
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  DBS Certificate
                </h3>
              </div>
              <div class="mt-4">
                <p>Has the Employee presented you with the Disclosure Certificate confirming the outcome of their DBS/DS check with EQUANS?
                  Note: this must be an EQUANS Disclosure Check *</p>
              </div>

              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-certificate-check-no"
                      name="certificate"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :checked="formData.certificate === 'No'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="recruitment-certificate-check-no"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-certificate-check-yes"
                      name="certificate"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :checked="formData.certificate === 'Yes'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="recruitment-certificate-check-yes"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
              </div>
            
            <p class="text-sm text-red-700 mt-4">Please remember to highlight to your HR Manager where a certificate highlights any 
              concerns and please also note that you must not retain copies of Disclosure Certificates 
              for more than 6 months.
            </p>

            <template v-if="formData.certificate === 'Yes'">
              <!-- Document upload -->
              <FileUploader
                  name="dbs-certificate"
                  :files="formData.files"
                  :disabled="disableForm"
                  :documentShortId="atr?.shortId"
                  @updateFiles="handleUpdateFiles"
                  :uploading="uploading"
                  @fileUploading="uploading = $event"
                  class="mt-4"
                />
              <!-- End Document upload -->

              <div>
                <label for="date_of_issue" class="block text-sm font-medium text-gray-700 mt-2">Date of issue</label>
                <div class="flex mt-1 rounded-md shadow-sm">
                  <input
                    :value="formData.date_of_issue"
                    :disabled="disableForm"
                    type="date"
                    name="date_of_issue"
                    id="date_of_issue-input"
                    class="
                      flex-1
                      block
                      w-full
                      focus:ring-indigo-500 focus:border-indigo-500
                      min-w-0
                      rounded-md
                      sm:text-sm
                      border-gray-300
                      disabled:bg-gray-200
                      disabled:cursor-not-allowed
                      disabled:text-gray-700
                    "
                    @input="handleInput"
                  />
                </div>
              </div>
            </template>

            </fieldset>
          </div>

        
          <div class="mt4 sm:mt-0 sm:col-span-2">
            <label for="comments" class="block text-sm font-medium text-gray-700">
              Additional Comments:
            </label>
            <textarea
              :value="formData.offer_comments"
              id="offer_comments-input"
              name="offer_comments"
              rows="3"
              class="
                shadow-sm
                focus:ring-indigo-500
                focus:border-indigo-500
                block w-full
                sm:text-sm
                border
                border-gray-300
                rounded-md
              " 
              @input="handleInput"
            /> 
          </div>

        <div v-if="forReapproval" class="p-3 w-full flex flex-row items-center">
          <ExclamationIcon class="text-red-600 h-16 w-16" />
          <p class="text-red-600 ml-2">
            Once submitted, this document will go for re-approval because the terms have changed.
          </p>
        </div>
        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="document.currentStageName === 'offer.notifyPrepOffer'">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="submitForm"
        >
          Save
        </button>
        <button
          :disabled="disableComplete"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="completeOffer"
        >
          Complete Offer
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import CheckList from "@/components/CheckList.vue";
import { ExclamationIcon } from '@heroicons/vue/solid'
import FileUploader from '@/components/FileUploader.vue'

export default {
  props: ['formData', 'disableForm', 'atr', 'forReapproval', 'document'],
  emits: ['update:formData', 'submit-form', 'complete-offer'],
  components: { CheckList, ExclamationIcon, FileUploader },
  data() {
    return {
      uploading: false,
      model: this.formData,
      additionalWorkRequirementsOptions: [
      'Regular twisting, lifting, bending',
      'Working at heights',
      'Working in confined spaces',
      'Exposure to heat',
      'Exposure to cold',
      'Work nights',
      'Shiftworker',
      'Works with powered vibrating tools',
      'Driving duties e.g. FLT',
      'Lone working',
      'Manual handling',
      'Safety critical role',
      'Prolonged standing',
      'Prolonged sitting',
      'High stress content',
      'Exposure to noise',
      'Respiratory sensitisers',
      'Skin sensitisers',
      'Works with food',
      'Works with bodily fluids'
      ]
    }
  },
  computed: {
    disableComplete() {
      const keys = [
        'candidate',
        'contractAmend',
        'salary',
        'workingHours',
        'startDetails',
        'additionalRequirements'
      ];
      let disabled = false;
      keys.forEach((key) => {
        if (!this.document[key].saved) {
          disabled = true;
        }
      });
      return disabled;
    },
  },
  methods: {
    handleUpdateFiles({ model }) {
      this.model.files = model;
      this.$emit('update:formData', { formName: 'additionalRequirements', formData: this.model });
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'additionalRequirements', formData: this.model })
    },
    handleChecklistChange(event) {
      const { name, value } = event;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'additionalRequirements', formData: this.model });
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'additionalRequirements', formData: this.model });
    },
    submitForm() {
      this.$emit('submit-form', { name: 'additionalRequirements', formData: this.model })
    },
    completeOffer() {
      this.$emit('complete-offer')
    }
  },
  mounted() {
    this.files = this.atr.additionalRequirements?.files ?? [];
  }
}

</script>

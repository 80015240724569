<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
        >
          <div
            class="
              overflow-hidden
              shadow
              border border-gray-200
              md:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-300 border-b-1 border-b-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    colspan="2"
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Profile
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- Start row - Id -->
                <tr>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    ID
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-for="id in userProfile.identities" :key="id">
                      {{ id.userId }}
                      <span v-if="userProfile.identities.indexOf(id) < userProfile.identities.length - 1">
                        ,<br>
                      </span>
                    </span>
                  </td>
                </tr>
                <!-- End row - Id -->
                <!-- Start row - Name -->
                <tr>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Name
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ userProfile.given_name }} {{ userProfile.family_name }}
                  </td>
                </tr>
                <!-- End row - Name -->
                <!-- Start row - Email -->
                <tr>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Email Address
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ userProfile.email }}
                  </td>
                </tr>
                <!-- End row - Email -->
                <!-- Start row - Email -->
                <tr>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Roles
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="userProfile.H2R">Hire 2 Retire<br></span>
                    <span v-if="userProfile.ADMIN">Admin<br></span>
                    <span v-if="userProfile.HROPS">HROPS<br></span>
                    <span v-if="userProfile.HM">Hiring Manager<br></span>
                    <span v-if="userProfile.RESOURCING">Resourcing</span>
                  </td>
                </tr>
                <!-- End row - Email -->
                <!-- Start row - Mailing preferences -->
                <tr>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Mailing preferences
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ userProfile.emailPrefs }}
                  </td>
                </tr>
                <!-- End row - Mailing preferences -->
              </tbody>
            </table>
            <div class="pl-5 mt-4 pt-4 pb-4 border border-grey-200 border-t-1 border-l-0 border-r-0 border-b-0">
              <label class="text-base font-medium text-gray-900">Mailing preferences</label>
              <p class="text-sm leading-5 text-gray-500">How do you prefer to receive emails?</p>
              <fieldset class="mt-4">
                <legend class="sr-only">Mailing preferences</legend>
                <div class="space-y-4">
                  <div class="flex items-center">
                    <input value="NO-EMAILS" id="email-prefs-none" name="notification-method" type="radio" @change="onChange($event)" :checked="'NO-EMAILS' === userProfile.emailPrefs" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                    <label for="notification-method" class="ml-3 block text-sm font-medium text-gray-700">
                      NO EMAILS
                    </label>
                  </div>
                  <div class="flex items-center">
                    <input value="INSTANT" id="email-prefs-instant" name="notification-method" type="radio" @change="onChange($event)" :checked="['INSTANT', 'NONE'].includes(userProfile.emailPrefs)" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                    <label for="notification-method" class="ml-3 block text-sm font-medium text-gray-700">
                      INSTANT
                    </label>
                  </div>
                </div>
              </fieldset>
              <button
                  type="submit"
                  class="
                  mt-5
                    bg-green-500
                    border border-transparent
                    rounded-md
                    shadow-sm
                    py-2
                    px-4
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    text-white
                    hover:bg-green-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:text-gray-400
                  "
                  @click="updateMailing"
                >
                  Save
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Auth } from 'aws-amplify'
import httpClient from '@/httpClient'

const store = useStore();

store.dispatch('setIsLoading', true);

const user = ref({});

const updateMailing = async() => {
  try {
    const userID = Auth.user.signInUserSession.idToken.payload.identities[0].userId.split('@')[0]
    const response = await httpClient.post(
        '/users/update-user',
        { userID: userID, emailPrefs: mailing.value }
    );
    user.value = response.data.body.Attributes
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'success',
      title: 'Success!',
      message: 'Email Preferences updated.'
    });
  } catch (err) {
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'error',
      title: 'ERROR!',
      message: 'Could not get user profile data.'
    });
  }
}

const userProfile = computed(() => {
  return {
    ...Auth?.user.signInUserSession.idToken.payload,
    ...user.value
  }
});

const mailing = ref(userProfile.value.emailPrefs);

const onChange = function(event) {
  mailing.value = event.target.value
}

const fetchUser = async () => {
  try {
    const userID = Auth.user.signInUserSession.idToken.payload.identities[0].userId.split('@')[0]
    const response = await httpClient.post(
      'users/user',
      { userID }
    );
    user.value = response.data.body.Item
  } catch (err) {
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'error',
      title: 'ERROR!',
      message: 'Could not get user profile data.'
    });
  }
};

onBeforeMount(async () => {
  await fetchUser();
});
onMounted(() => store.dispatch('setIsLoading', false));
watch(() => userProfile.value.emailPrefs, (newVal) => mailing.value = newVal);
</script>

<template>
  <form @submit="submitForm">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Hiring Manager & Secondary Contact Details
          </h3>
        </div>
        <div>
        
        <div class="grid grid-cols-3 gap-6">
          <p class="block text-sm font-medium text-gray-700">Hiring manager *</p>
          <div v-if="!hiring_manager" class="col-span-6 mb-4">
            <user-search group="HM" @updated-user="handleUser($event, 'primary')"></user-search>
          </div>
          <div
            v-else
            class="col-span-6"
            :class="{ 'p-4 border border-gray-300 rounded-md' : !showApprovalSteps }"
          >
            <div v-if="!showApprovalSteps" class="flex w-full justify-end">
              <button @click.prevent="handleClearUser('primary')">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" />
              </button>
            </div>
            
            <div class="col-span-6">
              <label 
                for="hiringManagerName"
                class="block text-sm font-medium text-gray-700"
              >Hiring manager name: *</label>
              <input
                :value="formData.hiringManagerName"
                type="text"
                name="hiringManagerName"
                id="hiring-manager-name-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                disabled
                @input="handleInput"
                @blur="v$.formData.hiringManagerName.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.hiringManagerName.$errors.length" class="text-red-500">
                * {{ v$.formData.hiringManagerName.$errors[0].$message }}
              </span>
            </div>
            <div class="col-span-6 mt-4">
              <label
                for="hiringManagerEmail"
                class="block text-sm font-medium text-gray-700"
              >Hiring Manager email address: *</label>
              <input
                :value="formData.hiringManagerEmail"
                type="email"
                name="hiringManagerEmail"
                id="hiring-manager-email-input"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                disabled
                @input="handleInput"
                @blur="v$.formData.hiringManagerEmail.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.hiringManagerEmail.$errors.length" class="text-red-500">
                * {{ v$.formData.hiringManagerEmail.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>

        <!-- add a secondary contact  -->
        <fieldset class="mt-4">
            <legend class="text-base font-medium text-gray-900">
              Would you like to add a secondary contact to this form? *
            </legend>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="yes"
                    id="radio-button-replacement-role"
                    name="secondary"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :checked="formData.secondary === 'yes'"
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : showApprovalSteps }"
                    :disabled="showApprovalSteps"
                    @blur="v$.formData.secondary.$touch()"
                    @change="handleInput"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="yes" class="font-medium text-gray-700">Yes</label>
                </div>
              </div>
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="no"
                      id="radio-button-candidates"
                      name="secondary"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :checked="formData.secondary === 'no'"
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      @blur="v$.formData.secondary.$touch()"
                      @change="handleInput"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="no" class="font-medium text-gray-700">No</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- End of add a secondary contact  -->


        <div v-if="formData.secondary === 'yes'" class="grid grid-cols-3 gap-6 sm:pt-5 mt-4">
          <p class="text-md">Secondary contact</p>
          <div v-if="!formData.secondaryName" class="col-span-6 mb-4">
            <user-search group="HM" @updated-user="handleUser($event, 'secondary')"></user-search>
          </div>
          <div class="col-span-6 p-4 border border-gray-300 rounded-md flex flex-col" v-else>
            <div v-if="!showApprovalSteps" class="flex w-full justify-end">
              <button @click.prevent="handleClearUser('secondary')">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" />
              </button>
            </div>
            <div>
              <label
                for="secondaryName"
                class="block text-sm font-medium text-gray-700"
              >What is the employee name for your contact? *:</label>
              <input
                :value="formData.secondaryName"
                type="text"
                name="secondaryName"
                id="secondary-name-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:border-none disabled:cursor-not-allowed"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                disabled
                @input="handleInput"
                @blur="v$.formData.secondaryName.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.secondaryName.$errors.length" class="text-red-500">
                * {{ v$.formData.secondaryName.$errors[0].$message }}
              </span>
            </div>
            <div class="col-span-6 mt-4">
              <label
                for="secondaryEmail"
                class="block text-sm font-medium text-gray-700"
              >What is their email address? *:</label>
              <input
                :value="formData.secondaryEmail"
                type="email"
                name="secondaryEmail"
                id="secondary-email-input"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:border-none disabled:cursor-not-allowed"
                disabled
                @input="handleInput"
                @blur="v$.formData.secondaryEmail.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.secondaryEmail.$errors.length" class="text-red-500">
                * {{ v$.formData.secondaryEmail.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>

        </div>
      </div>
      <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, requiredIf } from "@vuelidate/validators"
import { XIcon } from '@heroicons/vue/outline'
import UserSearch from '@/components/userDropdownSearch.vue'

export default {
  name: 'FormOne',
  props: ['formData', 'showApprovalSteps'],
  components: {
    UserSearch,
    XIcon,
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
    }
  },
  methods: {
    handleUser(event, type) {
        const { Title, userID, email } = event;
        if (type === 'primary') {
            this.model.hiringManagerName = Title;
            this.model.hiringManagerId = userID;
            this.model.hiringManagerEmail = email.trim();
        } else {
            this.model.secondaryName = Title;
            this.model.secondaryId = userID;
            this.model.secondaryEmail = email.trim();
        }
      this.$emit('update:formData', { formName: 'formOne', formData: this.model});
    },
    handleClearUser(type) {
        if (type === 'primary') {
            this.model.hiringManagerName = '';
            this.model.hiringManagerId = '';
            this.model.hiringManagerEmail = '';
        } else {
            this.model.secondaryName = '';
            this.model.secondaryId = '';
            this.model.secondaryEmail = '';
        }
      this.$emit('update:formData', { formName: 'formOne', formData: this.model});
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formOne', formData: this.model});
    },
    submitForm(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit('submit-form', { name: "formOne", formData: this.formData })
      } else {
        this.store.dispatch("setMessage", {
          type: 'error',
          title: 'ERROR!',
          message: 'Please fill out all the required fields'
        })
      }
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formOne');
    },
    // delete currently selected user to search for another
    selectAnotherUser() {
      this.model.hiringManagerName = '';
      this.model.hiringManagerEmail = '';
      this.hiring_manager = false;
      this.$emit('update:formData', { formName: 'formOne', formData: this.model});
    }
  },
  computed: {
    hiring_manager() {
      return !!this.model.hiringManagerName && this.model.hiringManagerEmail;
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  validations() {
    return {
      formData: {
        hiringManagerName: {
          required
        },
        hiringManagerEmail: {
          required,
          email
        },
        secondary: {
          required
        },
        secondaryName: {
          requiredIf: helpers.withMessage('This field is required', requiredIf(this.formData.secondary === 'yes'))
        },
        secondaryEmail: {
          requiredIf: helpers.withMessage('This field is required', requiredIf(this.formData.secondary === 'yes')),
          email
        }
      }
    }
  },
}
</script>

<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Recruitment Reason
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <!-- reason -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label for="reason" class="block text-sm font-medium text-gray-700"
              >What is the reason for recruiting this role? *</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="reason"
                id="reason"
                :value="formData?.recruitmentReason"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
          </div>
        </div>
        <!-- End reason -->

        <!-- justification -->
        <!-- <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="justification"
              class="block text-sm font-medium text-gray-700"
              >What is the business justification for recruiting?</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="justification"
                id="justification"
                v-model="formData.justification"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
          </div>
        </div> -->
        <!-- End justification -->

        <!-- Budget -->
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label for="budget" class="block text-sm font-medium text-gray-700"
              >Is this role in budget?</label
            >
            <div class="mt-1">
              <input
                type="text"
                name="budget"
                id="budget"
                :value="formData?.inBudget"
                :disabled="disableForm"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
              />
            </div>
          </div>
        </div>
        <!-- End Budget -->

        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "recruitmentReason",
  props: ['formData', 'disableForm'],
};
</script>

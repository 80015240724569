<template>
  <form-layout
    :navItemConfig="currentStage?.stageName === 'offer.notifyTermsHaveChanged' ? navItemConfigChanges : navItemConfig"
    :currentNavItem="currentNavItem"
    :formPoint="formPoint"
    :formData="formData"
    :hideAction="hideAction && !currentUserIsAssigned"
    :displayHeader="showApprovalSteps"
    :displayTimeline="showApprovalSteps"
    :displayFooter="false"
    @set-current-nav-item="setCurrentNavItem"
  >
    <template #pageTitle>Approval to Recruit (ATR)</template>
    <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Name of candidate offered:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.candidateName }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentStage.displayName }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.atr?.formOne?.hiringManagerEmail ?? formData.currentAssigneeEmail }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Status:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.status}}</dd>
      </div>
      <div class="flex flex-row justify-end w-full">
        <div v-if="currentStage?.stageName === 'offer.notifyOfferSent' && profile?.H2R">
          <p>Please confirm that the offer has been returned and can now be closed</p>
          <button
            type="button"
            class="
              inline-flex
              items-center
              justify-center
              rounded-md
              border
              border-transparent
              bg-green-700
              hover:bg-green-800
              hover:underline
              px-4
              py-2
              text-sm
              font-medium
              text-white
              shadow-sm
              hover:bg-green-800
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
              sm:w-auto
            " 
            @click="confirmOffer"
          >Confirm</button>
        </div>
        <div v-if="(profile?.ADMIN || formData.requestedBy === profile?.userID) && formData.status !== 'CANCELLED'" class="ml-2">
          <button
            type="button"
            class="
              inline-flex
              items-center
              justify-center
              rounded-md
              border
              border-transparent
              bg-red-700
              hover:bg-red-800
              hover:underline
              px-4
              py-2
              text-sm
              font-medium
              text-white
              shadow-sm
              hover:bg-green-800
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
              sm:w-auto
            " 
            @click="cancelOffer"
          >Cancel Offer</button>
        </div>
        <div v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING" class="ml-2">
          <button
            type="button"
            class="
              inline-flex
              items-center
              justify-center
              rounded-md
              border
              border-transparent
              bg-green-700
              hover:bg-green-800
              hover:underline
              px-4
              py-2
              text-sm
              font-medium
              text-white
              shadow-sm
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
              sm:w-auto
            " 
            @click="hanldeReassign"
          >Re-assign</button>
        </div>
      </div>
    </template>
    <template #navFooter>
      <button
          v-if="currentStage?.stageName === 'offer.notifyTermsHaveChanged' && profile.userID === formData.requestedBy"
          type="button"
          class="
            w-full
            inline-flex
            items-center
            justify-center
            rounded-md
            border
            border-transparent
            bg-green-700
            hover:bg-green-800
            hover:underline
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-800
            focus:outline-none
            focus:ring-2
            focus:ring-indigo-500
            focus:ring-offset-2
          " 
          @click="handleReapprove"
        >Send for Re-Approval</button>
    </template>
    <template #main>
      <component
        v-if="formData.atr"
        :is="currentNavItem.component"
        :atr="formData.atr"
        :atrReadyToSubmit="atrReadyToSubmit"
        :configData="configData"
        :configItems="configData"
        :document="formData"
        :formData="formData[currentNavItem.name] ? formData[currentNavItem.name] : formData.atr[currentNavItem.name]"
        :data="formData.atr"
        :showApprovalSteps="currentStage?.stageName !== 'offer.notifyTermsHaveChanged'"
        :disableForm="disableForm"
        :status="this.formData.status"
        :forReapproval="forReapproval"
        @update:formData="handleUpdateFormData"
        @update:reset="handleResetFormData"
        @submit-form="handleSubmitForm"
        @complete-offer="handleCompleteOffer"
      ></component>
      <div class="lg:hidden">
        <button
          class="
            w-full
            text-center
            px-4
            py-2
            rounded-md
            text-white
            bg-green-700
            hover:underline
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-cyan-500
            bg-no-repeat
          "
          @click="openApprovalModal"
        >
          Action
        </button>
      </div>
    </template>
    <template #timeline>
      <activity-feed v-if="formData.activityList" :activityList="formData?.activityList"></activity-feed>
    </template>
    <template #footer>
      <approval-steps v-if="formData.shortId" :formData="formData" docType="STARTER" @comment="fetchSingleAtr(formData.docId)"></approval-steps>
    </template>
  </form-layout>
  <!-- Modal -->
  <ModalWindow v-if="showModal" @close="handleModalClose">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>ATR Approval</template>
    <template v-slot:subtitle
      >What would you like to do with this ATR?</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="
          inline-flex
          justify-center
          w-full
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          my-2
          bg-green-600
          text-base
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          sm:text-sm
        "
        @click="handleApproveAtr"
      >
        Approve
      </button>
    </template>
  </ModalWindow>
</template>
<script>
import { useStore } from "vuex";
import httpClient from '@/httpClient'
import { getStorage, saveStorage } from '@/storageSave'
import { CheckIcon } from "@heroicons/vue/outline";
import { Auth } from 'aws-amplify'
import formLayout from '@/layouts/formLayout.vue'
import ActivityFeed from '@/components/activityFeed.vue'
import AdditionalWork from "@/components/approvalWorkflowForms/additionalWork.vue";
import ApprovalSteps from './create-starter/approvalSteps.vue'
import AmendContractDetails from '@/components/approvalWorkflowForms/AmendContractDetails.vue'
import formOne from './create-starter/formOne.vue'
import CandidateDetails from './approvalWorkflowForms/candidateDetails.vue'
import firstSecondLineApproval from '@/components/approvalWorkflowForms/firstSecondApproval.vue'
import formTwo from './create-starter/formTwo.vue'
import formThree from './create-starter/formThree.vue'
import formFour from './create-starter/formFour.vue'
import formFive from './create-starter/formFive.vue'
import formSix from './create-starter/formSix.vue'
import formSeven from './create-starter/formSeven.vue'
import formEight from './create-starter/formEight.vue'
import formNine from './create-starter/formNine.vue'
import formTen from './create-starter/formTen.vue'
import formEleven from './create-starter/formEleven.vue'
import formTwelve from './create-starter/formTwelve.vue'
import ModalWindow from "@/components/ModalWindow.vue";
import SalaryOvertimeForm from '@/components/approvalWorkflowForms/salaryOvertimeForm.vue'
import StartDetails from "@/components/approvalWorkflowForms/startDetails.vue"
import outlineCreditCard from './icons/outlineCreditCard.vue'
import outlineKey from './icons/outlineKey.vue'
import outlineUserGroup from './icons/outlineUserGroup.vue'
import outlineViewGridAdd from './icons/outlineViewGridAdd.vue'
import WorkingHours from '@/components/approvalWorkflowForms/workingHours'
export default {
  name: "ConfirmOffer",
  components: {
    AdditionalWork,
    AmendContractDetails,
    CheckIcon,
    CandidateDetails,
    formLayout,
    ActivityFeed,
    ApprovalSteps,
    firstSecondLineApproval,
    formOne,
    formTwo,
    formThree,
    formFour,
    formFive,
    formSix,
    formSeven,
    formEight,
    formNine,
    formTen,
    formEleven,
    formTwelve,
    ModalWindow,
    SalaryOvertimeForm,
    StartDetails,
    outlineCreditCard,
    outlineKey,
    outlineUserGroup,
    outlineViewGridAdd,
    WorkingHours
  },
  data() {
    return {
        store: useStore(),
        formPoint: 0,
        showModal: false,
        atrId: null,
        atrShortId: null,
        configData: {},
        formData: {
          formOne: {
            hiringManagerName: "",
            hiringManagerEmail: "",
            employeeName: "",
            employeeEmail: "",
            saved: false
          },
          candidate: {
            saved: false
          },
          salary: {
            saved: false
          },
          workingHours: {
            workingWeek: {
              monday: {
                start: '',
                end: '',
                break: '',
              },
              tuesday: {
                start: '',
                end: '',
                break: '',
              },
              wednesday: {
                start: '',
                end: '',
                break: '',
              },
              thursday: {
                start: '',
                end: '',
                break: '',
              },
              friday: {
                start: '',
                end: '',
                break: '',
              },
              saturday: {
                start: '',
                end: '',
                break: '',
              },
              sunday: {
                start: '',
                end: '',
                break: '',
              },
            }
          },
          startDetails: {},
          additionalRequirements: {
            date_of_issue: '',
            offer_comments: ''
          },
          formTwo: {
            recruitmentReason: "",
            inBudget: "",
            employee_jobTitle: '',
            employee_id: '',
            employee_name: '',
            salary_basis: '',
            rate: '',
            recovered: '',
            saved: false
          },
          formThree: {
            jobTitle: "",
            numberOfPositions: "",
            roleLocationSite: "",
            roleLocationPostcode: "",
            employeeType: "",
            employmentContract: "",
            duration: '',
            secondment: '',
            saved: false
          },
          formFour: {
            businesscontact: null,
            employedin: "",
            division: "",
            assigned: "",
            saved: false
          },
          formFive: {
            hoursPerWeek: "",
            weeksPerYear: "",
            shiftPattern: [],
            additionalInformation: "",
            saved: false
          },
          formSix: {
            contractType: "",
            contractLevel: "",
            salaryBasis: "",
            minimumPayRate: "",
            minimumPayRateCurrency: "GBP",
            maximumPayRate: "",
            maximumPayRateCurrency: "GBP",
            additionalAllowances: "",
            additionalAllowancesInformation: [
              {
                additionalAllowancesTitle: "",
                additionalAllowancesInformation: "",
              }
            ],
            additionalAllowancesTitle: "",
            payrollFrequency: "",
            saved: false
          },
          formSeven: {
            recruitmentTeam: "",
            chosen_not: null,
            advertise: "selected",
            channels: [],
            channelsOther: "",
            recruitmentShortlist: "",
            saved: false
          },
          formEight: {
            disclosureCheckRequired: "",
            disclosureSchemeEnglandWales: "",
            disclosureSchemeScotland: "",
            childSafeguardingRequired: "",
            certificate: '',
            saved: false
          },
          formNine: {
            securityRole: '',
            securitySIA: '',
            securityCheck: '',
            securityPreliminary: "",
            securityBSVR: '',
            securityGovernment: '',
            securityDWP: '',
            securityMobile: '',
            securityBefore: '',
            counterTerrorism: '',
            securityHidden: '',
            developedVetting: '',
            saved: false
          },
          formTen: {
            highwayWorker: "",
            mobileWorker: "",
            hybridWorker: "",
            collectiveBargaining: "",
            collectiveBargainingText: "",
            saved: false
          },
          formEleven: {
            files: [],
            saved: false
          },
          formTwelve: {
            firstLineApproval: '',
            saved: false
          },
          contractAmend: {}
        },
        navItemConfigChanges: [
          {
            formPoint: 4,
            name: "formFive",
            text: "Hours of Work",
            component: "formFive",
            icon: "ClockIcon"
          },
          {
            formPoint: 5,
            name: "formSix",
            text: "Contract Details",
            component: "formSix",
            icon: "DocumentTextIcon"
          },
        ],
        navItemConfig: [
          {
            formPoint: 0,
            name: "candidate",
            text: "Candidate Details",
            component: "candidateDetails",
            icon: "UserIcon"
          },
          {
            formPoint: 1,
            name: "contractAmend",
            text: "Confirm Contract Details",
            component: "AmendContractDetails",
            icon: "UserIcon"
          },
          {
            formPoint: 2,
            name: "salary",
            text: "Salary and Overtime details",
            component: "SalaryOvertimeForm",
            icon: "UserIcon"
          },
          {
            formPoint: 3,
            name: "workingHours",
            text: "Working Hours",
            component: "WorkingHours",
            icon: "UserIcon"
          },
          {
            formPoint: 4,
            name: "startDetails",
            text: "Start Details",
            component: "StartDetails",
            icon: "UserIcon"
          },
          {
            formPoint: 5,
            name: "additionalRequirements",
            text: "Additional Work Requirements",
            component: "AdditionalWork",
            icon: "UserIcon"
          },
          {
            formPoint: 6,
            name: "formOne",
            text: "Hiring Manager",
            component: "formOne",
            icon: "UserAddIcon"
          },
          {
            formPoint: 7,
            name: "formTwo",
            text: "Recruitment Reason",
            component: "formTwo",
            icon: "TicketIcon"
          },
          {
            formPoint: 8,
            name: "formThree",
            text: "Job Details",
            component: "formThree",
            icon: "ViewListIcon"
          },
          {
            formPoint: 9,
            name: "formFour",
            text: "Organisation Details",
            component: "formFour",
            icon: "UserGroupIcon"
          },
          {
            formPoint: 10,
            name: "formFive",
            text: "Hours of Work",
            component: "formFive",
            icon: "ClockIcon"
          },
          {
            formPoint: 11,
            name: "formSix",
            text: "Contract Details",
            component: "formSix",
            icon: "DocumentTextIcon"
          },
          {
            formPoint: 12,
            name: "formSeven",
            text: "Advertising Requirements",
            component: "formSeven",
            icon: "NewspaperIcon"
          },
          {
            formPoint: 13,
            name: "formEight",
            text: "Pre Employment Checks",
            component: "formEight",
            icon: "ClipboardListIcon"
          },
          {
            formPoint: 14,
            name: "formNine",
            text: "Security",
            component: "formNine",
            icon: "LockClosedIcon"
          },
          {
            formPoint: 15,
            name: "formTen",
            text: "Specialist Roles",
            component: "formTen",
            icon: "UserCircleIcon"
          },
          {
            formPoint: 16,
            name: "formEleven",
            text: "Document Upload",
            component: "formEleven",
            icon: "DocumentAddIcon"
          },
          {
            formPoint: 17,
            name: "formTwelve",
            text: "Next Steps",
            component: "formTwelve",
            icon: "ArrowNarrowRightIcon"
          },
          {
            formPoint: 18,
            name: "formEleven",
            text: "First & Second Line Approval",
            component: "firstSecondLineApproval",
            icon: "DocumentTextIcon"
          }
        ],
    }
  },
  inject: ['stages'],
  methods: {
    async handleReapprove() {
      this.$store.dispatch('setIsLoading', true);
      const payload = {
        requestedBy: this.formData.requestedBy,
        approved: "TRUE",
        docOwner: this.formData.requestedBy,
        id: this.formData.docId,
        stage: this.formData.currentStageName,
        approval_date: new Date().toUTCString()
      }
      await httpClient.post('/bff/action-request', payload);
      this.$store.dispatch('setIsLoading', false);
      this.$router.push('/')
    },
    openApprovalModal() { this.showModal = true },
    handleModalClose() { this.showModal = false },
    handleApproveAtr() { this.$router.push("/approve-workflow/1") },
    handleUpdateFormData({ formName, formData }) {
      this.formData[formName] = formData;
      saveStorage('formData', JSON.stringify(this.formData));
    },
    setCurrentNavItem(formPoint) { this.formPoint = formPoint },
    async confirmOffer() {
      const docOwner = this.formData.requestedBy;
      const docId = this.formData.docId
      await httpClient.post('bff/complete-request', { docId, docOwner, state: 'offer.notifyOfferSent' })
      this.$router.push('/')
    },
    async cancelOffer() {
      this.$store.dispatch('setIsLoading', true);
      httpClient.post('/bff/cancel-offer', {
        requestedBy: this.profile.userID,
        id: this.formData.docId,
        docOwner: this.formData.requestedBy
      });
      this.$router.push('/');
      this.$store.dispatch('setIsLoading', false);
    },
    hanldeReassign() {
      const id = this.$route.params.id;
      const docOwner = this.formData.requestedBy;
      this.$router.push(`/re-assign/${id}/${docOwner}`);
    },
    // A request to submit a form
    async handleSubmitForm({ name, completed }) {
      try {
        this.$store.dispatch('setIsLoading', true);
        // set saved attribute
        this.formData[name].saved = true;
        // Make the api appropriate API request for the form being submitted
        const payload = {
          formData: { ...this.formData[name] },
          requestedBy: this.formData.requestedBy,
          id: this.atrId,
          stage: this.formData.currentStageName
        }
        const response = await httpClient.post(
            `/bff/starter/${name}`,
            payload
        );
        // Get short id and long id to store in storage
        const body = response.data.body;
        this.formData = body.Item
        this.atrShortId = body.Item.shortId;
        this.atrId = body.Item.docId
        saveStorage('atrShortId', this.atrShortId);
        saveStorage('atrId', this.atrId);
        saveStorage('formData', this.formData)
        // show notification
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'SAVED!',
          message: 'The form has been saved.'
        })
        if (this.formPoint < 5) {
          this.formPoint++;
        }
        saveStorage('formData', JSON.stringify(this.formData));
        if (completed) this.$router.push('/');
        this.$store.dispatch('setIsLoading', false);

      } catch (err) {
        console.log(err)
        // set saved attribute
        this.formData[name].saved = false;
        this.store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was a problem submitting the form'
        })
        this.store.dispatch('setIsLoading', false);
      }
    },
    resetMessage() {
      this.$store.dispatch("resetMessage");
    },
    // function to get whether or not a form has been saved
    getFormSavedStatus(formName) {
      const cache = getStorage('formData');
      if (cache) {
        const cachedData = JSON.parse(cache);
        return cachedData[formName].saved;
      }
      return this.formData[formName]?.saved;
    },
    // A request to submit the atr
    async handleCompleteOffer() {
      const payload = {
        requestedBy: this.formData.requestedBy,
        id: this.atrId,
        formData: { completed: true },
        stage: this.formData.currentStageName,
        completed: true
      }
      try {
        await httpClient.post('/bff/starter/finalise', payload);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Your ATR has been submitted.'
        });
        this.$router.push('/');
      } catch (err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was a problem submitting this ATR.'
        });
      }
    },
    // function to fetch an atr and save it to local storage
    async fetchSingleAtr(id) {
      this.$store.dispatch('setIsLoading', true);
      const requestedBy = this.$route.params.docOwner;
      const payload = { requestedBy, id }
      const response = await httpClient.post('/bff/get-document', payload);
      const body = response.data.body;
      saveStorage('formData', JSON.stringify(body.Item));
      saveStorage('atrShortId', body.Item.shortId);
      saveStorage('atrId', body.Item.docId);
      this.formData = body.Item;
      this.$store.dispatch('setIsLoading', false);
    },
    // handle an event to reset a form
    handleResetFormData(formName) {
      const keys = Object.keys(this.formData[formName]);
      keys.forEach((key) => {
        if (typeof this.formData[formName][key] === 'string') {
          this.formData[formName][key] = '';
        } else if (typeof this.formData[formName][key] === 'number') {
          this.formData[formName][key] = null;
        } else if (Array.isArray(this.formData[formName][key])) {
          this.formData[formName][key] = [];
        } else if (typeof this.formData[formName][key] === 'object') {
          const nestedKeys = this.formData[formName][key];
          nestedKeys.forEach((nk) => this.formData[formName][key][nk] = '');
        }
      });
      saveStorage('formData', JSON.stringify(this.formData));
    },
    // Fetch config data
    async fetchConfigData() {
      const response = await httpClient.post('/config/all', { requestedBy: 'something' });
      this.configData = response.data.body;
    }
  },
  computed: {
    route() {
      return this.$route;
    },
    currentNavItem() {
      return this.navItemConfig.find(
        (item) => item.formPoint === this.formPoint
      ) ?? this.navItemConfig[0];
    },
    usersEmail() {
      return Auth?.user.signInUserSession.idToken.payload.email
    },
    atrReadyToSubmit() {
      // Check all form entries, if one is not saved return false
      if (Object.keys(this.formData).filter((i) => i.startsWith('form')).find((item) => !this.formData[item]?.saved))
        return false;
      return true;
    },
    showApprovalSteps() {
      if(this.formData?.status === undefined || this.formData?.status === 'DRAFT') {
        return false
      } else {
        return true
      }
    },
    currentStage() {
      return this.stages.find((stage) => stage.stageName === this.formData?.currentStageName);
    },
    hideAction() {
      return [
        'starter.notifyMoreNotifyHROps',
        'starter.notifyMoreInfoBudgetApprover',
        'starter.notifyMoreInfoBudgetApproval',
        'starter.notifyRecruitment'
      ].includes(this.formData?.currentStageName);
    },
    currentUserIsAssigned() { 
      return this.formData.currentAssignee === Auth.user.signInUserSession.idToken.payload.identities[0].userId.split('@')[0];
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    forReapproval() {
      return this.formData.contractAmend.hasChanged ||
        this.formData.salary.hasChanged ||
        this.formData.workingHours.hasChanged;
    },
    disableForm() {
      return ![
        'candidate',
        'contractAmend',
        'salary',
        'workingHours',
        'startDetails',
        'additionalRequirements'
      ].includes(this.currentNavItem.name)
    }
  },
  async created() {

    const id = this.$route.params.id;
    await this.fetchSingleAtr(id)

    await this.fetchConfigData();
    // short and long ids if they exist
    const atrId = getStorage('atrId');
    const atrShortId = getStorage('atrShortId');
    if (atrId) this.atrId = atrId;
    if (atrId) this.atrShortId = atrShortId;
  },
  watch: {
    route(newVal) {
      if (newVal.name === 'Create Starter') {
        this.formData = {
          formOne: {
            hiringManagerName: "",
            hiringManagerEmail: "",
            employeeName: "",
            employeeEmail: "",
            saved: false
          },
          formTwo: {
            recruitmentReason: "",
            inBudget: "",
            saved: false,
            employee_jobTitle: '',
            employee_id: '',
            employee_name: '',
            salary_basis: '',
            rate: '',
            recovered: '',
          },
          formThree: {
            jobTitle: "",
            numberOfPositions: "",
            roleLocationSite: "",
            roleLocationPostcode: "",
            employeeType: "",
            employmentContract: "",
            duration: '',
            secondment: '',
            saved: false
          },
          formFour: {
            businesscontact: null,
            employedin: "",
            division: "",
            assigned: "",
            saved: false
          },
          formFive: {
            hoursPerWeek: "",
            weeksPerYear: "",
            shiftPattern: [],
            additionalInformation: "",
            saved: false
          },
          formSix: {
            contractType: "",
            contractLevel: "",
            salaryBasis: "",
            minimumPayRate: "",
            minimumPayRateCurrency: "GBP",
            maximumPayRate: "",
            maximumPayRateCurrency: "GBP",
            additionalAllowances: "",
            additionalAllowancesInformation: [
              {
                additionalAllowancesTitle: "",
                additionalAllowancesInformation: "",
              }
            ],
            additionalAllowancesTitle: "",
            payrollFrequency: "",
            saved: false
          },
          formSeven: {
            recruitmentTeam: "",
            chosen_not: null,
            advertise: "selected",
            channels: [],
            channelsOther: "",
            recruitmentShortlist: "",
            saved: false
          },
          formEight: {
            disclosureCheckRequired: "",
            disclosureSchemeEnglandWales: "",
            disclosureSchemeScotland: "",
            childSafeguardingRequired: "",
            saved: false,
            certificate: ''
          },
          formNine: {
            securityRole: '',
            securitySIA: '',
            securityCheck: '',
            securityPreliminary: "",
            securityBSVR: '',
            securityGovernment: '',
            securityDWP: '',
            securityMobile: '',
            securityBefore: '',
            saved: false,
            counterTerrorism: '',
            securityHidden: '',
            developedVetting: ''
          },
          formTen: {
            highwayWorker: "",
            mobileWorker: "",
            hybridWorker: "",
            collectiveBargaining: "",
            collectiveBargainingText: "",
            saved: false
          },
          formEleven: {
            files: [],
            saved: false
          },
          formTwelve: {
            firstLineApproval: '',
            saved: false
          }
        }
      }
    } 
  }
};
</script>
<template>
  <div class="p-12">
    <!-- Page title-->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Confirm Request
      </h3>
    </div>
    <!-- End of page title-->

    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="p-8 w-1/3">
          <p class="text-lg text-gray-800">Comments</p>
          <div class="divide-y divide-gray-300 divide-solid">
            <p class="text-gray-800 py-2" v-for="comment in formData.H2RComments" :key="comment.timestamp"><span class="italic">"{{ comment.comment }}"</span> - {{ comment.userEmail }}</p>
          </div>
        </div>
        <div class="p-8">
            <p>Please confirm you would like to submit this request</p>
              <div class="my-4">
                <form>
                  <label for="comments" class="block text-sm font-medium text-gray-700">
                    Please specify if you would like more info:
                  </label>
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <textarea
                      v-model="comment"
                      id="comments-input"
                      name="comments"
                      rows="3"
                      class="
                        shadow-sm
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        block w-full
                        sm:text-sm
                        border
                        border-gray-300
                        rounded-md
                      " 
                    /> 
                  </div>
                </form>
              </div>
              <div class="mt-4 flex justify-end">
                <button
                  type="button" 
                  class="
                    inline-flex
                    items-center
                    justify-center
                    rounded-md border
                    border-transparent
                    bg-yellow-600
                    hover:bg-yellow-700
                    hover:underline
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    shadow-sm
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2 sm:w-auto
                    disabled:bg-gray-200
                    disabled:text-gray-500
                    disabled:cursor-not-allowed
                  "
                  :disabled="!comment"
                  @click="handleRequestMoreInfo">Request more info</button>
                <button
                  type="button" 
                  class="
                    ml-2
                    inline-flex
                    items-center
                    justify-center
                    rounded-md border
                    border-transparent
                    bg-green-700
                    hover:bg-green-800
                    hover:underline
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    shadow-sm
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2 sm:w-auto
                  "
                  @click="handleConfirmUser">Confirm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import httpClient from '@/httpClient.js';

const store = useStore()
const router = useRouter();
const formData = ref({})
const comment = ref('');

const docId = computed(() => router.currentRoute.value.params.docId);
const docOwner = computed(() => router.currentRoute.value.params.requestedBy);

const fetchDocument = async () => {
  store.dispatch('setIsLoading', true);
  const response = await httpClient.post('/bff/get-document', { id: docId.value, requestedBy: docOwner.value })
  formData.value = response.data.body.Item;
  store.dispatch('setIsLoading', false);
};

const actionDoc = async (approve) => {
  store.dispatch('setIsLoading', true);
  await httpClient.post('/bff/action-doc', {
    docOwner: docOwner.value,
    id: docId.value,
    approved: approve,
    stage: formData.value.currentStageName,
    comment: comment.value
  });
  router.go(-2);
  store.dispatch('setIsLoading', false);
}

const handleRequestMoreInfo = () => actionDoc('MOREINFO');
const handleConfirmUser = () => actionDoc('TRUE');

onMounted(async() => await fetchDocument());
</script>
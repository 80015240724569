const stages = [
  {
      stageName: "starter.notifyBudgetApprover",
      displayName: "ATR - Awaiting first line approval",
      type: "starter"
  },
  {
      stageName: "starter.notifyMoreInfoBudgetApproval",
      displayName: "ATR - More info requested by first line approver",
      type: "starter"
  },
  {
      stageName: "starter.notifyHROps",
      displayName: "ATR - Awaiting second line approval",
      type: "starter"
  },
  {
      stageName: "starter.notifyMoreNotifyHROps",
      displayName: "ATR - More info requested by second line approver",
      type: "starter"
  },
  {
      stageName: "starter.notifyRecruitment",
      displayName: "ATR - Recruitment team notified",
      type: "starter"
  },
  {
      stageName: "starter.notifyRecruitmentUpdated",
      displayName: "ATR - Recruitment Owner Updated",
      type: "starter"
  },
  {
      stageName: "starter.notifyRoleAdvertised",
      displayName: "ATR - Advert raised",
      type: "starter"
  },
  {
      stageName: "starter.notifyCVsCollected",
      displayName: "ATR - CV's collected",
      type: "starter"
  },
  {
      stageName: "starter.notifyCandidateSelected",
      displayName: "ATR - Ready for selection",
      type: "starter"
  },
  {
      stageName: "starter.complete",
      displayName: "ATR - Complete",
      type: "starter"
  },
  {
      stageName: "leaver.notifyH2RNewLeaver",
      displayName: "Leaver - H2R Notified of new leaver",
      type: "leaver"
  },
  {
      stageName: "leaver.notifyH2RAdminAssigned",
      displayName: "Leaver - Admin assigned",
      type: "leaver"
  },
  {
      stageName: "leaver.notifyHMMoreInfoNeeded",
      displayName: "Leaver - More info requested by H2R",
      type: "leaver"
  },
  {
    stageName: "leaver.notifyHiringManagerPayments",
    displayName: "Leaver - Payments confirmation",
    type: "leaver"
  },
  {
    stageName: "leaver.notifyHROps",
    displayName: "Leaver - HROps approval",
    type: "leaver"
  },
  {
    stageName: "leaver.notifyMoreNotifyHROps",
    displayName: "Leaver - More info requested by second line approver",
    type: "leaver"
  },
  {
    stageName: "leaver.notifyH2R",
    displayName: "Leaver - H2R Processing payments",
    type: "leaver"
  },
  {
      stageName: "leaver.complete",
      displayName: "Leaver - Complete",
      type: "leaver"
  },
  {
      stageName: "leaver.cancelled",
      displayName: "Leaver - Cancelled",
      type: "leaver"
  },
  {
      stageName: "amend.notifyBudgetApprover",
      displayName: "Amend - Awaiting first line approval",
      type: "amend"
  },
  {
      stageName: "amend.notifyMoreInfoBudgetApproval",
      displayName: "Amend - More info requested by first line approver",
      type: "amend"
  },
  {
    stageName: "amend.notifyHROps",
    displayName: "Amend - Awaiting second line approval",
    type: "amend"
  },
  {
    stageName: "amend.notifyMoreNotifyHROps",
    displayName: "Amend - More info requested by second line approver",
    type: "amend"
  },
  {
      stageName: "amend.notifyAmendH2R",
      displayName: "Amend - H2R notified",
      type: "amend"
  },
  {
      stageName: "amend.notifyAmendH2RAdminUpdated",
      displayName: "Amend - Admin assigned",
      type: "amend"
  },
  {
      stageName: "amend.notifyHMMoreInfo",
      displayName: "Amend - More info requested by admin",
      type: "amend"
  },
  {
      stageName: "amend.rejected",
      displayName: "Amend - Rejected",
      type: "amend"
  },
  {
      stageName: "amend.complete",
      displayName: "Amend - Complete",
      type: "amend"
  },
  {
      stageName: "offer.notifyPrepOffer",
      displayName: "Offer - Preparing offer",
      type: "offer"
  },
  {
      stageName: "offer.notifyBudgetReApproval",
      displayName: "Offer - Awaiting first line re-approval",
      type: "offer"
  },
  {
      stageName: "offer.notifyMoreInfoBudgetReApproval",
      displayName: "Offer - More info requested by first line approver",
      type: "offer"
  },
  {
      stageName: "offer.notifyHROpsReApproval",
      displayName: "Offer - Awaiting second line re-approval",
      type: "offer"
  },
  {
      stageName: "offer.notifyMoreInfoHROpsReApproval",
      displayName: "Offer - More info requested by second line approver",
      type: "offer"
  },
  {
      stageName: "offer.notifyH2RAdmin",
      displayName: "Offer - H2R notified",
      type: "offer"
  },
  {
      stageName: "offer.notifyH2RAdminAdded",
      displayName: "Offer - H2R Admin assigned",
      type: "offer"
  },
    {
      stageName: "offer.notifyHMMoreInfo",
      displayName: "Offer - More info requested by H2R admin",
      type: "offer"
  },
  {
      stageName: "offer.notifyMakeOffer",
      displayName: "Offer - Pre-contractual checks",
      type: "offer"
  },
  {
      stageName: "offer.notifyOfferSent",
      displayName: "Offer - Offer issued",
      type: "offer"
  },
  {
      stageName: "offer.complete",
      displayName: "Offer - Complete",
      type: "offer"
  },
  {
      stageName: "offer.cancelled",
      displayName: "Offer - Cancelled / Declined",
      type: "offer"
  }
];

export default stages;
export const activityCodes = [
    {
      code: "ACEE00",
      name: "ACE"
    },
    {
      code: "ACEM00",
      name: "ACE MGR"
    },
    {
      code: "ACES00",
      name: "ACE SNR"
    },
    {
      code: "ADMN00",
      name: "ADMINISTRATOR"
    },
    {
      code: "ADMN10",
      name: "ADMIN NWH"
    },
    {
      code: "ADMN15",
      name: "ADMIN ONWH 1.5"
    },
    {
      code: "ADMN17",
      name: "ADMIN ONWH 1.75"
    },
    {
      code: "ADMN18",
      name: "ADMIN ONWH 1.75"
    },
    {
      code: "ADMN20",
      name: "ADMIN ONWH 2"
    },
    {
      code: "BECL10",
      name: "EDF CLERICAL"
    },
    {
      code: "BECL15",
      name: "EDF CLERICAL ONWH 1."
    },
    {
      code: "BECL17",
      name: "EDF CLERICAL 1.75"
    },
    {
      code: "BECL18",
      name: "EDF CLERICAL 1.75"
    },
    {
      code: "BECL20",
      name: "EDF CLERICAL ONWH 2"
    },
    {
      code: "BURE00",
      name: "BUREAU SERVICES"
    },
    {
      code: "BURE10",
      name: "BUR SVCS NWH"
    },
    {
      code: "BURE15",
      name: "BUR SVCS ONWH 1.5"
    },
    {
      code: "BURE20",
      name: "BUR SVCS ONWH 2"
    },
    {
      code: "CCMA00",
      name: "CARBON & COMP"
    },
    {
      code: "CCON00",
      name: "CLIENT CONTROLLER"
    },
    {
      code: "CJMA00",
      name: "CLIENT JNR MGR"
    },
    {
      code: "CMAN00",
      name: "CLIENT MGR"
    },
    {
      code: "CMGR00",
      name: "COMMERCIAL - MANAGER"
    },
    {
      code: "CMGR10",
      name: "COMM-MANAG NWH"
    },
    {
      code: "CMGR15",
      name: "COMM-MANAG ONWH 1.5"
    },
    {
      code: "CMGR20",
      name: "COMM-MANAG ONWH 2"
    },
    {
      code: "COMM00",
      name: "COMMISSIONING"
    },
    {
      code: "COMM10",
      name: "COMMISSIONING NWH"
    },
    {
      code: "COMM15",
      name: "COMMISSION ONWH 1.5"
    },
    {
      code: "COMM20",
      name: "COMMISSIONING ONWH 2"
    },
    {
      code: "CONM00",
      name: "CONNECTIONS MGR"
    },
    {
      code: "CONS00",
      name: "CONTROLLER SOFTWARE"
    },
    {
      code: "CONS10",
      name: "CONTROLLER SOFTW NWH"
    },
    {
      code: "CONS15",
      name: "CONT SOFTWA ONWH 1.5"
    },
    {
      code: "CONS20",
      name: "CONT SOFTW ONWH 2"
    },
    {
      code: "CSMA00",
      name: "CUST SVCS MGR"
    },
    {
      code: "CSPE00",
      name: "CLIENT SPEC"
    },
    {
      code: "DOCU00",
      name: "DOCUMENTATION"
    },
    {
      code: "DOCU10",
      name: "DOCUMENTATION NWH"
    },
    {
      code: "DOCU15",
      name: "DOCUMENTA ONWH 1.5"
    },
    {
      code: "DOCU20",
      name: "DOCUMENTATION ONWH 2"
    },
    {
      code: "ECMA00",
      name: "ENERGY & CARBON"
    },
    {
      code: "ECON00",
      name: "ENERGY CONSULTANT"
    },
    {
      code: "EMTM00",
      name: "EMT MGR"
    },
    {
      code: "ENMG00",
      name: "ENERGY MANAGER"
    },
    {
      code: "EPRA00",
      name: "ENERGY ADV"
    },
    {
      code: "EPRM00",
      name: "ENERGY PROC"
    },
    {
      code: "EPRS00",
      name: "ENERGY SPEC"
    },
    {
      code: "ERMA00",
      name: "ENERGY RISK"
    },
    {
      code: "ERSP00",
      name: "ENERGY RISK SP"
    },
    {
      code: "FADM10",
      name: "ADMINISTRATOR-NWH10"
    },
    {
      code: "FADM15",
      name: "ADMINISTRATOR-ONWH15"
    },
    {
      code: "FADM20",
      name: "ADMINISTRATOR-ONWH20"
    },
    {
      code: "FCHF10",
      name: "FM RESDTCHEF-NWH10"
    },
    {
      code: "FCHF15",
      name: "FM RESDTCHEF-ONWH15"
    },
    {
      code: "FCHF20",
      name: "FM RESDTCHEF-ONWH20"
    },
    {
      code: "FMCA10",
      name: "FM MOBCATERASTTNWH10"
    },
    {
      code: "FMCA15",
      name: "FM MOBCATERASTONWH15"
    },
    {
      code: "FMCA20",
      name: "FMMOBCATERASTTONWH20"
    },
    {
      code: "FMCL10",
      name: "MOBCLEANER-NWH10"
    },
    {
      code: "FMCL15",
      name: "MOBCLEANER-ONWH15"
    },
    {
      code: "FMCL20",
      name: "MOBCLEANER-ONWH20"
    },
    {
      code: "FMFA10",
      name: "MOBFACILITIASTNWHH10"
    },
    {
      code: "FMFA15",
      name: "MOBFACILITIASTONWH15"
    },
    {
      code: "FMFA20",
      name: "MOBFACILITASTTONWH20"
    },
    {
      code: "FMLA10",
      name: "FM MOBLANDSCAP-NWH10"
    },
    {
      code: "FMLA15",
      name: "FM MOBLANDSCAPONWH15"
    },
    {
      code: "FMLA20",
      name: "FM MOBLANDSCAPONWH20"
    },
    {
      code: "FMMG10",
      name: "FM MOBMGMT-NWH10"
    },
    {
      code: "FMMG15",
      name: "FM MOBMGMT-ONWH15"
    },
    {
      code: "FMMG20",
      name: "FM MOBMGMT-ONWH20"
    },
    {
      code: "FMOS10",
      name: "FM MOBOFFSUPP-NWH10"
    },
    {
      code: "FMOS15",
      name: "FM MOBOFFSUPP-ONWH15"
    },
    {
      code: "FMOS20",
      name: "FM MOBOFFSUPP-ONWH20"
    },
    {
      code: "FMSE10",
      name: "FM MOBSECOFFCR-NWH10"
    },
    {
      code: "FMSE15",
      name: "FM MOBSECOFFCRONWH15"
    },
    {
      code: "FMSE20",
      name: "FM MOBSECOFFCRONWH20"
    },
    {
      code: "FMSP10",
      name: "FM MOBSUPRVSR-NWH10"
    },
    {
      code: "FMSP15",
      name: "FM MOBSUPRVSR-ONWH15"
    },
    {
      code: "FMSP20",
      name: "FM MOBSUPRVSR-ONWH20"
    },
    {
      code: "FMWL10",
      name: "FM MOBWLFROFFCRNWH10"
    },
    {
      code: "FMWL15",
      name: "FM MOBWLFROFFCONWH15"
    },
    {
      code: "FMWL20",
      name: "FM MOBWLFROFFCONWH20"
    },
    {
      code: "FPRA10",
      name: "FM PROJECTSASTTNWH10"
    },
    {
      code: "FPRA15",
      name: "FMPROJECTSASTTONWH15"
    },
    {
      code: "FPRA20",
      name: "FMPROJECTSASTTONWH20"
    },
    {
      code: "FRCA10",
      name: "FM RESCATERASTTNWH10"
    },
    {
      code: "FRCA15",
      name: "FM RESCATERASTONWH15"
    },
    {
      code: "FRCA20",
      name: "FM RESCATERASTONWH20"
    },
    {
      code: "FRCL10",
      name: "RESDTCLEANER-NWH10"
    },
    {
      code: "FRCL15",
      name: "RESDTCLEANER-ONWH15"
    },
    {
      code: "FRCL17",
      name: "RESDTCLEANER 1.75"
    },
    {
      code: "FRCL18",
      name: "RESDTCLEANER 1.75"
    },
    {
      code: "FRCL20",
      name: "RESDTCLEANER-ONWH20"
    },
    {
      code: "FRFA10",
      name: "RESDTFACILITASTNWH10"
    },
    {
      code: "FRFA15",
      name: "RESDTFACILIASTONWH15"
    },
    {
      code: "FRFA20",
      name: "RESDTFACILIASTONWH20"
    },
    {
      code: "FRLA10",
      name: "FM RESLANDSCPOPNWH10"
    },
    {
      code: "FRLA15",
      name: "FMRESLANDSCPOPONWH15"
    },
    {
      code: "FRLA17",
      name: "FM RESLANDSCPOP 1.75"
    },
    {
      code: "FRLA18",
      name: "RESID INDCLN 1.75"
    },
    {
      code: "FRLA20",
      name: "FMRESLANDSCPOPONWH20"
    },
    {
      code: "FRMG10",
      name: "FM RESDTMGMT-NWH10"
    },
    {
      code: "FRMG15",
      name: "FM RESDTMGMT-ONWH15"
    },
    {
      code: "FRMG17",
      name: "FM RESDTMGMT-ONWH17"
    },
    {
      code: "FRMG18",
      name: "FM RESDTMGMT-ONWH18"
    },
    {
      code: "FRMG20",
      name: "FM RESDTMGMT-ONWH20"
    },
    {
      code: "FROS10",
      name: "FM RESDTOFFSUPPNWH10"
    },
    {
      code: "FROS15",
      name: "FMRESDTOFFSUPPONWH15"
    },
    {
      code: "FROS17",
      name: "FM RESDTOFFSUPP 1.75"
    },
    {
      code: "FROS18",
      name: "FM RESDTOFFSUPP 1.75"
    },
    {
      code: "FROS20",
      name: "FMRESDTOFFSUPPONWH20"
    },
    {
      code: "FRSE10",
      name: "FMRESDTSECOFFCRNWH10"
    },
    {
      code: "FRSE15",
      name: "FMRESDTSECOFFCONWH15"
    },
    {
      code: "FRSE20",
      name: "FMRESDTSECOFFCONWH20"
    },
    {
      code: "FRSP10",
      name: "FM RESDTSUPRVSRNWH10"
    },
    {
      code: "FRSP15",
      name: "FMRESDTSUPRVSRONWH15"
    },
    {
      code: "FRSP17",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "FRSP18",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "FRSP20",
      name: "FMRESDTSUPRVSRONWH20"
    },
    {
      code: "FRWL10",
      name: "FMRESDTWLFROFFCNWH10"
    },
    {
      code: "FRWL15",
      name: "FMRESDWLFOFFCONWH15"
    },
    {
      code: "FRWL20",
      name: "FMRESDWLFROFFCONWH20"
    },
    {
      code: "GRAP00",
      name: "GRAPHICS SOFTWARE"
    },
    {
      code: "GRAP10",
      name: "GRAPH SOFTW NWH"
    },
    {
      code: "GRAP15",
      name: "GRAPH SOFTW ONWH 1.5"
    },
    {
      code: "GRAP20",
      name: "GRAPH SOFTW ONWH 2"
    },
    {
      code: "HOME00",
      name: "HIGHER ORDER MAINT E"
    },
    {
      code: "HOME10",
      name: "HIGH ORD MAINT ENG N"
    },
    {
      code: "HOME15",
      name: "HIGH ORD MAINT ENG O"
    },
    {
      code: "HOME20",
      name: "HIGH ORD MAINT ENG O"
    },
    {
      code: "INCL10",
      name: "RESID INDCLN"
    },
    {
      code: "INCL15",
      name: "RESID INDCLN ONWH 1."
    },
    {
      code: "INCL17",
      name: "RESID INDCLN 1.75"
    },
    {
      code: "INCL18",
      name: "RESID INDCLN 1.75"
    },
    {
      code: "INCL20",
      name: "RESID INDCLN ONWH 2"
    },
    {
      code: "INMA10",
      name: "INTL LABOUR MOB AC"
    },
    {
      code: "INMA15",
      name: "INTL LABR MOB AC 1.5"
    },
    {
      code: "INMA20",
      name: "INTL LABR MOB AC 2.0"
    },
    {
      code: "INMC10",
      name: "INTL LABR MOB COM"
    },
    {
      code: "INMC15",
      name: "INTL LABR MOB COM1.5"
    },
    {
      code: "INMC20",
      name: "INTL LABR MOB COM2.0"
    },
    {
      code: "INME10",
      name: "INTERNAL LABOUR MOB"
    },
    {
      code: "INME15",
      name: "INT LABR MOB ELEC1.5"
    },
    {
      code: "INME20",
      name: "INT LABR MOB ELEC2.0"
    },
    {
      code: "INMH10",
      name: "INTL LAB MOB HMAN"
    },
    {
      code: "INMH15",
      name: "INTL LAB MOB HMAN1.5"
    },
    {
      code: "INMH20",
      name: "INTL LAB MOB HMAN2.0"
    },
    {
      code: "INMM10",
      name: "INT LABR MOBILE MECH"
    },
    {
      code: "INMM15",
      name: "INT LABR MOB MECH1.5"
    },
    {
      code: "INMM20",
      name: "INT LABR MOB MECH2.0"
    },
    {
      code: "INMS10",
      name: "INTL LAB MOB SUPE"
    },
    {
      code: "INMS15",
      name: "INTL LAB MOB SUPE1.5"
    },
    {
      code: "INMS20",
      name: "INTL LAB MOB SUPE2.0"
    },
    {
      code: "INTL10",
      name: "INTERNAL LABOUR"
    },
    {
      code: "INTL15",
      name: "INTERNAL LABOUR 1.5"
    },
    {
      code: "INTL20",
      name: "INTERNAL LABOUR 2"
    },
    {
      code: "IVEX00",
      name: "IV EXEC"
    },
    {
      code: "IVSP00",
      name: "IV SPECIALIST"
    },
    {
      code: "LSTD00",
      name: "LABOUR STANDARD"
    },
    {
      code: "LSTD10",
      name: "LABOUR STANDARD NWH"
    },
    {
      code: "LSTD15",
      name: "LABOUR STD ONWH 1.5"
    },
    {
      code: "LSTD20",
      name: "LABOUR STD ONWH 2"
    },
    {
      code: "MCAT00",
      name: "MOBILE - CATERING"
    },
    {
      code: "MCAT10",
      name: "MOB - CAT NWH"
    },
    {
      code: "MCAT15",
      name: "MOB - CAT ONWH 1.5"
    },
    {
      code: "MCAT20",
      name: "MOB - CAT ONWH 2"
    },
    {
      code: "MCMG00",
      name: "MOB CONT MANAGER"
    },
    {
      code: "MCMG10",
      name: "MOB CONT MGR NWH"
    },
    {
      code: "MCMG15",
      name: "MOB CONT MGR ONWH 1."
    },
    {
      code: "MCMG20",
      name: "MOB CONT MGR ONWH 2"
    },
    {
      code: "MCOM00",
      name: "MOBILE - COMBUSTION"
    },
    {
      code: "MCOM10",
      name: "MOB - COM NWH"
    },
    {
      code: "MCOM15",
      name: "MOB - COM ONWH 1.5"
    },
    {
      code: "MCOM20",
      name: "MOB - COM ONWH 2"
    },
    {
      code: "MCON00",
      name: "MOBILE - CONTROLS"
    },
    {
      code: "MCON10",
      name: "MOB - CONT NWH"
    },
    {
      code: "MCON15",
      name: "MOB - CONT ONWH 1.5"
    },
    {
      code: "MCON20",
      name: "MOB - CONT ONWH 2"
    },
    {
      code: "MELE00",
      name: "MOBILE - ELECTRICAL"
    },
    {
      code: "MELE10",
      name: "MOB - ELEC NWH"
    },
    {
      code: "MELE15",
      name: "MOB - ELEC ONWH 1.5"
    },
    {
      code: "MELE20",
      name: "MOB - ELEC ONWH 2"
    },
    {
      code: "META00",
      name: "METERING ADM"
    },
    {
      code: "METM00",
      name: "METERING MGR"
    },
    {
      code: "MFIC00",
      name: "MOBILE FIRE COMM"
    },
    {
      code: "MFIC10",
      name: "MOB FIRE COMM NWH"
    },
    {
      code: "MFIC15",
      name: "MOB FIRE COMM ONWH 1"
    },
    {
      code: "MFIC20",
      name: "MOB FIRE COMM ONWH 2"
    },
    {
      code: "MFIS00",
      name: "MOBILE FIRE SERVICE"
    },
    {
      code: "MFIS10",
      name: "MOB FIRE SERV NWH"
    },
    {
      code: "MFIS15",
      name: "MOB FIRE SERV ONWH 1"
    },
    {
      code: "MFIS20",
      name: "MOB FIRE SERV ONWH 2"
    },
    {
      code: "MHAN00",
      name: "MOBILE - HANDYMAN"
    },
    {
      code: "MHAN10",
      name: "MOB - HAND NWH"
    },
    {
      code: "MHAN15",
      name: "MOB - HAND ONWH 1.5"
    },
    {
      code: "MHAN20",
      name: "MOB - HAND ONWH 2"
    },
    {
      code: "MMEC00",
      name: "MOBILE - MECHANICAL"
    },
    {
      code: "MMEC10",
      name: "MOB - MECH NWH"
    },
    {
      code: "MMEC15",
      name: "MOB - MECH ONWH 1.5"
    },
    {
      code: "MMEC17",
      name: "MOB - MECH ONWH 1.7"
    },
    {
      code: "MMEC18",
      name: "MOB - MECH ONWH 1.8"
    },
    {
      code: "MMEC20",
      name: "MOB - MECH ONWH 2"
    },
    {
      code: "MMSE00",
      name: "MOBILE MULTISKILLED"
    },
    {
      code: "MMSE10",
      name: "MOB MULTI SKILL ENG"
    },
    {
      code: "MMSE15",
      name: "MOB MULTI SKILL ENG"
    },
    {
      code: "MMSE20",
      name: "MOB MULTI SKILL ENG"
    },
    {
      code: "MOTH00",
      name: "MOBILE - OTHER"
    },
    {
      code: "MOTH10",
      name: "MOB - OTH NWH"
    },
    {
      code: "MOTH15",
      name: "MOB - OTH ONWH 1.5"
    },
    {
      code: "MOTH20",
      name: "MOB - OTH ONWH 2"
    },
    {
      code: "MPLA00",
      name: "MOBILE - PLANT ASSIS"
    },
    {
      code: "MPLA10",
      name: "MOB - PLAN NWH"
    },
    {
      code: "MPLA15",
      name: "MOB - PLAN ONWH 1.5"
    },
    {
      code: "MPLA20",
      name: "MOB - PLAN ONWH 2"
    },
    {
      code: "MPLU00",
      name: "MOBILE - PLUMBING"
    },
    {
      code: "MPLU10",
      name: "MOB - PLUM NWH"
    },
    {
      code: "MPLU15",
      name: "MOB - PLUM ONWH 1.5"
    },
    {
      code: "MPLU20",
      name: "MOB - PLUM ONWH 2"
    },
    {
      code: "MPRO10",
      name: "MOB - PROFESSIONAL"
    },
    {
      code: "MPRO15",
      name: "MOB- PROFESSIONAL 15"
    },
    {
      code: "MPRO20",
      name: "MOB- PROFESSIONAL 20"
    },
    {
      code: "MPRS10",
      name: "MOB - SENIOR PROFESS"
    },
    {
      code: "MPRS15",
      name: "MOB - SENIOR PROF 15"
    },
    {
      code: "MPRS20",
      name: "MOB - SENIOR PROF 20"
    },
    {
      code: "MREF00",
      name: "MOBILE - REFRIG"
    },
    {
      code: "MREF10",
      name: "MOB - REF NWH"
    },
    {
      code: "MREF15",
      name: "MOB - REF ONWH 1.5"
    },
    {
      code: "MREF20",
      name: "MOB - REF ONWH 2"
    },
    {
      code: "MSEC00",
      name: "MOBILE SECURITY COMM"
    },
    {
      code: "MSEC10",
      name: "MOB SECURITY COM NWH"
    },
    {
      code: "MSEC15",
      name: "MOB SEC COMM ONWH1.5"
    },
    {
      code: "MSEC20",
      name: "MOB SEC COMM ONWH 2"
    },
    {
      code: "MSES00",
      name: "MOBILE SECURITY SERV"
    },
    {
      code: "MSES10",
      name: "MOB SECURITY SERV NW"
    },
    {
      code: "MSES15",
      name: "MOB SECURITY SERV ON"
    },
    {
      code: "MSES20",
      name: "MOB SECURITY SERV ON"
    },
    {
      code: "MSUP00",
      name: "MOBILE - SUPERVISOR"
    },
    {
      code: "MSUP10",
      name: "MOB - SUP NWH"
    },
    {
      code: "MSUP15",
      name: "MOB - SUP ONWH 1.5"
    },
    {
      code: "MSUP20",
      name: "MOB - SUP ONWH 2"
    },
    {
      code: "MTCO00",
      name: "M & T CONTROLLER"
    },
    {
      code: "MTEC10",
      name: "MOB - TECHNICIAN"
    },
    {
      code: "MTEC15",
      name: "MOB - TECHNICIAN 15"
    },
    {
      code: "MTEC20",
      name: "MOB - TECHNICIAN 20"
    },
    {
      code: "MTES10",
      name: "MOB - SENIOR TECHNIC"
    },
    {
      code: "MTES15",
      name: "MOB - SENIOR TECH 15"
    },
    {
      code: "MTES20",
      name: "MOB - SENIOR TECH 20"
    },
    {
      code: "MTMA00",
      name: "M & T MANAGER"
    },
    {
      code: "MTSP00",
      name: "M & T SPECIAL"
    },
    {
      code: "MTSP10",
      name: "M & T SPEC NWH"
    },
    {
      code: "MTSP15",
      name: "M & T SPEC ONWH 1.5"
    },
    {
      code: "MTSP20",
      name: "M & T SPEC ONWH 2"
    },
    {
      code: "NONPRD",
      name: "NON PRODUCTIVE HOURS"
    },
    {
      code: "OPPR00",
      name: "OPERATION - PROJECTS"
    },
    {
      code: "OPPR10",
      name: "OP PROJ NWH"
    },
    {
      code: "OPPR15",
      name: "OP PROJ ONWH 1.5"
    },
    {
      code: "OPPR20",
      name: "OP PROJ ONWH 2"
    },
    {
      code: "OPSA00",
      name: "OPS ADMIN"
    },
    {
      code: "PADM00",
      name: "PROJECT-ADMINISTRATO"
    },
    {
      code: "PADM10",
      name: "PROJ-ADMIN NWH"
    },
    {
      code: "PADM15",
      name: "PROJ-ADMIN ONWH 1.5"
    },
    {
      code: "PADM20",
      name: "PROJ-ADMIN ONWH 2"
    },
    {
      code: "PASI00",
      name: "ASSISTANT INSTALLER"
    },
    {
      code: "PASI10",
      name: "ASSIST INSTL NWH"
    },
    {
      code: "PASI15",
      name: "ASSIST INSTL ONWH1.5"
    },
    {
      code: "PASI17",
      name: "ASSIST INSTL ONWH1.7"
    },
    {
      code: "PASI18",
      name: "ASSISTANT INSTAL 1.8"
    },
    {
      code: "PASI20",
      name: "ASSIST INSTL ONWH 2"
    },
    {
      code: "PCAD00",
      name: "CAD ENGINEER"
    },
    {
      code: "PCAD10",
      name: "CAD ENGNR NWH"
    },
    {
      code: "PCAD15",
      name: "CAD ENGNR ONWH 1.5"
    },
    {
      code: "PCAD20",
      name: "CAD ENGNR ONWH 2"
    },
    {
      code: "PELI00",
      name: "ELECTRICAL INSTALLER"
    },
    {
      code: "PELI10",
      name: "ELECT INSTALLER NWH"
    },
    {
      code: "PELI15",
      name: "ELECT INSTL ONWH 1.5"
    },
    {
      code: "PELI17",
      name: "ELECT INSTL ONWH 1.7"
    },
    {
      code: "PELI20",
      name: "ELECT INSTAL ONWH 2"
    },
    {
      code: "PENG00",
      name: "PROJECT-ENGINEER"
    },
    {
      code: "PENG10",
      name: "PROJ-ENGIN NWH"
    },
    {
      code: "PENG15",
      name: "PROJ-ENGIN ONWH 1.5"
    },
    {
      code: "PENG17",
      name: "PROJECTENGINEER 1.75"
    },
    {
      code: "PENG18",
      name: "RESID PORTER 1.75"
    },
    {
      code: "PENG20",
      name: "PROJ-ENGIN ONWH 2"
    },
    {
      code: "PL0001",
      name: "PR.LABOUR NORM."
    },
    {
      code: "PMAD10",
      name: "PROJ-MOB ADMIN NWH"
    },
    {
      code: "PMAD15",
      name: "PROJ-MOB ADMIN ONWH"
    },
    {
      code: "PMAD20",
      name: "PROJ-MOB ADMIN ONWH"
    },
    {
      code: "PMAN00",
      name: "PROJECT-MANAGER"
    },
    {
      code: "PMAN10",
      name: "PROJ-MANAG NWH"
    },
    {
      code: "PMAN15",
      name: "PROJ-MANAG ONWH 1.5"
    },
    {
      code: "PMAN17",
      name: "PROJECTMANAGER 1.75"
    },
    {
      code: "PMAN18",
      name: "RESID SSKILL 1.75"
    },
    {
      code: "PMAN20",
      name: "PROJ-MANAG ONWH 2"
    },
    {
      code: "PMAS10",
      name: "PROJ-MOBASSISTANT NW"
    },
    {
      code: "PMAS15",
      name: "PROJ-MOBASSISTANT ON"
    },
    {
      code: "PMAS20",
      name: "PROJ-MOBASSISTANT ON"
    },
    {
      code: "PMCA10",
      name: "PROJ-MOB CAD ENG NWH"
    },
    {
      code: "PMCA15",
      name: "PROJ-MOB CAD ENG ONW"
    },
    {
      code: "PMCA20",
      name: "PROJ-MOB CAD ENG ONW"
    },
    {
      code: "PMEI00",
      name: "MECHANICAL INSTALLER"
    },
    {
      code: "PMEI10",
      name: "MECH INSTL NWH"
    },
    {
      code: "PMEI15",
      name: "MECH INSTL ONWH 1.5"
    },
    {
      code: "PMEI20",
      name: "MECH INSTL ONWH 2"
    },
    {
      code: "PMEL10",
      name: "PROJ MOB ELEC NWH"
    },
    {
      code: "PMEL15",
      name: "PROJ MOB ELEC ONWH 1"
    },
    {
      code: "PMEL20",
      name: "PROJ MOB ELEC ONWH 2"
    },
    {
      code: "PMEN10",
      name: "PROJ-MOB ENG NWH"
    },
    {
      code: "PMEN15",
      name: "PROJ-MOB ENG ONWH 1."
    },
    {
      code: "PMEN20",
      name: "PROJ-MOB ENG ONWH 2"
    },
    {
      code: "PMGT00",
      name: "PROJECT MANAGEMENT"
    },
    {
      code: "PMGT10",
      name: "PROJECT MGT NWH"
    },
    {
      code: "PMGT15",
      name: "PROJECT MGT ONWH 1.5"
    },
    {
      code: "PMGT20",
      name: "PROJECT MGT ONWH 2"
    },
    {
      code: "PMMA10",
      name: "PROJ-MOB MANAGER NWH"
    },
    {
      code: "PMMA15",
      name: "PROJ-MOB MANAGER ONW"
    },
    {
      code: "PMMA20",
      name: "PROJ-MOB MANAGER ONW"
    },
    {
      code: "PMME10",
      name: "PROJ-MOB MECH NWH"
    },
    {
      code: "PMME15",
      name: "PROJ-MOB MECH ONWH 1"
    },
    {
      code: "PMME20",
      name: "PROJ-MOB MECH ONWH 2"
    },
    {
      code: "PMRI10",
      name: "PROJ-MOB REFRIG NWH"
    },
    {
      code: "PMRI15",
      name: "PROJ-MOB REFRIG ONWH"
    },
    {
      code: "PMRI20",
      name: "PROJ-MOB REFRIG ONWH"
    },
    {
      code: "PMSE10",
      name: "PROJ-MOB SNR ENG NWH"
    },
    {
      code: "PMSE15",
      name: "PROJ-MOB SNR ENG ONW"
    },
    {
      code: "PMSE20",
      name: "PROJ-MOB SNR ENG ONW"
    },
    {
      code: "PRAA10",
      name: "PROJ-RES SALES NWH"
    },
    {
      code: "PRAD10",
      name: "PROJ-RES ADMIN NWH"
    },
    {
      code: "PRAD15",
      name: "PROJ-RES ADMIN ONWH"
    },
    {
      code: "PRAD20",
      name: "PROJ-RES ADMIN ONWH"
    },
    {
      code: "PRAP10",
      name: "PROJ-RES MATE NWH"
    },
    {
      code: "PRAP15",
      name: "PROJ-RES MATE NWH"
    },
    {
      code: "PRAS10",
      name: "PROJ-RESASSISTANT NW"
    },
    {
      code: "PRAS15",
      name: "PROJ-RESASSISTANT ON"
    },
    {
      code: "PRAS20",
      name: "PROJ-RESASSISTANT ON"
    },
    {
      code: "PRCA10",
      name: "PROJ-RES CAD ENG NWH"
    },
    {
      code: "PRCA15",
      name: "PROJ-RES CAD ENG ONW"
    },
    {
      code: "PRCA20",
      name: "PROJ-RES CAD ENG ONW"
    },
    {
      code: "PREI00",
      name: "REFRIG INSTALLER"
    },
    {
      code: "PREI10",
      name: "REFRIG INSTL NWH"
    },
    {
      code: "PREI15",
      name: "REFRIG INSTL ONWH 1."
    },
    {
      code: "PREI20",
      name: "REFRIG INSTL ONWH 2"
    },
    {
      code: "PREL10",
      name: "PROJ RES ELEC NWH"
    },
    {
      code: "PREL15",
      name: "PROJ RES ELEC ONWH 1"
    },
    {
      code: "PREL20",
      name: "PROJ RES ELEC ONWH 2"
    },
    {
      code: "PREN10",
      name: "PROJ-RES ENG NWH"
    },
    {
      code: "PREN15",
      name: "PROJ-RES ENG ONWH"
    },
    {
      code: "PREN20",
      name: "PROJ-RES ENG ONWH 2"
    },
    {
      code: "PRMA10",
      name: "PROJ-RES MANAGER NWH"
    },
    {
      code: "PRMA15",
      name: "PROJ-RES MANAGER ONW"
    },
    {
      code: "PRMA20",
      name: "PROJ-RES MANAGER ONW"
    },
    {
      code: "PRME10",
      name: "PROJ-RES MECH NWH"
    },
    {
      code: "PRME15",
      name: "PROJ-RES MECH ONWH 1"
    },
    {
      code: "PRME20",
      name: "PROJ-RES MECH ONWH 2"
    },
    {
      code: "PRNONH",
      name: "PROJ- HOLS"
    },
    {
      code: "PRNONS",
      name: "PROJ- SICK"
    },
    {
      code: "PRNONT",
      name: "PROJ- TRAINING/OTH"
    },
    {
      code: "PRPM00",
      name: "PRINCIPLE PM"
    },
    {
      code: "PRPM10",
      name: "PRINCIPLE PM NWH"
    },
    {
      code: "PRPM15",
      name: "PRINCIPLEPM ONWH 1.5"
    },
    {
      code: "PRPM20",
      name: "PRINCIPLE PM ONWH 2"
    },
    {
      code: "PRQS00",
      name: "PRINCIPLE QS"
    },
    {
      code: "PRQS10",
      name: "PRINCIPLE QS NWH"
    },
    {
      code: "PRQS15",
      name: "PRINCIPLEQS ONWH 1.5"
    },
    {
      code: "PRQS20",
      name: "PRINCIPLE QS ONWH 2"
    },
    {
      code: "PRRI10",
      name: "PROJ-RES REFRIG NWH"
    },
    {
      code: "PRRI15",
      name: "PROJ-RES REFRIG ONWH"
    },
    {
      code: "PRRI20",
      name: "PROJ-RES REFRIG ONWH"
    },
    {
      code: "PRSA10",
      name: "PROJ-RES SALES NWH"
    },
    {
      code: "PRSA15",
      name: "PROJ-RES SALES OT"
    },
    {
      code: "PRSE10",
      name: "PROJ-RES SNR ENG NWH"
    },
    {
      code: "PRSE15",
      name: "PROJ-RES SNR ENG ONW"
    },
    {
      code: "PRSE20",
      name: "PROJ-RES SNR ENG ONW"
    },
    {
      code: "PRSU10",
      name: "PROJ-RES SUP NWH"
    },
    {
      code: "PRSU15",
      name: "PROJ-RES SUP ONWH"
    },
    {
      code: "PRSU17",
      name: "PROJ-RES SUP ONWH"
    },
    {
      code: "PRSU18",
      name: "PROJ-RES SUP ONWH"
    },
    {
      code: "PRSU20",
      name: "PROJ-RES SUP ONWH 2"
    },
    {
      code: "PSEN00",
      name: "PROJECT-SENIOR ENGIN"
    },
    {
      code: "PSEN10",
      name: "PROJ-SENIO NWH"
    },
    {
      code: "PSEN15",
      name: "PROJ-SENIO ONWH 1.5"
    },
    {
      code: "PSEN20",
      name: "PROJ-SENIO ONWH 2"
    },
    {
      code: "QSER00",
      name: "QUANTITY SURVEYOR"
    },
    {
      code: "QSER10",
      name: "QS NWH"
    },
    {
      code: "QSER15",
      name: "QS ONWH 1.5"
    },
    {
      code: "QSER20",
      name: "QS ONWH 2"
    },
    {
      code: "RBRL10",
      name: "RESID BRICK"
    },
    {
      code: "RBRL15",
      name: "RESID BRICK ONWH 1.5"
    },
    {
      code: "RBRL17",
      name: "RESID BRICK 1.75"
    },
    {
      code: "RBRL18",
      name: "FM RESLANDSCPOP 1.75"
    },
    {
      code: "RBRL20",
      name: "RESID BRICK ONWH 2"
    },
    {
      code: "RCAR10",
      name: "RESID JANCARE"
    },
    {
      code: "RCAR15",
      name: "RESID JANCARE ONWH 1"
    },
    {
      code: "RCAR20",
      name: "RESID JANCARE ONWH 2"
    },
    {
      code: "RCAT00",
      name: "RESIDENT-CATERING"
    },
    {
      code: "RCAT10",
      name: "RESID-CATERI NWH"
    },
    {
      code: "RCAT15",
      name: "RESID-CATER ONWH 1.5"
    },
    {
      code: "RCAT17",
      name: "RESID-CATER ONWH 1.7"
    },
    {
      code: "RCAT18",
      name: "RESID-CATER ONWH 1.7"
    },
    {
      code: "RCAT20",
      name: "RESID-CATER ONWH 2"
    },
    {
      code: "RCHP10",
      name: "RESID CARP"
    },
    {
      code: "RCHP15",
      name: "RESID CARP ONWH 1.5"
    },
    {
      code: "RCHP17",
      name: "RESID CARP 1.75"
    },
    {
      code: "RCHP18",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "RCHP20",
      name: "RESID CARP ONWH 2"
    },
    {
      code: "RCMG00",
      name: "RESID CONT MANAGER"
    },
    {
      code: "RCMG10",
      name: "RESID CONT MGR NWH"
    },
    {
      code: "RCMG15",
      name: "RESID CONT MGR ONWH"
    },
    {
      code: "RCMG17",
      name: "RESID CONT MANAGER 1"
    },
    {
      code: "RCMG18",
      name: "RESID SSKILL 1.75"
    },
    {
      code: "RCMG20",
      name: "RESID CONT MGR ONWH"
    },
    {
      code: "RCOM00",
      name: "RESIDENT - COMBUSTIO"
    },
    {
      code: "RCOM10",
      name: "RESID-COMB NWH"
    },
    {
      code: "RCOM15",
      name: "RESID-COMB ONWH 1.5"
    },
    {
      code: "RCOM20",
      name: "RESID-COMBUS ONWH 2"
    },
    {
      code: "RCON00",
      name: "RESIDENT-CONTROLS"
    },
    {
      code: "RCON10",
      name: "RESID-CONTRO NWH"
    },
    {
      code: "RCON15",
      name: "RESID-CONTR ONWH 1.5"
    },
    {
      code: "RCON20",
      name: "RESID-CONTR ONWH 2"
    },
    {
      code: "RCOR10",
      name: "RESID COORD"
    },
    {
      code: "RCOR15",
      name: "RESID COORD ONWH 1.5"
    },
    {
      code: "RCOR17",
      name: "RESID COORD 1.75"
    },
    {
      code: "RCOR18",
      name: "FM RESDTOFFSUPP 1.75"
    },
    {
      code: "RCOR20",
      name: "RESID COORD ONWH 2"
    },
    {
      code: "RCOU10",
      name: "RESID COURIER"
    },
    {
      code: "RCOU15",
      name: "RESID COURIER ONWH 1"
    },
    {
      code: "RCOU20",
      name: "RESID COURIER ONWH 2"
    },
    {
      code: "READ10",
      name: "RESID ESTADMIN"
    },
    {
      code: "READ15",
      name: "RESID ESTADMIN ONWH"
    },
    {
      code: "READ20",
      name: "RESID ESTADMIN ONWH"
    },
    {
      code: "RELE00",
      name: "RESIDENT-ELECTRICAL"
    },
    {
      code: "RELE10",
      name: "RESID-ELECTR NWH"
    },
    {
      code: "RELE15",
      name: "RESID-ELECT ONWH 1.5"
    },
    {
      code: "RELE17",
      name: "RESIDENTELECTRICAL 1"
    },
    {
      code: "RELE18",
      name: "RESID INDCLN 1.75"
    },
    {
      code: "RELE20",
      name: "RESID-ELECT ONWH 2"
    },
    {
      code: "REPS00",
      name: "REPORT SPEC"
    },
    {
      code: "RETC10",
      name: "RESID ESCTECH"
    },
    {
      code: "RETC15",
      name: "RESID ESCTECH ONWH 1"
    },
    {
      code: "RETC20",
      name: "RESID ESCTECH ONWH 2"
    },
    {
      code: "RFAB10",
      name: "RESID FABRIC"
    },
    {
      code: "RFAB15",
      name: "RESID FABRIC 1.5"
    },
    {
      code: "RFAB17",
      name: "RESID FABRIC 1.75"
    },
    {
      code: "RFAB18",
      name: "RESID FABRIC 1.8"
    },
    {
      code: "RFAB20",
      name: "RESID FABRIC 2"
    },
    {
      code: "RFIC00",
      name: "RESIDENT FIRE COMM"
    },
    {
      code: "RFIC10",
      name: "RESID FIRE COMM NWH"
    },
    {
      code: "RFIC15",
      name: "RESID FIRE COMM ONWH"
    },
    {
      code: "RFIC17",
      name: "RESIDENT FIRE COMM 1"
    },
    {
      code: "RFIC18",
      name: "RESID CARP 1.75"
    },
    {
      code: "RFIC20",
      name: "RESID FIRE COMM ONWH"
    },
    {
      code: "RFIS00",
      name: "RESIDENT FIRE SERVIC"
    },
    {
      code: "RFIS10",
      name: "RESID FIRE SERV NWH"
    },
    {
      code: "RFIS15",
      name: "RESID FIRE SERV ONWH"
    },
    {
      code: "RFIS17",
      name: "RESIDENT FIRE SERVIC"
    },
    {
      code: "RFIS18",
      name: "RESIDENTELECTRICAL 1"
    },
    {
      code: "RFIS20",
      name: "RESID FIRE SERV ONWH"
    },
    {
      code: "RGEN10",
      name: "RESID GEN DUTIES"
    },
    {
      code: "RGEN15",
      name: "RESID GEN DUTIES 1.5"
    },
    {
      code: "RGEN17",
      name: "RESID GEN DUTIES 1.7"
    },
    {
      code: "RGEN18",
      name: "RESID GEN DUTIES 1.8"
    },
    {
      code: "RGEN20",
      name: "RESID GEN DUTIES 2"
    },
    {
      code: "RHAN00",
      name: "RESIDENT-HANDYMAN"
    },
    {
      code: "RHAN10",
      name: "RESID-HANDYM NWH"
    },
    {
      code: "RHAN15",
      name: "RESID-HANDY ONWH 1.5"
    },
    {
      code: "RHAN17",
      name: "RESIDENTHANDYMAN 1.7"
    },
    {
      code: "RHAN18",
      name: "RESIDENT FIRE COMM 1"
    },
    {
      code: "RHAN20",
      name: "RESID-HANDY ONWH 2"
    },
    {
      code: "RHDK10",
      name: "RESID HLPDSK"
    },
    {
      code: "RHDK15",
      name: "RESID HLPDSK ONWH 1."
    },
    {
      code: "RHDK17",
      name: "RESID HLPDSK ONWH1.7"
    },
    {
      code: "RHDK18",
      name: "RESID HLPDSK ONWH1.8"
    },
    {
      code: "RHDK20",
      name: "RESID HLPDSK ONWH 2"
    },
    {
      code: "RHLT10",
      name: "RESID HLTH"
    },
    {
      code: "RHLT15",
      name: "RESID HLTH ONWH 1.5"
    },
    {
      code: "RHLT17",
      name: "RESID HLTH 1.75"
    },
    {
      code: "RHLT18",
      name: "RESID CONT MANAGER 1"
    },
    {
      code: "RHLT20",
      name: "RESID HLTH ONWH 2"
    },
    {
      code: "RHSK10",
      name: "RESID HKEEP"
    },
    {
      code: "RHSK15",
      name: "RESID HKEEP ONWH 1.5"
    },
    {
      code: "RHSK17",
      name: "RESID HKEEP 1.75"
    },
    {
      code: "RHSK18",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "RHSK20",
      name: "RESID HKEEP ONWH 2"
    },
    {
      code: "RINS10",
      name: "RESID INSASST"
    },
    {
      code: "RINS15",
      name: "RESID INSASST ONWH 1"
    },
    {
      code: "RINS17",
      name: "RESID INSASST 1.75"
    },
    {
      code: "RINS18",
      name: "RESIDENTPLANT ASSIS"
    },
    {
      code: "RINS20",
      name: "RESID INSASST ONWH 2"
    },
    {
      code: "RLAB10",
      name: "RESID LAB"
    },
    {
      code: "RLAB15",
      name: "RESID LAB ONWH 1.5"
    },
    {
      code: "RLAB17",
      name: "RESID LAB 1.75"
    },
    {
      code: "RLAB18",
      name: "RESID PLAN 1.75"
    },
    {
      code: "RLAB20",
      name: "RESID LAB ONWH 2"
    },
    {
      code: "RLFT10",
      name: "RESID LIFTTCH"
    },
    {
      code: "RLFT15",
      name: "RESID LIFTTCH ONWH 1"
    },
    {
      code: "RLFT20",
      name: "RESID LIFTTCH ONWH 2"
    },
    {
      code: "RMEC00",
      name: "RESIDENT-MECHANICAL"
    },
    {
      code: "RMEC10",
      name: "RESID-MECH NWH"
    },
    {
      code: "RMEC15",
      name: "RESID-MECH ONWH 1.5"
    },
    {
      code: "RMEC17",
      name: "RESIDENTMECHANICAL 1"
    },
    {
      code: "RMEC18",
      name: "RESIDENT FIRE SERVIC"
    },
    {
      code: "RMEC20",
      name: "RESID-MECH ONWH 2"
    },
    {
      code: "RMSE00",
      name: "RESIDENT MULTISKILLE"
    },
    {
      code: "RMSE10",
      name: "RESID MULTI SKILL EN"
    },
    {
      code: "RMSE15",
      name: "RESID MULTI SKILL EN"
    },
    {
      code: "RMSE17",
      name: "RESIDENT MULTISKILLE"
    },
    {
      code: "RMSE18",
      name: "RESIDENTHANDYMAN 1.7"
    },
    {
      code: "RMSE20",
      name: "RESID MULTI SKILL EN"
    },
    {
      code: "ROTH00",
      name: "RESIDENT-OTHER"
    },
    {
      code: "ROTH10",
      name: "RESID-OTHER NWH"
    },
    {
      code: "ROTH15",
      name: "RESID-OTH ONWH 1.5"
    },
    {
      code: "ROTH17",
      name: "RESIDENTOTHER 1.75"
    },
    {
      code: "ROTH18",
      name: "RESIDENTMECHANICAL 1"
    },
    {
      code: "ROTH20",
      name: "RESID-OTH ONWH 2"
    },
    {
      code: "RPAT10",
      name: "RESID PATTEST"
    },
    {
      code: "RPAT15",
      name: "RESID PATTEST ONWH 1"
    },
    {
      code: "RPAT17",
      name: "RESID PATTEST ONWH 1"
    },
    {
      code: "RPAT18",
      name: "RESID PATTEST ONWH 1"
    },
    {
      code: "RPAT20",
      name: "RESID PATTEST ONWH 2"
    },
    {
      code: "RPLA00",
      name: "RESIDENT-PLANT ASSIS"
    },
    {
      code: "RPLA10",
      name: "RESID-PLANT NWH"
    },
    {
      code: "RPLA15",
      name: "RESID-PLANT ONWH 1.5"
    },
    {
      code: "RPLA17",
      name: "RESIDENTPLANT ASSIS"
    },
    {
      code: "RPLA18",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "RPLA20",
      name: "RESID-PLANT ONWH 2"
    },
    {
      code: "RPLN10",
      name: "RESID PLAN"
    },
    {
      code: "RPLN15",
      name: "RESID PLAN ONWH 1.5"
    },
    {
      code: "RPLN17",
      name: "RESID PLAN 1.75"
    },
    {
      code: "RPLN18",
      name: "RESDTCLEANER 1.75"
    },
    {
      code: "RPLN20",
      name: "RESID PLAN ONWH 2"
    },
    {
      code: "RPLU00",
      name: "RESIDENT-PLUMBING"
    },
    {
      code: "RPLU10",
      name: "RESID-PLUMBI NWH"
    },
    {
      code: "RPLU15",
      name: "RESID-PLUMB ONWH 1.5"
    },
    {
      code: "RPLU17",
      name: "RESIDENTPLUMBING 1.7"
    },
    {
      code: "RPLU18",
      name: "RESIDENT MULTISKILLE"
    },
    {
      code: "RPLU20",
      name: "RESID-PLUMB ONWH 2"
    },
    {
      code: "RPNT10",
      name: "RESID PAINTER"
    },
    {
      code: "RPNT15",
      name: "RESID PAINTER ONWH 1"
    },
    {
      code: "RPNT17",
      name: "RESID PAINTER 1.75"
    },
    {
      code: "RPNT18",
      name: "RESIDENTOTHER 1.75"
    },
    {
      code: "RPNT20",
      name: "RESID PAINTER ONWH 2"
    },
    {
      code: "RPOR10",
      name: "RESID PORTER"
    },
    {
      code: "RPOR15",
      name: "RESID PORTER ONWH 1."
    },
    {
      code: "RPOR17",
      name: "RESID PORTER 1.75"
    },
    {
      code: "RPOR18",
      name: "RESID PAINTER 1.75"
    },
    {
      code: "RPOR20",
      name: "RESID PORTER ONWH 2"
    },
    {
      code: "RPST10",
      name: "RESID POST"
    },
    {
      code: "RPST15",
      name: "RESID POST ONWH 1.5"
    },
    {
      code: "RPST17",
      name: "RESID POST 1.75"
    },
    {
      code: "RPST18",
      name: "RESID POST 1.75"
    },
    {
      code: "RPST20",
      name: "RESID POST ONWH 2"
    },
    {
      code: "RREC10",
      name: "RESID RECPT"
    },
    {
      code: "RREC15",
      name: "RESID RECPT ONWH 1.5"
    },
    {
      code: "RREC17",
      name: "RESID RECPT 1.7"
    },
    {
      code: "RREC18",
      name: "RESID RECPT 1.8"
    },
    {
      code: "RREC20",
      name: "RESID RECPT ONWH 2"
    },
    {
      code: "RREF00",
      name: "RESIDENT-REFRIG"
    },
    {
      code: "RREF10",
      name: "RESID-REFRIG NWH"
    },
    {
      code: "RREF15",
      name: "RESID-REFR ONWH 1.5"
    },
    {
      code: "RREF17",
      name: "RESIDENTREFRIG 1.75"
    },
    {
      code: "RREF18",
      name: "EDF CLERICAL 1.75"
    },
    {
      code: "RREF20",
      name: "RESID-REFRIG ONWH 2"
    },
    {
      code: "RSEC00",
      name: "RESIDENT SECURITY CO"
    },
    {
      code: "RSEC10",
      name: "RESID SECURITY COMM"
    },
    {
      code: "RSEC15",
      name: "RESID SECURITY COMM"
    },
    {
      code: "RSEC17",
      name: "RESIDENT SECURITY CO"
    },
    {
      code: "RSEC18",
      name: "PROJECTENGINEER 1.75"
    },
    {
      code: "RSEC20",
      name: "RESID SECURITY COMM"
    },
    {
      code: "RSES00",
      name: "RESIDENT SECURITY SE"
    },
    {
      code: "RSES10",
      name: "RESID SECURITY SERV"
    },
    {
      code: "RSES15",
      name: "RESID SECURITY SERV"
    },
    {
      code: "RSES17",
      name: "RESIDENT SECURITY SE"
    },
    {
      code: "RSES18",
      name: "PROJECTMANAGER 1.75"
    },
    {
      code: "RSES20",
      name: "RESID SECURITY SERV"
    },
    {
      code: "RSPE10",
      name: "RESID SPECIALIST"
    },
    {
      code: "RSPE15",
      name: "RESID SPECIALIST 1.5"
    },
    {
      code: "RSPE17",
      name: "RESID SPECIALIST 1.7"
    },
    {
      code: "RSPE18",
      name: "RESDTCLEANER 1.75"
    },
    {
      code: "RSPE20",
      name: "RESID SPECIALIST 2"
    },
    {
      code: "RSPR10",
      name: "RESID STECH"
    },
    {
      code: "RSPR15",
      name: "RESID STECH ONWH 1.5"
    },
    {
      code: "RSPR17",
      name: "RESID STECH 1.75"
    },
    {
      code: "RSPR18",
      name: "RESIDENTELECTRICAL 1"
    },
    {
      code: "RSPR20",
      name: "RESID STECH ONWH 2"
    },
    {
      code: "RSSE10",
      name: "RESID SSKILL"
    },
    {
      code: "RSSE15",
      name: "RESID SSKILL ONWH 1."
    },
    {
      code: "RSSE17",
      name: "RESID SSKILL 1.75"
    },
    {
      code: "RSSE18",
      name: "RESIDENTPLUMBING 1.7"
    },
    {
      code: "RSSE20",
      name: "RESID SSKILL ONWH 2"
    },
    {
      code: "RSSU00",
      name: "RESID SITE SUPERVISO"
    },
    {
      code: "RSSU10",
      name: "RESID SITE SUP NWH"
    },
    {
      code: "RSSU15",
      name: "RESID SITE SUP ONWH"
    },
    {
      code: "RSSU17",
      name: "RESID SITE SUPERVISO"
    },
    {
      code: "RSSU18",
      name: "RESID INDCLN 1.75"
    },
    {
      code: "RSSU20",
      name: "RESID SITE SUP ONWH"
    },
    {
      code: "RTSS10",
      name: "RESID TSSUP"
    },
    {
      code: "RTSS15",
      name: "RESID TSSUP ONWH 1.5"
    },
    {
      code: "RTSS20",
      name: "RESID TSSUP ONWH 2"
    },
    {
      code: "RWAT10",
      name: "RESID WATERTECH"
    },
    {
      code: "RWAT15",
      name: "RESID WATERTECH ONWH"
    },
    {
      code: "RWAT20",
      name: "RESID WATERTECH ONWH"
    },
    {
      code: "RWLD10",
      name: "RESID WELDER"
    },
    {
      code: "RWLD15",
      name: "RESID WELDER ONWH 1."
    },
    {
      code: "RWLD17",
      name: "RESID WELDER 1.75"
    },
    {
      code: "RWLD18",
      name: "FM RESDTSUPRVSR 1.75"
    },
    {
      code: "RWLD20",
      name: "RESID WELDER ONWH 2"
    },
    {
      code: "SHEL10",
      name: "SHFT ELECRICAL NWH"
    },
    {
      code: "SHEL15",
      name: "SHFT ELECRICL ONWH15"
    },
    {
      code: "SHEL20",
      name: "SHFT ELECRICAL ONWH2"
    },
    {
      code: "SHFH10",
      name: "SHFT FABRCHNDY NWH"
    },
    {
      code: "SHFH15",
      name: "SHFT FABRHNDY ONWH15"
    },
    {
      code: "SHFH20",
      name: "SHFT FABRCHNDY ONWH2"
    },
    {
      code: "SHLM10",
      name: "SHFT LEAD/MGR NWH"
    },
    {
      code: "SHLM15",
      name: "SHFT LEAD/MGR ONWH15"
    },
    {
      code: "SHLM20",
      name: "SHFTLEADMGR ONWH20"
    },
    {
      code: "SHME10",
      name: "SHFT MECH NWH"
    },
    {
      code: "SHME15",
      name: "SHFT MECH ONWH15"
    },
    {
      code: "SHME20",
      name: "SHFT MECH ONWH20"
    },
    {
      code: "SHMU10",
      name: "SHFT MULTISKIL NWH"
    },
    {
      code: "SHMU15",
      name: "SHFT MULTSKIL ONWH15"
    },
    {
      code: "SHMU17",
      name: "SHFT MULTISKIL ONWH1"
    },
    {
      code: "SHMU18",
      name: "SHFT MULTISKIL ONWH1"
    },
    {
      code: "SHMU20",
      name: "SHFT MULTISKIL ONWH2"
    },
    {
      code: "SHPA10",
      name: "SHFT PLNT ASSIST NWH"
    },
    {
      code: "SHPA15",
      name: "SHFT PLNT ASST ONW15"
    },
    {
      code: "SHPA20",
      name: "SHFT PLNT ASST ONWH2"
    },
    {
      code: "SHPL10",
      name: "SHFT PLMBNG NWH"
    },
    {
      code: "SHPL15",
      name: "SHFT PLMBNGONWH15"
    },
    {
      code: "SHPL20",
      name: "SHFT PLMBNG ONWH20"
    },
    {
      code: "SHRE10",
      name: "SHFT REFRIGERN NWH"
    },
    {
      code: "SHRE15",
      name: "SHFT REFRIGRN ONWH15"
    },
    {
      code: "SHRE20",
      name: "SHFT REFRIGERNONWH20"
    },
    {
      code: "SHSU10",
      name: "SHFT SUPRVSR NWH"
    },
    {
      code: "SHSU15",
      name: "SHFT SUPRVSR ONWH15"
    },
    {
      code: "SHSU20",
      name: "SHFT SUPRVSR ONWH20"
    },
    {
      code: "SPRO00",
      name: "SPECIAL - PROJECTS"
    },
    {
      code: "SPRO10",
      name: "SPEC PROJ NWH"
    },
    {
      code: "SPRO15",
      name: "SPEC PROJ ONWH 1.5"
    },
    {
      code: "SPRO20",
      name: "SPEC PROJ ONWH 2"
    },
    {
      code: "SUBC",
      name: "SUB-CONTRACTOR"
    },
    {
      code: "SYSD00",
      name: "SYSTEM DESIGN"
    },
    {
      code: "SYSD10",
      name: "SYSTEM DESIGN NWH"
    },
    {
      code: "SYSD15",
      name: "SYST DESIGN ONWH 1.5"
    },
    {
      code: "SYSD20",
      name: "SYST DESIGN ONWH 2"
    },
    {
      code: "TECM00",
      name: "TECH MGR"
    },
    {
      code: "TOCE00",
      name: "TOC EXEC"
    },
    {
      code: "TOCJ00",
      name: "TOC JUNIOR"
    },
    {
      code: "TRAINA",
      name: "TRAINING ATTENDANCE"
    },
    {
      code: "TRAINI",
      name: "TRAINING INSTRUCTOR"
    },
    {
      code: "TRAV10",
      name: "TRAVEL"
    },
    {
      code: "TRAV15",
      name: "TRAVEL"
    },
    {
      code: "TRAV20",
      name: "TRAVEL"
    },
    {
      code: "TRAVEL",
      name: "TRAVEL"
    },
    {
      code: ""
    }
  ]
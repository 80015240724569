<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            First & Second Line Approval
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <div class="mt-6">
          <label 
            for="approval_comments"
            class="block text-sm font-medium text-gray-700"
          >Who provided HROps approval for this leaver?:</label>
          <input
            :value="props.data?.leaver_notifyHROpsApproval?.approverEmail"
            type="text"
            name="approval_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <p class="text-sm">The second line approver is the HR professional who will ensure all the correct policies and approvals have
            been adhered to. If further approvals are required, they will co-ordinate this with the business outside the
            system and keep records locally for audit purposes.
        </p>

        <div class="mt-6">
          <label 
            for="second_line_date"
            class="block text-sm font-medium text-gray-700"
          >Approval date:</label>
          <input
            :value="
              formatDate(props.data?.leaver_notifyHROpsApproval?.timeStamp, 'DD/MM/yyyy') === 'Invalid date' ?
                '' :
                formatDate(props.data?.leaver_notifyHROpsApproval?.timeStamp, 'DD/MM/yyyy')
            "
            type="text"
            name="second_line_date"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { defineProps, inject } from "vue"

const props = defineProps(['data', 'disableForm']);
const formatDate = inject('formatDate');

</script>

<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Security</h3>
        </div>
        <!-- end title header -->

        <!-- Form -->

        <!-- Security Role -->
        <div>
          <div class="grid grid-cols-3 gap-6">
            <div>
              <p>Is this a Security Role? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityRole-yes" name="securityRole" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :checked="formData.securityRole === 'Yes'" @change="handleRadioChange"
                    @blur="v$.formData.securityRole.$touch()" :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :disabled="showApprovalSteps" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityRole" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityRole-no" name="securityRole" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :disabled="showApprovalSteps" :checked="formData.securityRole === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityRole.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityRole-no" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityRole.$errors.length" class="text-red-500">
                * {{ v$.formData.securityRole.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- End Security Role -->
          <div v-if="formData.securityRole === 'Yes'">

          <!-- Security SIA -->
          <div class="
              grid grid-cols-3
              gap-6
              sm:border-t sm:border-gray-200 sm:pt-5
              mt-4
            ">
            <div>
              <p>Is a Security Industry Authority Licence (SIA) Required? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securitySIA-yes" name="securitySIA" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :checked="formData.securitySIA === 'Yes'" :disabled="showApprovalSteps"
                    @change="handleRadioChange" @blur="v$.formData.securitySIA.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securitySIA" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securitySIA-no" name="securitySIA" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :checked="formData.securitySIA === 'No'" :disabled="showApprovalSteps"
                    @change="handleRadioChange" @blur="v$.formData.securitySIA.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securitySIA" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securitySIA.$errors.length" class="text-red-500">
                * {{ v$.formData.securitySIA.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- End Security SIA -->

          <!-- Security Check -->
          <div class="
              grid grid-cols-3
              gap-6
              sm:border-t sm:border-gray-200 sm:pt-5
              mt-4
            ">
            <div>
              <p>Is a Security Check (BS7858) Required? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityCheck-yes" name="securityCheck" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :disabled="showApprovalSteps" :checked="formData.securityCheck === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityCheck.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityCheck" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityCheck-no" name="securityCheck" type="radio" class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    " :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }" :disabled="showApprovalSteps" :checked="formData.securityCheck === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityCheck.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityCheck" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityCheck.$errors.length" class="text-red-500">
                * {{ v$.formData.securityCheck.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm text-red-500" v-show="formData.securityCheck === 'Yes'">
              if a BS7858 is required, please refer to the Recruitment Guidance
              Notes to complete the Security Guard Application Assessment and
              forward this directly to the external vetting company, 15C
            </p>
          </div>

          <!-- End Security Check -->

          <!-- Security Check -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>
                Have the preliminary checks been carried out for BS7858 Security
                Clearance? *
              </p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityPreliminary-yes" name="securityPreliminary" type="radio"
                    class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityPreliminary === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityPreliminary.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityPreliminary" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityPreliminary-no" name="securityPreliminary" type="radio"
                    class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityPreliminary === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityPreliminary.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityPreliminary" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityPreliminary.$errors.length" class="text-red-500">
                * {{ v$.formData.securityPreliminary.$errors[0].$message }}
              </span>
            </div>
            <!-- End Security Check -->

            <p class="text-sm text-red-500">
              Please note a start date cannot be confirmed unless the preliminary
              checks are complete and we may need to return the ATR to you whilst
              the checks are being completed.
            </p>
          </div>

          <!-- Security BSVR -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>
                Is a Security Baseline Standard Verification Record (BSVR) Check
                Required? (EDF Only)? *
              </p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityBSVR-yes" name="securityBSVR" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityBSVR === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityBSVR.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityBSVR" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityBSVR-no" name="securityBSVR" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :checked="formData.securityBSVR === 'No'" :disabled="showApprovalSteps"
                    @change="handleRadioChange" @blur="v$.formData.securityBSVR.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityBSVR" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityBSVR.$errors.length" class="text-red-500">
                * {{ v$.formData.securityBSVR.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm text-red-500" v-show="formData.securityBSVR === 'Yes'">
              A BSVR check will include a CTC check, both of which are arranged and managed locally by site.
            </p>
          </div>
          <!-- End Security BSVR -->

          <!-- Security Government -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>Is Government Level Clearance Required? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityGovernment-yes" name="securityGovernment" type="radio"
                    class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :checked="formData.securityGovernment === 'Yes'" :disabled="showApprovalSteps"
                    @change="handleRadioChange" @blur="v$.formData.securityGovernment.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityGovernment" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityGovernment-no" name="securityGovernment" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityGovernment === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityGovernment.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityGovernment" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityGovernment.$errors.length" class="text-red-500">
                * {{ v$.formData.securityGovernment.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm text-red-500" v-if="formData.securityGovernment === 'Yes'">
              The Government Level Clearance process is arranged and managed locally by site.
            </p>
          </div>
          <!-- End Security Government -->

          <div v-show="formData.securityGovernment === 'Yes'">
            <!-- Counter Terror -->
            <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
              <div>
                <p>Is Counter Terrorism Check Required? *</p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Yes" id="radio-button-counterTerrorism-yes" name="counterTerrorism" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.counterTerrorism === 'Yes'" @change="handleRadioChange"
                      @blur="v$.formData.counterTerrorism.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="counterTerrorism" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="No" id="radio-button-counterTerrorism-no" name="counterTerrorism" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.counterTerrorism === 'No'" @change="handleRadioChange"
                      @blur="v$.formData.counterTerrorism.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="counterTerrorism" class="font-medium text-gray-700 cursor-pointer">No</label>
                  </div>
                </div>
                <span v-if="v$.formData.counterTerrorism.$errors.length" class="text-red-500">
                  * {{ v$.formData.counterTerrorism.$errors[0].$message }}
                </span>
              </div>
            </div>
            <!-- End Counter Terror -->

            <!-- security Hidden -->
            <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
              <div>
                <p>Is a Security Check Required? *</p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Yes" id="radio-button-securityHidden-yes" name="securityHidden" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.securityHidden === 'Yes'" @change="handleRadioChange"
                      @blur="v$.formData.securityHidden.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="securityHidden" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="No" id="radio-button-securityHidden-no" name="securityHidden" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.securityHidden === 'No'" @change="handleRadioChange"
                      @blur="v$.formData.securityHidden.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="securityHidden" class="font-medium text-gray-700 cursor-pointer">No</label>
                  </div>
                </div>
                <span v-if="v$.formData.securityHidden.$errors.length" class="text-red-500">
                  * {{ v$.formData.securityHidden.$errors[0].$message }}
                </span>
              </div>
            </div>
            <!-- End security Hidden -->

            <!-- developedVetting -->
            <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
              <div>
                <p>Is a Developed Vetting Check Required? *</p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Yes" id="radio-button-developedVetting-yes" name="developedVetting" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.developedVetting === 'Yes'" @change="handleRadioChange"
                      @blur="v$.formData.developedVetting.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="developedVetting" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="No" id="radio-button-developedVetting-no" name="developedVetting" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :checked="formData.developedVetting === 'No'" @change="handleRadioChange"
                      @blur="v$.formData.developedVetting.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="developedVetting" class="font-medium text-gray-700 cursor-pointer">No</label>
                  </div>
                </div>
                <span v-if="v$.formData.developedVetting.$errors.length" class="text-red-500">
                  * {{ v$.formData.developedVetting.$errors[0].$message }}
                </span>
              </div>
            </div>
            <!-- End developedVetting -->
          </div>

          <!-- Security DWP -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>
                Is a specific DWP Baseline Security Standard (BPSS) Check
                Required? (DWP Only)? *
              </p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityDWP-yes" name="securityDWP" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityDWP === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityDWP.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityDWP" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityDWP-no" name="securityDWP" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityDWP === 'No'" @change="handleRadioChange"
                    @blur="v$.formData.securityDWP.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityDWP" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityDWP.$errors.length" class="text-red-500">
                * {{ v$.formData.securityDWP.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- End Security DWP -->

          <!-- Security Mobile -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>Is this a Security Role Carrying Out Mobile Patrols? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityMobile-yes" name="securityMobile" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityMobile === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityMobile.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityMobile" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityDWP-no" name="securityMobile" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityMobile === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityMobile.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityMobile" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <span v-if="v$.formData.securityMobile.$errors.length" class="text-red-500">
                * {{ v$.formData.securityMobile.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- End Security Mobile -->

          <!-- Security Before -->
          <div class="
            grid grid-cols-3
            gap-6
            sm:border-t sm:border-gray-200 sm:pt-5
            mt-4
          ">
            <div>
              <p>Can Employee Start Before Checks Complete? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="Yes" id="radio-button-securityBefore-yes" name="securityBefore" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityBefore === 'Yes'"
                    @change="handleRadioChange" @blur="v$.formData.securityBefore.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityBefore" class="font-medium text-gray-700 cursor-pointer">Yes</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="No" id="radio-button-securityBefore-no" name="securityBefore" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityBefore === 'No'"
                    @change="handleRadioChange" @blur="v$.formData.securityBefore.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityBefore" class="font-medium text-gray-700 cursor-pointer">No</label>
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input value="N/A" id="radio-button-securityBefore-na" name="securityBefore" type="radio" class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                  " :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }" :disabled="showApprovalSteps" :checked="formData.securityBefore === 'N/A'"
                    @change="handleRadioChange" @blur="v$.formData.securityBefore.$touch()" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="securityBefore" class="font-medium text-gray-700 cursor-pointer">N/A</label>
                </div>
              </div>
              <span v-if="v$.formData.securityBefore.$errors.length" class="text-red-500">
                * {{ v$.formData.securityBefore.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- End Security Before -->
        </div>

          </div>
        <!-- End Form -->
      </div>
    </div>
    <!-- Save button -->
    <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button class="
          mr-2
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        " @click.prevent="handleResetForm">
        Reset Form
      </button>
      <button :disabled="v$.$invalid" type="submit" class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        " @click="handleSave">
        Next
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";

export default {
  name: "formNine",
  props: ["formData", "showApprovalSteps"],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData
    };
  },
  validations() {
    return {
      formData: {
        securityRole: {
          required,
        },
        securitySIA: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityCheck: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityPreliminary: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityBSVR: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityGovernment: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        counterTerrorism: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityGovernment === 'Yes')
          )
        },
        securityHidden: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityGovernment === 'Yes')
          )
        },
        developedVetting: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityGovernment === 'Yes')
          )
        },
        securityDWP: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityMobile: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
        securityBefore: {
          requiredIf: helpers.withMessage(
            'Value is required',
            requiredIf(this.formData.securityRole === 'Yes')
          ),
        },
      },
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formNine",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formNine');
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formNine', formData: this.model });
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
};
</script>

<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Contract Details
        </h3>
        <div>
            <div class="grid grid-cols-3 gap-6 ">
                <div class="col-span-6">
            <label 
              for="contractType"
              class="block text-sm font-medium text-gray-700"
            >Contract Type *</label>
            <select
              :value="formData.contractType"
              name="contractType"
              id="contract-type-input"
              autocomplete="name"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
              :disabled="showApprovalSteps"
              @change="handleInputChange"
              @blur="v$.formData.contractType.$touch()"
            >
              <template v-if="showApprovalSteps">
                <option
                  v-for="type in configData.CONTRACTS"
                  :key="type.ID"
                  :value="type.ContractType"
                >{{ type.ContractType }}</option>
              </template>
              <template v-else>
                <option
                  v-for="type in activeContracts"
                  :key="type.ID"
                  :value="type.ContractType"
                >{{ type.ContractType }}</option>
              </template>
            </select>
            <!-- error message -->
            <span v-if="v$.formData.contractType.$errors.length" class="text-red-500">
              * {{ v$.formData.contractType.$errors[0].$message }}
            </span>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                <div class="col-span-6">
            <label 
              for="contractLevel"
              class="block text-sm font-medium text-gray-700"
            >Contract Level *</label>
            <select
              :value="formData.contractLevel"
              name="contractLevel"
              id="contract-level-input"
              autocomplete="name"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
              :disabled="showApprovalSteps"
              @change="handleInputChange"
              @blur="v$.formData.contractLevel.$touch()"
            >
            <template v-if="showApprovalSteps">
              <option v-for="cl in contractLevels" :key="cl.Contract" :value="cl.ContractName">{{ cl.ContractName }}</option>
            </template>
            <template v-else>
              <option v-for="cl in activeContractLevels" :key="cl.Contract" :value="cl.ContractName">{{ cl.ContractName }}</option>
            </template>
            </select>
            <!-- error message -->
            <span v-if="v$.formData.contractLevel.$errors.length" class="text-red-500">
              * {{ v$.formData.contractLevel.$errors[0].$message }}
            </span>
            <p class="mt-2 block text-sm font-medium text-red-500 italic">
              * Confirmation and approval of the contract level is subject to change by the HR Approver.
            </p>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-6 mt-4">
                <div v-if="currentContractLevel" class="grid col-span-6">
            <p class="mt-4"><span class="text-blue-700 font-bold">Pension: </span>{{ currentContractLevel.PensionScheme }}</p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Life Cover: </span>{{ currentContractLevel.LifeCover }}</p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Private Medical Insurance: </span> {{ currentContractLevel.PMI }}</p>
            <p class="mt-4">
              <span class="text-blue-700">
                Car: </span>{{ currentContractLevel.Car }}<br>
                <span class="italic text-sm">
                  Where applicable, a car allowance will be offered instead of a car if the contract is &gt; 12 months duration
                </span>
            </p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Bonus %: </span>{{ currentContractLevel.Bonus }}</p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Overtime: </span>
              {{ currentContractLevel.OvertimeEligibility }}<br>
                <span v-if="currentContractLevel.OvertimeNote" class="italic text-sm">
                  {{ currentContractLevel.OvertimeNote }}
                </span>
              </p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Annual Leave Entitlement (Per Annum): </span>{{ currentContractLevel.Holidays }}<br>
              <span v-if="currentContractLevel.HolidaysCarryOver" class="italic text-sm">
                  {{ currentContractLevel.HolidaysCarryOver }}
                </span>
            </p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Sickness: </span>{{ currentContractLevel.Sickness }}</p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Probation Period: </span>{{ currentContractLevel.ProbationPeriod }}</p>
            <p class="mt-4"><span class="text-blue-700 font-bold">Notice Period: </span>{{ currentContractLevel.NoticePeriod }}</p>
                </div>
            </div>

            <template v-if="bonusType">
              <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4" />
                  <label 
                    for="bonusType"
                    class="text-base font-medium text-gray-900 block"
                  >Please confirm the bonus type *</label>
                  <select 
                    :value="formData.bonusType" 
                    name="bonusType" 
                    id="bonusType"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    @change="handleInputChange">                    
                    <option v-for="bt in bonusTypes" :key="bt" :value="bt">{{ bt }}</option>
                  </select>
            </template>
            
            <div class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <fieldset class="col-span-6">
            <legend class="text-base font-medium text-gray-900">
              What is the proposed salary basis? *
            </legend>
            <span v-if="v$.formData.salaryBasis.$errors.length" class="text-red-500">
              * {{ v$.formData.salaryBasis.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-basis-per-annum"
                      name="salaryBasis"
                      type="radio"
                      value="Per Annum"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.salaryBasis === 'Per Annum'"
                      @change="handleRadioChange"
                      @blur="v$.formData.salaryBasis.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="salaryBasis" class="font-medium text-gray-700"
                      >Per Annum</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-basis-per-hour"
                      name="salaryBasis"
                      type="radio"
                      value="Per Hour"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.salaryBasis === 'Per Hour'"
                      @change="handleRadioChange"
                      @blur="v$.formData.salaryBasis.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="salaryBasis" class="font-medium text-gray-700"
                      >Per Hour</label
                    >
                  </div>
                </div>
              </div>
            </div>
                </fieldset>
            </div>

            <div class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div class="col-span-6 flex flex-col">
                          <label 
                            for="minimumPayRate"
                            class="block text-sm font-medium text-gray-700"
                          >What is the minimum proposed salary or hourly rate? *</label>
                          <div class="flex">
                            <input
                              :value="formData.minimumPayRate"
                              type="number"
                              min="0"
                              name="minimumPayRate"
                              id="minimum-pay-rate-input"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                              :disabled="showApprovalSteps"
                              @input="handleInputChange"
                              @blur="v$.formData.minimumPayRate.$touch()"
                            >
                            <select
                              :value="formData.minimumPayRateCurrency"
                              name="minimumPayRateCurrency"
                              class="mt-1 ml-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                              @change="handleInputChange"
                            >
                              <option value="GBP" selected>GBP</option>
                              <option value="EUR">EUR</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                          <!-- error message -->
                          <span v-if="v$.formData.minimumPayRate.$errors.length" class="text-red-500">
                            * {{ v$.formData.minimumPayRate.$errors[0].$message }}
                          </span>
              </div>
            </div>

            <div class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div class="col-span-6 flex flex-col">
                          <label 
                            for="maximumPayRate"
                            class="block text-sm font-medium text-gray-700"
                          >What is the maximum proposed salary or hourly rate? *</label>
                          <div class="flex">
                            <input
                              :value="formData.maximumPayRate"
                              type="number"
                              min="0"
                              name="maximumPayRate"
                              id="maximum-pay-rate-input"
                              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                              :disabled="showApprovalSteps"
                              @input="handleInputChange"
                              @blur="v$.formData.maximumPayRate.$touch()"
                            >
                            <select
                              :value="formData.maximumPayRateCurrency"
                              name="maximumPayRateCurrency"
                              class="mt-1 ml-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                              @change="handleInputChange"
                            >
                              <option value="GBP" selected>GBP</option>
                              <option value="EUR">EUR</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                          <!-- error message -->
                          <span v-if="v$.formData.maximumPayRate.$errors.length" class="text-red-500">
                            * {{ v$.formData.maximumPayRate.$errors[0].$message }}
                          </span>
              </div>
            </div>

            <div class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <fieldset class="col-span-6">
            <legend class="text-base font-medium text-gray-900">
              Are there any additional allowances associated with this role? *
            </legend>
            <span v-if="v$.formData.additionalAllowances.$errors.length" class="text-red-500">
              * {{ v$.formData.additionalAllowances.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-additional-allowances-yes"
                      name="additionalAllowances"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.additionalAllowances === 'Yes'"
                      @change="handleRadioChange"
                      @blur="v$.formData.additionalAllowances.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="additionalAllowances" class="font-medium text-gray-700"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-additional-allowances-no"
                      name="additionalAllowances"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.additionalAllowances === 'No'"
                      @change="handleRadioChange"
                      @blur="v$.formData.additionalAllowances.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="additionalAllowances" class="font-medium text-gray-700"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
              </fieldset>
            </div>

            <div class="mt-4 sm:border-t sm:border-gray-200 sm:pt-5" v-if="model.additionalAllowances === 'Yes'">
              <div class="p-2 m-4 border border-gray-200 rounded" v-for="(aa, index) in model.additionalAllowancesInformation" :key="aa">
                <div class="col-span-6">
                  <div class="flex justify-between">
                    <label
                      for="specify"
                      class="block text-sm font-medium text-gray-700"
                      >Please Specify
                      *</label
                    >
                    <XIcon v-if="index !== 0" class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click.prevent="removeAllowance(index)" />
                  </div>
                  <select
                    id="aspecify"
                    name="additionalAllowancesInformation"
                    autocomplete="specify"
                    :value="aa.additionalAllowancesInformation"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    @change="handleAllowanceChange($event, index)"
                  >
                    <option selected="selected" disabled="true" value="selected">
                      Please select
                    </option>
                    <option value="Allowance">Allowance</option>
                    <option value="First Aid">First Aid</option>
                    <option value="Fixed Monthly Unsocial Allowance">Fixed Monthly Unsocial Allowance</option>
                    <option value="Flexable Allowance">Flexable Allowance</option>
                    <option value="Honorarium (NTC">Honorarium (NTC)</option>
                    <option value="London Allowance">London Allowance</option>
                    <option value="Night Allowance">Night Allowance</option>
                    <option value="On Call / Call Out">On Call / Call Out</option>
                    <option value="Shift Allowance">Shift Allowance</option>
                    <option value="Site Supplement">Site Supplement</option>
                    <option value="Standby">Standby</option>
                    <option value="Travel Allowance">Travel Allowance</option>
                    <option value="Weekend Allowance">Weekend Allowance</option>
                  </select>
                </div>
                <div class="col-span-6 mt-2">
                  <div class="flex">
                    <div class="w-full">
                      <label 
                        for="additional-allowance-title"
                        class="block text-sm font-medium text-gray-700"
                      >Specify Value (per month)</label>
                      <input
                        :value="aa.additionalAllowanceAmount"
                        type="number"
                        min="0"
                        name="additionalAllowanceAmount"
                        id="additional-allowance-amount"
                        autocomplete="name"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        @input="handleAllowanceChange($event, index)"
                      >
                    </div>
                    <div class="ml-2">
                      <label 
                        for="additional-allowance-title"
                        class="block text-sm font-medium text-gray-700"
                      >Currency:</label>
                      <select
                        id="aspecify"
                        name="additionalAllowancesCurrency"
                        autocomplete="specify"
                        :value="aa.additionalAllowancesCurrency"
                        class="
                          mt-1
                          w-24
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          shadow-sm
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        @change="handleAllowanceChange($event, index)"                        
                      >
                        <option selected value="GBP">GBP</option>
                        <option value="EUR">EUR</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                    
                  </div>
                  
                </div>
              </div>
              <div class="flex justify-end mt-4">
                <button class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click.prevent="addAllowance">Add allowance +</button>
              </div>
            </div>

            <div class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <fieldset class="col-span-6">
            <legend class="text-base font-medium text-gray-900">
              What is the payroll frequency for this role? *
            </legend>
            <span v-if="v$.formData.payrollFrequency.$errors.length" class="text-red-500">
              * {{ v$.formData.payrollFrequency.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-payroll-frequency-weekly"
                      name="payrollFrequency"
                      type="radio"
                      value="Weekly"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.payrollFrequency === 'Weekly'"
                      @change="handleRadioChange"
                      @blur="v$.formData.payrollFrequency.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="payroll-frequency-weekly" class="font-medium text-gray-700"
                      >Weekly</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-payroll-frequency-monthly"
                      name="payrollFrequency"
                      type="radio"
                      value="Monthly"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.payrollFrequency === 'Monthly'"
                      @change="handleRadioChange"
                      @blur="v$.formData.payrollFrequency.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="payroll-frequency-monthly" class="font-medium text-gray-700"
                      >Monthly</label
                    >
                  </div>
                </div>
              </div>
            </div>
              </fieldset>
            </div>
        </div>
      </div>
      <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click="handleSave"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators"
import { XIcon } from '@heroicons/vue/outline'

export default {
  name: 'FormSix',
  props: ['formData', 'configData', 'showApprovalSteps'],
  components: { XIcon },
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
      bonusTypes: ['Embedded Support', 'Support Functions', 'Profit Centre Management', 'Project Manager', 'Sales Manager']
    }
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit('submit-form', { name: "formSix", formData: this.formData });
      } else {
        this.store.dispatch("setMessage", {
          type: 'error',
          title: 'ERROR!',
          message: 'Please fill out all the required fields'
        })
      }
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formSix', formData: this.model });
    },
    handleAllowanceChange(event, index) {
      const { name, value } = event.currentTarget;
      this.model.additionalAllowancesInformation[index][name] = value;
      this.$emit('update:formData', { formName: 'formSix', formData: this.model });
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formSix', formData: this.model });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formSix');
    },
    addAllowance() {
      this.model.additionalAllowancesInformation.push({
        additionalAllowancesTitle: "",
        additionalAllowancesInformation: "",
        additionalAllowancesCurrency: "GBP",
      });
      this.$emit('update:formData', { formName: 'formSix', formData: this.model });
    },
    removeAllowance(index) {
      this.model.additionalAllowancesInformation.splice(index, 1);
      this.$emit('update:formData', { formName: 'formSix', formData: this.model });
    }
  },
  computed: {
    activeContracts() {
      return this.configData.CONTRACTS.filter((contract) => contract.LKStatus);
    },
    activeContractLevels() {
      return this.configData.CONTRACTS.find((contract) => contract.ContractType === this.formData.contractType)?.Items?.filter((item) => item.LKStatus);
    },
    bonusType() {
      const contract = this.formData?.contractType;
      const level = this.formData?.contractLevel;
      const levelTypes = [1,2,3,4]
      const value = new RegExp(levelTypes.join('|')).test(level)

      switch (contract) {
        case 'EQUANS':
        case 'Qwest':
          return value
        default:
          return false
      }
    },
    contractLevels() {
      const contract = this.configData.CONTRACTS.find((cont) => cont.ContractType === this.formData.contractType);
      if (contract) {
        return contract.Items;
      }
      return [];
    },
    currentContractLevel() {
      const contract = this.configData.CONTRACTS.find((cont) => cont.ContractType === this.formData.contractType);
      const level = contract?.Items.find((l) => l.ContractName === this.formData.contractLevel);
      return level;
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    },
    'model.additionalAllowances' (newVal) {
      if (newVal === 'Yes') {
        this.model.additionalAllowancesInformation = [
          {
            additionalAllowancesTitle: "",
            additionalAllowancesInformation: "",
            additionalAllowancesCurrency: 'GBP'
          }
        ]
      } else {
        this.model.additionalAllowancesInformation = []
      }
    }
  },
  validations() {
    return {
      formData: {
        contractType: {
          required
        },
        contractLevel: {
          required
        },
        salaryBasis: {
          required
        },
        minimumPayRate: {
          required
        },
        minimumPayRateCurrency: {
          required
        },
        maximumPayRate: {
          required
        },
        maximumPayRateCurrency: {
          required
        },
        additionalAllowances: {
          required
        },
        payrollFrequency: {
          required
        }
      }
    }
  },
  
}
</script>
<template>
  <loading-spinner v-if="isLoading" />
  <authenticated-layout v-if="userIsAuthenticated">
    <notification-component />
    <div class="min-h-full">
      <div>
        <main>
          <div>
            <span style="display:none"><ThemeToggler/></span>
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </authenticated-layout>
</template>
<style>
  @import './assets/styles/print.css';
</style>
<script>
import { mapGetters } from "vuex";
import { Auth } from "aws-amplify";
import httpClient from '@/httpClient'
import ThemeToggler from "./components/themeToggle.vue";
import authenticatedLayout from './layouts/authenticatedLayout.vue'
import notificationComponent from './components/notificationComponent.vue'
import loadingSpinner from './components/loadingSpinner.vue'
import { clearStorage } from './storageSave';

export default {
  name: "App",
  components: { authenticatedLayout, ThemeToggler, notificationComponent, loadingSpinner },
  data() {
    return {
      loaded: false,
      user: {}
    }
  },
  methods: {
    signOut: async () => {
      try {
        await Auth.signOut()
        clearStorage('auth');
      } catch (err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'ERROR!',
          message: 'There was an error signing you out.'
        })
      }
    },
    fetchUserProfile: async () => {
      try {
        const userID = Auth.user.signInUserSession.idToken.payload.identities[0].userId.split('@')[0]
        const response = await httpClient.post(
          'users/user',
          { userID }
        );
        return response.data.body.Item;
      } catch (err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'ERROR!',
          message: 'Could not get user profile data.'
        });
      }
    },
    refreshUser: async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        await currentUser.refreshSession(currentSession.refreshToken, (err, session) => {
          if (err) {
            Auth.federatedSignIn();
            return;
          }
          return session;
        });
      } catch (err) {
        console.log('refresh error', err);
        Auth.federatedSignIn();
      }
    }
  },
  async beforeMount() {
    this.$store.dispatch('setIsLoading', true);
    this.$store.dispatch("initTheme");
    try {
      await Auth.currentAuthenticatedUser();
      await Auth.currentSession();
      this.$store.dispatch('setIsLoading', false);
      this.loaded = true;
      this.user = await this.fetchUserProfile();
      if (!this.user?.userID) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'Could not find your user profile, please contact Shared Services at ssc-hiretoretire.uk@equans.com'
        });
        setTimeout(async () => await Auth.federatedSignIn(), 5000);
      } else {
        const userProfile = {
          ...Auth?.user.signInUserSession.idToken.payload,
          ...this.user
        }
        this.$store.dispatch('setProfile', userProfile);
        this.$store.dispatch('setIsLoading', false);
        setInterval(async () => {
          await this.refreshUser();
        }, 1800000)
      }
    } catch (err) {
      await Auth.federatedSignIn();
      this.$store.dispatch('setIsLoading', false);
    }
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
    userIsAuthenticated() {
      if (this.loaded) {
        return !!Auth?.user?.signInUserSession?.accessToken?.jwtToken || false;
      }
      return false;
    },
    isLoading() {
      return this.$store.getters.isLoading
    }
  },
  watch: {
    // eslint-disable-next-line
    theme(newTheme, oldTheme) {
      newTheme === "light"
        ? document.querySelector("html").classList.remove("dark")
        : document.querySelector("html").classList.add("dark");
    },
  },
  provide() {
    return {
      signOut: this.signOut
    }
  }
};
</script>

<template>
  <div
    :style="sizeStyle"
    class="
      inline-block
      rounded-full
      bg-gradient-to-r
      from-green-700
      to-green-700
      flex
      items-center
      justify-center
    ">
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt=""
    />
    <span class="text-white" :class="fontSize" v-else>{{ userInitials }}</span>
  </div>
</template>
<script>
import { useStore } from 'vuex';

export default {
  props: ['size', 'imageUrl', 'username'],
  data() {
    return {
      store: useStore()
    }
  },
  computed: {
    sizeStyle() {
      let size = '';
      if (this.size === 'xs') size = '1.5'
      else if (this.size === 'sm') size = '2'
      else if (this.size === 'md') size = '3'
      else if (this.size === 'lg') size = '5'
      else if (this.size === 'xl') size = '8'
      return `height: ${size}rem; width: ${size}rem`;
    },
    fontSize() {
      let fontClass = '';
      if (this.size === 'xs') fontClass = 'text-xs'
      else if (this.size === 'sm') fontClass = 'text-sm'
      else if (this.size === 'md') fontClass = 'text-lg'
      else if (this.size === 'lg') fontClass = 'text-2xl'
      else if (this.size === 'xl') fontClass = 'text-5xl'
      return fontClass
    },
    userInitials() {
      const firstName = this.username?.split(' ')[0];
      const surname = this.username?.split(' ')[1];
      return firstName && surname ? firstName[0] + surname[0] : "";
    }
  }
}
</script>
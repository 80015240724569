<template>
  <div>
    <h3 class="leading-6 font-medium text-gray-900">Typical working week:</h3>
    <div>
      <div class="flex">
        <div class="flex-1 mx-2"></div>
        <div class="flex-1 mx-2">Start</div>
        <div class="flex-1 mx-2">End</div>
        <div class="flex-1 mx-2">Breaks</div>
      </div>
      <div v-for="day in days" :key="day" class="flex">
        <div class="flex-1 mx-2">{{ day }}</div>
        <div class="flex-1 mx-2">
          <select
            :value="props.data[day.toLowerCase()].start"
            :id="`select-${day}-start`"
            :name="`select-${day}-start`"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed disabled:bg-gray-200
            "
            :disabled="disableForm"
            @change="handleInputChange($event, day, 'start')"
          >
            <option v-for="time in startEndOptions" :key="time" :value="time">
              {{ time }}
            </option>
          </select>
        </div>
        <div class="flex-1 mx-2">
          <select
            :value="props.data[day.toLowerCase()].end"
            :id="`select-${day}-end`"
            :name="`select-${day}-end`"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed disabled:bg-gray-200
            "
            :disabled="disableForm"
            @change="handleInputChange($event, day, 'end')"
          >
            <option v-for="time in startEndOptions" :key="time" :value="time">
              {{ time }}
            </option>
          </select>
        </div>
        <div class="flex-1 mx-2">
          <select
            :value="props.data[day.toLowerCase()].break"
            :id="`select-${day}-break`"
            :name="`select-${day}-break`"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed disabled:bg-gray-200
            "
            :disabled="disableForm"
            @change="handleInputChange($event, day, 'break')"
          >
            <option v-for="time in startEndOptions" :key="time" :value="time">
              {{ time }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRef, defineProps, defineEmits } from 'vue';

const props = defineProps(['data', 'disableForm']);
const emit = defineEmits(['update', 'mark-complete']);
const model = toRef(props, 'data');
const days = ref([
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
])

// Function to get times at 15 minute intervals to populate dropdowns
const getAllTimes = () => {
  const arr = ['Off'];
  let hour = 0;
  while (hour < 24) {
    arr.push(`${hour}:00`);
    arr.push(`${hour}:15`);
    arr.push(`${hour}:30`);
    arr.push(`${hour}:45`);
    hour++;
  }
  arr.push('00:00');
  return arr;
}

const startEndOptions = getAllTimes();

const handleInputChange = (event, day, type) => {
  const value = event.target.value;
  model.value[day.toLowerCase()][type] = value;
  emit('update', { data: model.value });
  if (checkIsComplete()) emit('mark-complete', true);
  else emit('mark-complete', false);
}

const checkIsComplete = () => {
  let complete = true;
  days.value.forEach((day) => {
    const dayData = props.data[day.toLowerCase()];
    if (!(dayData.start && dayData.end && dayData.break)) {
      complete = false;
    }
  });
  return complete
}

</script>

<template>
  <div>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Final Payments & Deductions
          </h3>

          <p class="text-gray-700 italic">* If there are no further leaver payments please click save at the bottom of the page</p>
        </div>
        <!-- end title header -->
        <!-- form -->
            <h4 class="text-blue-700">Purchased Annual Leave:</h4>
            <div class="mt-2">
              <label for="additional_leave" class="block text-sm font-medium text-gray-700">Please provide the number of days additional leave purchased:</label>
              <input
                v-model="formData.additional_leave"
                type="number"
                min="0"
                name="additional_leave"
                id="additional_leave-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'bg-gray-200 border-none cursor-not-allowed' : props.disableForm }"
                :disabled="props.disableForm"
              />
            </div>
            <div class="mt-2">
              <label for="current_leave" class="block text-sm font-medium text-gray-700">
                Please provide the number of days additional purchased leave which has been taken for the current leave year:
              </label>
              <input
                v-model="formData.current_leave"
                type="number"
                min="0"
                name="current_leave"
                id="current_leave-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'bg-gray-200 border-none cursor-not-allowed' : props.disableForm }"
                :disabled="props.disableForm"
              />
            </div>
            <p class="text-sm text-red-700">Please note any outstanding balance will be recovered from the employees final pay. If there is not a sufficient
            amount a communication will be issued to advise on how to pay the remaining balance.
            </p>
            <div class="mt-2">
              <label for="comments_leave" class="block text-sm font-medium text-gray-700">
                Comments re purchased annual leave:
              </label>
              <textarea
                v-model="formData.comments_leave"
                name="comments_leave"
                id="comments_leave-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'bg-gray-200 border-none cursor-not-allowed' : props.disableForm }"
                :disabled="props.disableForm"
              ></textarea>
            </div>
            <p class="text-sm text-red-700">Please note any comments related to the employees purchased annual leave which you would like to share with
            the SSC Leavers Team</p>

            <h4 class="text-blue-700">Pay in Lieu of Notice/Settlements:</h4>
            <p class="text-sm text-red-700">If you are awaiting final signatures / figures, please do not include the payment information here as, if provided, the team
                will process the information via Payroll when it may not be right to do so.
                Once finalised, the final payment information should be emailed separately to the ssc-hiretoretire.uk@equans.com email
                address, along with HR approval, and the team will process directly from your email instruction.
            </p>
            <p class="text-sm text-red-700">*Please Note: An employee is entitled to a maximum of £30,000 tax free for redundancy/settlement agreements and
                anything above this value must be subject to tax and national insurance.
            </p>
            <p class="text-sm text-red-700">Please bear in mind that the employee may have loans in place that may need to be retrieved from their final salary. If you
                are unsure, please check with your HR Manager.
            </p>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="in_lieu"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >Pay in lieu of notice (taxable and subject to NI):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.in_lieu"
                  type="text"
                  name="in_lieu"
                  id="in_lieu-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="redundancy_pay"
                class="block text-sm font-medium text-gray-700"
                >Redundancy pay (non-taxable):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.redundancy_pay"
                  type="text"
                  name="redundancy_pay"
                  id="redundancy_pay-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="redundancy_pay_ni"
                class="block text-sm font-medium text-gray-700"
                >Redundancy pay (taxable and subject to NI):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.redundancy_pay_ni"
                  type="text"
                  name="redundancy_pay_ni"
                  id="redundancy_pay_ni-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="settlement_pay"
                class="block text-sm font-medium text-gray-700"
                >Compromise/Settlement pay (non-taxable):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.settlement_pay"
                  type="text"
                  name="settlement_pay"
                  id="settlement_pay-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="settlement_pay_ni"
                class="block text-sm font-medium text-gray-700"
                >Compromise/Settlement pay (taxable and subject to NI):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.settlement_pay_ni"
                  type="text"
                  name="settlement_pay_ni"
                  id="settlement_pay_ni-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="ex_gratia"
                class="block text-sm font-medium text-gray-700"
                >Ex-Gratia (non-taxable):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.ex_gratia"
                  type="text"
                  name="ex_gratia"
                  id="ex_gratia-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="ex_gratia_ni"
                class="block text-sm font-medium text-gray-700"
                >Ex-Gratia (taxable and subject to NI):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.ex_gratia_ni"
                  type="text"
                  name="ex_gratia_ni"
                  id="ex_gratia_ni-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="consideration_payment"
                class="block text-sm font-medium text-gray-700"
                >Consideration payment (taxable and subject to NI):</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.consideration_payment"
                  type="text"
                  name="consideration_payment"
                  id="consideration_payment-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="pension_fund"
                class="block text-sm font-medium text-gray-700"
                >Payment to be made to the Employee's Pension Fund:</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.pension_fund"
                  type="text"
                  name="pension_fund"
                  id="pension_fund-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <p class="text-sm text-blue-700">Please provide details of any further deductions/payments to final day:</p>
            <p class="text-sm text-red-700">For example money that needs to be deducted for training fees if the employee is leaving during the qualifying
                period or repayment of any licence fee's due (i.e. SIA licence fees).
            </p>
            <div>
              <label class="block text-sm font-medium text-gray-700" for="deduction_payment">Is this a deduction or a payment?</label>
              <select
                :disabled="props.disableForm"
                :value="formData?.deduction_payment"
                name="deduction_payment"
                id="deduction_payment-select"
                class="
                  w-full
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                  disabled:border-none
                "
                @change="handleInput"
              >
                <option value="" selected disabled></option>
                <option value="Deduction">Deduction</option>
                <option value="Payment">Payment</option>
              </select>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="reason_deduction"
                class="block text-sm font-medium text-gray-700"
                >Reason for deduction/payment:</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.reason_deduction"
                  type="text"
                  name="reason_deduction"
                  id="reason_deduction-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="value_deduction"
                class="block text-sm font-medium text-gray-700"
                >Value of deduction/payment:</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.value_deduction"
                  type="text"
                  name="value_deduction"
                  id="value_deduction-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <p class="text-sm test-red-700">All payments quoted in the Compromise/Settlement Agreement should be quoted clearly in the Leaver Form sections
                above.
            </p>
            <p class="text-sm test-red-700">Please note, the Employee Services Team will process any payments recorded below in the next available payroll.</p>
            <p class="text-sm test-red-700">If the payment needs to be made in advance of the next available payroll via Manual Payment, please provide the date by
                which payment needs to be made in the box below.
            </p>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="date_of_payment"
                class="block text-sm font-medium text-gray-700"
                >Date of payment</label
              >
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="formData.date_of_payment"
                  type="date"
                  name="date_of_payment"
                  id="date_of_payment-input"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  :disabled="props.disableForm"
                  />
              </div>
            </div>
            <p class="text-sm test-red-700">Please submit the Manual Payment Request to Payroll attaching the usual approvals.</p>
      <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!props.disableForm">
        <button
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="clearForm"
        >
          Reset Form
        </button>
        <button
          type="submit"
          class="
            ml-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
  <ModalWindow v-if="showModal" @close="closeModal">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>Confirm</template>
    <template v-slot:subtitle>
      <p v-if="showUserSearch">Please select a HROps user to approve the payments</p>
      <p v-else>No payments have been selected, this will now be sent on to Hire to Retire to confirm the leaver has been processed</p>
    </template>
    <template v-slot:footer>
      <div v-if="showUserSearch">
        <p class="italic text-sm text-gray-700">* If you do not need to add an approver, please click the reset form button at the bottom of the form.</p>
        <UserSearch v-if="!formData.HROpsAssignee?.email" group="HROPS" @updated-user="handleUser" />
        <div class="flex" v-else>
          <input
            :value="formData.HROpsAssignee.email"
            type="email"
            name="email"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            disabled
          >
          <XIcon class="text-gray-400 h-5 w-5 hover:text-gray-800 self-center ml-2 cursor-pointer" @click="clearUser" />
        </div>
      </div>
      <button
        :disabled="showUserSearch && !formData.HROpsAssignee?.email"
        type="button"
        class="
          mt-8
          inline-flex
          justify-center
          w-full
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          my-2
          bg-green-600
          text-base
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          sm:text-sm
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-500
          disabled:cursor-not-allowed
        "
        @click="submitForm"
      >
        Confirm
      </button>
    </template>
  </ModalWindow>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { CheckIcon, XIcon } from "@heroicons/vue/outline";
import UserSearch from "@/components/userDropdownSearch.vue";
import ModalWindow from "@/components/ModalWindow.vue";

const store = useStore();
const router = useRouter();

const formData = computed(() => store.getters.getLeaverFormData('formNine'));
const showUserSearch = computed(() => Object.keys(formData.value).find((key) => formData.value[key] && key !== 'saved'));

const props = defineProps(['disableForm', 'configItems'])
const emit = defineEmits(['submit-form', 'submit-leaver']);

const showModal = ref(false);

const openModal = () => showModal.value = true;
const closeModal = () => showModal.value = false;

const handleUser = (event) => {
  const { userID, email } = event;
  formData.value.HROpsAssignee = { userID, email };
};

const clearUser = () => formData.value.HROpsAssignee = undefined;

const handleInput = (event) => {
  const { name, value } = event.target;
  formData.value[name] = value;
};

const clearForm = () => {
  store.dispatch('setLeaverFormState', {
    formName: 'formNine',
    formData: {
      additional_leave:'',
      current_leave:'',
      comments_leave:'',
      in_lieu:'',
      redundancy_pay:'',
      redundancy_pay_ni:'',
      settlement_pay:'',
      settlement_pay_ni:'',
      consideration_payment:'',
      pension_fund:'',
      reason_deduction:'',
      value_deduction:'',
      date_of_payment:''
    }
  });
  showUserSearch.value = false
}


const handleSave = async () => openModal();


const submitForm = () => {
  emit('submit-form', { name: 'formNine', formData: formData.value })
  router.push('/')
}

</script>

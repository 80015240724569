<template>
  <div class="p-12" :class="{'print-area': printMode}">

    <!-- Page title start -->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        <slot name="pageTitle"></slot>
      </h3>
      <p class="mt-4 text-sm text-gray-500" v-if="!printMode">
        <slot name="pageSubtitle"></slot>
      </p>
    </div>
    <!-- Page title end -->

    <!-- Page header start -->
    <div v-if="displayHeader" :class="!printMode && 'p-5 my-8 bg-slate-200 hidden lg:block'" style="border-radius: 20px;">
      <div :class="!printMode && 'bg-white p-5 shadow'"  style="border-radius: 5px;">

          <slot name="header"></slot>

      </div>
    </div>
    <!-- Page header end -->

    <!-- Navigation start -->
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 bg-slate-200 p-5" style="border-radius: 20px;" v-if="!printMode">
      <div class="lg:hidden sm:px-6">
        <dropdownSelect :items="navItemConfig" :currentNavItem="currentNavItem" @menu-item-click="setCurrentNavItem" />
      </div>
      <aside class="hidden lg:block py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-4 xl:col-span-3">
        <nav class="space-y-1">
          <button
            v-for="item in navItemConfig"
            :key="item.formPoint"
            v-on:click="setCurrentNavItem(item.formPoint)"
            class="
              w-full
              text-gray-900
              hover:text-gray-900
              hover:bg-gray-50
              hover:underline
              group
              rounded-md
              px-3
              py-2
              flex
              items-center
              text-sm
              font-medium
            "
            :class="{ 'text-gray-900 bg-gray-50': formPoint === item.formPoint }"
          >
            <div class="flex flex-row justify-between w-full">
              <div class="flex flex-row">
                <component class="h-5 w-5 text-gray-400" :is="item.icon"></component>
                <span class="truncate self-center ml-2">{{ item.text.length < 24 ? item.text : `${item.text.slice(0, 21)}...` }}</span>
              </div>
              <div class="self-center">
                <CheckCircleIcon class="text-green-500 h-5 w-5" v-if="getFormSavedStatus(item.name)" />
              </div>
            </div>
          </button>
        </nav>
        <!-- Navigation end -->

        <!-- Action button -->
        <div v-if="!hideAction" class="mx-3">
          <hr class="my-4 border-0 h-px bg-gray-300">
          <slot name="navFooter"></slot>
        </div>
      </aside>

      <!-- Main pane start -->
      <div
        class="space-y-6 sm:px-6 lg:px-0 lg:col-span-8"
        :class="displayTimeline ? 'xl:col-span-5' : 'xl:col-span-9'"
      >
        <slot name="main"></slot>
      </div>
      <!-- Main pane end -->

      <!-- Right pane start -->
      <div
        v-if="displayTimeline"
        class="hidden xl:block bg-white shadow overflow-hidden sm:rounded-t-md xl:col-span-4 overflow-y-scroll"
        style="height: 596px"
      >
        <slot name="timeline"></slot>
      </div>
      <!-- Right pane end -->
    </div>

    <!--- Print version -->
    <div v-if="printMode">
      <slot name="printArea"></slot>
    </div>


    <!-- Footer section start -->
    <div v-if="displayFooter">
      <div :class="!printMode && 'p-5 my-8 bg-slate-200 flex flex-col lg:flex-row'" style="border-radius: 20px;">
        <slot name="footer"></slot>
      </div>
    </div>
    <!-- Footer section end -->
  </div>
</template>
<script>
import dropdownSelect from '@/components/dropdownSelect.vue';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import {
  ArrowNarrowRightIcon,
  ClipboardListIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentAddIcon,
  DocumentTextIcon,
  KeyIcon,
  LockClosedIcon,
  LogoutIcon,
  NewspaperIcon,
  EmojiHappyIcon,
  TicketIcon,
  UserAddIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  ViewGridAddIcon,
  ViewListIcon
} from '@heroicons/vue/outline'

export default {
  components: {
    ArrowNarrowRightIcon,
    CheckCircleIcon,
    ClipboardListIcon,
    ClockIcon,
    CreditCardIcon,
    DocumentAddIcon,
    DocumentTextIcon,
    dropdownSelect,
    EmojiHappyIcon,
    KeyIcon,
    LockClosedIcon,
    LogoutIcon,
    NewspaperIcon,
    TicketIcon,
    UserAddIcon,
    UserCircleIcon,
    UserGroupIcon,
    UserIcon,
    ViewGridAddIcon,
    ViewListIcon
  },
  props: [
    'navItemConfig',
    'currentNavItem',
    'formName',
    'formPoint',
    'formData',
    'hideAction',
    'displayHeader',
    'displayTimeline',
    'displayFooter',
    'printMode'
  ],
  setup(props, { emit }) {
    const setCurrentNavItem = (index) => {
      emit('set-current-nav-item', index)
    };
    const getFormSavedStatus = (name) => {
      if(props.formData) {
        const form = props.formData[name] || (props.formData.atr && props.formData.atr[name]);
        return form?.saved;
      }
    }

    return {
      setCurrentNavItem,
      getFormSavedStatus,
    };
  }
}
</script>

import { createApp } from 'vue'
import router from './router.js'
import store from './store/index'
import * as AmplifyModules from 'aws-amplify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import moment from 'moment'
import stages from './stages.js'
import './assets/styles/tailwind.css'

AmplifyModules.Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USERPOOLID,
    userPoolWebClientId: process.env.VUE_APP_CLIENTID,
    oauth: {
      domain: process.env.VUE_APP_AUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_REDIRECT_URL,
      redirectSignOut: process.env.VUE_APP_REDIRECT_URL,
      responseType: 'code'
    }
  },
});

const formatDate = (timestamp, dateFormat) => {
  const date = new Date(timestamp);
  return moment(date).format(dateFormat);
};

createApp(App)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(AmplifyModules)
  .provide('formatDate', formatDate)
  .provide('stages', stages)
  .mount('#app')
<template>
  <div>
    <form class="space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Active</label>
              <fieldset class="mt-4">
                <legend class="sr-only">Location Active Status</legend>
                <div class="mr-2 flex flex-row align-center">
                  <div v-for="item in [true, false]" :key="item" class="flex items-center mr-6">
                    <input v-model="formData.LKStatus" :value="item" :id="`LKStatus-${item.toString()}`" :name="`LKStatus-${item.toString()}`" type="radio" :checked="formData.LKStatus === item" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                    <label :for="`LKStatus-${item.toString()}`" class="ml-3 block text-sm font-medium text-gray-700">{{ item.toString() }}</label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div v-for="field in fields" :key="field.id" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label :for="field.name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{{ field.label }}</label>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <input v-model="formData[field.key]" :type="field.type" :name="field.name" :id="field.id" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:text-gray-800 disabled:bg-gray-200 disabled:cursor-not-allowed" :disabled="selectedItem" @blur="v$.formData[field.key].$touch()" />
                <div v-if="v$.formData[field.key].$error">
                  <p class="text-red-600">* {{ v$.formData[field.key].$errors[0].$message }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:underline" @click="$emit('close-modal')">Cancel</button>
          <button :disabled="v$.$invalid" type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="handleCreateLocation">Save</button>
        </div>
      </div>
    </form>
  </div>

</template>
<script>
import httpClient from '@/httpClient';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  props: ['selectedItem'],
  data() {
    return {
      showModal: false,
      v$: useVuelidate(),
      formData: { 
        LKStatus: true,
        LOCATIONCODE: '',
        OBJECTNAME: '',
        POSTCODE: ''
      },
      fields: [
        {
          key: 'LOCATIONCODE',
          label: 'Location Code',
          type: 'text',
          name: 'location-code-field',
          id: 'location-code-field'
        },
        {
          key: 'OBJECTNAME',
          label: 'Object Name',
          type: 'text',
          name: 'object-name-field',
          id: 'object-name-field'
        },
        {
          key: 'POSTCODE',
          label: 'Postcode',
          type: 'text',
          name: 'postcode-field',
          id: 'postcode-field'
        },
      ]
    };
  },
  methods: {
    handleClickContract(contract) {
      this.selectedContract = contract;
      this.openModal();
    },
    async handleCreateLocation() {
      this.$store.dispatch('setIsLoading', true);
      const payload = { type: 'location', ...this.formData }
      try {
        const response = await httpClient.post('/config/create', payload);
        if (response.data.statusCode === 500) throw new Error();
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: this.selectedItem ? 'Changes saved' : 'Successfully created new location'
        });
        this.$emit('close-modal');
      } catch(err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: this.selectedItem ? 'Error saving changes' : 'Could not create new location at this time.'
        });
      }
      this.$store.dispatch('setIsLoading', false);
    }
  },
  created() {
    if (this.selectedItem) {
      this.formData = this.selectedItem;
    }
  },
  validations() {
    return {
      formData: {
        LKStatus: { required },
        LOCATIONCODE: { required },
        OBJECTNAME: { required },
        POSTCODE: { required }
      }
    };
  },
}
</script>
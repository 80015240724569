<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Attachments & Additional Information
          </h3>
        </div>
        <!-- Document upload -->
          <FileUploader 
            name="additional-attachments"
            :files="formData.files"
            :disabled="disableForm"
            :documentShortId="document?.shortId"
            @updateFiles="handleUpdateFiles"
            :uploading="uploading"
            @fileUploading="uploading = $event"
          />
        <!-- End Document upload -->

        <!-- Work Location -->
          <div class="col-span-6">
            <label for="comments" class="block text-sm font-medium text-gray-700">Comments / additional information:</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea id="comments" name="comments" :value="formData.comments" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" @input="handleInput" />
            </div>
          </div>
          <!-- Work Location end-->

      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="uploading"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { computed, toRef, ref } from 'vue'
import { getStorage } from '@/storageSave';
import FileUploader from '../FileUploader.vue';

export default {
  props: ['disableForm', 'formData'],
  components: { FileUploader },
  setup(props, { emit }) {
    const document = computed(() => {
      const data = getStorage('amendsFormData');
      if (data) return JSON.parse(data);
      return {};
    })
    const modelValue = toRef(props, 'formData');
    const uploading = ref(false);

    const handleUpdateFiles = ({ model }) => {
      modelValue.value.files = model;
      emit('update:formData', { formName: 'formFourteen', formData: modelValue.value });
    }

    const handleInput = (event) => {
      const { name, value } = event.currentTarget;
      modelValue.value[name] = value;
      emit('update:formData', { formName: 'formFourteen', formData: modelValue.value });
    };

    const handleSave = () => emit('submit-form', {
      name: 'formFourteen',
      formData: modelValue.value
    });

    return {
      uploading,
      modelValue,
      document,
      handleUpdateFiles,
      handleInput,
      handleSave
    }
  }
}

</script>

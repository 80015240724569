<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md w-full">
    <div class="block">
      <h3 class="text-lg leading-6 font-medium text-gray-900 m-2 p-2">Request Comments</h3>
      <div class="px-4 py-4 flex items-center sm:px-6">
        <div class="w-full flex-1 sm:flex sm:items-center sm:justify-between">
          <div v-if="formData" class="flex flex-col w-full">
            <!-- comment window -->
            <div class="max-h-64 overflow-y-auto border-t border-gray-200">
              <div class="my-2" v-if="formData.offer_notifyBudgetReApprovalApproval?.comment">
                <span class="text-xs italic text-gray-400">{{ formData.offer_notifyBudgetReApprovalApproval?.approverEmail }}</span>
                <p class="rounded-md p-2 bg-gray-200 message-bubble">
                  {{ formData.offer_notifyBudgetReApprovalApproval?.comment }}
                </p>
              </div>
              <div class="my-2 flex flex-col self-end justify-end w-full items-end" v-if="formData.offer_notifyMoreInfoBudgetReApprovalApproval?.comment">
                <span class="text-xs italic text-gray-400">{{ formData.offer_notifyMoreInfoBudgetReApprovalApproval?.approverEmail }}</span>
                <p class="rounded-lg w-full p-2 bg-green-700 message-bubble text-right text-white">
                  {{ formData.offer_notifyMoreInfoBudgetReApprovalApproval?.comment }}
                </p>
              </div>
              <div class="my-2" v-if="formData.offer_notifyHROpsReApprovalApproval?.comment">
                <span class="text-xs italic text-gray-400">{{ formData.offer_notifyHROpsReApprovalApproval?.approverEmail }}</span>
                <p class="rounded-md p-2 bg-gray-200 message-bubble">
                  {{ formData.offer_notifyHROpsReApprovalApproval?.comment }}
                </p>
              </div>
              <div class="my-2 flex flex-col self-end justify-end w-full items-end" v-if="formData.offer_notifyMoreInfoHROpsReApprovalApproval?.comment">
                <span class="text-xs italic text-gray-400">{{ formData.offer_notifyMoreInfoHROpsReApprovalApproval?.approverEmail }}</span>
                <p class="rounded-lg w-full p-2 bg-green-700 message-bubble text-right text-white">
                  {{ formData.offer_notifyMoreInfoHROpsReApprovalApproval?.comment }}
                </p>
              </div>
              <div v-if="
                !formData.offer_notifyBudgetReApprovalApproval?.comment &&
                !formData.offer_notifyMoreInfoBudgetReApprovalApproval?.comment &&
                !formData.offer_notifyHROpsReApprovalApproval?.comment &&
                !formData.offer_notifyMoreInfoHROpsReApprovalApproval?.comment
              ">
                <p class="text-gray-600 text-center mt-8 mb-2">No comments to show...</p>
              </div>
            </div>
            <!-- Reply controls -->
            <div class="flex overflow-hidden -space-x-1 mt-5 pt-8 border-t border-gray-200">
              <input
                :disabled="!showReply"
                v-model="inputValue"
                id="input-post-comment"
                name="post-comment"
                type="text"
                :placeholder="!showReply ? 'Cannot post comment when the request is not assigned to you' : 'Type your comment here...'"
                class="mr-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed">
              <button
                :disabled="!showReply || !inputValue"
                class="
                  relative
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-green-700
                  hover:underline
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-cyan-500
                  bg-no-repeat
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:text-gray-400
                "
                @click.prevent="sendReply"
              >
                Reply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import { Auth } from 'aws-amplify'
import { getStorage, saveStorage } from '@/storageSave';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import httpClient from '@/httpClient';

export default {
props: ['formData', 'docType'],
setup(props) {
  const store = useStore();
  const router = useRouter();
  const document = ref({});
  const mounted = ref(false);
  const inputValue = ref('');
  const currentUser = computed(() => Auth?.user.signInUserSession.idToken.payload);
  const formData = computed(() => props.formData);
  const firstLineApprover = computed(() => formData.value[`${props.docType.toLowerCase()}_notifyBudgetApproverApproval`]);
  const moreInfoComment = computed(() => formData.value[`${props.docType.toLowerCase()}_notifyMoreInfoBudgetApprovalApproval`] || formData.value[`${props.docType.toLowerCase()}_notifyMoreNotifyHROpsApproval`]);
  const secondLineApprover = computed(() => formData.value[`${props.docType.toLowerCase()}_notifyHROpsApproval`]);
  const currentUserIsAssigned = computed(() => formData.value.currentAssignee === currentUser.value.identities[0].userId.split('@')[0]);
  const commentExists = computed(() => !!formData.value[`${formData.value.currentStageName.replace('.', '_')}Approval`]);
  const profile = computed(() => store.getters.getProfile)
  const showReply = computed(() =>
    !commentExists.value && 
    (currentUserIsAssigned.value || profile.value.ADMIN) &&
    [
      'offer.notifyBudgetReApproval',
      'offer.notifyMoreInfoBudgetReApproval',
      'offer.notifyHROpsReApproval',
      'offer.notifyMoreInfoHROpsReApproval'

    ].includes(formData.value.currentStageName));

  const sendReply = async (e) => {
    store.dispatch('setIsLoading', true);
    e.preventDefault();
    const data = {
      approvalComment: inputValue.value,
      approved: 'MOREINFO',
      stage: formData.value.currentStageName,
      id: formData.value.docId,
      requestedBy: currentUser.value.email,
      docOwner: formData.value.requestedBy
    }
    const response = await httpClient.post('/bff/action-request', data)
    // update formData with the response
    if (props.docType === 'STARTER') saveStorage('formData', response.data.body.Attributes);
    if (props.docType === 'LEAVER') saveStorage('leaverForm', JSON.stringify(response.data.body.Attributes));

    router.push('/');
    store.dispatch('setIsLoading', false);
  }

  onMounted(() => {
    if (props.docType === 'STARTER') document.value = getStorage('formData');
    if (props.docType === 'LEAVER') document.value = JSON.parse(getStorage('leaverForm'));
    mounted.value = true;
  })

  return {
    document,
    inputValue,
    currentUser,
    firstLineApprover,
    moreInfoComment,
    secondLineApprover,
    currentUserIsAssigned,
    commentExists,
    showReply,
    sendReply,
    mounted
  }
},
}

</script>
<style scoped>
.message-bubble {
max-width: 70%;
width: fit-content;
white-space: initial;
}
</style>
<template>
  <form v-if="formData" action="#" method="POST">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Your Details
          </h3>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <!-- Name-->
          <div class="col-span-6" v-if="!formData.name">
            <p>Your details: *</p>
            <UserSearch group="HM" @updated-user="handleUser($event, 'primary')" />
          </div>
          <div class="p-8 m-2 border border-gray-300 rounded-md col-span-6" v-else>
            <div class="flex flex-col">
              <div v-if="!disableForm" class="flex w-full justify-end">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click="handleClearUser('primary')" />
              </div>
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700"
                  >Name *</label
                >
                <input
                  v-model="formData.name"
                  type="text"
                  name="name"
                  id="name-input"
                  autocomplete="name"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  disabled
                  @blur="v$.formData?.name.$touch()"
                />
                <span v-if="v$.formData.name.$error" class="text-red-500">
                  * {{ v$.formData.name.$errors[0].$message }}
                </span>

                <label for="email" class="block text-sm font-medium text-gray-700 mt-4"
                  >Email *</label
                >
                <input
                  v-model="formData.email"
                  type="text"
                  name="email"
                  id="email-input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  disabled
                  @blur="v$.formData.email.$touch()"
                />
                <span v-if="v$.formData.email.$error" class="text-red-500">
                  * {{ v$.formData.email.$errors[0].$message }}
                </span>
              </div>
            </div>
          </div>

          <!-- Telephone number -->
          <div class="col-span-6">
            <label for="phone" class="block text-sm font-medium text-gray-700"
              >Telephone number *</label
            >
            <input
              v-model="formData.phone"
              type="text"
              name="phone"
              id="phone-input"
              autocomplete="name"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @blur="v$.formData.phone.$touch()"
            />
            <span v-if="v$.formData.phone.$error" class="text-red-500">
              * {{ v$.formData.phone.$errors[0].$message }}
            </span>
          </div>
          <!-- End of telephone number -->
        
          <!-- add a secondary contact  -->
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              Would you like to add a secondary contact to this form? *
            </legend>
            <span v-if="v$.formData.secondary.$error" class="text-red-500">
              * {{ v$.formData.secondary.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.secondary"
                    value="yes"
                    id="radio-button-replacement-role"
                    name="yes"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    @blur="v$.formData.secondary.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="yes" class="font-medium text-gray-700">Yes</label>
                </div>
              </div>
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.secondary"
                      value="No"
                      id="radio-button-candidates"
                      name="no"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="no" class="font-medium text-gray-700">No</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- End of add a secondary contact  -->
        </div>
        <div v-if="formData.secondary === 'yes'">
            <!-- Start secondary contact name -->
            <div class="col-span-6" v-if="!formData.secondaryName">
              <p>Secondary contact details *</p>
              <UserSearch group="ALL" @updated-user="handleUser($event, 'secondary')" />
            </div>
            <div v-else class="p-8 m-2 border border-gray-300 rounded-md col-span-6">
              <div class="flex flex-col">
                <div v-if="!disableForm" class="flex w-full justify-end">
                  <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click="handleClearUser('secondary')" />
                </div>
                <div>
                  <label for="secondaryName" class="block text-sm font-medium text-gray-700"
                    >Secondary contact Name *</label
                  >
                  <input
                    v-model="formData.secondaryName"
                    type="text"
                    name="secondaryName"
                    id="phone-input"
                    autocomplete="name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    disabled
                    @blur="v$.formData.secondaryName.$touch()"
                  />
                  <span v-if="v$.formData.secondaryName.$error" class="text-red-500">
                    * {{ v$.formData.secondaryName.$errors[0].$message }}
                  </span>

                  <label for="secondaryEmail" class="block text-sm font-medium text-gray-700 mt-4"
                    >Secondary contact email address *</label
                  >
                  <input
                    v-model="formData.secondaryEmail"
                    type="text"
                    name="secondaryEmail"
                    id="phone-input"
                    autocomplete="name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    disabled
                    @blur="v$.formData.secondaryEmail.$touch()"
                  />
                  <span v-if="v$.formData.secondaryEmail.$error" class="text-red-500">
                    * {{ v$.formData.secondaryEmail.$errors[0].$message }}
                  </span>
                </div>
            </div>
            <!-- End secondary contact name -->
          </div>
          <div class="col-span-6 mt-4">
              <label for="secondaryPhone" class="block text-sm font-medium text-gray-700"
                >Secondary contact phone number *</label
              >
              <input
                v-model="formData.secondaryPhone"
                type="text"
                name="secondaryPhone"
                id="phone-input"
                autocomplete="name"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                :disabled="disableForm"
                @blur="v$.formData.secondaryPhone.$touch()"
              />
              <span v-if="v$.formData.secondaryPhone.$error" class="text-red-500">
                * {{ v$.formData.secondaryPhone.$errors[0].$message }}
              </span>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, requiredIf, helpers } from '@vuelidate/validators'
import UserSearch from '@/components/userDropdownSearch.vue';
import { XIcon } from '@heroicons/vue/solid';

export default {
    props: ["disableForm"],
    components: { UserSearch, XIcon },
    data() {
        return {
            v$: useVuelidate()
        };
    },
    computed: {
        formData() {
            const data = this.$store.getters.getLeaverFormData("formOne");
            return data;
        },
    },
    methods: {
        handleSave() {
            this.$emit("submit-form", { name: "formOne", formData: this.formData });
        },
        handleUser(event, type) {
            const { Title, userID, email } = event;
            if (type === 'primary') {
                this.formData.name = Title;
                this.formData.userId = userID;
                this.formData.email = email.trim();
            } else {
                this.formData.secondaryName = Title;
                this.formData.secondaryUserId = userID;
                this.formData.secondaryEmail = email.trim();
            }
        },
        handleClearUser(type) {
            if (type === 'primary') {
                this.formData.name = '';
                this.formData.userId = '';
                this.formData.email = '';
            } else {
                this.formData.secondaryName = '';
                this.formData.secondaryUserId = '';
                this.formData.secondaryEmail = '';
            }
        }
    },
    validations() {
        return {
            formData: {
                name: { required },
                email: { required, email },
                phone: { required },
                secondary: { required },
                secondaryName: {
                    requiredIf: helpers.withMessage("A value is required", requiredIf(this.formData.secondary === "yes"))
                },
                secondaryEmail: {
                    email,
                    requiredIf: helpers.withMessage("A value is required", requiredIf(this.formData.secondary === "yes"))
                },
                secondaryPhone: {
                    requiredIf: helpers.withMessage("A value is required", requiredIf(this.formData.secondary === "yes"))
                }
            }
        };
    }
}

</script>

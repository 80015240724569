<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            First & Second Line Approval
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div class="mt-6">
          <label 
            for="first_line"
            class="block text-sm font-medium text-gray-700"
          >Who provided first line approval for this ATR?</label>
          <input
            :value="props.data.amend_notifyBudgetApproverApproval?.approverEmail"
            type="text"
            name="first_line"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <p class="text-sm">The first line approver is the business representative who has the authority to approve the budget spend for this role.</p>

        <div class="mt-6">
          <label 
            for="first_date"
            class="block text-sm font-medium text-gray-700"
          >First line approval date:</label>
          <input
            :value="formatDate(props.data.amend_notifyBudgetApproverApproval?.timeStamp, 'DD/MM/yyyy')"
            type="text"
            name="first_date"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <div class="mt-6">
          <label 
            for="first_line_comments"
            class="block text-sm font-medium text-gray-700"
          >First line approval comments:</label>
          <textarea
            :value="firstLineApprovalComment"
            rows="3"
            name="first_line_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          ></textarea>
        </div>

        <div class="mt-6">
          <label 
            for="approval_comments"
            class="block text-sm font-medium text-gray-700"
          >Who provided second line approval for this ATR?:</label>
          <input
            :value="props.data.amend_notifyHROpsApproval?.approverEmail"
            type="text"
            name="approval_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <p class="text-sm">The second line approver is the HR professional who will ensure all the correct policies and approvals have
            been adhered to. If further approvals are required, they will co-ordinate this with the business outside the
            system and keep records locally for audit purposes.
        </p>

        <div class="mt-6">
          <label 
            for="second_line_date"
            class="block text-sm font-medium text-gray-700"
          >Second line approval date:</label>
          <input
            :value="formatDate(props.data.amend_notifyHROpsApproval?.timeStamp, 'DD/MM/yyyy')"
            type="text"
            name="second_line_date"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <div class="mt-6">
          <label 
            for="second_line_comments"
            class="block text-sm font-medium text-gray-700"
          >Second line approval comments:</label>
          <textarea
            :value="secondLineApprovalComment"
            rows="3"
            name="second_line_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          ></textarea>
        </div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { defineProps, inject, computed } from "vue"

const props = defineProps(['data', 'disableForm']);
const formatDate = inject('formatDate');

const secondLineApprovalComment = computed(() => {
  if (props.data?.approvalComments) {
    const approvalComment = props.data?.approvalComments.find(ac => ac.stage === 'amend.notifyHROps');
    return approvalComment?.approvalComment ?? ''
  }
  return '';
})

const firstLineApprovalComment = computed(() => {
  if (props.data?.approvalComments) {
    const approvalComment = props.data?.approvalComments.find(ac => ac.stage === 'amend.notifyBudgetApprover');
    return approvalComment?.approvalComment ?? ''
  }
  return '';
})

</script>

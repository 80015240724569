<template>
  <div>
    <form class="space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Active</label>
              <fieldset class="mt-4">
                <legend class="sr-only">Contract Type Active Status</legend>
                <div class="mr-2 flex flex-row align-center">
                  <div v-for="item in [true, false]" :key="item" class="flex items-center mr-6">
                    <input v-model="formData.LKStatus" :value="item" :id="`LKStatus-${item.toString()}`" :name="`LKStatus-${item.toString()}`" type="radio" :checked="formData.LKStatus === item" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                    <label :for="`LKStatus-${item.toString()}`" class="ml-3 block text-sm font-medium text-gray-700">{{ item.toString() }}</label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="contract-type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Contract Type *</label>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <input v-model="formData.ContractType" type="text" name="contract-type" id="contract-type" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:text-gray-800 disabled:bg-gray-200 disabled:cursor-not-allowed" :disabled="selectedItem" @blur="v$.formData.ContractType.$touch()" />
                <div v-if="v$.formData.ContractType.$error">
                  <p class="text-red-600">* {{ v$.formData.ContractType.$errors[0].$message }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedItem">
            <div class="my-8">
              <h3 class="text-lg text-center">Contract</h3>
            </div>
            <div class="flex justify-end my-4">
              <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="openModal">Add Contract</button>
            </div>
            <div v-if="selectedItem?.Items?.length" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="contract in selectedItem?.Items" :key="contract.ContractType">
                    <td
                      class="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-700 sm:pl-6 hover:underline cursor-pointer"
                      @click.prevent="handleClickContract(contract)">{{ contract.ContractName }}
                      <span v-if="!contract.LKStatus" class="text-red-700 border rounded-md px-2 border-red-700 absolute right-8">Inactive</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p v-else class="text-lg text-center text-gray-600">No Contracts exist in this Contract Type...</p>
          </div>
          <p v-else class="text-lg text-center text-gray-600">You can only add Contracts to this Contract Type once it has been saved...</p>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:underline" @click="$emit('close-modal')">Cancel</button>
          <button :disabled="v$.$invalid" type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="handleCreateContractType">Save</button>
        </div>
      </div>
    </form>
    <ModalWindow v-if="showModal" size="5xl" @close="closeModal">
      <template v-slot:icon>
        <DocumentTextIcon class="h-10 w-12 text-green-600" aria-hidden="true" />
      </template>
      <template v-slot:title>{{ selectedContract ? 'Edit Contract' : 'Add Contract' }}</template>
      <template v-slot:subtitle
        >Please fill out all the contract details</template
      >
      <template v-slot:main>
        <ContractForm
          :contractTypeId="selectedItem?.CODE"
          :contractType="selectedItem?.ContractType"
          :selectedContract="selectedContract"
          @cancel="closeModal"
        />
      </template>
    </ModalWindow>
  </div>

</template>
<script>
import httpClient from '@/httpClient';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { DocumentTextIcon } from '@heroicons/vue/solid';
import ContractForm from '@/components/admin/admin-components/config/forms/ContractForm.vue';
import ModalWindow from '@/components/ModalWindow.vue';

export default {
  components: { ContractForm, DocumentTextIcon, ModalWindow },
  props: ['selectedItem'],
  data() {
    return {
      showModal: false,
      v$: useVuelidate(),
      selectedContract: undefined,
      formData: { LKStatus: false, ContractType: '' },
    };
  },
  methods: {
    openModal() { this.showModal = true; },
    closeModal(refresh) {
      if (refresh) {
        this.$emit('refresh-config');
      }
      this.showModal = false;
      this.selectedContract = undefined;
    },
    handleClickContract(contract) {
      this.selectedContract = contract;
      this.openModal();
    },
    async handleCreateContractType() {
      this.$store.dispatch('setIsLoading', true);
      const payload = { type: 'contractType', ...this.formData }
      try {
        const response = await httpClient.post('/config/create', payload);
        if (response.data.statusCode === 500) throw new Error();
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: this.selectedItem ? 'Changes saved' : 'Successfully created new contract type.'
        });
        this.$emit('close-modal');
      } catch(err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: this.selectedItem ? 'Error saving changes' : 'Could not create new contract at this time.'
        });
      }
      this.$store.dispatch('setIsLoading', false);
    }
  },
  created() {
    if (this.selectedItem) {
      this.formData = this.selectedItem;
    }
  },
  validations() {
    return {
      formData: { ContractType: { required } }
    };
  },
}
</script>
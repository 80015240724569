<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Salary and overtime details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
        <div>
          <p class="mb-2">
            Approved Salary Range:
            <span v-if="atr?.formSix">
              {{ atr?.formSix?.minimumPayRate }} {{ atr?.formSix?.minimumPayRateCurrency }} - {{ atr?.formSix?.maximumPayRate }} {{ atr?.formSix?.maximumPayRateCurrency }}
            </span> 
            <span v-else>
              {{ atr?.atr?.formSix?.minimumPayRate }} {{ atr?.atr?.formSix?.minimumPayRateCurrency }} - {{ atr?.atr?.formSix?.maximumPayRate }} {{ atr?.atr?.formSix?.maximumPayRateCurrency }}
            </span>
          </p>
          <p v-if="document.candidate.internal_candidate === 'Yes'" class="mb-2">Current Salary: {{ document.candidate.candidateCurrentSalaryRate }}</p>
          <label for="minSalary" class="block text-sm font-medium text-gray-700">
            What is the proposed salary or hourly rate to offer? *
          </label>
          <div class="flex">
            <input
              :value="formData?.proposedSalary"
              id="proposedSalary"
              name="proposedSalary"
              type="number"
              min="0"
              :disabled="disableForm"
              class="
                grow
                block
                w-full
                focus:ring-indigo-500 focus:border-indigo-500
                min-w-0
                rounded-md
                sm:text-sm
                border-gray-300
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
              @input="handleInput"
              @blur="v$.formData.proposedSalary.$touch()"
            />
            <select
              :value="formData?.salaryCurrency"
              id="salaryCurrency"
              name="salaryCurrency"
              :disabled="disableForm"
              class="
                ml-2
                max-w-lg
                block
                focus:ring-indigo-500 focus:border-indigo-500
                shadow-sm
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
              @change="handleInput"
              @blur="v$.formData.salaryCurrency.$touch()"
            >
              <option>GBP</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>
          <div class="flex justify-between">
            <span v-if="v$.formData.proposedSalary.$error" class="text-red-500">
              * {{ v$.formData.proposedSalary.$errors[0].$message }}
            </span>
            <span v-if="v$.formData.salaryCurrency.$error" class="text-red-500">
              * {{ v$.formData.salaryCurrency.$errors[0].$message }}
            </span>
          </div>
          
          <div v-if="!salaryIsInRange">
            <p class="text-red-600 italic">* This offer will go for reapproval if the proposed salary is outside of approved range</p>
          </div>
        </div>
        <div class="mt-4">
          <CheckList
            label="Please select all overtime options that could apply to this role"
            name="overtime"
            :options="overtimeOptions"
            :disabled="disableForm"
            :modelValue="formData?.overtime || []"
            @update:modelValue="handleUpdateModelValue"
          />
        </div>
        
        <div class="col-span-6 mt-4">
          <label for="overtimeRatesInfo" class="block text-sm font-medium text-gray-700">
            Please provide overtime rates and when they will apply (e.g. if a specific rate is only applicable at weekend).<br>
            Also use this space for any specific payments due that are not captured in the Additional Allowances section.
          </label>
          <div class="mt-4 sm:mt-0 sm:col-span-2">
            <textarea
              :value="formData?.overtimeRatesInfo"
              id="overtime-rates-information"
              name="overtimeRatesInfo"
              rows="3"
              class="
                shadow-sm
                focus:ring-indigo-500
                focus:border-indigo-500
                block w-full
                sm:text-sm
                border
                border-gray-300
                rounded-md
              " 
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @input="handleInput"
            /> 
            <p class="italic text-red-600">
              * overtime must be stated within this box. 'Standard' should not be noted. This can delay the processing of the contract.
            </p>
          </div>
        </div>
        <div class="mt-4">
          <label for="additionalAllowances" class="block text-sm font-medium text-gray-700">
            Are there any additional allowances associated with this role? *
          </label>
          <input
            :value="model.additionalAllowances"
            name="additionalAllowances"
            id="additional-allowances-select"
            class="
              py-2
              px-3
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            disabled
            @change="handleInput"
            @blur="v$.formData.additionalAllowances.$touch()"
          >
          <span v-if="v$.formData.additionalAllowances.$error" class="text-red-500">
            * {{ v$.formData.additionalAllowances.$errors[0].$message }}
          </span>
        </div>
        <div v-if="model.additionalAllowances && model.additionalAllowancesInformation.length">
          <h3 class="text-md mt-4">Additional Allowances</h3>
          <div
            v-for="allowance in model.additionalAllowancesInformation"
            :key="allowance"
            class="border-b border-gray-200 border-solid my-1 py-4"
          >
            <p>Allowance Type: {{ allowance.additionalAllowancesInformation }}</p>
            <p>Allowance Amount: £{{ allowance.additionalAllowanceAmount }}</p>
          </div>
        </div>
        <div class="mt-4">
          <label for="salaryReviewMonth" class="block text-sm font-medium text-gray-700">
            In which month will the annual salary review take place? *
          </label>
          <select
            :value="formData?.salaryReviewMonth"
            :disabled="disableForm"
            name="salaryReviewMonth"
            id="salary-review-month-select"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              w-full
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            @change="handleInput"
            @blur="v$.formData.salaryReviewMonth.$touch()"
          >
            <option v-for="month in year" :key="month" :value="month">{{ month }}</option>
          </select>
          <span v-if="v$.formData.salaryReviewMonth.$error" class="text-red-500">
            * {{ v$.formData.salaryReviewMonth.$errors[0].$message }}
          </span>
        </div>
        <div class="mt-4">
          <label for="salaryReviewMonth" class="block text-sm font-medium text-gray-700">
            What is the payroll frequency for this role? *
          </label>
          <select
            :value="formData?.payrollFrequency"
            :disabled="disableForm"
            name="payrollFrequency"
            id="payroll-frequency-select"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              w-full
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            @change="handleInput"
            @blur="v$.formData.payrollFrequency.$touch()"
          >
            <option :selected="formData?.payrollFrequency === 'Weekly'" value="Weekly">Weekly</option>
            <option :selected="formData?.payrollFrequency === 'Monthly'" value="Monthly">Monthly</option>
          </select>
          <span v-if="v$.formData.payrollFrequency.$error" class="text-red-500">
            * {{ v$.formData.payrollFrequency.$errors[0].$message }}
          </span>
        </div>

        <div class="mt-4">
          <label for="appraisal" class="block text-sm font-medium text-gray-700">
            What type of appraisal will be carried out? *
          </label>
          <select
            :value="formData?.appraisal"
            :disabled="disableForm"
            name="appraisal"
            id="appraisal-select"
            class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              w-full
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            @change="handleInput"
            @blur="v$.formData.appraisal.$touch()"
          >
            <option value="Paper based">Paper based</option>
            <option value="One HR (Online)">One HR (Online)</option>
          </select>
          <span v-if="v$.formData.appraisal.$error" class="text-red-500">
            * {{ v$.formData.appraisal.$errors[0].$message }}
          </span>
        </div>

        <p class="italic text-red-600">
          * Employees in job levels 1-4 should be assigned to the OneHR System (online appraisal process).
        </p>
        <p class="italic text-red-600">
          * Employees in job levels 5-7 will be assigned to the paper based appraisals process unless you select One HR in the dropdown above.
        </p>
        <!-- End Form -->
        </div>
        <div v-if="!salaryIsInRange" class="p-3 w-full flex flex-row items-center">
          <ExclamationIcon class="text-red-600 h-16 w-16" />
          <p class="text-red-600 ml-2">
            Once submitted, this document will go for re-approval because the terms have changed.
          </p>
        </div>
      </div>
      <!-- Save button -->
      <div v-if="editable || (!disableForm && document.currentStageName === 'offer.notifyPrepOffer')" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Save
        </button>
      </div>
    <!-- End Save button -->
    </div>
  </form>
</template>

<script>
import CheckList from '@/components/CheckList.vue';
import { getStorage } from '@/storageSave';
import { ExclamationIcon } from '@heroicons/vue/solid'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators'

export default {
  props: ['formData', 'atr', 'document', 'disableForm', 'configItems', 'editable'],
  emits: ['update:formData'],
  components: { CheckList, ExclamationIcon },
  data() {
    return {
      model: this.formData,
      overtimeOptions: [
        'Overtime x 1.00',
        'Overtime x 1.25',
        'Overtime x 1.33',
        'Overtime x 1.35',
        'Overtime x 1.50',
        'Overtime x 1.66',
        'Overtime x 1.75',
        'Overtime x 1.80',
        'Overtime x 2.00',
        'Overtime x 3.00'
      ],
      year: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      v$: useVuelidate()
    }
  },
  computed: {
    salaryIsInRange() {
      const atr = JSON.parse(getStorage('formData'))
      const proposed = Number(this.model.proposedSalary) || 0;
      const minPayRate = atr?.atr?.formSix?.minimumPayRate;
      const maxPayRate = atr?.atr?.formSix?.maximumPayRate
      if (atr?.candidate?.internal_candidate === 'Yes') {
        if (proposed < minPayRate || proposed > maxPayRate) return false;
        const current = Number(atr.candidate.candidateCurrentSalaryRate);
        const percentage = Math.abs(((proposed / current) * 100) - 100);
        if (percentage > 5) return false;
        if (Math.abs(current - proposed) <= 5000) return true;
        return false;
      } else {
        return proposed >= minPayRate && proposed <= maxPayRate
      }
    }
  },
  methods: {
    handleUpdateModelValue ({ name, value }) {
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'salary', formData: this.model })
    },

    handleInput (event) {
      const { name, value } = event.target;
      this.model[name] = value;
      const hasChanged = !this.salaryIsInRange;
      if (hasChanged) {
        this.model.hasChanged = hasChanged;
      } else {
        this.model.hasChanged = undefined;
      }
      this.$emit('update:formData', { formName: 'salary', formData: this.model })
    },

    submitForm () {
      const hasChanged = !this.salaryIsInRange;
      this.$emit('submit-form', { name: 'salary', formData: { ...this.model, hasChanged } });
    }
  },
  mounted() {
    const payrollFrequency = this.atr?.formSix?.payrollFrequency;
    if (payrollFrequency) {
      this.model.payrollFrequency = payrollFrequency;
    }
    const additionalAllowances = this.atr?.formSix?.additionalAllowances || this.atr?.atr?.formSix?.additionalAllowances;
    if (additionalAllowances) {
      this.model.additionalAllowances = additionalAllowances;
    }
    const additionalAllowancesInformation = this.atr?.formSix?.additionalAllowancesInformation || this.atr?.atr?.formSix?.additionalAllowancesInformation;
    if (additionalAllowancesInformation) {
      this.model.additionalAllowancesInformation = additionalAllowancesInformation;
    }
    this.$emit('update:formData', { formName: 'salary', formData: this.model });
  },
  validations() {
    return {
      formData: {
        proposedSalary: { required },
        salaryCurrency: { required },
        additionalAllowances: { required },
        salaryReviewMonth: { required },
        payrollFrequency: { required },
        appraisal: { required }
      }
    }
    
  }
}

</script>

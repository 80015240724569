<template>
<form-layout
  :navItemConfig="navItemConfig"
  :currentNavItem="currentNavItem"
  :formPoint="formPoint"
  :formData="formData"
  :displayHeader="true"
  formName="leaverForm"
  @set-current-nav-item="setCurrentNavItem"
>
  <template #pageTitle>Advert Raised</template>
      <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">ATR Short ID:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.shortId }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Job Title:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.formThree.jobTitle }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{findStage(formData.currentStageName, formData.status)}}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.currentAssigneeEmail}}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Doctype:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.docType}}</dd>
      </div>
      <div class="flex justify-end" v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING">
        <button
          class="
            text-center
            px-4
            py-2
            rounded-md
            text-white
            bg-green-700
            hover:underline
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-cyan-500
            bg-no-repeat
          "
          @click.prevent="handleReassign"
        >Re-assign</button>
      </div>
    </template>
  <template #pageSubtitle>
    <b>*Please ensure that all mandatory fields are completed and the Job Description has been attached before submitting for approval</b><br>
    <b>*For compatible browsers please review this link. If your browser is not compatible it may effect the text you see within multi line text boxes and /or the speed of the people search.</b>
  </template>
  <template #main>
    <component
      :is="currentNavItem?.component"
      :data="formData"
      :formData="formData[currentNavItem?.name]"
      :disableForm="disableForm"
      :showApprovalSteps="disableForm"
      :configData="configItems"
      :configItems="configItems"
      @update:formData="handleUpdateFormData"
      @submit-form="handleSubmitForm" />
  </template>
</form-layout>
</template>

<script setup>
import { computed, onBeforeMount, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import httpClient from '@/httpClient'
import { saveStorage } from '@/storageSave'
import formLayout from '@/layouts/formLayout.vue'
import TaleoForm from '@/components/approvalWorkflowForms/taleoRecruitment.vue'
import formOne from '@/components/create-starter/formOne.vue';
import formTwo from '@/components/create-starter/formTwo.vue';
import formThree from '@/components/create-starter/formThree.vue';
import formFour from '@/components/create-starter/formFour.vue';
import formFive from '@/components/create-starter/formFive.vue';
import formSix from '@/components/create-starter/formSix.vue';
import formSeven from '@/components/create-starter/formSeven.vue';
import formEight from '@/components/create-starter/formEight.vue';
import formNine from '@/components/create-starter/formNine.vue';
import formTen from '@/components/create-starter/formTen.vue';
import formEleven from '@/components/create-starter/formEleven.vue';
import formTwelve from '@/components/approvalWorkflowForms/firstSecondApproval.vue';
import formThirteen from '@/components/approvalWorkflowForms/collectingApplications.vue'

const store = useStore();
const router = useRouter();
const formPoint = ref(0);
const stages = inject('stages');
const navItemConfig = [
  {
    formPoint: 0,
    name: "collectingApplications",
    text: "Collecting Applications",
    component: formThirteen,
    icon: "UserIcon"
  },
  {
    formPoint: 1,
    name: "resourcing",
    text: "Taleo Recruitment",
    component: TaleoForm,
    icon: "UserCircleIcon"
  },
  {
    formPoint: 2,
    name: "formOne",
    text: "Hiring Manager & Secondary Contact Details",
    component: formOne,
    icon: "UserIcon"
  },
  {
    formPoint: 3,
    name: "formTwo",
    text: "Recruitment Reason",
    component: formTwo,
    icon: "LogoutIcon"
  },
  {
    formPoint: 4,
    name: "formThree",
    text: "Job Details",
    component: formThree,
    icon: "EmojiHappyIcon"
  },
  {
    formPoint: 5,
    name: "formFour",
    text: "Organisation Details",
    component: formFour,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 6,
    name: "formFive",
    text: "Working Hours",
    component: formFive,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 7,
    name: "formSix",
    text: "Contract Details",
    component: formSix,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 8,
    name: "formSeven",
    text: "Advertising Requirements",
    component: formSeven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 9,
    name: "formEight",
    text: "Pre Employment Checks",
    component: formEight,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 10,
    name: "formNine",
    text: "Security",
    component: formNine,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 11,
    name: "formTen",
    text: "Specialist Roles",
    component: formTen,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 12,
    name: "formEleven",
    text: "Attachments",
    component: formEleven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 13,
    name: "formTwelve",
    text: "First & Second Line Approval",
    component: formTwelve,
    icon: "DocumentTextIcon"
  }
];
const formData = ref({
  resourcing: {},
  formOne: {},
  formTwo: {},
  formThree: {},
  formFour: {},
  formFive: {},
  formSix: {},
  formSeven: {},
  formEight: {},
  formNine: {},
  formTen: {},
  formEleven: {},
  formTwelve: {},
  collectingApplications: { saved: false }
});
const configItems = ref({});
const profile = computed(() => store.getters.getProfile);
const currentNavItem = computed(() => navItemConfig.find((item) => item.formPoint === formPoint.value));
const disableForm = computed(() => currentNavItem.value?.name !== 'collectingApplications');

const setCurrentNavItem = (value) => formPoint.value = value;
const handleSubmitForm = async ({ name, data }) => {
  try {
    store.dispatch('setIsLoading', true);
    formData.value[name].saved = true;
    const payload = {
        formData: { ...data },
        requestedBy: docOwner.value,
        id: docId.value,
        stage: formData.value.currentStageName
    }
    const res = await httpClient.post(`bff/starter/${name}`, payload);
    formData.value = res.data.body.Item;
    saveStorage('formData', JSON.stringify(formData.value));
    store.dispatch('setNotificationAll', {
        visible: true,
        type: 'success',
        title: 'SAVED!',
        message: 'The form has been saved.'
    });
    router.push('/');
    store.dispatch('setIsLoading', false);
} catch (err) {
    console.log(err)
    formData.value[name].saved = false;
    store.dispatch('setNotificationAll', {
        visible: true,
        type: 'error',
        title: 'Error!',
        message: 'There was an error submitting the form'
    });
    store.dispatch('setIsLoading', false);
}
};

const handleUpdateFormData = ({ formName, data }) => {
  formData.value[formName] = data;
  saveStorage('formData', JSON.stringify(formData.value));
};

const docId = computed(() => router.currentRoute.value.params.id);
const docOwner = computed(() => router.currentRoute.value.params.docOwner);

const fetchFormData = async () => {
  const response = await httpClient.post('/bff/get-document', { id: docId.value, requestedBy: docOwner.value })
  formData.value = response.data.body.Item;
  saveStorage('formData', JSON.stringify(formData.value));
};
const fetchConfigItems = async () => {
  const response = await httpClient.post('/config/all', { requestedBy: 'something' });
  configItems.value = response.data.body;
}

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
};

const handleReassign = () => {
  const id = formData.value.docId;
  const docOwner = formData.value.requestedBy;
  router.push(`/re-assign/${id}/${docOwner}`)
}


onBeforeMount(async () => {
  await fetchFormData();
  await fetchConfigItems();
});

</script>
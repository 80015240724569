<template>
  <div class="shadow sm:rounded-t-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
      <!-- title header -->
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          View Offers Generated
        </h3>
      </div>
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Current Assignee</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
            </tr>
          </thead>
          <tbody v-if="offers.length" class="bg-white">
            <tr v-for="offer in offers" :key="offer.docId">
              <td class="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6 text-sm text-gray-500 text-blue-600 hover:underline cursor-pointer" @click="navigateToDocument(offer.docId, offer.requestedBy, offer.currentStageName)">{{ offer.candidateName }}</td>
              <td class="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6 text-sm text-gray-500">{{ offer.currentAssigneeEmail }}</td>
              <td class="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500">
                <span
                  class="inline-flex rounded-full px-2 text-xs font-semibold leading-5"
                  :class="{ 
                    'bg-green-100 text-green-800': offer.status.toLowerCase() === 'submitted' || offer.status.toLowerCase() === 'completed',
                    'bg-yellow-100 text-yellow-800': offer.status.toLowerCase() === 'draft',
                    'bg-red-100 text-red-800': offer.status.toLowerCase() === 'rejected'
                  }"
                >
                  {{ offer.status }}
                </span>
              </td>
            </tr>
          </tbody>
          <p v-else class="text-gray-400 text-center p-8">No offers to show...</p>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/httpClient'
export default {
  data() {
    return {
      offers: {}
    }
  },
  methods: {
    async getOffers() {
      this.$store.dispatch('setIsLoading', true);
      const atrCreator = this.$route.params.docOwner;
      const docId = this.$route.params.id
      const response = await httpClient.post('/bff/get-offers-for-starter', { atrCreator, docId });
      this.offers = response.data.body.Items;
      this.$store.dispatch('setIsLoading', false);
    },
    navigateToDocument  (id, docOwner, stage) {
      if (stage === 'offer.notifyMakeOffer') {
        this.$router.push({ path: `/offer-hr/${id}/${docOwner}` });
      } else if (stage === 'offer.notifyPrepOffer') {
        this.$router.push({ path: `/confirm/${id}/${docOwner}` });
      } else if (stage === 'offer.notifyTermsHaveChanged') {
        this.$router.push({ path: `/amend-terms/${id}/${docOwner}` });
      } else if (stage === 'offer.notifyH2RAdmin') {
        this.$router.push({ path: `/assign-admin-owner/${id}/${docOwner}` });
      } else if (stage === 'offer.notifyOfferSent') {
        this.$router.push({ path: `/sap-confirmation/${id}/${docOwner}` });
      } else {
        this.$router.push({ path: `/view-offer/${id}/${docOwner}` });
      }
    }
  },
  async created() {
    await this.getOffers();
  }
}
</script>
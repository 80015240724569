<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Pre Employment Checks
        </h3>
        <div>
          <!-- title header -->
          <div>
            <p class="text-red-500 mt-4">
              For those working on HM Courts, ALL will require a specific HM Courts British Standard (BPSS)
              Check which will include a Disclosure Check as part of the British Standard.
            </p>
            <p class="text-red-500 mt-4">
              Therefore please do <span class="underline">not</span> select that a Disclosure Check is 
              required within the box below as this would indicate a separate Disclosure Check is required.
            </p>
          </div>
          <!-- end title header -->
          <!-- Form -->
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <div>
                <p>Is a Disclosure Check Required? *</p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-disclosure-check-no"
                      name="disclosureCheckRequired"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :checked="formData.disclosureCheckRequired === 'No'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureCheckRequired.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosure-check-no"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-disclosure-check-yes"
                      name="disclosureCheckRequired"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :checked="formData.disclosureCheckRequired === 'Yes'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureCheckRequired.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosure-check-yes"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <span
                  v-if="v$.formData.disclosureCheckRequired.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.formData.disclosureCheckRequired.$errors[0].$message }}
                </span>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4" v-if="formData.disclosureCheckRequired === 'Yes'">
            <fieldset>
              <span
                  v-if="
                    v$.formData.disclosureSchemeEnglandWales.$errors.length || 
                    v$.formData.disclosureSchemeScotland.$errors.length
                  "
                  class="text-red-500"
                >
                  * {{ 
                      v$.formData.disclosureSchemeEnglandWales.$errors[0].$message ??
                      v$.formData.disclosureSchemeScotland.$errors[0].$message
                    }}
              </span>
              <div>
                <p>If Disclosure and Barring Scheme (DBS) -
                  <span class="extra-bold">England</span> and <span class="extra-bold">Wales</span> Only
                </p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-dbs-basic"
                      name="disclosureSchemeEnglandWales"
                      type="radio"
                      value="DBS Basic"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.disclosureSchemeEnglandWales === 'DBS Basic'"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeEnglandWales.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosureSchemeEnglandWales"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DBS Basic</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-dbs-standard"
                      name="disclosureSchemeEnglandWales"
                      type="radio"
                      value="DBS Standard"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :checked="formData.disclosureSchemeEnglandWales === 'DBS Standard'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeEnglandWales.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosureSchemeEnglandWales"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DBS Standard</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-dbs-enhanced"
                      name="disclosureSchemeEnglandWales"
                      type="radio"
                      value="DBS Enhanced with Barred Lists"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :checked="formData.disclosureSchemeEnglandWales === 'DBS Enhanced with Barred Lists'"
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeEnglandWales.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="dbs-enhanced"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DBS Enhanced with Barred Lists</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4" v-if="formData.disclosureCheckRequired === 'Yes'">
            <fieldset>
              <div>
                <p>If Disclosure Scotland (DS) -
                  <span class="extra-bold">Scotland</span> Only
                </p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-ds-basic"
                      name="disclosureSchemeScotland"
                      type="radio"
                      value="DS Basic"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.disclosureSchemeScotland === 'DS Basic'"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeScotland.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="ds-basic"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DS Basic</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-ds-standard"
                      name="disclosureSchemeScotland"
                      type="radio"
                      value="DS Standard"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.disclosureSchemeScotland === 'DS Standard'"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeScotland.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosureSchemeScotland"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DS Standard</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-ds-enhanced"
                      name="disclosureSchemeScotland"
                      type="radio"
                      value="DS Enhanced"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.disclosureSchemeScotland === 'DS Enhanced'"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeScotland.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosureSchemeScotland"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DS Enhanced</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-ds-pvg"
                      name="disclosureSchemeScotland"
                      type="radio"
                      value="DS PVG"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.disclosureSchemeScotland === 'DS PVG'"
                      @change="handleRadioChange"
                      @blur="v$.formData.disclosureSchemeScotland.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="disclosureSchemeScotland"
                      class="font-medium text-gray-700 cursor-pointer"
                      >DS PVG</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <div>
                <p>Is a Child Safeguarding Declaration Required? *</p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-child-declaration-no"
                      name="childSafeguardingRequired"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.childSafeguardingRequired === 'No'"
                      @change="handleRadioChange"
                      @blur="v$.formData.childSafeguardingRequired.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="child-declaration-no"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-child-declaration-yes"
                      name="childSafeguardingRequired"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      :checked="formData.childSafeguardingRequired === 'Yes'"
                      @change="handleRadioChange"
                      @blur="v$.formData.childSafeguardingRequired.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="child-declaration-yes"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <span
                  v-if="v$.formData.childSafeguardingRequired.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.formData.childSafeguardingRequired.$errors[0].$message }}
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Save button -->
    <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        class="
          mr-2
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-600
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click.prevent="handleResetForm"
      >
        Reset Form
      </button>
      <button
        :disabled="v$.$invalid || uploading"
        type="submit"
        class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        "
        @click="handleSave"
      >
        Next
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";

export default {
  name: "formSeven",
  props: ['formData', 'showApprovalSteps'],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
      uploading: false,
    };
  },
  validations() {
    return {
      formData: {
        disclosureCheckRequired: {
          required,
        },
        disclosureSchemeEnglandWales: {
          requiredIf: helpers.withMessage(
            'If a disclosure check is required please fill in one of the following fields.',
            requiredIf(
              this.formData.disclosureCheckRequired === 'Yes' &&
              !this.formData.disclosureSchemeScotland
            )
          )
        },
        disclosureSchemeScotland: {
          requiredIf: helpers.withMessage(
            'If a disclosure check is required please fill in one of the following fields.',
            requiredIf(
              !this.formData.disclosureCheckRequired === 'Yes' &&
              !this.formData.disclosureSchemeEnglandWales
            )
          )
        },
        childSafeguardingRequired: {
          required,
        },
      },
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formEight",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formEight');
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formEight', formData: this.model });
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  }
};
</script>

import { createRouter, createWebHistory } from "vue-router"
import store from "./store"
import actionDocuments from './components/actionDocuments.vue'
import AdminPanel from './components/admin/AdminPanel.vue'
import allDocuments from './components/allDocuments.vue'
import AdminAssignView from './components/adminAssignView.vue'
import AdvancedSearch from './components/AdvancedSearch.vue'
import ApproveAmend from './components/approveAmend.vue'
import ApprovalPage from './components/approvalPage.vue'
import AssignAdminOwner from './components/workflowForms/AssignAdminOwner.vue'
import ConfirmLeaver from './components/ConfirmLeaver.vue'
import ConfirmAmend from './components/ConfirmAmend.vue'
import ConfirmOffer from './components/confirmOffer.vue'
import CreateStarter from './components/createStarter.vue'
import CreateLeaver from './components/createLeaver.vue'
import CreateAmend from './components/createAmend.vue'
import CompleteAmend from './components/CompleteAmend.vue'
import indexView from './components/indexView.vue'
import LeaverAssignView from './components/leaverAssignView.vue';
import LeaverCompleteView from './components/leaverCompleteView.vue'
import LogoutComponent from './components/logoutComponent.vue'
import profileView from './components/profileView.vue'
import RecruitmentUpdate from './components/workflowForms/RecruitmentUpdate.vue'
import recruitmentNotify from './components/workflowForms/step4RecruitmentTeam.vue'
import advertRaised from './components/workflowForms/step5AdvertRaised.vue'
import readyForSelection from './components/workflowForms/step6ReadyForSelection.vue'
import SapConfirmation from './components/workflowForms/SapConfirmation.vue'
import selectionComplete from './components/workflowForms/step7SelectionComplete.vue'
import ReAssign from './components/ReAssign.vue'
import RespondMoreInfo from './components/RespondMoreInfo.vue'
import ResourcingDashboard from './components/ResourcingDashboard.vue'
import recruitmentFeedback from './components/workflowForms/step8RecruitmentFeedback.vue'
import offerHr from './components/workflowForms/step13OfferHR.vue'
import flApproval from './components/workflowForms/step14OfferFLA.vue'
import slApproval from './components/workflowForms/step15OfferSLA.vue'
import offerView from './components/offerView.vue'
import searchResults from './components/searchResults.vue'
import ViewAllDocs from './components/ViewAllDocs.vue'

const isPermitted = (roles) => {
  const profile = store.getters.getProfile;
  let allowed = false;
  roles.forEach((role) =>  {
    if (profile[role]) {
      allowed = true;
    }
  });
  return allowed;
};

const routeInfos = [
    {
        name: "Home",
        path: "/",
        component: indexView,
        meta: {
            title: 'Equans Slam - Welcome',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - Welcome to the Slam Portal'
                }
              ]
        }
    },
    {
        name: "Logout",
        path: '/logout',
        component: LogoutComponent,
    },
    {
        path: '/create-starter/:id',
        name: 'Create Starter',
        component: CreateStarter,
        meta: {
            title: 'Equans Slam - Create Starter',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - Create a Starter Form'
                }
              ]
        }
    },
    {
        path: '/view-starter/:id/:docOwner',
        name: 'View ATR',
        component: CreateStarter,
        meta: {
            title: 'Equans Slam - View Starter',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - View a Starter Form'
                }
              ]
        }
    },
    {
        path: '/create-leaver/:id',
        name: 'Create Leaver',
        component: CreateLeaver,
        meta: {
            title: 'Equans Slam - Create Leaver',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - Create a Leaver Form'
                }
              ]
        }
    },
    {
      path: '/view-leaver/:id/:docOwner',
      name: 'View leaver',
      component: CreateLeaver,
      meta: {
          title: 'Equans Slam - View Leaver',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - View a Leaver Form'
              }
            ]
      }
    },
    {
      path: '/assign-leaver-admin/:docId/:requestedBy',
      name: 'Assign Leaver admin',
      component: LeaverAssignView,
      meta: {
          title: 'Equans Slam - Assign Admin',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Assign an admin to a leaver'
              }
            ]
      }
    },
    {
      path: '/confirm-leaver/:docId/:requestedBy',
      name: 'Confirm leaver',
      component: ConfirmLeaver,
      meta: {
          title: 'Equans Slam - Confirm Leaver',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Confirm leaver'
              }
            ]
      }
    },
    {
      path: '/confirm-amend/:docId/:requestedBy',
      name: 'Confirm Amendment',
      component: ConfirmAmend,
      meta: {
          title: 'Equans Slam - Confirm Amend',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Confirm leaver'
              }
            ]
      }
    },
    {
      path: '/more-info/:docId/:requestedBy',
      name: 'Respond to more info',
      component: RespondMoreInfo,
      meta: {
          title: 'Equans Slam - More info requested',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - More info requested'
              }
            ]
      }
    },
    {
      path: '/complete-leaver/:docId/:docOwner',
      name: 'Complete leaver',
      component: LeaverCompleteView,
      meta: {
          title: 'Equans Slam - Complete Leaver',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Complete a leaver request'
              }
            ]
      }
    },
    {
        path: '/create-amend',
        name: 'Create Amendment',
        component: CreateAmend,
        meta: {
          title: 'Equans Slam - Create Amendment',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Complete an amendment request'
              }
            ]
      }
    },
    {
      path: '/view-amend/:id/:docOwner',
      name: 'View Amend',
      component: CreateAmend,
      meta: {
          title: 'Equans Slam - View Amend',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - View an Amend Form'
              }
            ]
      }
    },
    {
      path: '/view-offer/:id/:docOwner',
      name: 'View Offer',
      component: offerView,
      meta: {
          title: 'Equans Slam - View Offer',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - View an Offer'
              }
            ]
      }
    },
    {
      path: '/confirm/:id/:docOwner',
      name: 'Confirm Offer',
      component: ConfirmOffer,
      meta: {
          title: 'Equans Slam - Confirm Offer',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Confirm an Offer'
              }
            ]
      }
    },
    {
      path: '/amend-terms/:id/:docOwner',
      name: 'Amend terms',
      component: ConfirmOffer,
      meta: {
          title: 'Equans Slam - Confirm Offer Terms',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Amend the terms of an offer'
              }
            ]
      }
    },
    {
      path: '/approve-amend/:id/:docOwner',
      name: 'Approve Amend',
      component: ApproveAmend,
      meta: {
          title: 'Equans Slam - Approve Amend',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Approve an Amend Form'
              }
            ]
      }
    },
    {
      path: '/admin-assign-amend/:id/:docOwner',
      name: 'Assign Amend Admin',
      component: AdminAssignView,
      meta: {
          title: 'Equans Slam - Assign Amend Admin',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Assign an admin to the amend'
              }
            ]
      }
    },
    {
      path: '/complete-amend/:id/:docOwner',
      name: 'Complete Amend',
      component: CompleteAmend,
      meta: {
          title: 'Equans Slam - Complete Amend',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Complete an amend request'
              }
            ]
      }
    },
    {
        path: '/approve-workflow/:action',
        name: 'Approval Workflow',
        component: ApprovalPage,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: profileView,
    },
    {
        path: '/recruitment-update/:id/:docOwner',
        name: 'Recruitment Team',
        component: recruitmentNotify,
        meta: {
          title: 'Equans Slam - Recruitment Details',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Approval Workflow, Step 4 Recruitment Team'
              }
            ]
      }
    },
    {
      path: '/recruitment-team/:id/:docOwner',
      name: 'Recruitment Details',
      component: RecruitmentUpdate,
      meta: {
        title: 'Equans Slam - Recruitment Details',
        metaTags: [
            {
              name: 'description',
              content: 'Equans Slam Portal - Approval Workflow, Recruitment Details'
            }
          ]
      }
    },
    {
        path: '/advert-raised/:id/:docOwner',
        name: 'Advert Raised',
        component: advertRaised,
        meta: {
          title: 'Equans Slam - Approval Workflow',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Approval Workflow, Step 5 Advert Raised'
              }
            ]
      }
    },
    {
      path: '/ready-for-selection/:id/:docOwner',
      name: 'Ready For Selection',
      component: readyForSelection,
      meta: {
        title: 'Equans Slam - Approval Workflow',
        metaTags: [
            {
              name: 'description',
              content: 'Equans Slam Portal - Approval Workflow, Step 6 Ready for selection'
            }
          ]
    }
  },
    {
        path: '/recruitment-feedback',
        name: 'Recruitment Feedback',
        component: recruitmentFeedback,
    },
    {
        path: '/selection-complete/:id/:docOwner',
        name: 'Selection Complete',
        component: selectionComplete,
        meta: {
          title: 'Equans Slam - Approval Workflow',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Approval Workflow, Step 7 Selection Complete'
              }
            ]
      }
    },
    {
      path: '/offer-hr/:id/:docOwner',
      name: 'Offer HR',
      component: offerHr,
      meta: {
        title: 'Equans Slam - Approval Workflow',
        metaTags: [
            {
              name: 'description',
              content: 'Equans Slam Portal - Approval Workflow, Step 13 Offer HR'
            }
          ]
    }
    },
    {
      path: '/first-line-approval',
      name: 'First Line',
      component: flApproval,
    },
    {
      path: '/second-line-approval',
      name: 'Second Line',
      component: slApproval,
    },
    {
        path: '/all-my-documents',
        name: 'Your Requests',
        component: allDocuments,
        meta: {
            title: 'Equans Slam - View My ATR Documemts',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - View my ATR Documemts'
                }
              ]
        }
    },
    {
      path: '/all-documents',
      name: 'All Requests',
      component: ViewAllDocs,
      meta: {
          title: 'Equans Slam - View all ATR Documemts',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - View all ATR Documemts'
              }
            ]
      },
      beforeEnter: (to, from, next) => {
        if (!isPermitted(['H2R', 'ADMIN', 'RESOURCING'])) next('/')
        else next()
      }
    },
    {
        path: '/action-documents',
        name: 'Assigned Approvals',
        component: actionDocuments,
        meta: {
            title: 'Equans Slam - View Assigned ATR Documemts',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - View Assigned ATR Documemts'
                }
              ]
        }
    },
    {
      path: '/search',
      name: 'Advanced Search',
      component: AdvancedSearch,
      meta: {
        title: 'Equans Slam - Search',
        metaTags: [
            {
              name: 'description',
              content: 'Equans Slam Portal - Search for a document'
            }
        ]
      }
    },
    {
        path: '/search-results/:docType/:searchString',
        name: 'Search Results',
        component: searchResults,
        meta: {
            title: 'Equans Slam - Search Results',
            metaTags: [
                {
                  name: 'description',
                  content: 'Equans Slam Portal - Search Results'
                }
              ]
        }
    },
    {
      path: '/resourcing',
      name: 'Resourcing',
      component: ResourcingDashboard,
      meta: {
          title: 'Equans Slam - Resourcing Dashboard',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Resourcing Dashboard'
              }
            ]
      },
      beforeEnter: (to, from, next) => {
        if (!isPermitted(['H2R', 'ADMIN', 'RESOURCING'])) {
          next('/')
        }
        else next()
      }
  },
  {
    path: '/assign-admin-owner/:id/:docOwner',
    name: 'Assign Admin Owner',
    component: AssignAdminOwner,
    meta: {
      title: 'Equans Slam - Assign Admin Owner',
          metaTags: [
              {
                name: 'description',
                content: 'Equans Slam Portal - Assign admin owner and view all data associated with the offer'
              }
          ]
    }
  },
  {
    path: '/sap-confirmation/:id/:docOwner',
    name: 'Sap confirmation',
    component: SapConfirmation,
    meta: {
        title: 'Equans Slam - Confirm candidate has been added to SAP',
        metaTags: [
            {
              name: 'description',
              content: 'Equans Slam Portal - Confirm candidate has been added to SAP'
            }
        ]
    }
  },
  {
    path: '/re-assign/:id/:docOwner',
    name: 'Re-assign Document',
    component: ReAssign,
    meta: {
      title: 'Equans Slam - Re-assign a document',
      metaTags: [
          {
            name: 'description',
            content: 'Equans Slam Portal - Change the current assignee of a document'
          }
      ]
    },
    beforeEnter: (to, from, next) => {
      if (!isPermitted(['H2R', 'ADMIN', 'RESOURCING'])) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin Panel',
    component: AdminPanel,
    meta: {
      title: 'Equans Slam - Admin Panel',
      metaTags: [
          {
            name: 'description',
            content: 'Equans Slam Portal - Admin Panel'
          }
      ]
    },
    beforeEnter: (to, from, next) => {
      if (!isPermitted(['H2R', 'ADMIN'])) {
        next('/');
      }
      else {
        next();
      }
    }
  }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routeInfos
})


router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    if(!nearestWithMeta) return next();
  
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));
  
    next();
  });

export default router;
<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Pre Employment Checks
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <p class="text-sm text-red-700">
          For those working on HM Courts, ALL will require a specific HM Courts
          British Standard (BPSS) Check which will include a Disclosure Check as
          part of the British Standard.
        </p>
        <p class="text-sm text-red-700">
          Therefore please do not select that a Disclosure Check is required
          within the box below as this would indicate a separate Disclosure
          Check is required.
        </p>
        <div v-html="disclosure_check"></div>
        <div v-html="child_safegarding"></div>

        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { Input } from "@/form";
import { defineProps } from 'vue'

const props = defineProps(['formData', 'disableForm']);

const disclosure_check = Input(
  "Is a Disclosure Check Required?",
  "disclosure_check",
  "text",
  props.disableForm,
  props.formData.disclosureCheckRequired
);

const child_safegarding = Input(
  "Is a Child Safeguarding Declaration Required?",
  "child_safegarding",
  "text",
  props.disableForm,
  props.formData.childSafeguardingRequired
);

</script>

<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Reporting & Process Continuity
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Does the employee have any people reporting to them? *
              </legend>
              <span v-if="v$.formData.employee_reporting_to.$error" class="text-red-500">
                * {{ v$.formData.employee_reporting_to.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.employee_reporting_to"
                      value="Yes"
                      id="radio-button-employee_reporting_to-yes"
                      name="employee_reporting_to"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.employee_reporting_to.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="employee_reporting_to" class="font-medium text-gray-700">Yes</label>
                  </div>
                </div>
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        v-model="formData.employee_reporting_to"
                        value="No"
                        id="radio-button-employee_reporting_to-no"
                        name="employee_reporting_to"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                        :disabled="disableForm"
                        @blur="v$.formData.employee_reporting_to.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="employee_reporting_to" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-sm mt-4">*If the leaver has MMS and has other employees directly reporting into them in SAP please select Yes.</p>
            </fieldset>

            <div v-if="formData.employee_reporting_to === 'Yes'">
              <p class="text-sm text-blue-700">Please provide information as to who the people will report to moving forward or in the interim period below:</p>
              
              <div class="col-span-6 mt-2">
                <label for="employee-name" class="block text-sm font-medium text-gray-700"
                  >New line manager name *</label
                >
                <input
                  v-model="formData.line_manager"
                  type="text"
                  name="line_manager"
                  id="line_manager_input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                  :disabled="disableForm"
                  @blur="v$.formData.line_manager.$touch()"
                />
                <span v-if="v$.formData.line_manager.$error" class="text-red-500">
                  * {{ v$.formData.line_manager.$errors[0].$message }}
                </span>
              </div>

              <div class="col-span-6 mt-2">
                <label for="employee-name" class="block text-sm font-medium text-gray-700"
                  >New line manager employee number *</label
                >
                <input
                  v-model="formData.employee_number"
                  type="text"
                  name="employee_number"
                  id="employee_number_input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                  :disabled="disableForm"
                  @blur="v$.formData.employee_number.$touch()"
                />
                <span v-if="v$.formData.employee_number.$error" class="text-red-500">
                  * {{ v$.formData.employee_number.$errors[0].$message }}
                </span>
              </div>

              <div class="col-span-6 mt-2">
                <label for="additional_information" class="block text-sm font-medium text-gray-700">Additional information:</label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea id="additional_information_input" name="additional_information" v-model="formData.additional_information" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" />
                </div>
              </div>
              <p class="text-sm mt-2">*Once submitted / approved this information will be sent to the SSC Data Management Team who will update SAP.</p>
            </div>

            <fieldset class="mt-2">
              <legend class="text-base font-medium text-gray-900">
                Is the leaver a coupa user? *
              </legend>
              <span v-if="v$.formData.coupa_user.$error" class="text-red-500">
                * {{ v$.formData.coupa_user.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.coupa_user"
                      value="Yes"
                      id="radio-button-coupa_user-yes"
                      name="coupa_user"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.coupa_user.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="employee_reporting_to" class="font-medium text-gray-700">Yes</label>
                  </div>
                </div>
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        v-model="formData.coupa_user"
                        value="No"
                        id="radio-button-coupa_user-no"
                        name="coupa_user"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                        :disabled="disableForm"
                        @blur="v$.formData.coupa_user.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="coupa_user" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div v-if="formData.coupa_user === 'Yes'">
              <div class="col-span-6 mt-2">
                <label for="coupa_user_name" class="block text-sm font-medium text-gray-700"
                  >Name of the user to re-assign responsibilities to: *</label
                >
                <input
                  v-model="formData.coupa_user_name"
                  type="text"
                  name="coupa_user_name"
                  id="coupa_user_name_input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                  :disabled="disableForm"
                  @blur="v$.formData.coupa_user_name.$touch()"
                />
                <span v-if="v$.formData.coupa_user_name.$error" class="text-red-500">
                  * {{ v$.formData.coupa_user_name.$errors[0].$message }}
                </span>
              </div>

              <div class="col-span-6 mt-2">
                <label for="coupa_user_number" class="block text-sm font-medium text-gray-700"
                  >Payroll number of the user to re-assign responsibilities to: *</label
                >
                <input
                  v-model="formData.coupa_user_number"
                  type="text"
                  name="coupa_user_number"
                  id="coupa_user_number_input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                  :disabled="disableForm"
                  @blur="v$.formData.coupa_user_number.$touch()"
                />
                <span v-if="v$.formData.coupa_user_number.$error" class="text-red-500">
                  * {{ v$.formData.coupa_user_number.$errors[0].$message }}
                </span>
              </div>

              <div class="col-span-6 mt-2">
                <label for="coupa_user_email" class="block text-sm font-medium text-gray-700"
                  >Email of the user to re-assign responsibilities to: *</label
                >
                <input
                  v-model="formData.coupa_user_email"
                  type="text"
                  name="coupa_user_email"
                  id="coupa_user_email_input"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                  :disabled="disableForm"
                  @blur="v$.formData.coupa_user_email.$touch()"
                />
                <span v-if="v$.formData.coupa_user_email.$error" class="text-red-500">
                  * {{ v$.formData.coupa_user_email.$errors[0].$message }}
                </span>
              </div>
            </div>
            <fieldset class="mt-2">
              <legend class="text-base font-medium text-gray-900">
                Is this leaver an operational engineer within a CAFM System? *
              </legend>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.cafm_leaver"
                      value="Yes"
                      id="radio-button-cafm_leaver-yes"
                      name="cafm_leaver"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.cafm_leaver.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="cafm_leaver_yes" class="font-medium text-gray-700">Yes</label>
                  </div>
                </div>
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        v-model="formData.cafm_leaver"
                        value="No"
                        id="radio-button-cafm_leaver-no"
                        name="cafm_leaver"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                        :disabled="disableForm"
                        @blur="v$.formData.cafm_leaver.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="cafm_leaver_no" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
                <div v-if="formData?.cafm_leaver === 'Yes'" class="col-span-6 mt-2">
                    <label for="cafm_leaver_contracts" class="block text-sm font-medium text-gray-700"
                      >Which contracts do they work on?: *</label
                    >
                    <input
                      v-model="formData.cafm_leaver_contracts"
                      id="cafm_leaver_contracts"
                      name="cafm_leaver_contracts"
                      type="text"
                      class="
                        mt-4
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.cafm_leaver_contracts.$touch()"
                    />
                    <span v-if="v$.formData.cafm_leaver_contracts.$error" class="text-red-500">
                      * {{ v$.formData.cafm_leaver_contracts.$errors[0].$message }}
                    </span>
                </div>
                <div v-if="formData?.cafm_leaver_contracts" class="col-span-6 mt-4">
                    <label for="designated_operative" class="block text-sm font-medium text-gray-700"
                      >Is there a designated operative to cover this leaver's work?: *
                    </label>
                    <div class="flex items-start mt-4">
                      <div class="h-5 flex items-center">
                        <input
                          v-model="formData.designated_operative_check"
                          value="Yes"
                          id="radio-button-designated_operative-yes"
                          name="designated_operative"
                          type="radio"
                          class="
                            focus:ring-indigo-500
                            h-4
                            w-4
                            text-indigo-600
                            border-gray-300
                            rounded
                            cursor-pointer
                          "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                          :disabled="disableForm"
                          @blur="v$.formData.cafm_leaver.$touch()"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="designated_operative_yes" class="font-medium text-gray-700">Yes</label>
                      </div>
                    </div>
                    <div class="flex items-start">
                      <div class="h-5 flex items-center mt-4">
                        <input
                          v-model="formData.designated_operative_check"
                          value="No"
                          id="radio-button-designated_operative-no"
                          name="designated_operative_check"
                          type="radio"
                          class="
                            focus:ring-indigo-500
                            h-4
                            w-4
                            text-indigo-600
                            border-gray-300
                            rounded
                            cursor-pointer
                          "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                          :disabled="disableForm"
                          @blur="v$.formData.cafm_leaver.$touch()"
                        />
                      </div>
                      <div class="ml-3 text-sm mt-4">
                        <label for="designated_operative_no" class="font-medium text-gray-700">No</label>
                      </div>
                    </div>
                </div>
                <div v-if="formData?.designated_operative_check === 'Yes'" class="col-span-6 mt-4">
                    <label for="designated_operative_payroll" class="block mt-4 text-sm font-medium text-gray-700"
                      >Please enter the designated operative's payroll number: *</label
                    >
                    <input
                      v-model="formData.designated_operative_payroll"
                      id="designated_operative_payroll"
                      name="designated_operative_payroll"
                      type="text"
                      class="
                        mt-4
                        focus:ring-indigo-500 focus:border-indigo-500
                        
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.designated_operative_payroll.$touch()"
                    />
                    <span v-if="v$.formData.designated_operative_payroll.$error" class="text-red-500">
                      * {{ v$.formData.designated_operative_payroll.$errors[0].$message }}
                    </span>

                    <label for="designated_operative_name" class="block mt-4 text-sm font-medium text-gray-700"
                      >Please enter the designated operative's name: *</label
                    >
                    <input
                      v-model="formData.designated_operative_name"
                      id="designated_operative_name"
                      name="designated_operative_name"
                      type="text"
                      class="
                        mt-4
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.designated_operative_name.$touch()"
                    />
                    <span v-if="v$.formData.designated_operative_name.$error" class="text-red-500">
                      * {{ v$.formData.designated_operative_name.$errors[0].$message }}
                    </span>
                </div>
                <div v-if="formData?.cafm_leaver === 'Yes'" class="col-span-6 mt-4">
                  <label for="helpdesk_email" class=" text-sm font-medium text-gray-700"
                      >Please enter the relevant helpdesk email address: *</label
                    >
                    <input
                      v-model="formData.helpdesk_email"
                      id="helpdesk_email"
                      name="helpdesk_email"
                      type="email"
                      class="
                        mt-4
                        focus:ring-indigo-500 focus:border-indigo-500
                        
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.helpdesk_email.$touch()"
                    />
                    <span v-if="v$.formData.helpdesk_email.$error" class="text-red-500">
                      * {{ v$.formData.helpdesk_email.$errors[0].$message }}
                    </span>
                </div>

              </div>
            </fieldset>
            
      <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf, email } from "@vuelidate/validators";
export default {
  props: ['disableForm'],
  data() {
    return {
      v$: useVuelidate()
    }
  },
  computed: {
    formData() {
      return this.$store.getters.getLeaverFormData('formSeven')
    }
  },
  methods: {
    submitForm() {
      this.$emit('submit-form', { name: 'formSeven', formData: this.formData });
    }
  },
  validations() {
    return {
      formData: {
        employee_reporting_to: { required },
        line_manager: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.employee_reporting_to === 'Yes')
          )
        },
        employee_number: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.employee_reporting_to === 'Yes')
          )
        },
        coupa_user: { required },
        coupa_user_name: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.coupa_user === 'Yes')
          )
        },
        coupa_user_number: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.coupa_user === 'Yes')
          )
        },
        coupa_user_email: {
          email,
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.coupa_user === 'Yes')
          )
        },
        cafm_leaver: {required},
        cafm_leaver_contracts: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.cafm_leaver === 'Yes')
          )
        },
        helpdesk_email: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.cafm_leaver === 'Yes')
          ),
          email
        },
        designated_operative_payroll: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.designated_operative_check === 'Yes')
          )
        },
        designated_operative_name: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData.designated_operative_check === 'Yes')
          )
        }
      }
    }
  }
}
</script>

import { createStore } from 'vuex'
import leaverForm from './modules/leaverForm'
import createPersistedState from "vuex-persistedstate";

const persisted = createPersistedState({
  paths: ['profile']
});

const store = createStore({
    modules: {
        leaverForm,
    },
    plugins: [persisted],
    state() {
        return {
            profile: {},
            theme: {},
            isLoading: false,
            notification: {
                visible: false,
                type: null,
                title: null,
                message: null
            },
        }
    },
    getters: {
        getProfile(state) { return state.profile },
        getTheme(state) { return state.theme },
        getNotification(state) { return state.notification },
        isLoading(state) { return state.isLoading }
    },
    mutations: {
        setProfile(state, value) {
          state.profile = value;
        },
        SET_THEME(state, theme) {
            state.theme = theme;
            localStorage.theme = theme;
        },
        setIsLoading(state, value) {
            state.isLoading = value;
        },
        setNotificationAll(state, {
            visible,
            type,
            title,
            message
        }) {
            state.notification.visible = visible
            state.notification.type = type
            state.notification.title = title
            state.notification.message = message
        }
    },
    actions: {
        // set profile
        setProfile({ commit }, value) {
          commit('setProfile', value);
        },
        // initialize theme based on user preference
        initTheme({ commit }) {
            const cachedTheme = localStorage.theme ? localStorage.theme : false;
            //  `true` if the user has set theme to `dark` on browser/OS
            const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

            if (cachedTheme)
                commit('SET_THEME', cachedTheme)
            else if (userPrefersDark)
                commit('SET_THEME', 'dark')
            else
                commit('SET_THEME', 'light')
        },
        // toggle theme
        toggleTheme({ commit }) {
            switch (localStorage.theme) {
                case 'light':
                    commit('SET_THEME', 'dark')
                    break;

                default:
                    commit('SET_THEME', 'light')
                    break;
            }
        },
        // show or hide the loading spinner
        setIsLoading({ commit }, value) {
            commit('setIsLoading', value);
        },
        // set notification visibility, type, title and message
        setNotificationAll({ commit }, data) {
            commit('setNotificationAll', data)
        },
        // hide notification
        closeNotification({ commit }) {
            commit('setNotificationAll', {
                visible: false,
                type: null,
                title: null,
                message: null
            })
        }
    }
})

// export store to use in main.js
export default store
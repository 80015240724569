<template>
  <div>
    
    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
        <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 mt-8">
          <nav class="mt-5 flex-1 space-y-1 bg-white px-2">
            <button v-for="item in navigation" class="w-full" :key="item.name" :href="item.href" :class="[item.name === currentNavItem.name ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']" @click="handleNavClick(item.index)">
              <component :is="item.icon" :class="[item.name === currentNavItem.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div class="flex flex-1 flex-col md:pl-64">
      <main class="flex-1">
        <div class="py-6">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div class="md:hidden flex relative">
              <h1 class="text-2xl font-semibold text-gray-900">{{ currentNavItem.name }}</h1>
              <button class="ml-2" @click="toggleDropdown">
                <ChevronDownIcon class="h-6 w-6 text-gray-800 duration-100" :class="{ 'rotate-180 duration-100' : dropdownOpen }" />
              </button>
              <div v-if="dropdownOpen" class="absolute left-0 top-6 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <div v-for="item in navigation" :key="item.index">
                    <button class="w-full" :class="[item.name === currentNavItem.name ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']" @click="handleNavClick(item.index)">
                      <component class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" :is="item.icon"></component>
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h1 class="text-2xl font-semibold text-gray-900 hidden md:block">{{ currentNavItem.name }}</h1>
          </div>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <!-- Replace with your content -->
            <div class="py-4">
              <component :is="currentNavItem.component"></component>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  AdjustmentsIcon,
  ChartBarIcon,
  ChevronDownIcon,
  PencilAltIcon,
  UsersIcon
} from '@heroicons/vue/outline';
import UserManagement from '@/components/admin/admin-components/users/UserManagement.vue';
import ConfigManagement from '@/components/admin/admin-components/config/ConfigManagement.vue';
import ReportingDownload from '@/components/admin/admin-components/reporting/ReportingDownload.vue';
import H2rDashboard from '@/components/admin/admin-components/h2rDashboard/H2rDashboard.vue';

const dropdownOpen = ref(false);
const navIndex = ref(0);

const navigation = [
  { name: 'Users', icon: UsersIcon, component: UserManagement, index: 0 },
  { name: 'Config Items', icon: AdjustmentsIcon, component: ConfigManagement, index: 1 },
  { name: 'Reporting', icon: ChartBarIcon, component: ReportingDownload, index: 2 },
  { name: 'H2R Dashboard', icon: PencilAltIcon, component: H2rDashboard, index: 3 },
];

const currentNavItem = computed(() => navigation.find((item) => item.index === navIndex.value));

const toggleDropdown = () => dropdownOpen.value = !dropdownOpen.value;
const handleNavClick = (index) => navIndex.value = index;
</script>

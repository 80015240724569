<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Recruitment Reason
          </h3>
        </div>
        <div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                What is the reason for recruiting this role? *
              </legend>
              <span v-if="v$.formData.recruitmentReason.$errors.length" class="text-red-500">
                * {{ v$.formData.recruitmentReason.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="Replacement Role"
                      id="radio-button-replacement-role"
                      name="recruitmentReason"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :checked="formData.recruitmentReason === 'Replacement Role'"
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.recruitmentReason.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="recruitmentReason" class="font-medium text-gray-700"
                      >Replacement Role</label
                    >
                  </div>
                </div>
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="Candidates"
                        id="radio-button-candidates"
                        name="recruitmentReason"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :checked="formData.recruitmentReason === 'Candidates'"
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                        :disabled="showApprovalSteps"
                        @change="handleRadioChange"
                        @blur="v$.formData.recruitmentReason.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="recruitmentReason" class="font-medium text-gray-700"
                        >New Candidates</label
                      >
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </fieldset>
          </div>

          <div v-if="formData.recruitmentReason === 'Replacement Role'">
            <p>As you have selected Replacement Role, please provided
              the information below for the employee who is leaving/moving:
            </p>
              <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                  <label
                    for="employee_id"
                    class="block text-sm font-medium text-gray-700"
                    >Employee ID</label
                  >
                  <input
                    :value="formData.employee_id"
                    type="text"
                    name="employee_id"
                    id="employee_id"
                    autocomplete="name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    @change="handleInputChange"
                  />
            
              </div>
              <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                  <label
                    for="employee_name"
                    class="block text-sm font-medium text-gray-700"
                    >Name</label
                  >
                  <input
                    :value="formData.employee_name"
                    type="text"
                    name="employee_name"
                    id="employee_name"
                    autocomplete="name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    @change="handleInputChange"
                  />
            
              </div>
              <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                  <label
                    for="employee_jobTitle"
                    class="block text-sm font-medium text-gray-700"
                    >Job Title</label
                  >
                  <input
                    :value="formData.employee_jobTitle"
                    type="text"
                    name="employee_jobTitle"
                    id="employee_jobTitle"
                    autocomplete="name"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    @change="handleInputChange"
                  />
            
              </div>
              <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
                  <label
                    for="employee_salary"
                    class="block text-sm font-medium text-gray-700"
                    >Salary</label
                  >
                  <input
                    :value="formData.employee_salary"
                    type="text"
                    name="employee_salary"
                    id="employee_salary"
                    autocomplete="salary"
                    class="
                      mt-1
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      shadow-sm
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    @change="handleInputChange"
                  />

              </div>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Is this role in budget? *
              </legend>
              <span v-if="v$.formData.inBudget.$errors.length" class="text-red-500">
                * {{ v$.formData.inBudget.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="Yes"
                        id="radio-budget-yes"
                        name="inBudget"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                        :disabled="showApprovalSteps"
                        :checked="formData.inBudget === 'Yes'"
                        @change="handleRadioChange"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="inBudget" class="font-medium text-gray-700"
                        >Yes</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="No"
                        id="radio-budget-no"
                        name="inBudget"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                        :disabled="showApprovalSteps"
                        :checked="formData.inBudget === 'No'"
                        @change="handleRadioChange"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="inBudget" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4" v-show="formData.inBudget === 'No'">
            <div class="col-span-6">
              <label
                for="recovered"
                class="block text-sm font-medium text-gray-700"
                >Please outline the business case for recruiting for this vacancy/the impact of not 
                recruting and how the costs will be recovered*</label
              >
              <textarea
                :value="formData.recovered"
                name="recovered"
                id="input-role-recovered"
                autocomplete="recovered"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                @input="handleInputChange"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        class="
          mr-2
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click.prevent="handleResetForm"
      >
        Reset Form
      </button>
      <button
        :disabled="v$.$invalid"
        type="submit"
        class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-500
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        "
        @click="handleSave"
      >
        Next
      </button>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators"
export default {
  name: "FormTwo",
  props: ['formData', 'showApprovalSteps'],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData
    }
  },
  methods: {
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTwo', formData: this.model })
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTwo', formData: this.model});
    },
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit('submit-form', { name: "formTwo", formData: this.formData });
      } else {
        this.store.dispatch("setMessage", {
          type: 'error',
          title: 'ERROR!',
          message: 'Please fill out all the required fields'
        })
      }
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formTwo');
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  validations() {
    return {
      formData: {
        recruitmentReason: {
          required
        },
        inBudget: {
          required
        },
        recovered: {
          requiredIf: helpers.withMessage(
            'This field is required if the role is not in budget',
            requiredIf(this.formData.inBudget === 'No')
          )
        }
      }
    }
    
  },
};
</script>


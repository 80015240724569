<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Assign Resourcing Owner
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
          <div class="overflow-hidden sm:rounded-lg">
            <div v-if="!formData?.recruitment_owner">
              <p>Select the admin you would like to assign this offer to: *</p>
              <UserSearch group="RESOURCING" @updated-user="handleUser" />
            </div>
            <div v-else class="border border-gray-300 rounded-lg p-6">
              <div class="flex justify-end">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click="handleClearUser" />
              </div>
              <div class="mt-6">
                <label 
                  for="hiringManagerName"
                  class="block text-sm font-medium text-gray-700"
                >Admin name: *</label>
                <input
                  :value="username"
                  type="text"
                  name="hiringManagerName"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                  disabled
                >
              </div>
              <div class="col-span-6 mt-4">
                <label
                  for="hiringManagerEmail"
                  class="block text-sm font-medium text-gray-700"
                >Admin email address: *</label>
                <input
                  :value="userEmail"
                  type="email"
                  name="hiringManagerEmail"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!disableForm && data.currentStageName === 'starter.notifyRecruitment'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="confirmAssignAdmin"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import UserSearch from '@/components/userDropdownSearch.vue'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { XIcon } from '@heroicons/vue/solid';

export default {
  props: ['formData', 'disableForm', 'data'],
  emits: ['update:formData', 'submit-form'],
  components: { UserSearch, XIcon },
  data() {
    return {
      model: this.formData,
      username: '',
      userEmail: '',
      v$: useVuelidate()
    }
  },
  methods: {
    handleUser(event) {
      this.username = event.Title;
      this.userEmail = event.email;
      this.model.recruitment_owner = event.userID;
      this.model.recruitment_owner_email = event.email
      this.$emit('update:formData', { formName: 'resourcingOwner', data: this.model });
    },

    handleClearUser() {
      this.username = '';
      this.userEmail = '';
      this.model.recruitment_owner = '';
      this.model.recruitment_owner_email = '';
      this.$emit('update:formData', { formName: 'resourcingOwner', data: this.model });
    },

    confirmAssignAdmin() {
      this.$emit('submit-form', { name: 'resourcingOwner', data: this.model });
    }
  },
  validations() {
    return {
      formData: {
        recruitment_owner: { required },
        recruitment_owner_email: { required }
      }
    }
  }
}
</script>

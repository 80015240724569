<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Hours of Work
        </h3>
        <div>

          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-6">
              <label
                  for="hoursPerWeek"
                  class="block text-sm font-medium text-gray-700"
              >How many hours will be worked in a typical week? *</label>
              <input
                  :value="formData.hoursPerWeek"
                  type="number"
                  min="0"
                  name="hoursPerWeek"
                  id="hours-per-week-input"
                  autocomplete="name"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                  :disabled="showApprovalSteps"
                  @input="handleInputChange"
                  @blur="v$.formData.hoursPerWeek.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.hoursPerWeek.$errors.length" class="text-red-500">
              * {{ v$.formData.hoursPerWeek.$errors[0].$message }}
            </span>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label
                  for="weeksPerYear"
                  class="block text-sm font-medium text-gray-700"
              >How many weeks will be worked in a typical year? *</label>
              <input
                  :value="formData.weeksPerYear"
                  type="number"
                  min="0"
                  name="weeksPerYear"
                  id="weeks-per-year-input"
                  autocomplete="email"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                  :disabled="showApprovalSteps"
                  @input="handleInputChange"
                  @blur="v$.formData.weeksPerYear.$touch()"
              >
              <!-- error message -->
              <span v-if="v$.formData.weeksPerYear.$errors.length" class="text-red-500">
              * {{ v$.formData.weeksPerYear.$errors[0].$message }}
            </span>
            </div>
          </div>
          <p v-if="parseInt(formData.weeksPerYear) < 52" class="text-sm font-medium text-red-700 mt-2">In all cases it
            is expected that the employee will receive an annual leave entitlement to be taken as part of their working
            time as there should be no roles in EQUANS where annual leave is not permitted. If this is not the case,
            please tick the box below and provide the justification why the employee should receive a payment in lieu of
            holidays instead of being allowed to take annual leave.</p>
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
               v-if="parseInt(formData.weeksPerYear) < 52">
            <div class="col-span-6">
              <label for="paidHoliday" class="block text-sm font-medium text-gray-70"
              >This employee is to be paid holidays instead of taking them:</label
              >
              <select
                  id="paidHoliday"
                  name="paidHoliday"
                  autocomplete="paidHoliday"
                  :value="formData.paidHoliday"
                  class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                  @change="handleInputChange"
              >
                <option
                    value="yes"
                >Yes
                </option>
                <option
                    value="no"
                >No
                </option>
              </select>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
               v-if="parseInt(formData.weeksPerYear) < 52">
            <div class="col-span-6">
              <label
                  for="additional-information"
                  class="block text-sm font-medium text-gray-700"
              >Justification:</label>
              <textarea
                  :value="formData.justification"
                  type="text"
                  name="justification"
                  id="justification-input"
                  autocomplete="email"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  @input="handleInputChange"
              ></textarea>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <fieldset>
                <legend class="text-base font-medium text-gray-900">
                  What is the anticipated shift pattern for this role? {{ isZeroHourContract ? '' : '*' }}
                </legend>
                <span v-if="v$.formData.shiftPattern.$errors.length" class="text-red-500">
                * {{ v$.formData.shiftPattern.$errors[0].$message }}
              </span>
                <div class="mt-4 space-y-4">
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                          id="checkbox-shift-pattern-call-out-rotor"
                          name="shiftPattern"
                          type="checkbox"
                          value="Call Out Rotor (Dealing with Emergencies)"
                          class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                          :disabled="showApprovalSteps"
                          :checked="formData.shiftPattern.includes('Call Out Rotor (Dealing with Emergencies)')"
                          @change="handleCheckboxChange"
                          @blur="v$.formData.shiftPattern.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftPattern" class="font-medium text-gray-700"
                      >Call Out Rota (Dealing with Emergencies)</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                          id="checkbox-shift-pattern-complex"
                          name="shiftPattern"
                          type="checkbox"
                          value="Complex Work Pattern"
                          class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                          :disabled="showApprovalSteps"
                          :checked="formData.shiftPattern.includes('Complex Work Pattern')"
                          @change="handleCheckboxChange"
                          @blur="v$.formData.shiftPattern.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftPattern" class="font-medium text-gray-700"
                      >Complex Work Pattern</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                          id="checkbox-shift-pattern-evenings-and-weekends"
                          name="shiftPattern"
                          type="checkbox"
                          value="Evenings and Weekends"
                          class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                          :disabled="showApprovalSteps"
                          :checked="formData.shiftPattern.includes('Evenings and Weekends')"
                          @change="handleCheckboxChange"
                          @blur="v$.formData.shiftPattern.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftPattern" class="font-medium text-gray-700"
                      >Evenings and Weekends</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                          id="checkbox-shift-pattern-part-time"
                          name="shiftPattern"
                          type="checkbox"
                          value="Part Time"
                          class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                          :disabled="showApprovalSteps"
                          :checked="formData.shiftPattern.includes('Part Time')"
                          @change="handleCheckboxChange"
                          @blur="v$.formData.shiftPattern.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftPattern" class="font-medium text-gray-700"
                      >Part Time</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                          id="checkbox-shift-pattern-standard"
                          name="shiftPattern"
                          type="checkbox"
                          value="Standard Hours (e.g. 9am - 5pm)"
                          class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                      "
                          :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                          :disabled="showApprovalSteps"
                          :checked="formData.shiftPattern.includes('Standard Hours (e.g. 9am - 5pm)')"
                          @change="handleCheckboxChange"
                          @blur="v$.formData.shiftPattern.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftPattern" class="font-medium text-gray-700"
                      >Standard Hours (e.g. 9am - 5pm)</label
                      >
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label
                  for="additional-information"
                  class="block text-sm font-medium text-gray-700"
              >Please detail below any additional information relating to the shift pattern:</label>
              <textarea
                  :value="formData.additionalInformation"
                  type="text"
                  name="additionalInformation"
                  id="additional-information-input"
                  autocomplete="email"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                  :disabled="showApprovalSteps"
                  @input="handleInputChange"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!showApprovalSteps" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
            class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
            @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
            :disabled="v$.$invalid"
            type="submit"
            class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
            @click="handleSave"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import {useStore} from "vuex"
import useVuelidate from "@vuelidate/core"
import {helpers, required, numeric} from "@vuelidate/validators"

// Custom validator for number field
const mustBeGreaterThanZero = (value) => value > 0;

export default {
  name: 'FormFive',
  props: ['data','formData', 'showApprovalSteps'],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData
    }
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit('submit-form', {name: "formFive", formData: this.formData});
      } else {
        this.store.dispatch("setMessage", {
          type: 'error',
          title: 'ERROR!',
          message: 'Please fill out all the required fields'
        })
      }
    },
    handleInputChange(event) {
      const {name, value} = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', {formName: 'formFive', formData: this.model});
    },
    handleCheckboxChange(event) {
      const {name, value, checked} = event.target;
      if (checked) {
        this.model[name].push(value)
      } else {
        const index = this.model[name].indexOf(value);
        this.model[name].splice(index, 1);
      }
      this.$emit('update:formData', {formName: 'formFive', formData: this.model});
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formFive');
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  computed: {
    isFixedTermContract(){
      return this.data?.formThree?.employmentContract === "Fixed Term"
    },
    isZeroHourContract(){
      return this.data?.formThree?.employmentContract === "Zero Hours"
    }

  },
  validations() {
    let hoursPerWeek = {
      required,
      numeric,
    }

    if(!this.isFixedTermContract && !this.isZeroHourContract){
      hoursPerWeek = {
        ...hoursPerWeek,
        mustBeGreaterThanZero:
              helpers.withMessage("Value must be greater than 0", mustBeGreaterThanZero)
      }
    }

    let shiftPattern = {
      required
    }

    if(this.isZeroHourContract){
      shiftPattern = {}
    }

    return {
      formData: {
        hoursPerWeek,
        weeksPerYear: {
          required,
          numeric,
          mustBeGreaterThanZero:
              helpers.withMessage("Value must be greater than 0", mustBeGreaterThanZero)
        },
        shiftPattern
      }
    }
  },
}
</script>

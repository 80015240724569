<template>
  <div class="p-12">
    <!-- Page title-->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-700">
        Approve Amendment
      </h3>
    </div>
    <!-- End of page title-->

    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="p-8">
          <div v-if="['amend.notifyBudgetApprover', 'amend.notifyHROps'].includes(amendsFormData.currentStageName)" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comments" class="block text-sm font-medium text-gray-700">Approval Comments</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea id="about" name="about" v-model="approvalComment" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>
          <p v-if="amendsFormData.currentStageName === 'amend.notifyHROps'" class="my-2 text-sm font-medium text-gray-700">You will not be able to change this request once it has been approved. Are you sure you would like to approve this amendment request?</p>
          <div v-if="amendsFormData.currentStageName === 'amend.notifyBudgetApprover'">
            <p class="my-2 text-sm font-medium text-gray-700">Please select a HROps representitive for second line approval</p>
            <user-search v-if="!user.userId" group="HROPS" @updated-user="handleUser($event)"></user-search>
            <div class="flex items-center" v-else>
              <input
                :value="user.email"
                disabled
                type="text"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
              >
              <button class="h-5 w-5 ml-2" @click="clearUser" >
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800" />
              </button>
            </div>
              
            </div>
              <div class="mt-4 flex justify-end">
                <button
                  v-if="amendsFormData.currentStageName === 'amend.notifyBudgetApprover'"
                  :disabled="!user.userId"
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    rounded-md
                    border
                    border-transparent
                    bg-green-700
                    hover:bg-green-800
                    hover:underline
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    shadow-sm
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2
                    sm:w-auto
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:text-gray-400
                  "
                  @click="handleCompleteUser
                ">
                  Complete
                </button>
                <button
                  v-if="amendsFormData.currentStageName === 'amend.notifyHROps'"
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    rounded-md
                    border
                    border-transparent
                    bg-green-700
                    hover:bg-green-800
                    hover:underline
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    shadow-sm
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2
                    sm:w-auto
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:text-gray-400
                  "
                  @click="handleCompleteUser
                ">
                  Complete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import httpClient from '@/httpClient';
import { XIcon } from '@heroicons/vue/solid';
import UserSearch from '@/components/userDropdownSearch.vue'

export default {
  components: {
    UserSearch,
    XIcon
  },
  data() {
    return {
      amendsFormData: {},
      user: {
        userId: '',
        name: '',
        email: ''
      },
      approvalComment: ''
    }
  },
  methods: {
    async fetchAmend() {
      this.$store.dispatch('setIsLoading', true);
      const docId = this.$route.params.id;
      const docOwner = this.$route.params.docOwner;
      const response = await httpClient.post('/bff/get-document', { id: docId, requestedBy: docOwner })
      this.amendsFormData = response.data.body.Item;
      this.$store.dispatch('setIsLoading', false);
    },
    handleUser(event) {
      this.user.userId = event.userID;
      this.user.name = event.Title;
      this.user.email = event.email.trim();
    },
    clearUser() {
      this.user.userId = '';
      this.user.name = '';
      this.user.email = '';
    },
    async handleCompleteUser() {
      try {
        const docId = this.$route.params.id;
        const docOwner = this.$route.params.docOwner;
        this.$store.dispatch('setIsLoading', true);
        const payload = {

          approved: 'TRUE',
          stage: this.amendsFormData.currentStageName,
          id: docId,
          requestedBy: this.profile.email,
          docOwner,
          userId: this.user.userId,
          secondLineApproval: this.user.email,
          approvalComment: this.approvalComment,
        }
        await httpClient.post('bff/action-request', { ...payload })
        this.$store.dispatch('setIsLoading', false);
        this.$router.push('/');
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Successfully complete amendment request'
        });
      } catch (err) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was an error completing the amendment request.'
        });
      }
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile;
    }
  },
  async mounted() {
    await this.fetchAmend();
  }
}
</script>
<template>
    <form>
        <div class="shadow sm:rounded-t-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                <!-- title header -->
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Contract Details
                    </h3>
                </div>
                <!-- end title header -->
                <!-- form -->
                <div>
                    <div class="grid grid-cols-3 gap-6">
                        <div class="col-span-6">
                            <label for="contractType" class="block text-sm font-medium text-gray-70"
                            >Contract Type: *</label
                            >
                            <select
                                :value="formData?.contractType"
                                id="select-contract-type"
                                name="contractType"
                                class="
                                    mt-1
                                    focus:ring-indigo-500 focus:border-indigo-500
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    border-gray-300
                                    rounded-md
                                    disabled:bg-gray-200
                                    disabled:text-gray-600
                                    disabled:cursor-not-allowed
                                "
                                :disabled="disableForm"
                                @change="handleInput"
                                @blur="v$.formData.contractType.$touch()"
                            >
                              <template v-if="disableForm">
                                <option v-for="contract in configItems.CONTRACTS" :key="contract.ContractType">
                                    {{ contract.ContractType }}
                                </option>
                              </template>
                              <template v-else>
                                <option v-for="contract in activeContractTypes" :key="contract.ContractType">
                                    {{ contract.ContractType }}
                                </option>
                              </template>
                            </select>
                            <span v-if="v$.formData.contractType.$error" class="text-red-500">
                              * {{ v$.formData.contractType.$errors[0].$message }}
                            </span>
                        </div>
                        <div class="col-span-6">
                            <label for="contractLevel" class="block text-sm font-medium text-gray-70"
                            >Contract Level: *</label
                            >
                            <select
                                :value="formData?.contractLevel"
                                id="select-contract-level"
                                name="contractLevel"
                                class="
                                    mt-1
                                    focus:ring-indigo-500 focus:border-indigo-500
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    border-gray-300
                                    rounded-md
                                    disabled:bg-gray-200
                                    disabled:text-gray-600
                                    disabled:cursor-not-allowed
                                "
                                :disabled="disableForm"
                                @change="handleInput"
                                @blur="v$.formData.contractLevel.$touch()"
                            >
                              <template v-if="disableForm">
                                <option v-for="contract in currentContractType?.Items" :key="contract.ID">
                                    {{ contract.ContractName }}
                                </option>
                              </template>
                              <template v-else>
                                <option v-for="contract in activeContracts" :key="contract.ID">
                                    {{ contract.ContractName }}
                                </option>
                              </template>
                            </select>

                            <span v-if="v$.formData.contractLevel.$error" class="text-red-500">
                              * {{ v$.formData.contractLevel.$errors[0].$message }}
                            </span>
                          </div>
                        <div class="col-span-6" v-for="cd in detailsFields" :key="cd.index">
                            <label :for="cd.index" class="block text-sm font-medium text-gray-70"
                            >{{ cd.label }}</label
                            >
                            <input
                                :value="formData[cd.index]"
                                :id="`select-${cd.index}`"
                                :name="cd.index"
                                type="text"
                                class="
                                    mt-1
                                    focus:ring-indigo-500 focus:border-indigo-500
                                    block
                                    w-full
                                    shadow-sm
                                    sm:text-sm
                                    border-gray-300
                                    rounded-md
                                    disabled:bg-gray-200
                                  disabled:text-gray-600
                                  disabled:cursor-not-allowed
                                "
                                :disabled="disableForm"
                                @change="handleInput"
                            />
                        </div>
                        
                    </div>
                </div>
                <div v-if="hasChanged" class="p-3 w-full flex flex-row items-center">
                  <ExclamationIcon class="text-red-600 h-16 w-16" />
                  <p class="text-red-600 ml-2">
                    Once submitted, this document will go for re-approval because the terms have changed.
                  </p>
                </div>
            </div>
        </div>
        <div v-if="editable || (!disableForm && document.currentStageName === 'offer.notifyPrepOffer')" class="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end items-center">
          <button
              :disabled="v$.$invalid"
              type="submit"
              class="
                  ml-4
                  bg-green-500
                  border border-transparent
                  rounded-md
                  shadow-sm
                  py-2
                  px-4
                  inline-flex
                  justify-center
                  text-sm
                  font-medium
                  text-white
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:text-gray-400
              "
              @click.prevent="submitForm"
          >
              Save
          </button>
        </div>
    </form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ExclamationIcon } from '@heroicons/vue/solid'


export default {
  props: ['formData', 'configItems', 'disableForm', 'document', 'editable'],
  emits: ['update:formData', 'submit-form'],
  components: { ExclamationIcon },
  data() {
    return {
      model: this.formData,
      formCopy: '',
      currentContractString: '',
      detailsFields: [
        {
            index: 'PensionScheme',
            label: 'Pension Scheme'
        },
        {
            index: 'LifeCover',
            label: 'Life Cover'
        },
        {
            index: 'PMI',
            label: 'Private Medical Insurance'
        },
        {
            index: 'Car',
            label: 'Car'
        },
        {
            index: 'Bonus',
            label: 'Bonus'
        },
        {
            index: 'OvertimeEligibility',
            label: 'Overtime Eligibility'
        },
        {
            index: 'Holidays',
            label: 'Holidays'
        },
        {
            index: 'Sickness',
            label: 'Sickness'
        },
        {
            index: 'ProbationPeriod',
            label: 'Probation Period'
        },
        {
            index: 'NoticePeriod',
            label: 'Notice Period'
        }
      ],
      v$: useVuelidate(),
      hasChanged: false,
    }
  },
  computed: {
    currentContractType() {
      return this.configItems.CONTRACTS.find((item) => item.ContractType === this.formData?.contractType)
    },
    activeContractTypes() {
      return this.configItems.CONTRACTS.filter((ct) => ct.LKStatus);
    },
    activeContracts() {
      return this.currentContractType?.Items?.filter((contract) => contract.LKStatus);
    }
  },
  methods: {
    getContract(contractLevel){
      return this.currentContractType?.Items?.find((item) => item.ContractName === contractLevel);
    },
    checkHasChanged() {
      if (this.formData.contractLevel !== this.document.atr.formSix.contractLevel) {
        return true
      }
      const contract = this.getContract(this.formData.contractLevel);
      if (contract) {
        let changed = Object.keys(this.formData).some((key) => {
        if (![
            'contractType',
            'contractLevel',
            'saved',
            'hasChanged'
          ].includes(key)) {
            return this.formData[key] !== contract[key]
          }
          return false
        })
        if(changed) return true;
      }
      return false;
    },
    handleInput(event) {
      const { name, value } = event.target;
      this.model[name] = value;
      this.hasChanged = this.checkHasChanged();
      this.model.hasChanged = this.hasChanged || undefined;
      this.$emit('update:formData', { formName: 'contractAmend', formData: this.model });
    },

    async submitForm() {
      const formData = { ...this.model, hasChanged: this.hasChanged };
      this.$emit('submit-form', { name: 'contractAmend', formData });
    },

    async setContractDetails(contractLevel) {
      const contract = this.getContract(contractLevel);
      if(contract){
        [
          'PensionScheme',
          'LifeCover',
          'PMI',
          'Car',
          'Bonus',
          'OvertimeEligibility',
          'Holidays',
          'Sickness',
          'ProbationPeriod',
          'NoticePeriod'
        ].forEach(field=>{
          if(!contract[field]){
            return
          }
          this.model[field] = contract[field]
        })
      }

      await this.$emit('update:formData', { formName: 'contractAmend', formData: this.model });
      this.currentContractString = JSON.stringify(this.formData);
      this.$emit('update:formData', { formName: 'contractAmend', formData: this.model });
    }
  },
  async mounted() {
    const atr = this.document;
    const contractType = this.formData.contractType || atr.atr.formSix.contractType;
    const contractLevel = this.formData.contractLevel || atr.atr.formSix.contractLevel;
    this.model.contractType = contractType;
    this.model.contractLevel = contractLevel;
    if(!this.checkHasChanged()){
      await this.setContractDetails(contractLevel);
    }
    this.formCopy = JSON.stringify(this.formData);
    this.currentContractString = JSON.stringify(this.formData);
  },
  watch: {
    'model.contractLevel' () {
      if (this.model.contractLevel) {
        this.setContractDetails(this.model.contractLevel);
      }
    }
  },
  validations() {
    return {
      formData: {
        contractType: { required },
        contractLevel: { required }
      }
    }
  }
}
</script>
<template>
  <div class="m-4 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">All My Requests</h1>
        <p class="mt-2 text-sm text-gray-700">A list of all my requests.</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click="navigateNewDoc('starter')">Create ATR</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('amend')">Create Amendment</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('leaver')">Create Leaver</button>
      </div>
    </div>
    <div class="mt-8 flex flex-col md:flex-row justify-end">
      <div class="mt-2">
        <label for="filter-doctype" class="text-gray-600">Doctype:</label>
        <select
          v-model="doctype"
          name="filter-doctype"
          id="filter-doctype-select"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option value="STARTER">Starters</option>
          <option value="LEAVER">Leavers</option>
          <option value="AMEND">Amends</option>
          <option value="OFFER">Offers</option>
        </select>
      </div>
      <div class="mt-2 md:ml-2">
        <label for="filter-doctype" class="text-gray-600">Stage:</label>
        <select
          v-model="stage"
          name="filter-stage"
          id="filter-stage-select"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option value="all">All</option>
          <!-- Starter Options -->
          <option
            v-for="stage in stagesOptions"
            :key="stage.stageName"
            :value="stage.stageName"
          >{{ stage.displayName }}</option>
        </select>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Short ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigned to</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Current stage</th>
                </tr>
              </thead>
              <tbody v-if="atrs.length" class="bg-white">
                <tr v-for="(atr, index) in atrs" :key="atr.docId" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-blue-600 hover:underline cursor-pointer" @click="navigateToDocument(atr.docId, atr.requestedBy)">{{ atr.shortId }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="doctype === 'OFFER'">
                      {{ atr.atr?.formThree?.jobTitle ? atr.atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                    <span v-else>
                      {{ atr.formThree?.jobTitle ? atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ currentAssigneeEmail(atr) }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ findStage(atr.currentStageName, atr.status) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="loaded && !atrs.length" class="text-gray-400 text-center p-8"> No Requests to show... </p>
          </div>
        </div>
        <div class="flex justify-center md:justify-end mx-1 md:mx-8 my-4 px-4 md:px-8 py-4 shadow rounded ring-1 ring-black ring-opacity-5">
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="prevPage">Prev</button>
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="nextPage">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, watch, computed, inject } from 'vue';
import { clearStorage } from '@/storageSave';

const store = useStore();
const router = useRouter();
const atrs = ref([]);
const loaded = ref(false);
const doctype = ref('STARTER');
const stage = ref('all');
const pageNumber = ref(0);
const atEnd = ref(false);
const tokens = ref(['']);

const routes = {
  STARTER: 'view-starter',
  LEAVER: 'view-leaver',
  AMEND: 'view-amend',
  OFFER: 'view-offer'
}

const stages = inject('stages');

const navigateNewDoc = (docType) => {
  // clear storage
  if (docType.toUpperCase() === 'STARTER') {
    clearStorage('atrShortId');
    clearStorage('atrId');
    clearStorage('formData');
  }
  if (docType.toUpperCase() === 'AMEND') {
    clearStorage('amendsFormData');
  }
  if (docType.toUpperCase() === 'LEAVER') {
    store.dispatch('clearAllLeaverFormData');
    clearStorage('leaverShortId');
    clearStorage('leaverForm');
  }

  const routes = {
    starter: '/create-starter/new-atr',
    amend: '/create-amend',
    leaver: '/create-leaver/new-leaver'
  };
  router.push(routes[docType]);
};

const stagesOptions = computed(() => stages.filter((stage) => stage.type === doctype.value.toLowerCase()));

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
}
  

const navigateToDocument = async (id, docOwner) => {
  // find document
  const document = atrs.value.find((atr) => atr.docId === id);
  if (document && document.currentStageName === 'starter.notifyRecruitment') {
    router.push({ path: `/recruitment-team/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRecruitmentUpdated') {
    router.push({ path: `/recruitment-update/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRoleAdvertised') {
    router.push({ path: `/advert-raised/${id}/${docOwner}` });
  }  else if (document && document.currentStageName === 'starter.notifyCVsCollected') {
    router.push({ path: `/ready-for-selection/${id}/${docOwner}` });
  } else if (document && (document.currentStageName === 'starter.notifyCandidateSelected' || document.currentStageName === 'starter.complete')) {
    router.push({ path: `/selection-complete/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyMakeOffer') {
    router.push({ path: `/offer-hr/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyPrepOffer') {
    router.push({ path: `/confirm/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyTermsHaveChanged') {
    router.push({ path: `/amend-terms/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyH2RAdmin') {
    router.push({ path: `/assign-admin-owner/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyOfferSent') {
    router.push({ path: `/sap-confirmation/${id}/${docOwner}` });
  } else {
    router.push({ path: `/${routes[doctype.value]}/${id}/${docOwner}` });
  }
}
const currentAssigneeEmail = (atr) => atr.currentAssigneeEmail ? 
  atr.currentAssigneeEmail.includes('@') ? 
    atr.currentAssigneeEmail : atr.currentAssignee.includes('@') ? 
      atr.currentAssignee : atr.currentAssigneeEmail 
  : 'Unassigned'
const fetchAtrs = async (type="next") => {
  let url = '/bff/list-mydocs-by-doctype'
  const token = type === 'next' ? tokens.value[pageNumber.value] : tokens.value[pageNumber.value - 2]
  const response = await httpClient.post(url, {
    requestedBy: '12345678910',
    docType: doctype.value,
    stage: stage.value,
    next: token ? token : undefined,
  });
  if (type === 'next') {
    pageNumber.value++;
  }
  if (type === 'prev') {
    pageNumber.value--;
  }
  if (!response.data.body.next) {
    atEnd.value = true;
  } else {
    atEnd.value = false;
    tokens.value.push(response.data.body.next);
  }
  atrs.value = response.data.body.Items;
};

const nextPage = async() => {
  if (atEnd.value) return;
  store.dispatch('setIsLoading', true);
  await fetchAtrs();
  store.dispatch('setIsLoading', false);
}

const prevPage = async() => {
  if (pageNumber.value === 1) return;
  store.dispatch('setIsLoading', true);
  await fetchAtrs('prev');
  store.dispatch('setIsLoading', false);
}

onMounted(async() => {
  store.dispatch('setIsLoading', true);
  await fetchAtrs();
  store.dispatch('setIsLoading', false);
  loaded.value = true;
});

watch(doctype, async () => {
  store.dispatch('setIsLoading', true);
  pageNumber.value = 0;
  atEnd.value = false;
  tokens.value = [''];
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});

watch(stage, async () => {
  store.dispatch('setIsLoading', true);
  pageNumber.value = 0;
  atEnd.value = false;
  tokens.value = [''];
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});
</script>

<template>
  <div>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <p class="mt-2 text-sm text-gray-700">Create, read, update and delete config items.</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <select
          v-model="selectedConfigItemType"
          class="
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option v-for="item in configItemTypes" :key="item.text" :value="item.text">{{ item.text }}</option>
        </select>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <component
        :is="currentSelectedType.component"
        :contracts="configItems.CONTRACTS"
        :divisions="configItems.DIVISIONS"
        :entities="configItems.ENTITIES"
        :locations="configItems.LOCATIONS"
        @openModal="handleToggleModal"
      ></component>
    </div>
    <ModalWindow v-if="showModal" size="5xl" @close="handleToggleModal">
      <template v-slot:icon>
        <component
          class="h-10 w-12 text-green-600"
          aria-hidden="true"
          :is="selectedItem ? currentSelectedType.modalContent.editIcon || currentSelectedType.modalContent.icon : currentSelectedType.modalContent.icon"
        ></component>
      </template>
      <template v-slot:title>{{ selectedItem ? currentSelectedType.modalContent.editTitle : currentSelectedType.modalContent.title }}</template>
      <template v-slot:subtitle
        >{{ currentSelectedType.modalContent.subtitle }}</template
      >
      <template v-slot:main>
        <component :is="currentSelectedType.modalContent.component" :selectedItem="selectedItem" @refresh-config="fetchConfigItems" @close-modal="handleToggleModal"></component>
      </template>
    </ModalWindow>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, markRaw } from 'vue';
import { useStore } from 'vuex';
import { DocumentAddIcon, DocumentTextIcon, FlagIcon, OfficeBuildingIcon, ViewGridAddIcon, ViewGridIcon } from '@heroicons/vue/solid';
import httpClient from '@/httpClient';
import ContractManagement from '@/components/admin/admin-components/config/ContractManagement.vue';
import ContractTypeForm from '@/components/admin/admin-components/config/forms/ContractTypeForm.vue';
import DivisionManagement from '@/components/admin/admin-components/config/DivisionManagement.vue';
import DivisionForm from '@/components/admin/admin-components/config/forms/DivisionForm.vue'
import EntityManagement from '@/components/admin/admin-components/config/EntityManagement.vue';
import EntityForm from '@/components/admin/admin-components/config/forms/EntityForm.vue'
import LocationManagement from '@/components/admin/admin-components/config/LocationManagement.vue';
import LocationForm from '@/components/admin/admin-components/config/forms/LocationForm.vue';
import ModalWindow from '@/components/ModalWindow.vue';

const store = useStore();
const configItems = ref({});
const selectedItem = ref();
const showModal = ref(false);
const configItemTypes = markRaw([
  {
    text: 'Contracts',
    component: ContractManagement,
    modalContent: {
      icon: DocumentAddIcon,
      editIcon: DocumentTextIcon,
      title: 'New Contract',
      editTitle: 'Edit Contract',
      subtitle: 'Please fill out all the contract details',
      component: ContractTypeForm
    }
  },
  {
    text: 'Divisions',
    component: DivisionManagement,
    modalContent: {
      icon: ViewGridAddIcon,
      editIcon: ViewGridIcon,
      title: 'New Division',
      editTitle: 'Edit Division',
      subtitle: 'Please fill out all the division details',
      component: DivisionForm
    }
  },
  {
    text: 'Entities',
    component: EntityManagement,
    modalContent: {
      icon: FlagIcon,
      editIcon: FlagIcon,
      title: 'New Entity',
      editTitle: 'Edit Entity',
      subtitle: 'Please fill out all the entity details',
      component: EntityForm
    }
  },
  {
    text: 'Locations',
    component: LocationManagement,
    modalContent: {
      icon: OfficeBuildingIcon,
      editIcon: OfficeBuildingIcon,
      title: 'New Location',
      editTitle: 'Edit Location',
      subtitle: 'Please fill out all the location details',
      component: LocationForm
    }
  }
]);
const selectedConfigItemType = ref('Contracts');
const currentSelectedType = computed(() => configItemTypes.find((item) => item.text === selectedConfigItemType.value));

const handleToggleModal = (config) => {
  if (config) {
    selectedItem.value = config.configData;
  } else {
    selectedItem.value = undefined;
  }
  showModal.value = !showModal.value;
};

const fetchConfigItems = async () => {
  store.dispatch('setIsLoading', true);
  try {
    const response = await httpClient.post('/config/all', { requestedBy: 'something' });
    configItems.value = response.data.body;
  } catch (err) {
    console.log(err);
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'error',
      title: 'Error!',
      message: 'Could not fetch config items'
    })
  }
  store.dispatch('setIsLoading', false);
};

onMounted(async () => {
  store.dispatch('setIsLoading', true);
  await fetchConfigItems();
  store.dispatch('setIsLoading', false);
});
</script>
<template>
  <div>
    <form class="space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Active</label>
              <fieldset class="mt-4">
                <legend class="sr-only">Division Active Status</legend>
                <div class="mr-2 flex flex-row align-center">
                  <div v-for="item in [true, false]" :key="item" class="flex items-center mr-6">
                    <input v-model="formData.LKStatus" :value="item" :id="`LKStatus-${item.toString()}`" :name="`LKStatus-${item.toString()}`" type="radio" :checked="formData.LKStatus === item" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                    <label :for="`LKStatus-${item.toString()}`" class="ml-3 block text-sm font-medium text-gray-700">{{ item.toString() }}</label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div v-for="field in fields" :key="field.id" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label :for="field.name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{{ field.label }}</label>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <input v-model="formData[field.key]" :type="field.type" :name="field.name" :id="field.id" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:text-gray-800 disabled:bg-gray-200 disabled:cursor-not-allowed" :disabled="selectedItem" @blur="v$.formData[field.key].$touch()" />
                <div v-if="v$.formData[field.key].$error">
                  <p class="text-red-600">* {{ v$.formData[field.key].$errors[0].$message }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedItem">
            <div class="my-8">
              <h3 class="text-lg text-center">Sectors</h3>
            </div>
            <div class="flex justify-end my-4">
              <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="openModal">Add Sector</button>
            </div>
            <div v-if="selectedItem?.SECTORS?.length" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="sector in selectedItem?.SECTORS" :key="sector.ID">
                    <td
                      class="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-700 sm:pl-6 hover:underline cursor-pointer"
                      @click.prevent="handleClickSector(sector)">{{ sector.NAME }}
                      <span v-if="!sector.LKStatus" class="text-red-700 border rounded-md px-2 border-red-700 absolute right-8">Inactive</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p v-else class="text-lg text-center text-gray-600">No Sectors exist in this Division..</p>
          </div>
          <p v-else class="text-lg text-center text-gray-600">You can only add Sectors to this Division once it has been saved...</p>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:underline" @click="$emit('close-modal')">Cancel</button>
          <button :disabled="v$.$invalid" type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="handleCreateDivision">Save</button>
        </div>
      </div>
    </form>
    <ModalWindow v-if="showModal" size="5xl" @close="closeModal">
      <template v-slot:icon>
        <ViewGridIcon v-if="selectedSector" class="h-10 w-12 text-green-600" aria-hidden="true" />
        <ViewGridAddIcon v-else class="h-10 w-12 text-green-600" aria-hidden="true" />
      </template>
      <template v-slot:title>{{ selectedSector ? 'Edit Sector' : 'Add Sector' }}</template>
      <template v-slot:subtitle
        >Please fill out all the sector details</template
      >
      <template v-slot:main>
        <SectorForm
          :divisionId="selectedItem?.ID"
          :selectedSector="selectedSector"
          @cancel="closeModal"
        />
      </template>
    </ModalWindow>
  </div>

</template>
<script>
import httpClient from '@/httpClient';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ViewGridAddIcon, ViewGridIcon } from '@heroicons/vue/solid';
import SectorForm from '@/components/admin/admin-components/config/forms/SectorForm.vue';
import ModalWindow from '@/components/ModalWindow.vue';

export default {
  components: { SectorForm, ViewGridAddIcon, ViewGridIcon, ModalWindow },
  props: ['selectedItem'],
  data() {
    return {
      showModal: false,
      v$: useVuelidate(),
      selectedSector: undefined,
      formData: { 
        LKStatus: true,
        ID: '',
        NAME: ''
      },
      fields: [
        {
          key: 'ID',
          label: 'ID',
          type: 'text',
          name: 'ID-field',
          id: 'ID-field'
        },
        {
          key: 'NAME',
          label: 'Division Name',
          type: 'text',
          name: 'name-field',
          id: 'name-field'
        },
      ]
    };
  },
  methods: {
    openModal() { this.showModal = true; },
    closeModal(refresh) {
      if (refresh) {
        this.$emit('refresh-config');
      }
      this.showModal = false;
      this.selectedSector = undefined;
    },
    handleClickSector(sector) {
      this.selectedSector = sector;
      this.openModal();
    },
    async handleCreateDivision() {
      this.$store.dispatch('setIsLoading', true);
      const payload = { type: 'division', ...this.formData }
      try {
        const response = await httpClient.post('/config/create', payload);
        if (response.data.statusCode === 500) throw new Error();
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: this.selectedItem ? 'Changes saved' : 'Successfully created new Division'
        });
        this.$emit('close-modal');
      } catch(err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: this.selectedItem ? 'Error saving changes' : 'Could not create new Division at this time.'
        });
      }
      this.$store.dispatch('setIsLoading', false);
    }
  },
  created() {
    if (this.selectedItem) {
      this.formData = this.selectedItem;
    }
  },
  validations() {
    return {
      formData: {
        LKStatus: { required },
        ID: { required },
        NAME: { required },
      }
    };
  },
}
</script>
<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Attachments
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <p>This offer will be rejected by the approvers or HR Admin if you do not attach the appropriate verified documentation.</p>
        <p>This must be in the required format to ensure we maintain the standards required for Legal and Audit
            purposes. We also need to adhere to British Standards to enable EQUANS to bid on all new business.
        </p>
        <h3 class="text-lg leading-6 font-medium text-gray-900">New starters to the business:</h3>
        <p>Recruitment is a legal process so all relevant documentation must be completed and correct. If not a
            contract cannot be issued and the new starter will not be paid.
        </p>
        <p>I confirm that the following attachments have been included:</p>
        <ul>
            <li>Verified and signed copy of Right to Work in the UK or where applicable proof of an online check via
                the Home Office online service *</li>
            <li>Proof of their identity (usually a passport) to support Home Office online check *</li>
            <li>Candidate's CV *</li>
            <li>Job Description *</li>
        </ul>
        <p>You must have had sight of the original Right to Work document. You should take a copy and write on each
            copy: “I certify that this is a true copy of the original”, then sign, print your name, note your job title,
            and date the copy.
        </p>
        <p>For EEA Nationals you will need to check proof of their immigration status – either (i) proof of their EEA
            Settled status – this has to be done via the Home Office online service through the provision of a share
            code; or (ii) if they meet the new requirements of the immigration system.
        </p>
        <p>Please be sure to check the Right to Work Policy to ensure you have provided the correct
            documentation. The Offer will be rejected if the incorrect documentation is provided.
        </p>
        <h3 class="text-lg leading-6 font-medium text-gray-900">Internal appointments: </h3>
        <p>Right to work documentation will already be on file for the employee, therefore only the new job
            description is required.</p>
        <p>Once ready, please scan and attach the copies below.</p>

        <!-- Document upload -->
          <FileUploader
            name="attachments-form"
            :disabled="disableForm"
            :files="data.candidates.files"
            :documentShortId="data.shortId"
            @updateFiles="handleUpdateFiles"
            :uploading="uploading"
            @fileUploading="uploading = $event"
          />
        <!-- End Document upload -->

        <div v-html="radio"></div>
    
        <div v-html="textarea"></div>
        
            
        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="submitForm"
        >
          Reset Form
        </button>
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          :disabled="uploading"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { Radio, Textarea } from "@/form";
import { defineProps, defineEmits, toRef, ref } from "vue";
import FileUploader from "@/components/FileUploader.vue";

const props = defineProps(['disableForm', 'data']);
const emit = defineEmits(['update:formData', 'submit-form'])

const modelValue = toRef(props, 'data');

const uploading = ref(false);

const textarea = Textarea(
  "Please advise if any further documentation or qualifications should be requested by the HR Administration Onboarding Team at the Job Offer stage. In addition where the offer requires re-approval please use the box below to add any comments for your approver:",
  "documentation",
  props.disableForm,
  modelValue.value.candidates.documentation
);
const radio = Radio("Please verify that all attachments have been uploaded: * ", "attachments", undefined, undefined, props.disableForm, modelValue.value.attachments.attachmentsUploaded);

const handleUpdateFiles = ({ model }) => {
  modelValue.value.candidates.files = model
  emit('update:formData', modelValue.value.candidates)
}

const submitForm = async () => {
  // Get input elements
  const documentationInput = document.getElementById("documentation");
  const attachmentsYesInput = document.getElementById("radio_button_attachments_yes");
  const attachmentsNoInput = document.getElementById("radio_button_attachments_no");

  // Set formData with input values from fields
  modelValue.value.documentation = documentationInput.value;
  if (attachmentsYesInput.checked) modelValue.value.attachments.attachments = attachmentsYesInput.value;
  else if (attachmentsNoInput.checked) modelValue.value.attachments.attachments = attachmentsNoInput.value;

  await emit('update:formData', modelValue.value.candidates)
  emit('submit-form', { name: 'attachments', data: props.data.candidates });
};
</script>
<template>
    <!--        OutstandingRequests.vue component - Displays overview of oustanding Offers, Amends and Leavers      -->
    <section>
        <div class="border rounded-lg">
            <table class="min-w-full text-center text-sm rounded">
                <thead>
                    <tr>
                        <th scope="col" colspan="5" class="py pt-2 font-normal text-5xl">
                            {{ sum }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b">
                        <td colspan="5" class="whitespace-nowrap pb-2 font-light text-base">
                            Outstanding {{ title }}
                        </td>
                    </tr>
                    <tr class="divide-x font-light">
                        <td class="whitespace-nowrap px-2 py-2 bg-green-100 bg-opacity-50">
                            <strong>{{ data[1] }}</strong> <br /> SLA 1
                        </td>
                        <td class="whitespace-nowrap px-2 py-2 bg-lime-100 bg-opacity-50">
                            <strong>{{ data[2] }}</strong> <br />SLA 2
                        </td>
                        <td class="whitespace-nowrap px-2 py-2 bg-yellow-100 bg-opacity-50">
                            <strong>{{ data[3] }}</strong> <br />SLA 3
                        </td>
                        <td class="whitespace-nowrap px-2 py-2 bg-orange-100 bg-opacity-50">
                            <strong>{{ data[4] }}</strong> <br />SLA 4
                        </td>
                        <td class="whitespace-nowrap px-1 py-2 bg-red-100 bg-opacity-50">
                            <strong>{{ data[5] }}</strong> <br />SLA 5+
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>    
    </section>
</template>

<script>
export default {
    props: ['title' ,'data'],
    computed: {
        sum() {
            return this.data?.reduce((accumulator, currentValue) => {  return accumulator + currentValue},0) ?? 0;
        }
    },
}
</script>
<template>
<form-layout
  :navItemConfig="navItemConfig"
  :currentNavItem="currentNavItem"
  :formPoint="formPoint"
  formName="leaverForm"
  @set-current-nav-item="setCurrentNavItem"
  :navpoints=false
>
  <template #pageTitle>Advert Raised</template>
  <template #pageSubtitle>
    <b>*Please ensure that all mandatory fields are completed and the Job Description has been attached before submitting for approval</b><br>
    <b>*For compatible browsers please review this link. If your browser is not compatible it may effect the text you see within multi line text boxes and /or the speed of the people search.</b>
  </template>
  <template #main>
    <component :is="currentNavItem?.component" @submit-form="handleSubmitForm" />
  </template>
</form-layout>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import formLayout from '@/layouts/formLayout.vue'
import formOne from '@/components/approvalWorkflowForms/attachmentsForm.vue'
import formTwo from '@/components/approvalWorkflowForms/hiringSecondaryDetails.vue'
import formThree from '@/components/approvalWorkflowForms/recruitmentReason.vue'
import formFour from '@/components/approvalWorkflowForms/jobDetails.vue'
import formFive from '@/components/approvalWorkflowForms/organisationDetails.vue'
import formSix from '@/components/approvalWorkflowForms/workingHours.vue'
import formSeven from '@/components/approvalWorkflowForms/contractDetails.vue'
import formEight from '@/components/approvalWorkflowForms/advertisingRequirements.vue'
import formNine from '@/components/approvalWorkflowForms/preEmployment.vue'
import formTen from '@/components/approvalWorkflowForms/sercurityForm.vue'
import formEleven from '@/components/approvalWorkflowForms/specialistRoles.vue'
import formTwelve from '@/components/approvalWorkflowForms/firstSecondApproval.vue'
import formThirteen from '@/components/approvalWorkflowForms/firstLineApprove.vue'
const store = useStore();
const formPoint = ref(0);
const navItemConfig = [
  {
    formPoint: 0,
    name: "formTwo",
    text: "Hiring Manager & Secondary Contact Details",
    component: formTwo,
    icon: "UserIcon"
  },
  {
    formPoint: 1,
    name: "formThree",
    text: "Recruitment Reason",
    component: formThree,
    icon: "LogoutIcon"
  },
  {
    formPoint: 2,
    name: "formFour",
    text: "Job Details",
    component: formFour,
    icon: "EmojiHappyIcon"
  },
  {
    formPoint: 3,
    name: "formFive",
    text: "Organisation Details",
    component: formFive,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 4,
    name: "formSix",
    text: "Working Hours",
    component: formSix,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 5,
    name: "formSeven",
    text: "Contract Details",
    component: formSeven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 6,
    name: "formEight",
    text: "Advertising Requirements",
    component: formEight,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 7,
    name: "formNine",
    text: "Pre Employment Checks",
    component: formNine,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 8,
    name: "formTen",
    text: "Security",
    component: formTen,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 9,
    name: "formEleven",
    text: "Specialist Roles",
    component: formEleven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 10,
    name: "formTwelve",
    text: "First & Second Line Approval",
    component: formTwelve,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 11,
    name: "formOne",
    text: "Attachments",
    component: formOne,
    icon: "UserCircleIcon"
  },
    {
    formPoint: 12,
    name: "formThirteen",
    text: "First Line Offer Approval",
    component: formThirteen,
    icon: "UserCircleIcon"
  },
];

const currentNavItem = computed(() => navItemConfig.find((item) => item.formPoint === formPoint.value));
const setCurrentNavItem = (value) => formPoint.value = value;
const handleSubmitForm = async (event) => {
  const res = await store.dispatch('saveLeaverFormStep', event);
  if (res) formPoint.value++;
};

</script>
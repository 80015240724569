<template>
  <div class="p-12">
    <!-- Page title-->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Re-assign Approvers
      </h3>
    </div>
    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
      <div class="bg-white p-8 shadow overflow-hidden sm:rounded-lg">
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div class="my-4">
            Document: {{ formData?.docId }}<br/>
            Current Stage: {{ currentStage?.displayName }}
          </div>
          <div class="my-4 flex">
            <div class="w-full" v-if="!selectedUser">
              <UserSearch :group="currentType.group" @updated-user="handleUser" />
            </div>
            <div class="w-full" v-else>
              <div class="flex">
                <label 
                  for="userId"
                  class="block text-sm font-medium text-gray-700"
                >User ID:</label>
                <input
                  :value="selectedUser"
                  type="text"
                  name="userId"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                  disabled
                >
                <button class="ml-2" @click.prevent="handleCancelUser">
                  <XIcon class="w-5 h-5 text-gray-400 hover:text-gray-800" />
                </button>
              </div>
            </div>
            <div class="ml-4">
              <select
                v-model="selectedType"
                name="type"
                id="type-select"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
                <option v-for="t in types" :key="t.value" :value="t.value">{{ t.text }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button
            class="
              text-center
              px-4
              py-2
              rounded-md
              text-white
              bg-green-700
              hover:underline
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-cyan-500
              bg-no-repeat
              disabled:bg-gray-200
              disabled:text-gray-400
              disabled:cursor-not-allowed
            "
            :disabled="!selectedUser"
            @click.prevent="handleReassign"
          >Confirm Re-assignment</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { saveStorage } from '@/storageSave';
import { XIcon } from '@heroicons/vue/solid';
import httpClient from '@/httpClient';
import UserSearch from '@/components/userDropdownSearch.vue';

const store = useStore();
const router = useRouter();
const formData = ref({});
const stages = inject('stages');
const selectedType = ref('HM');
const selectedUser = ref('');

const types = [
  {
    text: 'Hiring manager',
    value: 'HM',
    group: 'HM'
  },
  {
    text: 'Secondary contact',
    value: 'CONTACT',
    group: 'ALL'
  },
  {
    text: 'First line approver',
    value: 'FIRST',
    group: 'HM'
  },
  {
    text: 'Second line approver',
    value: 'SECOND',
    group: 'HROPS'
  },
  {
    text: 'Admin owner',
    value: 'ADMIN',
    group: 'H2R'
  },
  {
    text: 'Resourcing Owner',
    value: 'RESOURCING',
    group: 'RESOURCING'
  }
]

const currentStage = computed(() => stages.find((stage) => stage.stageName === formData.value.currentStageName));
const currentType = computed(() => types.find((type) => type.value === selectedType.value))
const docId = computed(() => router.currentRoute.value.params.id);
const docOwner = computed(() => router.currentRoute.value.params.docOwner);

const handleUser = (event) => selectedUser.value = event.userID;
const handleCancelUser = () => selectedUser.value = '';

const fetchSingleAtr = async () => {
  store.dispatch('setIsLoading', true);
  const payload = {
    requestedBy: docOwner.value,
    id: docId.value 
  }
  const response = await httpClient.post('/bff/get-document', payload);
  const body = response.data.body;
  formData.value = body.Item;
  saveStorage('formData', JSON.stringify(body.Item));
  saveStorage('atrShortId', body.Item.shortId);
  saveStorage('atrId', body.Item.docId);
  store.dispatch('setIsLoading', false);
};

const handleReassign = async () => {
  const payload = {
    requestedBy: docOwner.value,
    id: docId.value,
    selectedType,
    selectedUser
  }
  try {
    await httpClient.post('/bff/reassign-request', payload);
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'success',
      title: 'Success!',
      message: 'This approver has been updated'
    });
    router.go(-1);
  } catch(err) {
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'error',
      title: 'Error!',
      message: 'Could not update the approver'
    });
  }
  
}

onMounted(async () => await fetchSingleAtr())
</script>
<template>
  <div>
    <div
      v-if="!disabled"
      class="
        relative
        border-dashed
        border-2
        h-32
        rounded
        col-span-6
        w-full
        cursor-pointer
        ease-out
        duration-200
      "
      :class="{ 
        'bg-gray-200 border-none cursor-not-allowed': disabled,
        'border-green-400': dropzoneActive
      }"
      @click="handleClickFileUpload"
      @drop.prevent="handleFileDrop"
      @dragover.prevent="dropzoneActive = true"
      @dragenter.prevent="dropzoneActive = true"
      @dragleave.prevent="dropzoneActive = false"
      @mouseover.prevent="dropzoneActive = true"
      @mouseenter.prevent="dropzoneActive = true"
      @mouseleave.prevent="dropzoneActive = false"
    >
      <div class="w-full h-full relative flex items-center justify-center">
        <CloudUploadIcon class="h-7 w-7 text-gray-500" />
        <label :for="`${name}-file-upload`" class="cursor-pointer text-gray-500 ml-2">Drop your files here</label>
      </div>
      <input
        multiple
        type="file"
        :name="`${name}-file-upload`"
        :id="`${name}-file-upload`"
        class="invisible absolute top-0 left-0 w-full h-full"
        :accept="allowedFileTypes.join(', ')"
        :disabled="disabled"
        @change="handleFileChange"
      >
    </div>

    <p v-if="!disabled" class="italic mt-2 text text-gray-700">* Allowed file extensions: .pdf, .doc, .docx, .xlsx, .zip, .msg</p>

    <div class="mt-4 shadow mb-2" v-for="(file, index) in files" :key="file.name">
      <div class="w-full p-4 flex justify-between">
        <div class="flex">
          <DocumentTextIcon class="h-10 w-10 text-gray-500" />
          <p class="ml-4 self-center">{{ file.name.split('/')[1] }}</p>
        </div>
        <div v-if="!disabled && file.uploaded" class="flex">
          <button @click.prevent="handleDownload(index)">
            <DownloadIcon class="text-gray-500 hover:text-gray-800 h-6 w-6" />
          </button>
          <button class="ml-4" @click="handleDeleteFile(index)">
            <XIcon class="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </button>
        </div>
        <div v-if="disabled && file.uploaded" class="flex items-center">
          <button @click.prevent="handleDownload(index)">
            <DownloadIcon class="text-gray-500 hover:text-gray-800 h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CloudUploadIcon, DocumentTextIcon, DownloadIcon, XIcon } from '@heroicons/vue/outline'
import axios from 'axios'
import httpClient from '@/httpClient'
import { nanoid } from 'nanoid'

export default {
  props: ['name', 'disabled', 'files', 'documentShortId', 'uploading'],
  components: { CloudUploadIcon, DocumentTextIcon, DownloadIcon, XIcon },
  data() {
    return {
      dropzoneActive: false,
      fileModel: this.files ?? [],
      allowedFileTypes: [
        ".pdf",
        ".doc",
        ".docx",
        ".xlsx",
        ".xlsm",
        ".xlsb",
        ".xltx",
        ".zip",
        ".msg",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/zip",
        "application/x-zip",
        "application/x-zip-compressed",
        "application/octet-stream",
        "application/vsd.ms-outlook",
      ]
    }
  },
  methods: {
    handleClickFileUpload() {
      const el = document.getElementById(`${this.name}-file-upload`);
      el.click();
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (!files) return;
      this.handleFiles(files);
    },
    handleFileDrop(event) {
      const files = event.dataTransfer.files;
      if (!files) return;
      this.handleFiles(files);
      this.dropzone = false;
    },
    handleFiles(files) {
      ([...files]).forEach(async (file) => {
        // Check allowed file type. Note: .msg files don't have an assigned type, so need to check extension manually
        if (this.allowedFileTypes.includes(file.type) || file.name.split('.').at(-1) === 'msg') {
          this.fileModel.push({ file, name: `${nanoid()}/${file.name.replace(/[^a-zA-Z0-9.]/g, '')}`, uploaded: false })

          this.$emit('updateFiles', { model: this.fileModel });
          await this.handleFileUpload(this.fileModel.length - 1);
        } 
      });
    },
    async handleFileUpload(index) {
      const shortId = this.documentShortId;
      const fileName = this.files && this.files[index] ? this.files[index].name : this.fileModel[index].name
      this.$emit('fileUploading', true);
      try {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'info',
          title: 'Uploading',
          message: 'Uploading document'
        });
        // get presigned url
        const response = await httpClient.post(
          `/docs/upload`,
          { key: `${shortId}/${fileName}` }
        );
        
        // put to presigned url
        const url = response.data.body.signedUrl;
        const file = this.files ? this.files[index].file : this.fileModel[index].file
        await axios.put(url, file);
        this.fileModel[index].uploaded = true;
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Uploaded',
          message: 'Successfully uploaded document'
        });
        this.$emit('updateFiles', { model: this.fileModel });

        // clear input ready for another file
        const el = document.getElementById(`${this.name}-file-upload`);
        el.value = '';
      } catch (err) {
        console.log(err);
        this.fileModel.splice(index, 1);
        this.$emit('updateFiles', { model: this.fileModel });
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error',
          message: 'Could not upload document'
        });
        // clear input ready for another file
        const el = document.getElementById(`${this.name}-file-upload`);
        el.value = '';
      } finally {
        this.$emit('fileUploading', false);
      }
    },
    async handleDownload(index) {
      const file = this.files[index];
      const shortId = this.documentShortId;
      const fileName = file.name.replace(/\s/g, '_')
      try {
        const response = await httpClient.post(
          '/docs/download',
          { key: `${shortId}/${fileName}` }
        );
        const url = response.data.body?.signedUrl;
        if (url) {
          if (response.data.statusCode === 403) throw new Error(url);
          else window.open(url);
        }
        else throw new Error();
      } catch (err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: err.message ?? 'Could not download file'
        });
        console.warn(err)
      }
    },
    handleDeleteFile(index) {
      // Delete locally
      this.fileModel.splice(index, 1);
      // Update parent
      this.$emit('updateFiles', { model: this.fileModel });
    },
  }
}
</script>

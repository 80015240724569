<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Assign Admin Owner
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
          <div class="overflow-hidden sm:rounded-lg">
            <div v-if="!props.formData?.userId">
              <p>Select the admin you would like to assign this offer to:</p>
              <UserSearch group="H2R" @updated-user="handleUser" />
            </div>
            <div v-else class="border border-gray-300 rounded-lg p-6">
              <div class="flex justify-end">
                <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click="handleClearUser" />
              </div>
              <div class="mt-6">
                <label 
                  for="hiringManagerName"
                  class="block text-sm font-medium text-gray-700"
                >Admin name:</label>
                <input
                  :value="username"
                  type="text"
                  name="hiringManagerName"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                  disabled
                >
              </div>
              <div class="col-span-6 mt-4">
                <label
                  for="hiringManagerEmail"
                  class="block text-sm font-medium text-gray-700"
                >Admin email address:</label>
                <input
                  :value="userEmail"
                  type="email"
                  name="hiringManagerEmail"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!props.disableForm && (profile.H2R || profile.ADMIN) && document.currentStageName === 'offer.notifyH2RAdmin'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="!username"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="confirmAssignAdmin"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>
<script setup>
import { defineProps, defineEmits, ref, toRef, computed, onMounted } from 'vue'
import { useStore } from 'vuex';
import UserSearch from '@/components/userDropdownSearch.vue'
import { XIcon } from '@heroicons/vue/solid';

const props = defineProps(['formData', 'disableForm', 'document']);
const store = useStore();
const emit = defineEmits(['update:formData', 'submit-form'])
const model = toRef(props, 'formData');
const username = ref('');
const userEmail = ref('');

const profile = computed(() => store.getters.getProfile);

const handleUser = (event) => {
  username.value = event.Title;
  userEmail.value = event.email;
  emit('update:formData', { formName: 'adminOwner', formData: {userId: event.userID, email: event.email, name: event.Title} });
};

const handleClearUser = () => {
  username.value = '';
  userEmail.value = '';
  emit('update:formData', { formName: 'adminOwner', formData: {userId: event.userID, email: event.email, name: event.Title} });
}

const confirmAssignAdmin = () => {
  emit('submit-form', { name: 'adminOwner', data: model.value });
}

onMounted(() => {
  username.value = model.value.name;
  userEmail.value = model.value.email;
})
</script>

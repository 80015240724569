<template>
  <form>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Re-Employment
          </h3>
        </div>
        <section v-if="reasonResignation === 'Death in Service'">
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              N/A
            </legend>
          </fieldset>
        </section>
        <section v-if="reasonResignation !== 'Death in Service'">
        <!-- re-employ  -->
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              Would you re-employ the employee in the future? *
            </legend>
            <span v-if="v$.formData.re_employ.$error" class="text-red-500">
              * {{ v$.formData.re_employ.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.re_employ"
                    value="yes"
                    id="radio-button-re_employ"
                    name="re_employ-yes"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    @blur="v$.formData.re_employ.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="re_employ-yes" class="font-medium text-gray-700">Yes</label>
                </div>
              </div>
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.re_employ"
                      value="No"
                      id="radio-button-re_employ"
                      name="re_employ-no"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.re_employ.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="re_employ-no" class="font-medium text-gray-700">No</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- End of Agency Temp  -->

          <div v-if="formData.re_employ === 'No'" class="col-span-6">
            <label for="reason" class="block text-sm font-medium text-gray-700">If no, please provide a reason why: *</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="reason-for-not-re-employing"
                name="reason"
                v-model="formData.reason"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                :disabled="disableForm"
                @blur="v$.formData.reason.$touch()"
              />
              <span v-if="v$.formData.reason.$error" class="text-red-500">
                * {{ v$.formData.reason.$errors[0].$message }}
              </span>
            </div>
          </div>
          </section>

      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid && reasonResignation !== 'Death in Service'"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, helpers } from '@vuelidate/validators'

export default {
  props: ['disableForm', 'reasonResignation'],
  data() {
    return {
      v$: useVuelidate()
    }
  },
  computed: {
    formData() {
      return this.$store.getters.getLeaverFormData('formFour')
    }
  },
  methods: {
    handleSave() {
      this.$emit('submit-form', { name: 'formFour', formData: this.formData })
    }
  },
  validations() {
    return {
      formData: {
        re_employ: { required },
        reason: {
          requiredIf: helpers.withMessage('This field is required', requiredIf(this.formData.re_employ === 'No'))
        }
      }
    }
  }
}
</script>

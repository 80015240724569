<template>
  <div>
    <fieldset>
      <legend class="text-base font-medium text-gray-900">
        {{ props.label }}
      </legend>
      <span v-if="error" class="text-red-500">
        * {{ error }}
      </span>
      <div class="mt-4 space-y-4">
        <div class="flex items-start" v-for="option in options" :key="option">
          <div class="h-5 flex items-center">
            <input
              :id="`checklist-option-${option}`"
              :name="props.name"
              :value="option"
              type="checkbox"
              class="
                focus:ring-indigo-500
                h-4
                w-4
                text-indigo-600
                border-gray-300
                rounded
                disabled:bg-gray-200
                disabled:border-none
                disabled:cursor-not-allowed
              "
              :disabled="props.disabled"
              :checked="props.modelValue.includes(option)"
              @change="handleClick"
              @blur="handleBlur"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="shiftPattern" class="font-medium text-gray-700"
              >{{ option }}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, toRef } from 'vue';

const props = defineProps(['options', 'label', 'error', 'disabled', 'modelValue', 'name']);
const emit = defineEmits(['update:modelValue', 'blur']);
const model = toRef(props, 'modelValue')

const handleClick = (event) => {
  const { name, value } = event.target;
  if (model.value.includes(value)) {
    const index = model.value.indexOf(value);
    model.value.splice(index, 1);
  } else model.value.push(value);
  emit('update:modelValue', { name, value: model.value })
};

const handleBlur = () => emit('blur');
</script>
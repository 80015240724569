import httpClient from "@/httpClient"
import router from "@/router";
import { getStorage, saveStorage } from "@/storageSave";

export default {
  state () {
    return {
      leaverForm: {
        leaverId: null,
        formOne: {
          name: "",
          email: "",
          phone: "",
          secondary: "",
          secondaryName: "",
          secondaryEmail: "",
          secondaryPhone: "",
          saved: false
        },
        formTwo: {
          is_temp: "",
          employee_name: "",
          unable_to_find: "",
          employee_number: "",
          division: "",
          sector: "",
          company_code: "",
          personal_email: "",
          location: "",
          location_postcode: "",
          saved: false
        },
        formThree: {
          reason: "",
          reasonResignation: "",
          comments: "",
          saved: false
        },
        formFour: {
          re_employ: "",
          saved: false
        },
        formFive: {
          files: [],
          comments: "",
          saved: false
        },
        formSix: {
          leaving_date: ""
        },
        formSeven: {
          employee_reporting_to: "",
          additional_information: "",
          line_manager: "",
          employee_number: "",
          coupa_user: "",
          coupa_user_name: "",
          coupa_user_email: "",
          coupa_user_number: ""
        },
        formEight: {
          leave_situation: "",
          leave_comments: "",
          be_paid: "",
          days_hours: ""
        },
        formNine: {
          additional_leave: "",
          current_leave: "",
          comments_leave: "",
          in_lieu: "",
          redundancy_pay: "",
          redundancy_pay_ni: "",
          settlement_pay: "",
          settlement_pay_ni: "",
          ex_gratia: "",
          ex_gratia_ni: "",
          consideration_payment: "",
          pension_fund: "",
          deduction_payment: "",
          reason_deduction: "",
          value_deduction: "",
          date_of_payment: ''
        },
        formTen: {}
      }
    }
  },
  getters: {
    getLeaver: (state) => state.leaverForm,
    getLeaverFormData: (state) => formName => state.leaverForm[formName],
    getLeaverId: (state) => state.leaverForm.leaverId,
    leaverFormIsReadyToSubmit: (state) => {
      const forms = [
        'formOne',
        'formTwo',
        'formThree',
        'formFour',
        'formFive',
      ]
      let ready = true;
      Object.keys(state.leaverForm).forEach((form) => {
        if (forms.includes(form)) {
          if (!state.leaverForm[form].saved) {
            ready = false
          }
        }
      })
      return ready;
    }
  },
  mutations: {
    setLeaverFormSavedStatus: (state, { formName, value }) => state.leaverForm[formName].saved = value,
    setFormState: (state, { formName, formData }) => state.leaverForm[formName] = { ...formData, saved: true },
    setWholeFormState: (state, formData) => state.leaverForm = formData,
    setLeaverId: (state, id) => state.leaverForm.leaverId = id,
    updateFiles: (state, files) => state.leaverForm.formFive.files = files,
  },
  actions: {
    // Action to save a part of the leaver form
    saveLeaverFormStep: async ({ commit, state }, { event, requestedBy, id, stage, changes, edited }) => {
      try {
        event.formData.saved = true;
        const payload = {
          formData: event.formData,
          requestedBy,
          id,
          stage
        }
        if (edited) {
          payload.edited = edited;
          payload.changes = changes;
        }
        const response = await httpClient.post(`/bff/leaver/${event.name}`, payload);
        commit('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Form saved'
        }, { root: true });
        const body = response.data.body;
        commit('setLeaverId', body.id);
        commit('setLeaverFormSavedStatus', { formName: event.name, value: true })
        commit('setWholeFormState', { ...body.Item, ...state.leaverForm });
        saveStorage('leaverForm', JSON.stringify(state.leaverForm));
        if (event.name === 'formOne') router.push(`/create-leaver/${body.id}`)
        return true;
      } catch (err) {
        event.formData.saved = false;
        commit('setLeaverFormSavedStatus', { formName: event.name, value: false });
        commit('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was an error saving the form'
        }, { root: true });
        return false;
      }
    },
    // update file array in formFive
    updateFiles: ({ commit }, files) => commit('updateFiles', files),
    // Action to complete the leaver form
    completeLeaver: async ({ commit, state }) => {
      try {
        await httpClient.post('/bff/leaver/complete', {
          id: state.leaverForm.leaverId
        });
        commit('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'The leaver form has been submitted'
        }, { root: true });
        router.push('/');
      } catch (err) {
        commit('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'There was an error submitting the form'
        }, { root: true });
      }
    },
    // Action to update localstorage with the current leaver form state
    updateLeaverLocalStorage: ({ state }) => saveStorage('leaverForm', JSON.stringify(state.leaverForm)),
    updateLeaverStore: ({ commit }) => {
      const formData = JSON.parse(getStorage('leaverForm'));
      commit('setWholeFormState', formData);
    },
    setLeaverFormState: ({ commit }, { formName, formData }) => commit('setFormState', { formName, formData }),
    clearAllLeaverFormData: ({ commit }) => commit('setWholeFormState', {
      leaverId: null,
      formOne: {
        name: "",
        email: "",
        phone: "",
        secondary: "",
        saved: false
      },
      formTwo: {
        secondary_contact: "",
        is_temp: "",
        employee_name: "",
        unable_to_find: "",
        employee_number: "",
        division: "",
        sector: "",
        company_code: "",
        personal_email: "",
        location: "",
        location_postcode: "",
        saved: false
      },
      formThree: {
        reason: "",
        saved: false
      },
      formFour: {
        re_employ: "",
        saved: false
      },
      formFive: {
        file: null,
        comments: "",
        saved: false
      },
      formSix: {
        leaving_date: ""
      },
      formSeven: {
        additional_information: "",
        line_manager: "",
        radio: "",
        employee_number: "",
        coupa_user: "",
        coupa_user_name: "",
        coupa_user_email: ""
      },
      formEight: {
        leave_situation: [],
        leave_comments: "",
        be_paid: "",
        days_hours: ""
      },
      formNine: {
        additional_leave: "",
        current_leave: "",
        comments_leave: "",
        in_lieu: "",
        redundancy_pay: "",
        redundancy_pay_ni: "",
        settlement_pay: "",
        settlement_pay_ni: "",
        ex_gratia: "",
        ex_gratia_ni: "",
        consideration_payment: "",
        pension_fund: "",
        deduction_payment: "",
        reason_deduction: "",
        value_deduction: "",
        date_of_payment: ''
      },
      formTen: {}
    })
  }
}
<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Candidate Selection
          </h3>
        </div>
        <!-- end title header -->
        <p class="text-gray-700">Approved number of candidates: {{ approvedRoles }}</p>
        <!-- form -->
            <div class="col-span-6 mt-4">
              <label
                for="offer_stage"
                class="block text-sm font-medium text-gray-700"
              >How many candidates are proceeding to offer stage?: *</label>
              <input
                :value="data.selection?.offer_stage"
                type="text"
                name="offer_stage"
                id="offering_to-input"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                :disabled="disableForm"
                @input="handleInput"
                @blur="v$.data?.selection?.offer_stage.$touch()"
              >
              <span v-if="v$.data?.selection?.offer_stage.$error" class="text-red-500">
                * {{ v$.data?.selection?.offer_stage.$errors[0].$message }}
              </span>
            </div>

            <div class="col-span-6 mt-4">
              <label
                for="offering_to"
                class="block text-sm font-medium text-gray-700"
              >Please provide the names of the Candidates you are offering to:</label>
              <div class="relative" v-for="(n, index) in numberOfOffers + cancelledOffers?.length || 0" :key="n">
                <input
                  :value="data.selection?.offering_to[index]"
                  type="text"
                  name="offering_to"
                  id="offering_to-input"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                  :disabled="disableForm || (offeringToCopy && JSON.parse(offeringToCopy)?.includes(data.selection.offering_to[index]))"
                  @input="handleNameInput($event, index)"
                >
                <span v-if="data?.offersArray && data?.offersArray[index]?.active === 'CANCELLED'" class="absolute text-red-600 top-2 right-2">Cancelled</span>
              </div>
            </div>
            <div>
              <p>Do you want the recruitment team to send any regret letters? *</p>
              <span v-if="v$.data?.selection?.regret_letters.$error" class="text-red-500">
                * {{ v$.data?.selection?.regret_letters.$errors[0].$message }}
              </span>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio_button_regret_letters_Yes"
                    name="regret_letters"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    :checked="data.selection?.regret_letters === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.regret_letters.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="regret_letters"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio_button_regret_letters_No"
                    name="regret_letters"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    :checked="data.selection?.regret_letters === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.regret_letters.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="regret_letters"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
            </div>
            
            <div>
              <p>Are you withdrawing any roles? *</p>
              <span v-if="v$.data?.selection?.withdrawing_roles.$error" class="text-red-500">
                * {{ v$.data?.selection?.withdrawing_roles.$errors[0].$message }}
              </span>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio_button_withdrawing_roles_Yes"
                    name="withdrawing_roles"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    :checked="data.selection?.withdrawing_roles === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.withdrawing_roles.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="withdrawing_roles"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio_button_withdrawing_roles_No"
                    name="withdrawing_roles"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    :checked="data.selection?.withdrawing_roles === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.withdrawing_roles.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="withdrawing_roles"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
            </div>
            
            <div>
              <p>Is re-advertising required? *</p>
              <span v-if="v$.data?.selection?.re_advertising.$error" class="text-red-500">
                * {{ v$.data?.selection?.re_advertising.$errors[0].$message }}
              </span>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio_button_re_advertising_Yes"
                    name="re_advertising"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm || disableReAdvertise"
                    :checked="model.selection?.re_advertising === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.re_advertising.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="re_advertising"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio_button_re_advertising_No"
                    name="re_advertising"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm || disableReAdvertise"
                    :checked="model.selection?.re_advertising === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.data?.selection?.re_advertising.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="re_advertising"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
            </div>

            <div class="mt-6">
              <label 
                for="approval_comments"
                class="block text-sm font-medium text-gray-700"
              >Additional comments for the Recruitment Team:</label>
              <textarea
                :disabled="disableForm"
                :value="data.additional_comments?.comment"
                rows="3"
                name="approval_comments"
                class="
                  mt-1
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border
                  border-gray-300
                  rounded-md
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
                @input="handleTextInput"
              ></textarea>
            </div>
        <!-- End Form -->
      </div>
      <div v-if="(profile?.userID?.toUpperCase() === data.requestedBy?.toUpperCase() || profile?.ADMIN || profile?.RESOURCING) && (data.currentStageName === 'starter.notifyCVsCollected' || data.currentStageName === 'starter.notifyCandidateSelected')" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="toggleModal"
        >
          Generate Offers
        </button>
      </div>
      <ModalWindow v-if="showModal" size="2xl" @close="toggleModal">
        <template v-slot:icon>
          <component :is="currentModalContent.icon" class="h-12 w-12 bg-white text-yellow-500" aria-hidden="true"></component>
        </template>
        <template v-slot:title>{{ currentModalContent.title }}</template>
        <template v-slot:subtitle>{{ currentModalContent.subtitle }}</template>
        <template v-slot:footer>
          <button
            type="button"
            class="
              inline-flex
              justify-center
              w-full
              rounded-md
              border border-transparent
              shadow-sm
              px-4
              py-2
              my-2
              bg-green-700
              text-base
              text-white
              font-medium
              hover:bg-green-800
              hover:underline
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              sm:text-sm
            "
            @click="handleSave"
          >
            Generate Offers and Save
          </button>
          <button
            type="button"
            class="
              inline-flex
              justify-center
              w-full
              rounded-md
              border border-transparent
              shadow-sm
              px-4
              py-2
              my-2
              bg-yellow-500
              text-base
              font-medium
              hover:bg-yellow-600
              hover:underline
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              sm:text-sm
            "
            @click="submitForm"
          >
            Generate Offers and Proceed
          </button>
          <button
            type="button"
            class="
              inline-flex
              justify-center
              w-full
              rounded-md
              border border-transparent
              shadow-sm
              px-4
              py-2
              my-2
              bg-gray-200
              text-base
              font-medium
              hover:bg-gray-300
              hover:underline
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              sm:text-sm
            "
            @click="toggleModal"
          >
            Cancel
          </button>
        </template>
      </ModalWindow>
    </div>
  </form>
</template>

<script>
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core";
import { ExclamationIcon } from "@heroicons/vue/solid";
import ModalWindow from "@/components/ModalWindow.vue";

export default {
  props: ['data', 'disableForm'],
  emits: ['update:formData', 'submit-form'],
  components: { ExclamationIcon, ModalWindow },
  data() {
    return {
      model: this.data,
      offeringToCopy: null,
      v$: useVuelidate(),
      showModal: false,
      modalContent: {
        proceed: {
          icon: ExclamationIcon,
          title: 'Warning!',
          subtitle: 'Are you sure you want to proceed? You will not be able to make any further offers if you proceed. Click the green button if you need to make more offers, or the yellow button if you are ready to move on.',
        },
        reAdvertise: {
          icon: ExclamationIcon,
          title: 'Warning!',
          subtitle: 'Are you sure you want to re-advertise for this role? You will not be able to make any further offers until the document has come back to this stage. Click the yellow button if you are ready to re-advertise, or the green button if you wish to make more offers first.',
        }
      }
    }
  },
  computed: {
    approvedRoles() {
      return this.data?.formThree?.numberOfPositions
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    numberOfOffers() {
      return this.data?.selection?.offer_stage ? parseInt(this.data.selection.offer_stage) : 0
    },
    cancelledOffers() {
      return this.data?.offersArray?.filter((offer) => offer.active === 'CANCELLED')
    },
    disableReAdvertise() {
      return Number(this.approvedRoles) === Number(this.model.selection?.offer_stage) && this.model.selection?.offering_to.length === Number(this.model.selection?.offer_stage) && !this.model.selection.offering_to.includes('')
    },
    currentModalContent() {
      if (this.data.selection.re_advertising === 'Yes') {
        return this.modalContent.reAdvertise;
      } else {
        return this.modalContent.proceed;
      }
    }
  },
  methods: {
    toggleModal() { this.showModal = !this.showModal; },
    handleNameInput(event, index) {
      const value = event.target.value;
      this.model.selection.offering_to[index] = value;
      this.$emit('update:formData', { formName: 'selection', data: this.model.selection });
      if (this.model.selection?.offering_to.length === Number(this.model.selection?.offer_stage)) {
        this.model.selection.re_advertising = "No"
      } else {
        this.model.selection.re_advertising = "Yes"
      }
    },

    handleTextInput(event) {
      const { name, value } = event.currentTarget;
      this.model.selection[name] = value;
      this.$emit('update:formData', { formName: 'selection', data: this.model.selection });
    },

    handleInput(event) {
      let number;
      const { name, value } = event.currentTarget;
      if (value > Number(this.approvedRoles)) number = Number(this.approvedRoles);
      else number = Number(value);
      this.model.selection[name] = number;
      this.$emit('update:formData', { formName: 'selection', data: this.model.selection });
    },

    handleRadioChange(event) {
      const { name, value } = event.target;
      this.model.selection[name] = value;
      this.$emit('update:formData', { formName: 'selection', data: this.model.selection });
    },

    async handleSave() {
      await this.$emit('update:formData', this.model.selection)
      this.$emit('submit-form', { name: 'selection', data: this.data.selection });
    },

    async submitForm () {
      await this.$emit('update:formData', this.model.selection)
      this.$emit('submit-form', { name: 'selection', data: { ...this.data.selection, completed: true } });
    }
  },
  watch: {
    data() {
      if (this.data?.selection?.offering_to) {
        this.offeringToCopy = JSON.stringify(this.data?.selection?.offering_to);
      }
      if (this.data.selection?.saved) {
        this.model.selection = this.data.selection
      }
      this.model.selection.offer_stage = this.approvedRoles
    },
    disableReAdvertise(value) {
      if (!value) {
        this.model.selection.re_advertising = 'Yes'
      }
    }
  },
  validations() {
    return {
      data: {
        selection: {
          offer_stage: { required },
          regret_letters: { required },
          withdrawing_roles: { required },
          re_advertising: { required }
        }
      }
    }
  }
}
</script>

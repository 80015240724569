<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Job details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <div v-html="amount"></div>
        
        <div v-html="type"></div>
      
        <div v-html="location"></div>

        <div v-html="location_postcode"></div>

        <div v-html="contract_type"></div>

        <!-- End Form -->
      </div>
      <!-- Save button -->
    <!-- End Save button -->
    </div>
  </form>
</template>

<script setup>
import { Input, Textarea } from '@/form';
import { defineProps } from 'vue';


const props = defineProps(['formData', 'disableForm'])

const amount = Input('How many positions are you recruiting for?', 'amount', 'text', props.disableForm, props.formData.numberOfPositions)
const type = Input('What employee type are you recruiting? ', 'type', 'text', props.disableForm, props.formData.employeeType)
const location = Textarea('Role Location (Site):', 'location', props.disableForm, props.formData.roleLocationSite)
const location_postcode = Textarea('Role Location (Postcode):', 'location_postcode', props.disableForm, props.formData.roleLocationPostcode)
const contract_type = Input('What is the employment contract for this role?', 'contract_type', 'text', props.disableForm, props.formData.employmentContract)

</script>

<template>
  <div class="shadow sm:rounded-t-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
      <h3>Are you happy to submit the form?</h3>
      <p>If you are happy all the information you have provided is correct, please click the button to submit the form</p>
      <div class="flex justify-end">
        <button
          :disabled="disableButton"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400"
            @click.prevent="handleSumitLeaver"
        >Submit</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['data'],
  emits: ['submit-leaver'],
  methods: {
    handleSumitLeaver() {
      this.$emit('submit-leaver')
    }
  },
  computed: {
    disableButton() {
      let disable = false;
      const keys = [
        'formOne',
        'formTwo',
        'formThree',
        'formFour',
        'formFive',
        'formSix',
        'formSeven',
        'formEight'
      ];
      keys.forEach((key) => {
        if(!this.data[key].saved) {
          disable = true;
        }
      });
      return disable;
    }
  }
}
</script>

<template>
    <!--        ProcessedWork.vue component - Displays completed work by user, plus workload breakdown, with date filtering     -->
    <h1 class="text-2xl font-semibold text-gray-900 hidden md:block py-2 pt-4">Processed Work</h1>
    <section>
        <div class="flex flex-col font-light py-2 items-left">
            <div class="flex">
                <span class="mr-2">
                    <label for="startDate" class="text-sm font-medium text-gray-700"
                    >Start Date:</label
                    >
                    <input
                    :value="formData?.startDate"
                    type="date"
                    min="0"
                    name="startDate"
                    id="startDate"
                    class="
                        mt-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                    "
                    @input="handleInput"
                    />
                </span>
                <span class="mr-2">
                    <label for="endDate" class="text-sm font-medium text-gray-700"
                    >End Date:</label
                    >
                    <input
                    :value="formData?.endDate"
                    type="date"
                    min="0"
                    name="endDate"
                    id="endDate"
                    class="
                        mt-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                    "
                    @input="handleInput"
                    />
                </span>
                <button
                class="
                    inline-flex
                    items-center
                    align-bottom    
                    rounded-md
                    border
                    border-transparent
                    bg-green-700
                    hover:bg-green-800
                    hover:underline
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    shadow-sm
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2
                    sm:w-auto
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:text-gray-400
                    h-9
                    mt-7
                    "
                    :disabled="!formData?.startDate || !formData?.endDate"
                    @click="searchProcessed"
                    >
                    Search
                </button>
            </div>
        </div>
        <div class="table-border-padding">
            <div class="border rounded-lg pt-2 min-w-[900px]">
                <table class="min-w-full text-center text-sm">
                    <thead>
                        <tr class="divide-gray-200">
                            <th scope="col" class="border-b py-2 font-normal text-sm w-10">
                            </th>
                            <th scope="col" colspan="5" class="border-b py-2 font-normal text-sm text-left">
                                Employee Email
                            </th>
                            <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                                Offers
                            </th>
                            <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                                Amends
                            </th>
                            <th scope="col" colspan="6" class="border-b py-2 font-normal text-sm">
                                Leavers
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(user, index) in Object.keys(users)" :key="index">
                            <tr class="divide-y border-b divide-gray-200">
                                <td 
                                    v-if="rowDetail.includes(user)"
                                    class="flex justify-center">
                                    <button>
                                        <ChevronDownIcon 
                                            class="h-5 w-5 mr-2"
                                            @click="showWorkDetail(user)" />
                                    </button>
                                </td>
                                <td v-else
                                    class="flex justify-center">
                                    <button>
                                        <ChevronRightIcon
                                            class="h-5 w-5 mr-2"
                                            @click="showWorkDetail(user)" />
                                    </button>
                                </td>
                                <td colspan="5" class="font-light text-left">
                                    <p class="cursor-pointer" @click="showWorkDetail(user)">{{ user }}</p>
                                </td>
                                <td colspan="6">{{ sum(users[user].OFFER) }}</td>
                                <td colspan="6">{{ sum(users[user].AMEND) }}</td>
                                <td colspan="6">{{ sum(users[user].LEAVER) }}</td>
                            </tr>
                            <tr v-if="rowDetail.includes(user)" class="process-row">
                                <td colspan="6" class="font-medium">Breakdown by SLA:</td>

                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50 border-b font-bold">1</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50 border-b font-bold">2</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50 border-b font-bold">3</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50 border-b font-bold">4</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50 border-b font-bold">5+</td>
                                <td class="w-px"></td>

                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50 border-b font-bold">1</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50 border-b font-bold">2</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50 border-b font-bold">3</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50 border-b font-bold">4</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50 border-b font-bold">5+</td>
                                <td class="w-px"></td>
                                
                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50 border-b font-bold">1</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50 border-b font-bold">2</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50 border-b font-bold">3</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50 border-b font-bold">4</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50 border-b font-bold">5+</td>
                            </tr>
                            <tr v-if="rowDetail.includes(user)" class="">
                                <td colspan="6" class="font-medium"></td>
                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].OFFER[0] + users[user].OFFER[1] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].OFFER[2] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].OFFER[3] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].OFFER[4] }}</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].OFFER[5] }}</td>
                                <td class="w-px"></td>
                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].AMEND[0] + users[user].AMEND[1] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].AMEND[2] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].AMEND[3] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].AMEND[4] }}</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].AMEND[5] }}</td>
                                <td class="w-px"></td>
                                <td class="whitespace-nowrap px-3 py-2 bg-green-100 bg-opacity-50">{{ users[user].LEAVER[0] + users[user].LEAVER[1] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-lime-100 bg-opacity-50">{{ users[user].LEAVER[2] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-yellow-100 bg-opacity-50">{{ users[user].LEAVER[3] }}</td>
                                <td class="whitespace-nowrap px-3 py-2 bg-orange-100 bg-opacity-50">{{ users[user].LEAVER[4] }}</td>
                                <td class="whitespace-nowrap px-2 py-2 bg-red-100 bg-opacity-50">{{ users[user].LEAVER[5] }}</td>
                            </tr>
                            <tr v-if="rowDetail.includes(user)" class="border-b details">
                                <td colspan="6" class="font-medium"></td>
                                <td colspan="6" class="whitespace-normal p-1 text-left text-xs">{{ slaText(users[user], 'OFFER', 'Offers') }}</td>
                                <td colspan="6" class="whitespace-normal p-1 text-left text-xs">{{ slaText(users[user], 'AMEND', 'Amends') }}</td>
                                <td colspan="6" class="whitespace-normal p-1 text-left text-xs">{{ slaText(users[user], 'LEAVER', 'Leavers') }}</td>
                            </tr>
                        </template>
                        <tr class="divide-y divide-gray-200">
                                <td></td>
                                <td colspan="5" class="font-normal text-left">
                                    <a href="#">Totals</a>
                                </td>
                                <td colspan="6">{{ sum(overall.OFFER) }}</td>
                                <td colspan="6">{{ sum(overall.AMEND) }}</td>
                                <td colspan="6">{{ sum(overall.LEAVER) }}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/solid';
export default {
    components: { ChevronRightIcon, ChevronDownIcon},

    props: ['formData', 'overall', 'users'],
    emits: ['update:formData', 'searchProcessed'],

    data() {
        return {
            model: this.formData,
            rowDetail: [],
            showModal: false,            
        }
    },
    methods: {
        openDateModal() { this.showModal = true },
        handleModalClose() { this.showModal = false },
        showWorkDetail(id) {
            /* 
                checks rowDetail for user id, if present it's removed, else id pushed into rowDetail
                used to expand the 'processed work' table row for more detail
            */
            const index = this.rowDetail.indexOf(id);
            if (index > -1) {
                this.rowDetail.splice(index, 1)
            } else {
                this.rowDetail.push(id)
            }
        },
        handleInput(event) {
            const { name, value } = event.target;
            this.model[name] = value;
            this.$emit('update:formData', { formName: 'processedWork', formData: this.model });
        },
        sum(data = []) {
            return data?.reduce((accumulator, currentValue) => {  return accumulator + currentValue},0) ?? 0;
        },
        searchProcessed() {
            this.$emit('searchProcessed');
        },
        slaPercent(a = []) {
            const array = [...a]
            const newArray = [0, array[0] + array[1], ...array.splice(2)];
            const sla = newArray.indexOf(Math.max(...newArray));
            const percent = sla > 0 ? Math.round(newArray[sla] / this.sum(newArray) * 100) : 0;
            const response = {
                sla: `${sla} ${sla > 1 ? 'days' : 'day'}`,
                percent
            }
            return response
        },
        slaText(user, type, text) {
            const slaData = this.slaPercent(user[type]);
            return slaData?.percent > 0 ? `${slaData?.percent}% of ${text} completed within ${slaData?.sla}` : ''
        }
    }
}
</script>

<style scoped>
@media only screen and (max-width: 1300px) {
    .table-border-padding {
        width: 950px;
        padding-right: 50px;
    }
}
.process-row td {
    width: 50px;
}

.details td {
    max-width: 150px;
    overflow-wrap: break-word;
}
</style>
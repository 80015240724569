<template>
  <div>
    <p class="text-gray-700">Click the button below to download the daily report</p>
    <div class="mt-8 h-80 rounded-lg border-4 border-dashed border-gray-200 p-12 flex items-center">
      <button class="w-full p-8 rounded-md bg-green-700 hover:bg-green-800 text-white hover:underline" @click="handleDownloadReporting">Download CSV</button>
    </div>
  </div>
</template>
<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const user = computed(() => store.getters.getProfile);

const handleDownloadReporting = async () => {
  const response = await httpClient.post('/bff/get-report', { requestedBy: user.value.userID });
  window.open(response.data.body.signedUrl);
}
</script>
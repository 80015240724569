<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flow-root p-4">
    <h3 v-if="!activityList?.length" class="text-lg text-center text-gray-500">No activity to show...</h3>
    <ul v-else role="list" class="-mb-8">
      <li v-for="(event, eventIdx) in timeline" :key="event.timestamp">
        <div class="relative pb-8">
          <span v-if="eventIdx !== timeline.length - 1" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span :class="[background[event.status], 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                <component :is="icons[event.status]" class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div>
                <p class="text-sm text-gray-500">
                  {{ messageCodes[event.message] }} 
                  <a v-if="event.status === 'EDITED'" class="underline text-blue-600 hover:text-blue-800" href="#" @click.prevent="event.show = !event.show">{{ !event.show ? 'show' : 'hide' }}</a>
                </p>
                <div v-if="event.comment && event.show">
                  <p class="text-sm text-gray-500" v-for="(comment, index) in getComments(event)" :key="index">{{ comment }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { AnnotationIcon, CheckIcon, XIcon, PencilIcon } from '@heroicons/vue/solid'
import { ref, computed, defineProps, watch, inject } from '@vue/runtime-core'

const props = defineProps(['activityList']);
const activityFeed = ref(props.activityList)

const formatDate = inject('formatDate')

const getComments = (event) => {
  const changes = JSON.parse(event.comment);
  let html = [];
  for (const obj of changes) {
    for (const key of Object.keys(obj)) {
      for (const key2 of Object.keys(obj[key])) {
        const textKey = key2.replace(/_/g, ' ');
        html.push(`${textKey.split(' ')
          .map(text => text.charAt(0).toUpperCase() + text.slice(1))
            .join(' ')}: ${obj[key][key2].old} to ${obj[key][key2].new}`)
      }
    }
  }
  if (html.length) {
    html.push(`By ${event.userEmail} on ${formatDate(event.timeStampUTC, 'DD/MM/YYYY HH:mm')}`)
  }
  return html;
}


watch(props, () => activityFeed.value = props.activityList)

// sort activity by timestamp (oldest event first)
const timeline = computed(() => activityFeed.value.sort((a, b) => a.timestamp - b.timestamp));

// enums for accessing correct backgrounds and icons
const background = {
  TRUE: 'bg-green-500',
  FALSE: 'bg-red-500',
  MOREINFO: 'bg-gray-500',
  ASSIGNED: 'bg-green-500',
  REJECTED: 'bg-red-500',
  EDITED: 'bg-orange-500'
}

const icons = {
  TRUE: CheckIcon,
  FALSE: XIcon,
  MOREINFO: AnnotationIcon,
  ASSIGNED: CheckIcon,
  REJECTED: XIcon,
  EDITED: PencilIcon
}

// enum for activity feed using message codes
const messageCodes = {
  S1: 'Request created',
  L1: 'Admin assigned',
  L2: 'Request complete',
  L3: 'Approved by HROps',
  L4: 'More info supplied to HROps',
  LM3: 'More info requested by HROps',
  LM4: 'More info supplied to HROps',
  R1: 'Request rejected',
  A1: 'Approved by budget holder',
  A2: 'Responded to more info request',
  A3: 'Approved by HROps',
  A4: 'Responded to more info request',
  A5: 'Resourcing have advertised role',
  A6: 'Candidate CVs collected',
  A7: 'Candidate Selected',
  A8: 'Re-approved by budget holder',
  A9: 'Responded to more info request',
  A10: 'Re-approved by HROps',
  A11: 'Responded to more info request',
  A12: 'Offer made',
  A13: 'Offer sent to candidate',
  A14: 'Documents returned',
  A15: 'Notify internal teams',
  A16: 'Request complete',
  A17: 'Offer prepared',
  A18: 'Admin owner added',
  A19: 'Leaver raised',
  A20: 'Replied to more info request',
  A21: 'Sent to HROps',
  A22: 'Sent for HROps approval',
  M1: 'More info requested',
  M2: 'Replied to more info request',
  M3: 'More info requested',
  M4: 'Replied to more info request',
  M5: 'Terms of offer confirmed',
  M6: 'Terms of offer changed',
  M7: 'Checks complete and offer sent',
  M8: 'More info requested',
  M9: 'Terms amended',
  M10: 'More info requested by H2R',
  M11: 'Replied to more info request',
  M12: 'Replied to more info request',
  M13: 'Approved by HROps',
  M14: 'More info requested from HROps',
  M15: 'Message',
  M16: 'Replied to more info request',
  O01: 'Admin has processed offer',
  O02: 'Admin has requested more info',
  O03: 'More info supplied by hiring manager',
  O04: 'More info supplied by hiring manager',
  AM01: '',
  AM02: 'More info requested by H2R',
  U1: 'Changes made'
}

</script>
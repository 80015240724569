<template>
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
      <div class="flex justify-between items-center mb-2">
        <h3 class="text-lg">Divisions</h3>
        <div class="my-2">
          <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="$emit('openModal')">Add Division</button>
        </div>
      </div>
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="division in props.divisions" :key="division.NAME">
              <td
                class="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-700 sm:pl-6 hover:underline cursor-pointer"
                @click.prevent="handleClickDivision(division)">{{ division.NAME }}
                <span v-if="!division.LKStatus" class="text-red-700 border rounded-md px-2 border-red-700 absolute right-8">Inactive</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['openModal'])

const props = defineProps({
  divisions: {
    type: Array,
    default: () => [],
  }
});

const handleClickDivision = (division) => emit('openModal', { type: 'division', configData: division });
</script>
<template>
    <form action="#" method="POST">
        <div class="shadow sm:rounded-t-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                <!-- title header -->
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Confirm Employee Details
                    </h3>
                </div>
                <!-- end title header -->
                <!-- form start -->
                <div>
                    <div class="mt-6">
                        <label 
                        for="sapNumber"
                        class="block text-sm font-medium text-gray-700"
                        >Employee SAP Number *</label>
                        <input
                            :value="props.formData?.sapNumber"
                            type="text"
                            name="sapNumber"
                            class="
                                mt-1
                                focus:ring-indigo-500
                                focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border
                                border-gray-300
                                rounded-md
                                disabled:cursor-not-allowed
                                disabled:bg-gray-200
                                disabled:cursor-not-allowed
                            "
                            :disabled="props.disableForm"
                            @input="handleInput"
                        >
                    </div>
                </div>
            </div>
            <div v-if="!disableForm && document.currentStageName === 'offer.notifyOfferSent'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                    type="submit"
                    class="
                        bg-green-500
                        border border-transparent
                        rounded-md
                        shadow-sm
                        py-2
                        px-4
                        inline-flex
                        justify-center
                        text-sm
                        font-medium
                        text-white
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                        disabled:cursor-not-allowed
                        disabled:bg-gray-200
                        disabled:text-gray-400
                    "
                    @click.prevent="submitForm"
                >
                    Save
                </button>
                <button
                    type="submit"
                    class="
                        ml-2
                        bg-green-500
                        border border-transparent
                        rounded-md
                        shadow-sm
                        py-2
                        px-4
                        inline-flex
                        justify-center
                        text-sm
                        font-medium
                        text-white
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                        disabled:cursor-not-allowed
                        disabled:bg-gray-200
                        disabled:text-gray-400
                    "
                    :disabled="!props.formData?.saved"
                    @click.prevent="handleConfirm"
                >
                    Confirm
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { defineProps, defineEmits, toRef } from 'vue';

const props = defineProps(['disableForm', 'formData', 'document']);
const emit = defineEmits(['update:formData', 'submit-form', 'complete-offer']);
const model = toRef(props, 'formData');

const handleInput = (event) => {
    const { name, value } = event.target;
    model.value[name] = value;
    emit('update:formData', { formName: 'sap', formData: model.value })
};

const submitForm = async () => {
    emit('submit-form', { name: 'sap', data: model.value });
};

const handleConfirm = async () => {
    emit('submit-form', { name: 'sap', data: model.value, completed: true });
}
</script>
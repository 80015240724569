export const baseRoutes = {
  STARTER: "view-starter",
  LEAVER: "view-leaver",
  AMEND: "view-amend",
  OFFER: "view-offer",
};

export const pathToDocument = (atr, docType) => {
  const { docId, requestedBy, currentStageName } = atr;
  if (currentStageName === "starter.notifyRecruitment") {
    return `/recruitment-team/${docId}/${requestedBy}`;
  } else if (currentStageName === "starter.notifyRecruitmentUpdated") {
    return `/recruitment-update/${docId}/${requestedBy}`;
  } else if (currentStageName === "starter.notifyRoleAdvertised") {
    return `/advert-raised/${docId}/${requestedBy}`;
  } else if (currentStageName === "starter.notifyCVsCollected") {
    return `/ready-for-selection/${docId}/${requestedBy}`;
  } else if (
    currentStageName === "starter.notifyCandidateSelected" ||
    currentStageName === "starter.complete"
  ) {
    return `/selection-complete/${docId}/${requestedBy}`;
  } else if (currentStageName === "offer.notifyMakeOffer") {
    return `/offer-hr/${docId}/${requestedBy}`;
  } else if (currentStageName === "offer.notifyPrepOffer") {
    return `/confirm/${docId}/${requestedBy}`;
  } else if (currentStageName === "offer.notifyTermsHaveChanged") {
    return `/amend-terms/${docId}/${requestedBy}`;
  } else if (currentStageName === "offer.notifyH2RAdmin") {
    return `/assign-admin-owner/${docId}/${requestedBy}`;
  } else if (currentStageName === "offer.notifyOfferSent") {
    return `/sap-confirmation/${docId}/${requestedBy}`;
  } else {
    return `/${baseRoutes[docType]}/${docId}/${requestedBy}`;
  }
};

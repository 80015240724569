<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Specialist Roles
          </h3>
        </div>
        <!-- end title header -->

        <!-- Form -->

        <!-- Highway Worker -->
        <div>
          <div class="grid grid-cols-3 gap-6">
            <div>
              <p>Is this a Highway Worker Role? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio-button-highwayWorker-yes"
                    name="highwayWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.highwayWorker === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.formData.highwayWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="highwayWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio-button-securityRole-no"
                    name="highwayWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.highwayWorker === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.formData.highwayWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="highwayWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
              <span
                v-if="v$.formData.highwayWorker.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.highwayWorker.$errors[0].$message }}
              </span>
            </div>
            <div class="text-sm text-red-500" v-if="formData.highwayWorker === 'Yes'">
              <p class="mb-1">The following wording will appear on the Offer Contract:</p>
              <p>In addition to your normal hours of work you are required to form part of your team’s call-out rota to deal with emergencies out of hours.  You are required to attend such call-outs as and when requested by the Company.</p>
            </div>
          </div>
          <!-- End Highway Worker -->

          <!-- Mobile Worker -->
          <div
            class="
              grid grid-cols-3
              gap-6
              sm:border-t sm:border-gray-200 sm:pt-5
              mt-4
            "
          >
            <div>
              <p>Is this a Mobile Worker Role? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio-button-mobileWorker-yes"
                    name="mobileWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.mobileWorker === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.formData.mobileWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="mobileWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio-button-securityRole-no"
                    name="mobileWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.mobileWorker === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.formData.mobileWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="mobileWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
              <span
                v-if="v$.formData.mobileWorker.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.mobileWorker.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm text-red-500">
              *If yes, the word mobile should be included in the job title.
            </p>
          </div>
          <!-- End Mobile Worker -->

          <!-- Hybrid Worker -->
          <div
            class="
              grid grid-cols-3
              gap-6
              sm:border-t sm:border-gray-200 sm:pt-5
              mt-4
            "
          >
            <div>
              <p>Is this a Hybrid Worker Role? *</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="Yes"
                    id="radio-button-hybridWorker-yes"
                    name="hybridWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.hybridWorker === 'Yes'"
                    @change="handleRadioChange"
                    @blur="v$.formData.hybridWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="hybridWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    value="No"
                    id="radio-button-securityRole-no"
                    name="hybridWorker"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    :checked="formData.hybridWorker === 'No'"
                    @change="handleRadioChange"
                    @blur="v$.formData.hybridWorker.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="hybridWorker"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
              <span
                v-if="v$.formData.hybridWorker.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.hybridWorker.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm text-red-500">
              *Please check with your HR Manager whether Hybrid Working applies;
              if so, this will be noted in the job offer
            </p>
          </div>
          <!-- End Hybrid Worker -->

          <!-- Collective Bargaining -->
          <div
            class="
              grid grid-cols-3
              gap-6
              sm:border-t sm:border-gray-200 sm:pt-5
              mt-4
            "
          >
            <div class="col-span-6">
              <p class="block text-sm font-medium text-gray-700">
                Will the employee be subject to Collective Bargaining, if so
                please provide the name of the Collective Bargaining Arrangement
                as this will be included in their Contract of Employment: *
              </p>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="Yes"
                      id="radio-button-collectiveBargaining-yes"
                      name="collectiveBargaining"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.collectiveBargaining === 'Yes'"
                      @change="handleRadioChange"
                      @blur="v$.formData.collectiveBargaining.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="collectiveBargaining"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="No"
                      id="radio-button-collectiveBargaining-no"
                      name="collectiveBargaining"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.collectiveBargaining === 'No'"
                      @change="handleRadioChange"
                      @blur="v$.formData.collectiveBargaining.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="collectiveBargaining-no"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
                <span
                  v-if="v$.formData.collectiveBargaining.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.formData.collectiveBargaining.$errors[0].$message }}
                </span>
              </div>
              <!-- End Collective Bargaining -->

              <!-- Start collectiveBargainingText -->
              <div v-if="formData.collectiveBargaining === 'Yes'">
              <label class="block text-sm font-medium text-gray-700 mt-4" for="collectiveBargaining-input">Name of Collective Bargaining Arrangement *</label>
                <input
                  :value="formData.collectiveBargainingText"
                  type="text"
                  name="collectiveBargainingText"
                  id="collectiveBargaining-input"
                  autocomplete="email"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }"
                  :disabled="showApprovalSteps"
                  @input="handleInput"
                />
                <span
                  v-if="v$.formData.collectiveBargainingText.$errors.length"
                  class="text-red-500"
                >
                  *
                  {{ v$.formData.collectiveBargainingText.$errors[0].$message }}
                </span>
              </div>
              <!-- End collectiveBargainingText -->
            </div>
          </div>
          <!-- End Form -->
        </div>
      </div>
    </div>

    <!-- Save button -->
    <div
      v-if="!showApprovalSteps"
      class="px-4 py-3 bg-gray-50 text-right sm:px-6"
    >
      <button
        class="
          mr-2
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click.prevent="handleResetForm"
      >
        Reset Form
      </button>
      <button
        :disabled="v$.$invalid"
        type="submit"
        class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        "
        @click="handleSave"
      >
        Next
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";

export default {
  name: "formTen",
  props: ["formData", "showApprovalSteps"],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData
    };
  },
  validations() {
    return {
      formData: {
        highwayWorker: {
          required,
        },
        mobileWorker: {
          required,
        },
        hybridWorker: {
          required,
        },
        collectiveBargaining: {
          required,
        },
        collectiveBargainingText: {
          requiredIf: helpers.withMessage(
            "Please provide the name of the Collective Bargaining Arrangement.",
            requiredIf(this.formData.collectiveBargaining === "Yes")
          ),
        },
      }
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formTen",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit('update:reset', 'formTen');
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTen', formData: this.model});
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTen', formData: this.model });
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
};
</script>

<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Candidate Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
          <div class="flex items-start flex-col my-4">
            <p>Does the candidate have any direct reports? *</p>
            <span v-if="v$.formData.directReports.$error" class="text-red-500">
              * {{ v$.formData.directReports.$errors[0].$message }}
            </span>
            <div class="h-5 mt-4 flex items-center">
              <div>
                <input
                  value="Yes"
                  id="radio_button_direct_reports_Yes"
                  name="directReports"
                  type="radio"
                  class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  :checked="formData?.directReports === 'Yes'"
                  @change="handleInput"
                  @blur="v$.formData.directReports.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="directReports"
                  class="font-medium text-gray-700 cursor-pointer"
                  >Yes</label
                >
              </div>
            </div>
            <div class="h-5 mt-4 flex items-center">
              <div>
                <input
                  value="No"
                  id="radio_button_direct_reports_No"
                  name="directReports"
                  type="radio"
                  class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  :checked="formData?.directReports === 'No'"
                  @change="handleInput"
                  @blur="v$.formData.directReports.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="directReports"
                  class="font-medium text-gray-700 cursor-pointer"
                  >No</label
                >
              </div>
            </div>
          </div>
          <div v-if="formData?.directReports === 'Yes'" class="mt-6">
            <label 
              for="directReportsInfo"
              class="block text-sm font-medium text-gray-700"
            >Please specify *</label>
            <input
              :value="formData?.directReportsInfo"
              type="text"
              name="directReportsInfo"
              class="
                mt-1
                focus:ring-indigo-500
                focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border
                border-gray-300
                rounded-md
                disabled:cursor-not-allowed
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              "
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.directReportsInfo.$touch()"
            >
            <span v-if="v$.formData.directReportsInfo.$error" class="text-red-500">
              * {{ v$.formData.directReportsInfo.$errors[0].$message }}
            </span>
          </div>
          <div class="flex items-start flex-col my-4">
            <p>Are you offering this position to an internal candidate? *</p>
            <span v-if="v$.formData.internal_candidate.$error" class="text-red-500">
              * {{ v$.formData.internal_candidate.$errors[0].$message }}
            </span>
            <div class="h-5 mt-4 flex items-center">
              <div>
                <input
                  value="Yes"
                  id="radio_button_internal_candidate_Yes"
                  name="internal_candidate"
                  type="radio"
                  class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  :checked="formData?.internal_candidate === 'Yes'"
                  @change="handleInput"
                  @blur="v$.formData.internal_candidate.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="internal_candidate"
                  class="font-medium text-gray-700 cursor-pointer"
                  >Yes</label
                >
              </div>
            </div>
            <div class="h-5 mt-4 flex items-center">
              <div>
                <input
                  value="No"
                  id="radio_button_internal_candidate_No"
                  name="internal_candidate"
                  type="radio"
                  class="
                    focus:ring-indigo-500
                    h-4
                    w-4
                    text-indigo-600
                    border-gray-300
                    rounded
                    cursor-pointer
                    disabled:bg-gray-200
                    disabled:border-none
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  :checked="formData?.internal_candidate === 'No'"
                  @change="handleInput"
                  @blur="v$.formData.internal_candidate.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="internal_candidate"
                  class="font-medium text-gray-700 cursor-pointer"
                  >No</label
                >
              </div>
            </div>
          </div>
          <div v-if="formData?.internal_candidate === 'No'">
            <div class="flex items-start flex-col my-4">
              <p>Is this employee transferring from another EQUANS company? *</p>
              <span v-if="v$.formData.transferring.$error" class="text-red-500">
                * {{ v$.formData.transferring.$errors[0].$message }}
              </span>
              <div class="h-5 mt-4 flex items-center">
                <div>
                  <input
                    value="Yes"
                    id="radio_button_transferring_Yes"
                    name="transferring"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm"
                    :checked="formData?.transferring === 'Yes'"
                    @change="handleInput"
                    @blur="v$.formData.transferring.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="transferring"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="h-5 mt-4 flex items-center">
                <div>
                  <input
                    value="No"
                    id="radio_button_transferrings_No"
                    name="transferring"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm"
                    :checked="formData?.transferring === 'No'"
                    @change="handleInput"
                    @blur="v$.formData.transferring.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="tempTermProcess"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-start flex-col my-4">
              <p>Is the starter as a result of the Temp to Perm Process(i.e. were they already working here as an Agency Worker?) *</p>
              <span v-if="v$.formData.tempTermProcess.$error" class="text-red-500">
                * {{ v$.formData.tempTermProcess.$errors[0].$message }}
              </span>
              <div class="h-5 mt-4 flex items-center">
                <div>
                  <input
                    value="Yes"
                    id="radio_button_temp_perm_process_Yes"
                    name="tempTermProcess"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm"
                    :checked="formData?.tempTermProcess === 'Yes'"
                    @change="handleInput"
                    @blur="v$.formData.tempTermProcess.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="tempTermProcess"
                    class="font-medium text-gray-700 cursor-pointer"
                    >Yes</label
                  >
                </div>
              </div>
              <div class="h-5 mt-4 flex items-center">
                <div>
                  <input
                    value="No"
                    id="radio_button_temp_perm_process_No"
                    name="tempTermProcess"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:border-none
                      disabled:cursor-not-allowed
                    "
                    :disabled="disableForm"
                    :checked="formData?.tempTermProcess === 'No'"
                    @change="handleInput"
                    @blur="v$.formData.tempTermProcess.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="tempTermProcess"
                    class="font-medium text-gray-700 cursor-pointer"
                    >No</label
                  >
                </div>
              </div>
            </div>
            <div class="mt-6">
              <label 
                for="d59Number"
                class="block text-sm font-medium text-gray-700"
              >If known / applicable please specify the employees GID/D59 number</label>
              <input
                :value="formData?.d59Number"
                type="text"
                name="d59Number"
                class="
                  mt-1
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border
                  border-gray-300
                  rounded-md
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                "
                :disabled="disableForm"
                @input="handleInput"
              >
            </div>
            <p class="text-sm text-red-700">
              <b>Please note:</b> the employees name must be entered as shown on
              their right to work documentation. Any offers submitted where the name
              differs will be rejected.
            </p>

            <div class="grid grid-cols-3 gap-6">
              <div class="col-span-6">
                <label for="title" class="block text-sm font-medium text-gray-70"
                  >Title *</label
                >
                <select
                  :value="formData.title"
                  id="title"
                  name="title"
                  autocomplete="title"
                  class="
                    mt-1
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  @input="handleInput"
                  @blur="v$.formData.title.$touch()"
                >
                  <option>Mr</option>
                  <option>Mrs</option>
                  <option>Miss</option>
                  <option>Ms</option>
                  <option>Dr</option>
                  <option>Mx</option>
                </select>
                <span v-if="v$.formData.title.$error" class="text-red-500">
                  * {{ v$.formData.title.$errors[0].$message }}
                </span>
              </div>
            </div>
              <div class="mt-6">
                <label 
                  for="firstName"
                  class="block text-sm font-medium text-gray-700"
                >First Name: *</label>
                <input
                  :value="formData?.firstName"
                  type="text"
                  name="firstName"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.firstName.$touch()"
                >
                <span v-if="v$.formData.firstName.$error" class="text-red-500">
                  * {{ v$.formData.firstName.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="knownAs"
                  class="block text-sm font-medium text-gray-700"
                >Known as:</label>
                <input
                  :value="formData?.knownAs"
                  type="text"
                  name="knownAs"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                >
              </div>
              <div class="mt-6">
                <label 
                  for="middleName"
                  class="block text-sm font-medium text-gray-700"
                >Middle Name:</label>
                <input
                  :value="formData?.middleName"
                  type="text"
                  name="middleName"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                >
              </div>
              <div class="mt-6">
                <label 
                  for="surname"
                  class="block text-sm font-medium text-gray-700"
                >Surname *</label>
                <input
                  :value="formData?.surname"
                  type="text"
                  name="surname"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.surname.$touch()"
                >
                <span v-if="v$.formData.surname.$error" class="text-red-500">
                  * {{ v$.formData.surname.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="firstLine"
                  class="block text-sm font-medium text-gray-700"
                >Home Address - 1st Line *</label>
                <input
                  :value="formData?.firstLine"
                  type="text"
                  name="firstLine"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.firstLine.$touch()"
                >
                <span v-if="v$.formData.firstLine.$error" class="text-red-500">
                  * {{ v$.formData.firstLine.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="secondLine"
                  class="block text-sm font-medium text-gray-700"
                >Home Address - 2nd Line</label>
                <input
                  :value="formData?.secondLine"
                  type="text"
                  name="secondLine"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                >
              </div>
              <div class="mt-6">
                <label 
                  for="town"
                  class="block text-sm font-medium text-gray-700"
                >Home Address - Town</label>
                <input
                  :value="formData?.town"
                  type="text"
                  name="town"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                >
              </div>
              <div class="mt-6">
                <label 
                  for="county"
                  class="block text-sm font-medium text-gray-700"
                >Home Address - County *</label>
                <input
                  :value="formData?.county"
                  type="text"
                  name="county"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.county.$touch()"
                >
                <span v-if="v$.formData.county.$error" class="text-red-500">
                  * {{ v$.formData.county.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="postcode"
                  class="block text-sm font-medium text-gray-700"
                >Home Address - Postcode *</label>
                <input
                  :value="formData?.postcode"
                  type="text"
                  name="postcode"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.postcode.$touch()"
                >
                <span v-if="v$.formData.postcode.$error" class="text-red-500">
                  * {{ v$.formData.postcode.$errors[0].$message }}
                </span>
              </div>
              <p class="text-sm">* Please ensure the postcode is accurate and the correct format
                  has been used. You can access the
                  <a href="https://www.royalmail.com/find-a-postcode" target="_blank" class="text-blue-600 hover:underline hover:font-bold">Royal Mail Postcode Finder</a>.
              </p>
              <div class="mt-6">
                <label 
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >Email Address *</label>
                <input
                  :value="formData?.email"
                  type="text"
                  name="email"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.email.$touch()"
                >
                <span v-if="v$.formData.email.$error" class="text-red-500">
                  * {{ v$.formData.email.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="homeTele"
                  class="block text-sm font-medium text-gray-700"
                >Home Telephone Number *</label>
                <input
                  :value="formData?.homeTele"
                  type="text"
                  name="homeTele"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.homeTele.$touch()"
                >
                <span v-if="v$.formData.homeTele.$error" class="text-red-500">
                  * {{ v$.formData.homeTele.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="mobileTele"
                  class="block text-sm font-medium text-gray-700"
                >Mobile Telephone Number *</label>
                <input
                  :value="formData?.mobileTele"
                  type="text"
                  name="mobileTele"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.mobileTele.$touch()"
                >
                <span v-if="v$.formData.mobileTele.$error" class="text-red-500">
                  * {{ v$.formData.mobileTele.$errors[0].$message }}
                </span>
              </div>
              <!-- End Form -->
            </div>

            <div v-if="formData?.internal_candidate === 'Yes'">
              <div class="mt-6">
                <label 
                  for="candidateEmployeeNumber"
                  class="block text-sm font-medium text-gray-700"
                >What is the internal candidates employee number? *</label>
                <input
                  :value="formData?.candidateEmployeeNumber"
                  type="text"
                  name="candidateEmployeeNumber"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.candidateEmployeeNumber.$touch()"
                >
                <span v-if="v$.formData.candidateEmployeeNumber.$error" class="text-red-500">
                  * {{ v$.formData.candidateEmployeeNumber.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="internalCandidateName"
                  class="block text-sm font-medium text-gray-700"
                >What is the internal candidates name? *</label>
                <input
                  :value="formData?.internalCandidateName"
                  type="text"
                  name="internalCandidateName"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.internalCandidateName.$touch()"
                >
                <span v-if="v$.formData.internalCandidateName.$error" class="text-red-500">
                  * {{ v$.formData.internalCandidateName.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-6">
                <label 
                  for="candidateCurrentSalaryRate"
                  class="block text-sm font-medium text-gray-700"
                >What is the internal candidates current salary or hourly rate? *</label>
                <input
                  :value="formData?.candidateCurrentSalaryRate"
                  type="number"
                  min="0"
                  name="candidateCurrentSalaryRate"
                  class="
                    mt-1
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    block
                    w-full
                    shadow-sm
                    sm:text-sm
                    border
                    border-gray-300
                    rounded-md
                    disabled:cursor-not-allowed
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.formData.candidateCurrentSalaryRate.$touch()"
                >
                <span v-if="v$.formData.candidateCurrentSalaryRate.$error" class="text-red-500">
                  * {{ v$.formData.candidateCurrentSalaryRate.$errors[0].$message }}
                </span>
              </div>
              <div class="flex items-start flex-col my-4">
                <p>Is this in addition to the internal candidates existing role *</p>
                <span v-if="v$.formData.additionToExistingRole.$error" class="text-red-500">
                  * {{ v$.formData.additionToExistingRole.$errors[0].$message }}
                </span>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="Yes"
                      id="radio_button_additionToExistingRole_Yes"
                      name="additionToExistingRole"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.additionToExistingRole === 'Yes'"
                      @change="handleInput"
                      @blur="v$.formData.additionToExistingRole.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="additionToExistingRole"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="No"
                      id="radio_button_additionToExistingRole_No"
                      name="additionToExistingRole"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.additionToExistingRole === 'No'"
                      @change="handleInput"
                      @blur="v$.formData.additionToExistingRole.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="additionToExistingRole"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
              <div class="flex items-start flex-col my-4">
                <p>Is this an internal promotion? *</p>
                <span v-if="v$.formData.promotion.$error" class="text-red-500">
                  * {{ v$.formData.promotion.$errors[0].$message }}
                </span>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="Yes"
                      id="radio_button_promotion_Yes"
                      name="promotion"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.promotion === 'Yes'"
                      @change="handleInput"
                      @blur="v$.formData.promotion.$touch()"
                    />
                    
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="promotion"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="No"
                      id="radio_button_promotion_No"
                      name="promotion"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.promotion === 'No'"
                      @change="handleInput"
                      @blur="v$.formData.promotion.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="promotion"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
              <div class="flex items-start flex-col my-4">
                <p>Is this a secondment? *</p>
                <span v-if="v$.formData.secondment.$error" class="text-red-500">
                  * {{ v$.formData.secondment.$errors[0].$message }}
                </span>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="Yes"
                      id="radio_button_secondment_Yes"
                      name="secondment"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.secondment === 'Yes'"
                      @change="handleInput"
                      @blur="v$.formData.secondment.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="secondment"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="h-5 mt-4 flex items-center">
                  <div>
                    <input
                      value="No"
                      id="radio_button_secondment_No"
                      name="secondment"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                        disabled:bg-gray-200
                        disabled:border-none
                        disabled:cursor-not-allowed
                      "
                      :disabled="disableForm"
                      :checked="formData?.secondment === 'No'"
                      @change="handleInput"
                      @blur="v$.formData.secondment.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="secondment"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8">
              <h3 class="text-lg my-2">Right To Work</h3>
              <p class="my-2 text-sm font-medium text-gray-700">New starters to the business:</p>
              <p class="my-2 text-sm font-medium text-gray-700">
                Recruitment is a legal process so all relevant documentation must be completed and correct.
                If not a contract cannot be issued and the new starter will not be paid.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">
                I confirm that the following attachments have been included:
                <ul class="list-disc list-inside marker:text-black">
                  <li class="text-sm font-medium text-gray-700">Verified and signed copy of Right to Work in the UK or where applicable proof of an online check via the Home Office online service</li>
                  <li class="text-sm font-medium text-gray-700">Proof of their identity (usually a passport) to support Home Office online check</li>
                  <li class="text-sm font-medium text-gray-700">Candidate's CV</li>
                  <li class="text-sm font-medium text-gray-700">Job Description</li>
                </ul>
              </p>
              <!-- TODO: Add policy link-->
              <p class="my-2 text-sm font-medium text-gray-700">Policy (LINK)</p>
              <p class="my-2 text-sm font-medium text-gray-700">You must have had sight of the original Right to Work document. You should take a copy and write on each copy: “I certify that this is a true
                copy of the original”, then sign, print your name, note your job title, and date the copy.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">
                For EEA Nationals you will need to check proof of their immigration status – either (i) proof of their EEA Settled status – this has to be done 
                via the Home Office online service through the provision of a share code; or (ii) if they meet the new requirements of the immigration system.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">Please be sure to check the Right to Work Policy to ensure you have provided the correct documentation. The Offer will be rejected if the
                incorrect documentation is provided.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">Tips to avoid Right to Work rejections:</p>
              <p class="my-2 text-sm font-medium text-gray-700">Right to work evidence must be verified with the certification statement above, signature, printed name, and date.</p>
              <p class="my-2 text-sm font-medium text-gray-700">
                Where an employee has submitted evidence of their National Insurance Number and their Birth Certificate as their Right to Work evidence, 
                they must submit their full birth certificate showing at least one parent’s details.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">You must also provide proof of the National Insurance Number or an official document showing the National Insurance Number</p>
              <p class="my-2 text-sm font-medium text-gray-700">Where an employee has provided a Biometric Card you should take copies of the front and back of the Biometric Card (Biometric Residence Permit).</p>
              <p class="my-2 text-sm font-medium text-gray-700">
                The names on all documentation must match, e.g. if passport or biometric card is in their maiden name, then the employee must submit their marriage 
                certificate to show the name change.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">
                Evidence of an Home Office online check being completed (where applicable). Watch the Right to Work presentation here. Internal appointments: 
                Right to work documentation will already be on file for the employee, therefore only the new job description is required. Once ready, please scan and 
                attach the copies below.
              </p>
              <p class="my-2 text-sm font-medium text-gray-700">Internal appointments</p>
              <p class="my-2 text-sm font-medium text-gray-700">Right to work documentation will already be on file for the employee, therefore only the new job description is required.</p>
            </div>
            <p class="my-2 text-sm font-medium text-gray-700">Please upload any right to work documents here:</p>
            <FileUploader
              name="right-to-work"
              :disabled="disableForm"
              :files="formData?.files || []"
              :documentShortId="formData?.shortId || shortId"
              @updateFiles="handleUpdateFiles"
              :uploading="uploading"
              @fileUploading="uploading = $event"
            />
          </div>
      </div>
      <div v-if="editable || (!disableForm && document.currentStageName === 'offer.notifyPrepOffer')" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          :disabled="(formData.internal_candidate === 'No' && (!formData?.files || !formData?.files?.length)) || v$.$invalid || uploading"
          @click.prevent="submitForm"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { getStorage } from "@/storageSave";

export default {
  props: ['formData', 'disableForm', 'document', 'editable'],
  emits: ['update:formData', 'submit-form'],
  components: { FileUploader },
  data() {
    return {
      uploading: false,
      model: this.formData,
      v$: useVuelidate()
    }
  },
  computed: {
    shortId() {
      return getStorage('atrShortId')
    }
  },
  methods: {
    handleInput(event) {
      const { name, value } = event.target;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'candidate', formData: this.model })
    },
    handleUpdateFiles(data) {
      this.model.files = data.model;
      this.$emit('update:formData', { formName: 'candidate', formData: this.model })
    },
    submitForm() {
      this.$emit('submit-form', { name: 'candidate', formData: this.model });
    }
  },
  watch: {
    'formData.internal_candidate' (newVal) {
      if (newVal === 'Yes') {
        this.model.transferring = undefined;
        this.model.tempTermProcess = undefined;
        this.model.d59Number = undefined;
        this.model.title = undefined;
        this.model.firstName = undefined;
        this.model.knownAs = undefined;
        this.model.middleName = undefined;
        this.model.surname = undefined;
        this.model.firstLine = undefined;
        this.model.secondLine = undefined;
        this.model.town = undefined;
        this.model.county = undefined;
        this.model.postcode = undefined;
        this.model.email = undefined;
        this.model.homeTele = undefined;
        this.model.mobileTele = undefined;
      }
      if (newVal === 'No') {
        this.model.candidateEmployeeNumber = undefined;
        this.model.internalCandidateName = undefined;
        this.model.candidateCurrentSalaryRate = undefined;
        this.model.additionToExistingRole = undefined;
        this.model.promotion = undefined;
        this.model.secondment = undefined;
      }
    }
  },
  validations() {
    return {
      formData: {
        directReports: { required },
        directReportsInfo: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.directReports === 'Yes'))
        },
        internal_candidate: { required },
        transferring: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        tempTermProcess: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        title: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        firstName: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        surname: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        firstLine: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        county: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        postcode: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        email: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        homeTele: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        mobileTele: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'No'))
        },
        candidateEmployeeNumber: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        },
        internalCandidateName: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        },
        candidateCurrentSalaryRate: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        },
        additionToExistingRole: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        },
        promotion: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        },
        secondment: {
          requiredIf: helpers.withMessage('Value is required', requiredIf(this.formData?.internal_candidate === 'Yes'))
        }
      }
    }
  }
}
</script>

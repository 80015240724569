<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Second Line Approval
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div v-html="sla_approval"></div>
            <p class="text-sm">*Any comments provided by the Hiring Manager in relation to the approval will show in the box above.</p>
        <!-- End Form -->
      </div>
       <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="submitForm"
        >
          Reset Form
        </button>
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { Radio } from "@/form";
import { ref } from "@vue/reactivity";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";


const sla_approval = Radio(
  "Are you happy to approve this Offer? *",
  "sla_approval"
);

const formData = ref({
  textarea: "",
  input: "",
  radio: "",
});

const rules = ref({
  textarea: { required },
  input: { required },
  radio: { required },
});

const errors = ref({
  textarea: "",
  radio: "",
  input: "",
});

const v$ = useVuelidate(rules.value, formData.value);

const submitForm = () => {
  // Get input elements
  const roleInput = document.getElementById("role");
  const amountInput = document.getElementById("amount");
  const typeInput = document.getElementById("type");

  // Set formData with input values from fields
  formData.value.role = roleInput.value;
  formData.value.amount = amountInput.value;
  formData.value.type = typeInput.value;

  Object.keys(rules.value).forEach((field) => {
    // Set each value as dirty in validation object
    v$.value[field].$touch();
    // Set error in the errors object if field is invalid
    if (v$.value[field].$error)
      errors.value[field] = v$.value[field].$errors[0];
  });

  const formIsValid = !v$.$invalid;

  if (formIsValid) {
    // save to vuex store and call endpoint here
  }
};
</script>

<style>
</style>
<template>

  <div class="p-12">

    <!-- Page title-->
    <div class="mb-4">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Assign Admin
      </h3>
    </div>
    <!-- End of page title-->

    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="p-8">
          <div v-if="!user.userId">
            <p >Select the admin you would like to assign this leaver request to:</p>
            <user-search group="H2R" @updated-user="handleUser($event)"></user-search>
          </div>
          <div v-else class="border border-gray-300 rounded-lg p-6">
            <div class="flex justify-end">
              <XIcon class="h-5 w-5 text-gray-400 hover:text-gray-800 cursor-pointer" @click="handleClearUser" />
            </div>
            <div class="mt-6">
              <label 
                for="hiringManagerName"
                class="block text-sm font-medium text-gray-700"
              >Admin name</label>
              <input
                :value="user.name"
                type="text"
                name="hiringManagerName"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                disabled
              >
            </div>
            <div class="col-span-6 mt-4">
              <label
                for="hiringManagerEmail"
                class="block text-sm font-medium text-gray-700"
              >Admin email address:</label>
              <input
                :value="user.email"
                type="email"
                name="hiringManagerEmail"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:cursor-not-allowed"
                disabled
              >
              <div class="mt-4 flex justify-end">
                <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click="confirmAssignAdmin">Assign Admin</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>
<script>
import UserSearch from '@/components/userDropdownSearch.vue'
import { XIcon } from '@heroicons/vue/outline'
import httpClient from '@/httpClient'

export default {
  data() {
    return {
      user: {
        userId: '',
        name: '',
        email: ''
      }
    }
  },
  components: {
    UserSearch,
    XIcon
  },
  methods: {
    handleUser(event) {
      this.user.userId = event.userID;
      this.user.name = event.Title;
      this.user.email = event.email.trim();
    },
    async confirmAssignAdmin() {
      try {
        this.$store.dispatch('setIsLoading', true);
        await httpClient.post('/bff/assign-admin', {
          id: this.$route.params.docId,
          requestedBy: this.$route.params.requestedBy,
          docOwner: this.$route.params.requestedBy,
          userId: this.user.userId,
          userEmail: this.user.email
        });
        this.$store.dispatch('setIsLoading', false);
        this.$router.go(-2);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Successfully assigned leaver admin.'
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'Could not assign admin.'
        });
      }
    },
    handleClearUser() {
      this.user = {
        userId: '',
        name: '',
        email: ''
      }
    }
  }
}
</script>


<template>
  <div>
    <div v-if="!readOnly">
      <p class="italic text-gray-700 text-sm">Please enter the first 3 letters of the users surname:</p>
      <div>
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <UsersIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input type="userSearch" name="userSearch" id="userSearch" autocomplete="off" v-model="userSearch"
              placeholder="Search for a user" @blur="$emit('blur')" @keydown.enter.stop.prevent="fetchRequestedUsers"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border border-gray-300" />
          </div>
          <button type="button" @click="fetchRequestedUsers"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-200"
            :disabled="userSearch.length <= 2">
            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>Search</span>
          </button>
        </div>
      </div>
      <div v-if="users">
        <div v-if="notArchived.length">
          <Listbox as="div" v-model="selected">
            <div class="mt-4 relative">
              <ListboxButton
                class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <span class="block truncate">{{ selected?.Title }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  <ListboxOption as="template" v-for="user in notArchived" :key="user.id" :value="user"
                    v-slot="{ active, selected }">
                    <li
                      :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                        {{ user.Title }}
                      </span>

                      <span v-if="selected"
                        :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
              <div class="flex justify-end">
                <button type="button" class="
                    mt-4
                    mr-2
                    bg-green-500
                    border border-transparent
                    rounded-md
                    shadow-sm
                    py-2
                    px-4
                    inline-flex
                    justify-center
                    text-sm
                    font-medium
                    text-white
                    hover:bg-green-800
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500" @click.prevent='selectedUser'>Select</button>
              </div>

            </div>
          </Listbox>
        </div>
        <div class="text-center mt-2 text-gray-700" v-else>
          No results found...
        </div>
      </div>
      <span v-if="errors?.length" class="text-red-500">
        * {{ errors[0].$message }}
      </span>
    </div>
    <div v-else class="mt-1 sm:mt-0 sm:col-span-2">
      <!-- Read only mode -->
      <div class="max-w-lg flex rounded-md">
        <input type="email" name="approval" id="approval" :value="selectedEmail" disabled class="
            flex-1
            block
            w-full
            focus:ring-indigo-500 focus:border-indigo-500
            min-w-0
            rounded-none rounded-r-md
            sm:text-sm
            border-gray-300
            disabled:cursor-not-allowed disabled:bg-gray-200
          " />
        <button class="text-gray-400 hover:text-gray-800 ml-2" @click.prevent="clearSelection">
          <XIcon class="h-5 w-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon, UsersIcon, SearchIcon } from '@heroicons/vue/solid'
import httpClient from '@/httpClient';
import { XIcon } from "@heroicons/vue/solid";

export default {
  name: 'UserSearchLookup',
  emits: ['blur', 'update:selectedEmail', 'updatedUser'],
  props: {
    group: {
      default: "HM"
    },
    readOnly: {
      default: false
    },
    errors: {
      default: []
    },
    selectedEmail: {
      default: null
    },
    sla: {
      default: null
    }
  },
  components: {
    XIcon,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    UsersIcon,
    SearchIcon
  },
  data() {
    return {
      users: null,
      userSearch: '',
      selected: null,
      notArchived: [],
    }
  },
  methods: {
    fetchRequestedUsers() {
      this.notArchived = [];
      this.$store.dispatch('setIsLoading', true);
      const payload = {
        "group": this.group,
        "searchText": this.userSearch
      }
      httpClient.post('bff/query-users', payload).then(response => {
        this.users = response.data.body.Items
        for (let i = 0; i < this.users.length; i++) {  // create array of unarchived users
          if (!this.users[i].archived) {
            this.notArchived.push(this.users[i])
          }
        }
        this.selected = this.notArchived[0]
      });
      this.$store.dispatch('setIsLoading', false);
    },
    selectedUser() {
      const updatedEmail = (this.selected?.email?.trim() ?? null)
      this.$emit('update:selectedEmail', updatedEmail)
      this.$emit('updatedUser', this.selected)
      this.resetForm();
    },
    clearSelection() {
      this.$emit('update:selectedEmail', null)
    },
    resetForm() {
      this.users = null;
      this.selected = null;
      this.userSearch = '';
    }
  },
};
</script>
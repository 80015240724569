<template>
  <div>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Change Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <!-- Effective date -->
        <div class="col-span-6">
          <label for="effectiveDate" class="block text-sm font-medium text-gray-700">Effective date: *</label>
          <input :value="formData.effectiveDate" :disabled="disableForm" type="date" name="effectiveDate"
            id="effectiveDate-input" autocomplete="name"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
            @input="handleInput" @blur="v$.formData.effectiveDate.$touch()">
          <span v-if="v$.formData.effectiveDate.$error" class="text-red-500">
            * {{ v$.formData.effectiveDate.$errors[0].$message }}
          </span>
        </div>
        <!-- Effective date end -->

        <!-- Type of amendment -->
        <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              Type of amendment: *
            </legend>
            <span v-if="v$.formData.amendmentType.$error" class="text-red-500">
              * {{ v$.formData.amendmentType.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Allowance Change" id="allowance-change-yes" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Allowance Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Allowance Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Business Structure Change" id="business_structure-no" name="amendmentType"
                      type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Business Structure Change')"
                      :disabled="disableForm" @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Business Structure Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Contract change" id="contract-change-no" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Contract change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Contract change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Entity Change" id="entity-change-no" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Entity Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Entity / Cost Centre Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Hours/Work Pattern Change" id="pattern-change-no" name="amendmentType" type="checkbox"
                      class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Hours/Work Pattern Change')"
                      :disabled="disableForm" @change="handleCheckboxChange" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Hours/Work Pattern Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Job Level Change" id="level-change-no" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Job Level Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Job Level Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Line Manager Change" id="manager-change-no" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Line Manager Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Line Manager Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Location Change" id="location-change-no" name="amendmentType" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Location Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Location Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Role & Benefits Change" id="benifits-change-no" name="amendmentType" type="checkbox"
                      class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Role & Benefits Change')" :disabled="disableForm"
                      @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="benifits-change-no" class="font-medium text-gray-700">Role & Benefits Change</label>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="Salary/Hourly Rate Change" id="salary-change-no" name="amendmentType" type="checkbox"
                      class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData.amendmentType?.includes('Salary/Hourly Rate Change')"
                      :disabled="disableForm" @change="handleCheckboxChange" @blur="v$.formData.amendmentType.$touch()" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="amendmentType" class="font-medium text-gray-700">Salary/Hourly Rate Change</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <!-- Type of amendment end -->

        <!-- Job Level Code -->
        <div v-if="formData?.amendmentType?.includes('Job Level Change')" class="col-span-6 mt-4">
          <h3 class="text-lg my-4">Job Level Change</h3>
          <label for="newContractType" class="block text-sm font-medium text-gray-70"
          >New Contract: *</label
          >
          <select
              :value="formData?.newContractType"
              id="select-contract-type"
              name="newContractType"
              class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:text-gray-600
                  disabled:cursor-not-allowed
              "
              :disabled="disableForm"
              @change="handleInput"
              @blur="v$.formData.newContractType ? v$.formData.newContractType.$touch() : null"
          >
            <template v-if="disableForm">
              <option v-for="contract in configItems.CONTRACTS" :key="contract.ContractType">
                  {{ contract.ContractType }}
              </option>
            </template>
            <template v-else>
              <option v-for="contract in activeContractTypes" :key="contract.ContractType">
                  {{ contract.ContractType }}
              </option>
            </template>
          </select>
          <span v-if="v$.formData.newContractType?.$error" class="text-red-500">
            * {{ v$.formData.newContractType?.$errors[0]?.$message }}
          </span>
          <label for="newJobLevel" class="block text-sm font-medium text-gray-700 mt-4">New job level: *</label>
          <select
              :value="formData?.newJobLevel"
              id="newJobLevel" name="newJobLevel"
              class="
              mt-1
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border-gray-300
              rounded-md
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            " @change="handleInput" 
              @blur="v$.formData.newJobLevel.$touch()"
              :disabled="disableForm"
          >
            <template v-if="disableForm">
              <option v-for="contract in currentContractType?.Items" :key="contract.ID">
                  {{ contract.ContractName }}
              </option>
            </template>
            <template v-else>
              <option v-for="contract in activeJobLevels" :key="contract.ID">
                  {{ contract.ContractName }}
              </option>
            </template>
          </select>
          <span v-if="v$.formData.newJobLevel.$error" class="text-red-500">
            * {{ v$.formData.newJobLevel.$errors[0].$message }}
          </span>
        </div>
        <!-- Job Level end -->

        <div v-if="formData?.amendmentType?.includes('Entity Change')">
          <h3 class="text-lg my-4">Entity / Cost Centre Change</h3>
          <!-- Entity -->
          <div class="col-span-6">
            <label for="entity" class="block text-sm font-medium text-gray-700">New Entity: *</label>
            <select id="entity_change" name="entity" :value="formData.entity" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.entity.$touch()">
              <template v-if="disableForm">
                <option v-for="entity in configItems.ENTITIES" :key="entity.entity" :value="entity.entity">{{
                  entity.entity }}</option>
              </template>
              <template v-else>
                <option v-for="entity in activeEntities" :key="entity.entity" :value="entity.entity">{{ entity.entity }}
                </option>
              </template>
            </select>
            <span v-if="v$.formData.entity.$error" class="text-red-500">
              * {{ v$.formData.entity.$errors[0].$message }}
            </span>
          </div>

          <div v-if="formData.entity" class="col-span-6 mt-4">
            <label for="costCenter" class="block text-sm font-medium text-gray-700">Cost Centre: *</label>
            <select id="entity_change" name="costCenter" :value="formData.costCenter" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.costCenter.$touch()">
              <template v-if="disableForm">
                <option v-for="cc in currentEntity.Items" :key="cc.ID" :value="cc.CostCodeLookUp">{{ cc.CostCodeLookUp }}
                </option>
              </template>
              <template v-else>
                <option v-for="cc in activeCCs" :key="cc.ID" :value="cc.CostCodeLookUp">{{ cc.CostCodeLookUp }}</option>
              </template>
            </select>
            <span v-if="v$.formData.costCenter.$error" class="text-red-500">
              * {{ v$.formData.costCenter.$errors[0].$message }}
            </span>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label for="agreesEntityChange" class="block text-sm font-medium text-gray-70">Has the employee been
                consulted and agrees to the entity change and potentially their pay date (completed by 2nd line approver)
                *</label>
              <select id="agreesEntityChange" name="agreesEntityChange" autocomplete="agreesEntityChange"
                :value="formData.agreesEntityChange" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                " @change="handleInput" @blur="v$.formData.agreesEntityChange.$touch()">
                <option value="yes">Yes
                </option>
                <option value="no">No
                </option>
              </select>
              <span v-if="v$.formData.agreesEntityChange.$error" class="text-red-500">
                * {{ v$.formData.agreesEntityChange.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- Entity end -->
        </div>

        <div v-if="formData?.amendmentType?.includes('Contract change')">
          <div v-if="formData.contractType" class="col-span-6 mt-4">
            <label for="contract" class="block text-sm font-medium text-gray-700">Contract change: *</label>
            <select id="contract" name="contract" :value="formData.contract" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.contract.$touch()">
              <template v-if="disableForm">
                <option v-for="contract in currentContract?.Items" :key="contract.ID">{{ contract.Contract }}</option>
              </template>
              <template v-else>
                <option v-for="contract in activeContracts" :key="contract.ID">{{ contract.Contract }}</option>
              </template>
            </select>
          </div>

          <div class="col-span-6 mt-4">
            <label for="contractType" class="block text-sm font-medium text-gray-700">Contract type: *</label>
            <select id="contractTerm" name="contractTerm" :value="formData.contractTerm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.contractTerm.$touch()">
              <option value="permanent">Permanent</option>
              <option value="fixed-term"> Fixed term</option>
              <option value="zero-hour">Zero hour</option>
            </select>
            <span v-if="v$.formData.contractTerm.$error" class="text-red-500">
              * {{ v$.formData.contractTerm.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-6 mt-4" v-if="formData.contractTerm === 'fixed-term'">
            <label class="block text-sm font-medium text-gray-700">Fixed term end date: *</label>
            <div>
              <input id="fixedTermEndDate-input" name="fixedTermEndDate"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                type="date" :value="formData.fixedTermEndDate" @change="handleInput"
                @blur="v$.formData.fixedTermEndDate.$touch()" />
              <span v-if="v$.formData.fixedTermEndDate.$error" class="text-red-500">
                * {{ v$.formData.fixedTermEndDate.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-6 mt-4">
              <label for="businesscontact" class="block text-sm font-medium text-gray-700">What is the name of the
                Business Contract that this role will be
                working on? *</label>
              <input type="text" name="businesscontact" id="businesscontact" :value="formData.businesscontact"
                autocomplete="businesscontact" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                " @input="handleInput" @blur="v$.formData.businesscontact.$touch()" />
              <span v-if="v$.formData.businesscontact.$error" class="text-red-500">
                * {{ v$.formData.businesscontact.$errors[0].$message }}
              </span>
            </div>
          </div>
          <!-- Sector end -->
        </div>

        <div v-if="formData?.amendmentType?.includes('Salary/Hourly Rate Change')">
          <h3 class="text-lg my-4">Salary/Hourly Rate Change</h3>
          <!-- Sector Code -->
          <div class="col-span-6 mt-4">
            <label for="salaryChange" class="block text-sm font-medium text-gray-700">Type of salary/hourly rate change:
              *</label>
            <select id="salaryChange" name="salaryChange" :value="formData.salaryChange" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.salaryChange.$touch()">
              <option v-for="type in changeTypes" :key="type" :value="type">{{ type }}</option>
            </select>
            <span v-if="v$.formData.salaryChange.$error" class="text-red-500">
              * {{ v$.formData.salaryChange.$errors[0].$message }}
            </span>
          </div>
          <!-- Sector end -->

          <!-- CURRENCY -->
          <div class="col-span-6 mt-4">
            <label for="currency" class="block text-sm font-medium text-gray-700">Currency of salary: *</label>
            <select id="currency" name="currency" :value="formData.currency" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.currency.$touch()">
              <option selected value="GBP">GBP</option>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
            </select>
            <span v-if="v$.formData.currency.$error" class="text-red-500">
              * {{ v$.formData.currency.$errors[0].$message }}
            </span>
          </div>
          <!-- CURRENCY END -->

          <!-- Current Salary -->
          <div class="col-span-6 mt-4">
            <label for="currentSalary" class="block text-sm font-medium text-gray-700">Current salary/hourly rate:
              *</label>
            <input :value="formData.currentSalary" :disabled="disableForm" min="0" type="number" name="currentSalary"
              id="current_salary-name-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.currentSalary.$touch()">
            <span v-if="v$.formData.currentSalary.$error" class="text-red-500">
              * {{ v$.formData.currentSalary.$errors[0].$message }}
            </span>
          </div>
          <!-- End Current Salary -->

          <!-- New salary -->
          <div class="col-span-6 mt-4">
            <label for="newSalary" class="block text-sm font-medium text-gray-700">New salary/hourly rate: *</label>
            <input :value="formData.newSalary" :disabled="disableForm" min="0" type="number" name="newSalary"
              id="new_salary-name-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.newSalary.$touch()">
            <span v-if="v$.formData.newSalary.$error" class="text-red-500">
              * {{ v$.formData.newSalary.$errors[0].$message }}
            </span>
          </div>
          <!-- End New Salary -->

          <!-- Diff -->
          <div v-if="formData.diff" class="col-span-6 mt-4">
            <label for="diff" class="block text-sm font-medium text-gray-700">Difference: *</label>
            <input :value="`${formData.diff}%`" type="text" name="diff" id="diff-name-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              disabled @blur="v$.formData.diff.$touch()">
            <span v-if="v$.formData.diff.$error" class="text-red-500">
              * {{ v$.formData.diff.$errors[0].$message }}
            </span>
          </div>
          <!-- End Diff -->
        </div>

        <div v-if="formData?.amendmentType?.includes('Allowance Change')">
          <h3 class="text-lg my-4">Allowance Change</h3>
          <div class="flex justify-between mt-4" v-for="n in 5" :key="n">
            <!-- Type of change -->
            <div class="col-span-6 w-full mx-1">
              <label for="salary_change" class="block text-sm font-medium text-gray-700">Type of allowance change</label>
              <select :id="`typeChange${n}`" :name="`typeChange${n}`" :value="formData[`typeChange${n}`]"
                :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                " @change="handleInput">
                <option value="Amendment">Amendment</option>
                <option value="Addition">Addition</option>
                <option value="Removal">Removal</option>
              </select>

              <!-- Allowance permanent -->
              <div v-if="formData[`typeChange${n}`] === 'Amendment' || formData[`typeChange${n}`] === 'Addition'" class="">
                <div class="">
                  <label for="allowanceExpire" class="block text-sm font-medium text-gray-700 mt-2">Is this a permanent allowance change?</label>
                  <label :for="`allowance-expire-yes${n}`" class=" text-sm font-medium text-gray-700">Yes</label>
                  <input type="radio" value="Yes" :checked="formData[`allowanceExpire${n}`]==='Yes'" :name="`allowanceExpire${n}`" :id="`allowance-expire-yes${n}`" @change="handleRadioChange"
                    class="
                      ml-2
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:cursor-not-allowed">
                  <label :for="`allowance-expire-no${n}`" class="text-sm font-medium text-gray-700 pl-2">No</label>
                  <input type="radio" value="No" :checked="formData[`allowanceExpire${n}`]==='No'" :name="`allowanceExpire${n}`" :id="`allowance-expire-no${n}`" @change="handleRadioChange"
                    class="
                      ml-2
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                      disabled:bg-gray-200
                      disabled:cursor-not-allowed">
                </div>
              </div>
              <!-- Allowance permanent end -->
            </div>

            <!-- Type of change -->

            <!-- New allowance type -->
            <div class="col-span-6 w-full mx-1">
              <label :for="`newAllowanceType${n}`" class="block text-sm font-medium text-gray-700">New allowance
                type</label>
              <input :value="formData[`newAllowanceType${n}`]" :disabled="disableForm" type="text"
                :name="`newAllowanceType${n}`" :id="`new_allowance_type-name-input_${n}`"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                @input="handleInput">
              
              
                <!-- Allowance expiry date -->
                <div v-if="formData[`allowanceExpire${n}`] === 'No'" class="mt-2">
                  <label :for="`allowanceEndDate${n}`" class="text-sm font-medium text-gray-700">Allowance end date:</label>
                  <input :disabled="disableForm" type="date" :name="`allowanceEndDate${n}`"
                    id="allowanceEndDate-input" autocomplete="name"
                    :value="formData[`allowanceEndDate${n}`]"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                    @input="handleInput">
                </div>
                <!-- Allowance expiry date end -->

            </div>
            <!-- New allowance type end -->

            <!-- per annum -->
            <div class="col-span-6 w-full mx-1">
              <label :for="`perAnnum${n}`" class="block text-sm font-medium text-gray-700">New allowance per annum</label>
              <input :value="formData[`perAnnum${n}`]" :disabled="disableForm" type="text" :name="`perAnnum${n}`"
                :id="`per_annum-name-input_${n}`"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                @input="handleInput">
            </div>
            <!-- per annum end -->

            
          </div>

          <!-- End of TODO-->

          <!-- per annum -->
          <div class="col-span-6 mt-4">
            <label for="roleChange" class="block text-sm font-medium text-gray-700">Will the overtime rates that are
              applicable to the role change? *</label>
            <input :value="formData.roleChange" :disabled="disableForm" type="text" name="roleChange"
              id="role_change-name-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.roleChange.$touch()">
            <span v-if="v$.formData.roleChange.$error" class="text-red-500">
              * {{ v$.formData.roleChange.$errors[0].$message }}
            </span>
          </div>
          <!-- per annum end -->
        </div>

        <div v-if="formData?.amendmentType?.includes('Line Manager Change')">
          <h3 class="text-lg my-4">Line Manager Change</h3>
          <div class="col-span-6 mt-4">
            <label for="lineManager" class="block text-sm font-medium text-gray-700">New Line Manager: *</label>
            <input :value="formData.lineManager" :disabled="disableForm" type="text" name="lineManager" id="orgName-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.lineManager.$touch()">
            <span v-if="v$.formData.lineManager.$error" class="text-red-500">
              * {{ v$.formData.lineManager.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label for="employeeNumber" class="block text-sm font-medium text-gray-700">New line manager employee number:
              *</label>
            <input :value="formData.employeeNumber" :disabled="disableForm" type="text" name="employeeNumber"
              id="orgName-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.employeeNumber.$touch()">
            <span v-if="v$.formData.employeeNumber.$error" class="text-red-500">
              * {{ v$.formData.employeeNumber.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label for="orgName" class="block text-sm font-medium text-gray-700">SAP organisation name: *</label>
            <input :value="formData.orgName" :disabled="disableForm" type="text" name="orgName" id="orgName-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput" @blur="v$.formData.orgName.$touch()">
            <span v-if="v$.formData.orgName.$error" class="text-red-500">
              * {{ v$.formData.orgName.$errors[0].$message }}
            </span>
          </div>
          <!-- per annum end -->
        </div>

        <div v-if="formData?.amendmentType?.includes('Location Change')">
          <h3 class="text-lg my-4">Location Change</h3>
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label for="roleLocationSite" class="block text-sm font-medium text-gray-700">New Role Location (Site):
                *</label>
              <select :value="formData.newRoleLocationSite" :disabled="disableForm" name="newRoleLocationSite"
                id="input-new-role-location-site" autocomplete="newRoleLocationSite" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:text-gray-600
                " @change="handleInput" @blur="v$.formData.newRoleLocationSite.$touch()">
                <template v-if="disableForm">
                  <option v-for="location in configData.LOCATIONS" :key="location.LOCATIONCODE"
                    :value="`${location.OBJECTNAME} - ${location.LOCATIONCODE}`">
                    {{ location.DISPLAYNAME }}
                  </option>
                </template>
                <template v-else>
                  <option v-for="location in activeLocations" :key="location.LOCATIONCODE"
                    :value="`${location.OBJECTNAME} - ${location.LOCATIONCODE}`">
                    {{ location.DISPLAYNAME }}
                  </option>
                </template>
              </select>
              <span v-if="v$.formData.newRoleLocationSite.$error" class="text-red-500">
                * {{ v$.formData.newRoleLocationSite.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label for="roleLocationPostcode" class="block text-sm font-medium text-gray-700">New Location
                (Postcode):</label>
              <input disabled :value="formData.locationPostcode" name="roleLocationPostcode"
                id="input-role-location-postcode" autocomplete="roleLocationPostcode" class="
                  mt-1
                  p-2
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border
                  border-gray-300
                  rounded-md
                  disabled:cursor-not-allowed
                  disabled:bg-gray-200
                  disabled:text-gray-600
                " />
            </div>
          </div>

          <div class="col-span-6 w-full mt-4">
            <label for="dwpSite" class="block text-sm font-medium text-gray-700">Is this a DWP site? *</label>
            <select id="dwpSite" name="dwpSite" :value="formData.dwpSite" :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                " @change="handleInput" @blur="v$.formData.dwpSite.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.dwpSite.$error" class="text-red-500">
              * {{ v$.formData.dwpSite.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 w-full mt-4">
            <label for="hybridWorker" class="block text-sm font-medium text-gray-700">Will the employee be a Hybrid
              Worker, i.e. has their working status changed? *</label>
            <select id="hybridWorker" name="hybridWorker" :value="formData.hybridWorker" :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                " @change="handleInput" @blur="v$.formData.hybridWorker.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.hybridWorker.$error" class="text-red-500">
              * {{ v$.formData.hybridWorker.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div v-if="formData?.amendmentType?.includes('Business Structure Change')">
          <h3 class="text-lg my-4">Business Structure Change</h3>
          <label for="moreDetails" class="block text-sm font-medium text-gray-700">Please note any additional details
            relating to a change in cost code/centre or internal order number: </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <textarea :value="formData.moreDetails" id="moreDetails" name="moreDetails" rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              @input="handleInput" />
          </div>

          <div class="col-span-6 mt-4">
            <label for="employeeSeconding" class="block text-sm font-medium text-gray-700">Is the employee seconding
              (temporarily transferring) from/to another EQUANS entity?: *</label>
            <select id="employeeSeconding" name="employeeSeconding" :value="formData.employeeSeconding"
              :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @blur="v$.formData.employeeSeconding.$touch()" @change="handleInput">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.employeeSeconding.$error" class="text-red-500">
              * {{ v$.formData.employeeSeconding.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div v-if="formData?.amendmentType?.includes('Hours/Work Pattern Change')">
          <h3 class="text-lg my-4">Hours/Work Pattern Change</h3>
          <div class="col-span-6 mt-4">
            <label for="currentSalary" class="block text-sm font-medium text-gray-700">Current annual salary/hourly rate:
              *</label>
            <input :value="formData.currentSalary" :disabled="disableForm" type="text" name="currentSalary"
              id="currentSalary-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput">
            <span v-if="v$.formData.currentSalary.$error" class="text-red-500">
              * {{ v$.formData.currentSalary.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label for="newSalary" class="block text-sm font-medium text-gray-700">New annual salary/hourly rate:
              *</label>
            <input :value="formData.newSalary" :disabled="disableForm" type="text" name="newSalary" id="newSalary-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput">
            <span v-if="v$.formData.newSalary.$error" class="text-red-500">
              * {{ v$.formData.newSalary.$errors[0].$message }}
            </span>
          </div>

          <div class="mt-4">
            <WorkingWeek :data="formData.pattern" @update="handleUpdateWorkingHours" :disableForm="disableForm" />
          </div>

          <div class="col-span-6 mt-4">
            <label for="hoursPerWeek" class="block text-sm font-medium text-gray-700">New hours per week</label>
            <input :value="formData.hoursPerWeek" :disabled="disableForm" type="text" name="hoursPerWeek"
              id="hoursPerWeek-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput">
          </div>

          <div class="col-span-6 mt-4">
            <label for="weeksPerYear" class="block text-sm font-medium text-gray-700">How many weeks will be worked per
              year?</label>
            <input :value="formData.weeksPerYear" :disabled="disableForm" type="text" name="weeksPerYear"
              id="weeksPerYear-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput">
          </div>
        </div>

        <div v-if="formData?.amendmentType?.includes('Role & Benefits Change')">
          <h3 class="text-lg my-4">Role & Benefits Change</h3>
          <div class="col-span-6 mt-4">
            <label for="jobTitle" class="block text-sm font-medium text-gray-700">New job title:</label>
            <input :value="formData.jobTitle" :disabled="disableForm" type="text" name="jobTitle" id="jobTitle-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              @input="handleInput">
          </div>

          <div class="mt-4">
            <label for="secondment" class="block text-sm font-medium text-gray-700">Is this a secondment? *</label>
            <select id="secondment" name="secondment" :value="formData.secondment" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.secondment.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.secondment.$error" class="text-red-500">
              * {{ v$.formData.secondment.$errors[0].$message }}
            </span>
            <div v-if="formData?.secondment === 'Yes'">
              <label for="secondmentAllowance" class="block text-sm font-medium text-gray-700 mt-4 w-2/3">Is the secondment to be paid as an Allowance (i.e. overtime will <b><u>not</u></b> be paid at the seconded hourly rate) or as an increase in Annual Salary (i.e. overtime <b><u>will</u></b> be paid at the higher seconded hourly rate</label>
              <select id="secondmentAllowance" name="secondmentAllowance" :value="formData.secondmentAllowance" :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed" 
                  @change="handleInput" @blur="v$.formData.secondmentAllowance.$touch()">
                <option value="Allowance">Allowance</option>
                <option value="Salary">Salary</option>
              </select>
              <span v-if="v$.formData.secondmentAllowance.$error" class="text-red-500">
              * {{ v$.formData.secondmentAllowance.$errors[0].$message }}
              </span>
              <div v-if="formData?.secondmentAllowance === 'Allowance'" class="mt-4">
                <div>
                  <label for="allowanceAmount" class="col-span-6 text-sm font-medium text-gray-700 mt-4 w-2/3">Allowance amount:</label>
                </div>
                <div class="flex">
                  <input type="text" name="allowanceAmount" id="allowanceAmount" :value="formData.allowanceAmount"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500  w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                  @change="handleInput"
                  @blur="v$.formData.allowanceAmount.$touch()"
                >
                
                <select
                  id="allowanceAmountCurrency"
                  name="allowanceAmountCurrency"
                  :value="formData?.allowanceAmountCurrency"
                  :disabled="disableForm"
                  class="
                    mt-1
                    h-10
                    ml-2
                    max-w-lg
                    focus:ring-indigo-500 focus:border-indigo-500
                    shadow-sm
                    sm:max-w-xs sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                  "
                  @change="handleInput" @blur="v$.formData.allowanceAmountCurrency.$touch()"
                  >
                  <option>GBP</option>
                  <option>USD</option>
                  <option>EUR</option>
                </select>
                  <span v-if="v$.formData.allowanceAmountCurrency.$error" class="text-red-500">
                    * {{ v$.formData.allowanceAmountCurrency.$errors[0].$message }}
                  </span>
                </div>
                <span v-if="v$.formData.allowanceAmount.$error" class="text-red-500">
                * {{ v$.formData.allowanceAmount.$errors[0].$message }}
                </span>
              </div>
              
              <label for="secondmentEndDate" class="block text-sm font-medium text-gray-700 mt-4">Secondment end date: *</label>
              <input :value="formData.secondmentEndDate" :disabled="disableForm" type="date" name="secondmentEndDate"
                id="secondmentEndDate-input" autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                @input="handleInput" @blur="v$.formData.secondmentEndDate.$touch()">
              <span v-if="v$.formData.secondmentEndDate.$error" class="text-red-500">
              * {{ v$.formData.secondmentEndDate.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="mt-4">
            <label for="promotion" class="block text-sm font-medium text-gray-700">Is this a promotion? *</label>
            <select id="promotion" name="promotion" :value="formData.promotion" :disabled="disableForm" class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @blur="v$.formData.promotion.$touch()" @change="handleInput">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.promotion.$error" class="text-red-500">
              * {{ v$.formData.promotion.$errors[0].$message }}
            </span>
          </div>

          <fieldset class="mt-4">
            <legend class="text-base font-medium text-gray-900">
              Will the employee retain their existing T&C?
            </legend>
            <div class="mt-4 space-y-4">
              <div>
                <div v-for="item in retainList" :key="item" class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input :value="item" :id="item.replace(' ', '-')" name="retainTC" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData?.retainTC?.includes(item)" :disabled="disableForm"
                      @change="handleCheckboxChange" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="retainTC" class="font-medium text-gray-700">{{ item }}</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="mt-4">
            <legend class="text-base font-medium text-gray-900">
              Will the employee move to EQUANS T&C?
            </legend>
            <div class="mt-4 space-y-4">
              <div>
                <div v-for="item in retainList" :key="item" class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input :value="item" :id="item.replace(' ', '-')" name="moveTC" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData?.moveTC?.includes(item)" :disabled="disableForm"
                      @change="handleCheckboxChange" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="moveTC" class="font-medium text-gray-700">{{ item }}</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="mt-4">
            <label for="benifitsAdd" class="block text-sm font-medium text-gray-700">Please detail any benefits you wish
              to add:</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea :value="formData.benifitsAdd" id="benifitsAdd" name="benifitsAdd" rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                @input="handleInput" />
            </div>
          </div>

          <div class="mt-4">
            <label for="benifitsRemove" class="block text-sm font-medium text-gray-700">Please detail any benefits you
              wish to remove:</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea :value="formData.benifitsRemove" id="benifitsRemove" name="benifitsRemove" rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                @input="handleInput" />
            </div>
          </div>

          <div class="mt-4">
            <label for="overtimeRates" class="block text-sm font-medium text-gray-700">Will the overtime rates that are
              applicable to the role change? *</label>
            <select id="overtimeRates" name="overtimeRates" :value="formData.overtimeRates" :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                " @change="handleInput" @blur="v$.formData.overtimeRates.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.overtimeRates.$error" class="text-red-500">
              * {{ v$.formData.overtimeRates.$errors[0].$message }}
            </span>
          </div>

          <div class="mt-4">
            <label for="healthAndSafetyChange" class="block text-sm font-medium text-gray-700">Will the health & safety
              aspects of the role change? *</label>
            <select id="healthAndSafetyChange" name="healthAndSafetyChange" :value="formData.healthAndSafetyChange"
              :disabled="disableForm" class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:cursor-not-allowed
                " @change="handleInput" @blur="v$.formData.healthAndSafetyChange.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.healthAndSafetyChange.$error" class="text-red-500">
              * {{ v$.formData.healthAndSafetyChange.$errors[0].$message }}
            </span>
          </div>

          <fieldset class="mt-4">
            <legend class="text-base font-medium text-gray-900">
              Please select if any of the following are now required due to the role change?
            </legend>
            <div class="mt-4 space-y-4">
              <div>
                <div v-for="item in roleUpdateOptions" :key="item" class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input :value="item" :id="item.replace(' ', '-')" name="roleUpdate" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData?.roleUpdate?.includes(item)" :disabled="disableForm"
                      @change="handleCheckboxChange" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="roleUpdate" class="font-medium text-gray-700">{{ item }}</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="mt-4">
            <legend class="text-base font-medium text-gray-900">
              Will the appraisal method change?
            </legend>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input value="TRUE" id="confirmation" name="confirmation" type="checkbox" class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        " :checked="formData?.confirmation?.includes('TRUE')" :disabled="disableForm"
                      @change="handleCheckboxChange" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="confirmation" class="font-medium text-gray-700">I confirm that the employee is in Job
                      Level 5-7 and that they should be included in the OneHR system (online appraisal process).</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>


        </div>

        <div class="mt-8" v-if="formData.amendmentType?.includes('Business Structure Change') ||
          formData.amendmentType?.includes('Role & Benefits Change')
          ">
          <h3 class="text-lg my-4">Direct Reports</h3>
          <div class="col-span-6 mt-4">
            <label for="peopleReporting" class="block text-sm font-medium text-gray-700">Did the employee have people
              reporting into them? *</label>
            <select id="peopleReporting" name="peopleReporting" :value="formData.peopleReporting" :disabled="disableForm"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.peopleReporting.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.peopleReporting.$error" class="text-red-500">
              * {{ v$.formData.peopleReporting.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label for="reportingToNow" class="block text-sm font-medium text-gray-700">Will the employee now have people
              reporting into them? *</label>
            <select id="reportingToNow" name="reportingToNow" :value="formData.reportingToNow" :disabled="disableForm"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:cursor-not-allowed
              " @change="handleInput" @blur="v$.formData.reportingToNow.$touch()">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-if="v$.formData.reportingToNow.$error" class="text-red-500">
              * {{ v$.formData.reportingToNow.$errors[0].$message }}
            </span>
          </div>

          <div class="mt-4" v-if="formData.reportingToNow === 'Yes'">
            <label for="directReports" class="block text-sm font-medium text-gray-700">Please detail any direct
              reports:</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea :value="formData.directReports" id="directReports" name="directReports" rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                @input="handleInput" @blur="v$.formData.directReports.$touch()" />
              <span v-if="v$.formData.directReports.$error" class="text-red-500">
                * {{ v$.formData.directReports.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>

        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button :disabled="v$.$invalid" type="submit" class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          " @click.prevent="handleSaveForm">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WorkingWeek from '@/components/WorkingWeek.vue';
import httpClient from "@/httpClient";
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators'

export default {
  props: ['formData', 'disableForm', 'configItems'],
  components: { WorkingWeek },
  data() {
    return {
      configData: {},
      model: this.formData,
      v$: useVuelidate(),
      jobLevels: [
        '1A',
        '1B (Board)',
        '1B (Senior Mgt)',
        '2A',
        '2B',
        '3A',
        '3A (Sales)',
        '3B',
        '4A',
        '4A (Sales)',
        '4B',
        '4B (Sales)',
        '5A',
        '5B',
        '6A',
        '6B',
        '7A',
        '7B'
      ],
      changeTypes: [
        "Allowance - Consolidate",
        "Amended Work Pattern",
        "Demotion",
        "Merit Increase",
        "Performance",
        "Promotion",
        "Redeployment",
        "Regrading",
        "Review - Annual Salary Review",
        "Review - Client Requested",
        "Review- London Living Wage Increase",
        "Review - National Minimum Wage Review",
        "Salary Correction",
        "Spine Point Increment",
        "Temporary",
        "Transfer"
      ],
      retainList: [
        'Car',
        'Pension',
        'Holidays',
        'Sickness',
        'Bonus',
        'PMI',
        'Life cover'
      ],
      roleUpdateOptions: [
        'Disclosure Basic Check',
        'Disclosure Enhanced Check',
        'BS7858 Security Vetting',
        'Child Safeguarding Declaration',
        'SIA Licence'
      ]
    }
  },
  methods: {
    // Fetch config data
    async fetchConfigData() {
      const response = await httpClient.post('/config/all', { requestedBy: 'something' });
      this.configData = response.data.body;
    },
    handleUpdateWorkingHours({ data }) {
      this.model.pattern = data;
      this.$emit('update:formData', { formName: 'formThree', formData: this.model });
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      if (this.model.currentSalary && this.model.newSalary) {
        const a = this.model.currentSalary;
        const b = this.model.newSalary;
        const diff = (b - a) / a * 100.0;
        this.model.diff = diff;
      }
      this.$emit('update:formData', { formName: 'formThree', formData: this.model });
    },
    handleCheckboxChange(event) {
      const { name, value } = event.target;
      if (!this.model[name]) {
        this.model[name] = [value];
      } else if (!this.model[name].includes(value)) {
        this.model[name].push(value)
      } else {
        const index = this.model[name].indexOf(value);
        this.model[name].splice(index, 1);
      }
      if (this.model.amendmentType.includes('Hours/Work Pattern Change')) {
        if (!this.model.currency) this.model.currency = 'GBP';
      } else {
        this.model.currency = undefined;
      }
      this.$emit('update:formData', { formName: 'formThree', formData: this.model });
    },
    handleRadioChange(event) {
        const { name, value } = event.target;
        this.model[name] = value;
        this.$emit('update:formData', { formName: 'formThree', formData: this.model });
      },
    handleSaveForm() {
      this.$emit('submit-form', { name: "formThree", formData: this.formData })
    },
  },
  computed: {
    activeEntities() {
      return this.configItems.ENTITIES.filter((entity) => entity.LKStatus);
    },
    activeLocations() {
      return this.configItems.LOCATIONS.filter((loc) => loc.LKStatus);
    },
    currentEntity() {
      return this.configItems.ENTITIES.find((entity) => entity.entity === this.formData.entity);
    },
    currentContract() {
      return this.configItems.CONTRACTS.find((contract) => contract.ContractType === this.formData.contractType)
    },
    activeContracts() {
      return this.currentContract.Items.filter((contract) => contract.LKStatus);
    },
    activeContractTypes() {
      return this.configItems.CONTRACTS.filter((ct) => ct.LKStatus);
    },
    currentContractType() {
      let contractType = this.configItems.CONTRACTS.find((contract) => contract.ContractType === this.formData.newContractType);
      // handle legacy job levels without a matching contract type
      if (!contractType && this.formData.newJobLevel) {
        contractType = {
          Items: [{ContractName: this.formData.newJobLevel}]
        }
      }
      return contractType
    },
    costCodes() {
      return this.configItems.ENTITIES.find((e) => e.entity.toLowerCase().replace(/\s/g, "-") === this.formData.entity)?.Items ?? [];
    },
    activeCCs() {
      const entity = this.configItems.ENTITIES.find((e) => e.entity === this.formData.entity);
      return entity?.Items?.filter((item) => item.LKStatus)
    },
    activeJobLevels() {
      let jobLevels = this.currentContractType?.Items?.filter((contract) => contract.LKStatus);
      // handle legacy job levels without a matching contract type
      if (!jobLevels.length && this.formData.newJobLevel && !this.formData.newContractType) {
        jobLevels = [{ContractName: this.formData.newJobLevel}];
      }
      return jobLevels;
    }
  },
  async created() {
    await this.fetchConfigData();
  },
  watch: {
    'model.newRoleLocationSite'(newLocation) {
      const location = this.configData.LOCATIONS.find((loc) => `${loc.OBJECTNAME} - ${loc.LOCATIONCODE}` === newLocation);
      this.model.locationPostcode = location ?
        location.POSTCODE :
        '';
      this.$emit('update:formData', { formName: 'formThree', formData: this.model });
    }
  },
  validations() {
    return {
      formData: {
        effectiveDate: { required },
        amendmentType: { required },
        newJobLevel: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Job Level Change'))
          )
        },
        newContractType: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Job Level Change'))
          )
        },
        entity: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Entity Change'))
          )
        },
        costCenter: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Entity Change'))
          )
        },
        agreesEntityChange: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Entity Change'))
          )
        },
        contractTerm: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Contract change'))
          )
        },
        fixedTermEndDate: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Contract change') && this.formData?.contractTerm === 'fixed-term')
          )
        },
        businesscontact: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Contract change'))
          )
        },
        salaryChange: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Salary/Hourly Rate Change'))
          )
        },
        currency: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Salary/Hourly Rate Change'))
          )
        },
        currentSalary: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(
              this.formData?.amendmentType?.includes('Hours/Work Pattern Change') ||
              this.formData?.amendmentType?.includes('Salary/Hourly Rate Change')
            )
          )
        },
        newSalary: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(
              this.formData?.amendmentType?.includes('Hours/Work Pattern Change') ||
              this.formData?.amendmentType?.includes('Salary/Hourly Rate Change')
            )
          )
        },
        diff: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Salary/Hourly Rate Change'))
          )
        },
        roleChange: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Allowance Change'))
          )
        },
        lineManager: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Line Manager Change'))
          )
        },
        employeeNumber: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Line Manager Change'))
          )
        },
        orgName: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Line Manager Change'))
          )
        },
        newRoleLocationSite: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Location Change'))
          )
        },
        dwpSite: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Location Change'))
          )
        },
        hybridWorker: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Location Change'))
          )
        },
        employeeSeconding: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Business Structure Change'))
          )
        },
        secondment: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Role & Benefits Change'))
          )
        },
        secondmentAllowance: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.secondment?.includes('Yes'))
          )
        },
        allowanceAmountCurrency: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.secondmentAllowance?.includes('Yes'))
          )
        },
        allowanceAmount: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.secondmentAllowance?.includes('Allowance'))
          )
        },
        secondmentEndDate: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.secondment?.includes('Yes'))
          )
        },
        promotion: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Role & Benefits Change'))
          )
        },
        overtimeRates: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Role & Benefits Change'))
          )
        },
        healthAndSafetyChange: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(this.formData?.amendmentType?.includes('Role & Benefits Change'))
          )
        },
        peopleReporting: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(
              this.formData?.amendmentType?.includes('Role & Benefits Change') ||
              this.formData?.amendmentType?.includes('Business Structure Change')
            )
          )
        },
        reportingToNow: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(
              this.formData?.amendmentType?.includes('Role & Benefits Change') ||
              this.formData?.amendmentType?.includes('Business Structure Change')
            )
          )
        },
        directReports: {
          requiredIf: helpers.withMessage(
            'This field is required',
            requiredIf(
              (this.formData?.amendmentType?.includes('Role & Benefits Change') ||
                this.formData?.amendmentType?.includes('Business Structure Change')) &&
              this.formData.reportingToNow === 'Yes'
            )
          )
        }
      }
    }
  }
};
</script>

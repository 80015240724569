<template>
  <main class="flex-1 pb-8">
        <!-- Page header -->
        <div class="bg-white shadow">
          <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div class="py-6 md:flex md:items-center md:justify-between">
              <div class="flex-1 min-w-0">
                <!-- Profile -->
                <div class="flex items-center">
                  
                  <div>
                    <div class="flex items-center">
                      <avatar-ui size="md" :username="username" />
                      <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Welcome, {{username}}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 shadow rounded-md py-5">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
            <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <!-- Card -->
              <div v-for="card in cards" :key="card.name" class="bg-white overflow-hidden shadow rounded-lg">
                <div class="p-5">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <component :is="card.icon" class="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div class="ml-5 w-0 flex-1">
                      <dl>
                        <dt class="text-sm font-medium text-gray-500 truncate">
                          {{ card.name }}
                        </dt>
                        <dd>
                          <div class="text-lg font-medium text-gray-900">
                            {{ card.amount }} <span v-if="card.amount >= 25">+</span>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Activity table (small breakpoint and up) -->
          <div>
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 shadow rounded-md mt-5 " style="min-height: 850px;">
              <h2 class="text-lg leading-6 font-medium text-gray-900 pt-5">Recent Assigned Documents</h2>
              <div class="flex flex-col mt-2">
                <div class="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
                  <div class="px-7 py-3" v-show="selected.title === 'Assigned to you'">
                    <div class="mt-1 relative rounded-md">
                      <input type="text" name="searchQueryAssigned" id="searchQueryAssigned" v-model="searchQueryAssigned" class="px-3 py-4 placeholder-slate-300 text-slate-600 block w-full pl-4 sm:text-sm border-none rounded-md" placeholder="Search by short ID..."/>
                    </div>
                  </div>
                  <div class="px-7 py-3" v-show="selected.title === 'Created by you'">
                    <div class="mt-1 relative rounded-md">
                      <input type="text" name="searchQueryCreated" id="searchQueryCreated" v-model="searchQueryCreated" class="px-3 py-4 placeholder-slate-300 text-slate-600 block w-full pl-4 sm:text-sm border-none rounded-md" placeholder="Search by short ID..."/>
                    </div>
                  </div>
                </div>

              </div>

              <!-- All assigned documents cards-->
              <span v-if="this.allUserDocuments?.length === 0">
                <DocumentTextIcon v-show="selected.title === 'Assigned to you'" class="w-24 h-24 flex-shrink-0 mx-auto text-gray-400" />
                <h1 class="mt-5 text-center" v-show="selected.title === 'Assigned to you'">You currently have no documents assigned to you</h1>
              </span>
              <span v-else>
                <document-cards v-bind:allDocuments="paginatedResults" v-bind:userprofile="userprofile" v-show="selected.title === 'Assigned to you'" />
              </span>
              <!-- All assigned documents cards end-->

              <!-- All created documents cards-->
              <span v-if="this.allUserRequests?.length === 0">
                <DocumentTextIcon v-show="selected.title === 'Created by you'" class="w-24 h-24 flex-shrink-0 mx-auto text-gray-400" />
                <h1 class="mt-5 text-center"  v-show="selected.title === 'Created by you'">You have not created any documents</h1>
              </span>
              <span v-else>
                <document-cards v-bind:allDocuments="paginatedResults" v-bind:userprofile="userprofile" v-show="selected.title === 'Created by you'" />
              </span>
              <!-- All created documents cards end-->

            </div>

            <!-- Pagination -->
            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 shadow rounded-md">
              <nav class="bg-white px-4 py-3 flex items-center justify-between overflow-hidden border-t border-gray-200 sm:px-6" aria-label="Pagination">
                <div class="hidden sm:block">

                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button 
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed"
                    :disabled="disablePrevious"
                    @click="handleClickPrevPage"
                  > Previous </button>
                  <button
                    class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed"
                    :disabled="disableNext"
                    @click="handleClickNextPage"
                  > Next </button>
                </div>
              </nav>
            </div>
            <!-- Pagination End -->

          </div>
        </div>
      </main>
</template>

<script>
import { Auth } from 'aws-amplify';
import DocumentCards from '@/components/dashboard/document-cards.vue'
import avatarUi from "@/components/avatarUi.vue"
import httpClient from '@/httpClient'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions 
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt1Icon,
  XIcon,
} from '@heroicons/vue/outline'
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
  UserRemoveIcon,
  UserAddIcon,
  CheckIcon,
  DocumentTextIcon,
  PencilIcon
} from '@heroicons/vue/solid'

const publishingOptions = [
  { title: 'Assigned to you', description: 'All documents assigned to you.', current: true },
  { title: 'Created by you', description: 'All documents created by you', current: false },
];

export default {
  inject: ['formatDate'],
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
    avatarUi,
    UserAddIcon,
    DocumentCards,
    CheckIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    DocumentTextIcon
  },
  data() {
    return {
      allUserDocuments: [],
      allUserATRRequests: [],
      allUserAmendRequests: [],
      allUserLeaverRequests: [],
      currentPage: 1,
      publishingOptions: publishingOptions,
      searchQueryAssigned: null,
      searchQueryCreated: null,
      selected: publishingOptions[0],
      pollData1: null,
      pollData2: null,
      statusStyles: {
        SUBMITTED: 'bg-green-100 text-green-800',
        DRAFT: 'bg-yellow-100 text-yellow-800',
        REJECTED: 'bg-gray-100 text-gray-800',
      }
    }
  },
  computed: {
    allUserRequests() {
      return [...this.allUserATRRequests, ...this.allUserAmendRequests, ...this.allUserLeaverRequests]
    },
    userprofile() {
      return Auth?.user.signInUserSession.idToken.payload;
    },
    username() {
      const firstName = Auth?.user.signInUserSession.idToken.payload.given_name;
      const lastName = Auth?.user.signInUserSession.idToken.payload.family_name
      return `${firstName} ${lastName}`;
    },
    allStarterRequests() {
      return ""
    },
    cards() { 
      const myATRTotal = this.allUserATRRequests?.length
      const myLeaverTotal = this.allUserLeaverRequests?.length
      const myAmendTotal = this.allUserAmendRequests?.length
      return [
        { name: 'ATRs raised by me', href: '#', icon: UserAddIcon, amount: myATRTotal ?? '-' },
        { name: 'Amends raised by me', href: '#', icon: PencilIcon, amount: myAmendTotal ?? '-' },
        { name: 'Leavers raised by me', href: '#', icon: UserRemoveIcon, amount: myLeaverTotal ?? '-' },
      ]},
    filterAssigned() {
      if(this.searchQueryAssigned) {
        return this.allUserDocuments.filter(item => {
          return this.searchQueryAssigned.toLowerCase().split(" ").every(v => item.shortId.toLowerCase().includes(v));
        });
      } else {
        return this.allUserDocuments;
      }
    },
    filterCreated() {
      if(this.searchQueryCreated) {
        return this.allUserRequests.filter(item => {
          return this.searchQueryCreated.toLowerCase().split(" ").every(v => item.shortId.toLowerCase().includes(v));
        });
      } else {
        return this.allUserRequests;
      }
    },
    paginatedResults() {
      return this.selected.title === 'Assigned to you' ?
        this.filterAssigned?.slice((this.currentPage - 1) * 8, this.currentPage * 8) :
        this.filterCreated?.slice((this.currentPage - 1) * 8, this.currentPage * 8);
    },
    totalPages() {
      return this.selected.title === 'Assigned to you' ?
        Math.ceil(this.filterAssigned?.length / 8) :
        Math.ceil(this.filterCreated?.length / 8); 
    },
    disablePrevious() {
      return this.currentPage === 1;
    },
    disableNext() {
      return this.currentPage === this.totalPages;
    }
  },
  methods: {
    getAtrSingle(sortKey) {
      const id = sortKey.split('#')[1];
      this.$router.push({ path: `/create-starter/${id}` })
    },
    handleClickPrevPage() {
      if (this.currentPage >= 2) this.currentPage--;
    },
    handleClickNextPage() {
      if (this.currentPage <= this.totalPages - 1) this.currentPage++;
    },

  // Collects all assigned user documents
    async fetchAssignedDocuments() {
      const response = await httpClient.post('/bff/assigned-documents', {
        requestedBy: this.userprofile.email
      });
      this.allUserDocuments = response.data.body.Items;
    },

    // Collects all ATR forms raised by a user - Email is required for ID
    async fetchCreatedDocuments(type) {
      const response = await httpClient.post('/bff/list-mydocs-by-doctype', {
        requestedBy: this.userprofile.email,
        docType: type
      });

      if(type === "STARTER") {
        this.allUserATRRequests = response.data.body.Items
      }      
      if(type === "AMEND") {
        this.allUserAmendRequests = response.data.body.Items
      }     
      if(type === "LEAVER") {
        this.allUserLeaverRequests = response.data.body.Items
      }
    }
  },
  mounted() {
    this.fetchAssignedDocuments()
    this.fetchCreatedDocuments("STARTER")
    this.fetchCreatedDocuments("AMEND")
    this.fetchCreatedDocuments("LEAVER")
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

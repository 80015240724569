<template>
  <form class="space-y-8 divide-y divide-gray-200 px-4 py-3" @submit="sendApprove" novalidate>
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Approve ATR
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <!-- First line approval -->
          <div
            v-if="currentStage === 'starter.notifyBudgetApprover'"
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="approval"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              v-if="this.atrData.currentStageName === 'starter.notifyBudgetApprover'"
              >Who will be providing first line approval for this ATR? *</label
            >
            <div v-if="!first_line_show" class="mt-1 sm:mt-0 sm:col-span-2">
              <user-search
                group="HM"
                @updated-user="handleUser($event)"
                @blur="v$.first_line.$touch()"
              ></user-search>
              <span v-if="v$.first_line.$errors.length" class="text-red-500">
                * {{ v$.first_line.$errors[0].$message }}
              </span>
            </div>
            <div v-else class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md">
                <input
                  type="email"
                  name="approval"
                  id="approval"
                  v-model="first_line"
                  disabled
                  class="
                    flex-1
                    block
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    min-w-0
                    rounded-none rounded-r-md
                    sm:text-sm
                    border-gray-300
                    disabled:cursor-not-allowed disabled:bg-gray-200
                  "
                />
                <button class="text-gray-400 hover:text-gray-800 ml-2" @click.prevent="handleClearFirstLine">
                  <XIcon class="h-5 w-5" />
                </button>
              </div>
              <p class="mt-1 text-sm text-gray-500" :class="{'displayNone' : atrData.currentStageName !== 'starter.notifyBudgetApprover'}">
                The first line approver is the business representative who has
                the authority to approve the budget spend for this role.
              </p>
              <p class="mt-1 text-sm text-gray-500" :class="{'displayNone' : atrData.currentStageName !== 'starter.notifyHROps'}">
                The second line approver is the HROps representative who has
                the authority to approve the budget spend for this role.
              </p>
            </div>
          </div>
          <!--End of first line approval-->

          <!-- Are you happy with-->
          <div class="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-notifications">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div
                    class="
                      text-base
                      font-medium
                      text-gray-900
                      sm:text-sm sm:text-gray-700
                    "
                    id="approve-atr"
                  >
                    Are you happy to approve this ATR? *
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <span v-if="v$.happy_to_approve.$errors.length" class="text-red-500">
                    * {{ v$.happy_to_approve.$errors[0].$message }}
                  </span>
                  <div class="max-w-lg">
                    <div class="mt-4 space-y-4">
                      <div class="flex items-center">
                        <input
                          id="approve-atr"
                          name="approve-atr"
                          v-model="happy_to_approve"
                          type="radio"
                          value="yes"
                          class="
                            focus:ring-indigo-500
                            h-4
                            w-4
                            text-indigo-600
                            border-gray-300
                          "
                          @blur="v$.happy_to_approve.$touch()"
                        />
                        <label
                          for="approve-atr"
                          class="ml-3 block text-sm font-medium text-gray-700"
                          >Yes</label
                        >
                      </div>
                      <div class="flex items-center">
                        <input
                          id="approve-atr"
                          name="approve-atr"
                          type="radio"
                          value="no"
                          class="
                            focus:ring-indigo-500
                            h-4
                            w-4
                            text-indigo-600
                            border-gray-300
                          "
                          @blur="v$.happy_to_approve.$touch()"
                        />
                        <label
                          for="approve-atr"
                          class="ml-3 block text-sm font-medium text-gray-700"
                          >No</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of are you happy with -->

          <!-- First line approval date -->
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="approval"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >Approval Date: *</label
            >
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <input
                  readonly
                  type="date"
                  name="approval"
                  id="approval"
                  v-model="approval_date"
                  autocomplete="username"
                  class="
                    flex-1
                    block
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    min-w-0
                    rounded-none rounded-r-md
                    sm:text-sm
                    border-gray-300
                  "
                  @blur="v$.approval_date.$touch()"
                />
                <span v-if="v$.approval_date.$errors.length" class="text-red-500">
                  * {{ v$.approval_date.$errors[0].$message }}
                </span>
              </div>
            </div>
          </div>
          <!--End of first line approval date-->

          <!-- First line comments -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comments" class="block text-sm font-medium text-gray-700">Approval Comments</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea id="about" name="about" v-model="approvalComment" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>
          <!-- First line comments end-->

          <!-- Second line approval -->

          <!-- Autofill Second Line Approver -->
          <div v-if="!editedSecondLine && hasSecondLineApprover() && currentStage === 'starter.notifyHROps'" 
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
              ">
              <label for="secondLineApprover" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Who will be providing second line approval for this ATR? *
              </label>
              <user-search group="HROPS" :read-only="hasSecondLineApprover" :selected-email="sla" @update:selected-email="editedSecondLine=true"></user-search>
          </div>
          <!-- END OF Autofill Second Line Approver -->

          <div
            v-else-if="(!hasSecondLineApprover() || editedSecondLine) && (currentStage === 'starter.notifyBudgetApprover' || currentStage === 'starter.notifyHROps')"
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
          <label
              for="approval"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
              Who will be providing second line approval for this ATR? *
              </label
            >
            <div v-if="!hr_prof_show" class="mt-1 sm:mt-0 sm:col-span-2">
              <user-search group="HROPS" @updated-user="handleSecond($event)" @blur="v$.second_line.$touch()"></user-search>
              <span v-if="v$.second_line.$errors.length" class="text-red-500">
                  * {{ v$.second_line.$errors[0].$message }}
                </span>
            </div>
            
            <div v-else class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md">
                <input
                  type="email"
                  name="hr-managers"
                  id="hr-managers"
                  autocomplete="hr-managers"
                  v-model="second_line"
                  disabled
                  class="
                    flex-1
                    block
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    min-w-0
                    rounded-none rounded-r-md
                    sm:text-sm
                    border-gray-300
                    disabled:cursor-not-allowed disabled:bg-gray-200
                  "
                />
                <button class="text-gray-400 hover:text-gray-800 ml-2" @click.prevent="handleClearSecondLine">
                  <XIcon class="h-5 w-5" />
                </button>
              </div>
              <p class="mt-2 text-sm text-blue-500">
                * The second line approver is the HR professional who will ensure all the correct policies and approvals have
                been adhered to. If further approvals are required, they will co-ordinate this with the business outside the 
                system and keep records locally for audit purposes. You can either type their name (surname than space first name)
                eg Bloggs Joe and select person icon or you can open the address book and search for their name (this is linked to the email directory)
              </p>
              <p class="mt-2 text-sm text-blue-500">
                * Once all mandatory fields (*) have been completed, you will be able to see an Approve this ATR button to allow
                you to submit the ATR to the next stage in the process. If you cannot see a Approve this ATR button, the you will need to review
                need to review the form and complete any missing information.
              </p>
            </div>
          </div>
          <!--End of first line approval-->
        </div>
      </div>
    </div>

    <div class="pt-5" v-if="currentStage === 'starter.notifyBudgetApprover' || currentStage === 'starter.notifyHROps'">
      <div class="flex justify-end">
        <button
          type="button"
          class="
            bg-white
            py-2
            px-4
            border border-gray-300
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            ml-3
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-green-500
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:bg-gray-200
            disabled:text-gray-400
            disabled:cursor-not-allowed
          "
        >
          Save
        </button> 
      </div>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex"
import { Auth } from 'aws-amplify'
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { getStorage, saveStorage } from '@/storageSave';
import { XIcon } from "@heroicons/vue/solid";
import httpClient from '@/httpClient';
import UserSearch from '@/components/userDropdownSearch.vue'
export default {
  components: {
    UserSearch,
    XIcon
  },
  props:{
    sla: {
      default: null
    }
  },
  data() {
    return {
      editedSecondLine: false,
      store: useStore(),
      first_line: '',
      first_line_show: false,
      second_line: '',
      happy_to_approve: '',
      approval_date: '',
      approvalComment: '',
      hr_prof: '',
      hr_prof_show: false,
      v$: useVuelidate()
    }
  },
  computed: {
    atrData() {
      return JSON.parse(getStorage('formData'));
    },
    currentStage() {
      return this.atrData.currentStageName;
    },
    userprofile() {
      return Auth?.user.signInUserSession.idToken.payload ?? "";
    },
    username() {
      return this.userprofile.email;
    },
  },
  methods: {
    hasSecondLineApprover() {
      if(this.sla) {
        return true;
      }
    },
    handleUser(event) {
      this.first_line = event.email.trim();
      this.first_line_show = true
    },
    handleClearFirstLine(event) {
      if (event.explicitOriginalTarget.id !== 'userSearch') {
        this.first_line = '';
        this.first_line_show = false
      }
    },
    handleSecond(event) {
      this.second_line = event.email.trim();
      this.hr_prof_show = true
    },
    handleClearSecondLine() {
      this.second_line = '';
      this.hr_prof_show = false
    },
    async sendApprove(event) {
      event.preventDefault();
      let firstLine;
      if (this.atrData['starter.notifyBudgetApproverApproval']?.approverEmail) {
        firstLine = this.atrData['starter.notifyBudgetApproverApproval']?.approverEmail;
      } else {
        firstLine = this.first_line;
      }
      const formData = {
        first_line: firstLine,
        happy_to_approve: this.happy_to_approve,
        approval_date: this.approval_date,
        approvalComment: this.approvalComment,
        secondLineApproval: this.second_line,
        approved: 'TRUE',
        stage: this.atrData.currentStageName,
        id: this.atrData.docId,
        requestedBy: this.userprofile.email,
        docOwner: this.atrData.requestedBy
      }
      const response = await httpClient.post('/bff/action-request', formData)
      // update formData with the response
      saveStorage('formData', response.data.body.Attributes);
      this.$router.push('/')
    },
    handleCancel() { this.$router.go(-1) }
  }, 
  mounted() {
    const date = new Date()
    this.approval_date = date
      .getFullYear()
      .toString()
      .padStart(4, '0') + '-' + (date
      .getMonth()+1)
      .toString()
      .padStart(2, '0') + '-' + date
      .getDate()
      .toString()
      .padStart(2, '0');
      if (this.currentStage === 'starter.notifyHROps') {
        this.first_line === this.atrData['starter_notifyBudgetApproverApproval'].approverEmail
      }
  },
  created() {
    if(this.hasSecondLineApprover) {
      this.second_line = this.sla
    }
  },
  validations() {
    return {
      first_line: {
        requiredIf: helpers.withMessage(
          'This field is required',
          requiredIf(this.currentStage === 'starter.notifyBudgetApprover')
        )
      },
      approval_date: { required },
      happy_to_approve: { required },
      second_line: { 
        requiredIf: helpers.withMessage(
          'This field is required',
          requiredIf(this.currentStage === 'starter.notifyBudgetApprover')
        ) 
      }
    }
  }
  
};
</script>

<style>
.displayNone {
  display: none !important;
}
</style>
<template>
  <form class="space-y-8 divide-y divide-gray-200 px-4 py-3">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Reject ATR
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-red-500">
            The offer and contract have been rejected, the Hiring Manager and
            Approvers will be notifed. Please complete the information below and
            then select Reject.
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <!-- Reject Reason -->
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="country"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Reason for rejection *
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                v-model="reason"
                type="text"
                name="employeeName"
                id="employee-name-input"
                autocomplete="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:border-none disabled:cursor-not-allowed"
                @change="v$.$validate()"
                @input="v$.reason.$touch()"
              >
              <span v-if="v$.reason.$error" class="text-red-500">
                * {{ v$.reason.$errors[0].$message }}
              </span>
            </div>
          </div>
          
          <!--End of reject reason-->

          <!-- First line comments -->
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="comments"
              class="block text-sm font-medium text-gray-700"
              >Comments</label
            >
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                v-model="comments"
                id="about"
                name="about"
                rows="3"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border border-gray-300
                  rounded-md
                "
                @blur="v$.comments.$touch()"
              />
              <span v-if="v$.comments.$error && v$.comments.$dirty" class="text-red-500">
                * {{ v$.comments.$errors[0].$message }}
              </span>
              <p class="mt-2 text-sm text-red-500">
                * Please include any relevant comments, as these will be emailed
                to the Hiring Manager and Approvers
              </p>
            </div>
            <!-- First line comments end-->
          </div>
        </div>
      </div>

      <div class="pt-5" v-if="atr.currentStageName === 'starter.notifyBudgetApprover' || atr.currentStageName === 'starter.notifyHROps'">
        <div class="flex justify-end">
          <button
            type="button"
            class="
              bg-white
              py-2
              px-4
              border border-gray-300
              rounded-md
              shadow-sm
              text-sm
              font-medium
              text-gray-700
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
            @click.prevent="handleCancel"
          >
            Cancel
          </button>
          <button
            :disabled="!v$.$dirty || v$.$error"
            type="submit"
            class="
              ml-3
              inline-flex
              justify-center
              py-2
              px-4
              border border-transparent
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-white
              bg-green-500
              hover:bg-green-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              disabled:bg-gray-200
              disabled:text-gray:500
              disabled:cursor-not-allowed
            "
            @click.prevent="handleRejectAtr"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators"
import httpClient from '@/httpClient';

export default {
  props: ['atr'],
  data() {
    return {
      reason: '',
      comments: '',
      v$: useVuelidate(),
      rejectionReasons: [
        'Apprentice Information Missing',
        'Current Salary Incorrect',
        'Employing Entity / Cost Centre Incorrect',
        'Employee Number Incorrect',
        'Job Level and T&C Missing',
        'Multiple Reasons',
        'Overtime Rates Missing',
        'Right to Work Incomplete',
        'Right to Work Not Received',
        'Right to Work Not Verified',
        'Shift Rotation Plan Missing',
        'Start Date Missing',
        'Work Pattern Does Not Match Hours',
        'Wrong Contract Selected',
        'Other (Please specify)'
      ]
    }
  },
  methods: {
    async handleRejectAtr() {
      try {
        const payload = {
          docOwner: this.atr.requestedBy,
          id: this.atr.docId,
          state: this.atr.currentStageName,
          reason: this.reason,
          comment: this.comments
        }
        this.$store.dispatch('setIsLoading', true);
        await httpClient.post('/bff/reject-request', payload);
        this.$router.push('/');
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'The request was rejected'
        });
      } catch(err) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'Could not process your request at this time'
        });
      }
      
    },
    handleCancel() { this.$router.go(-1) }
  },
  computed: {
    profile() { return this.$store.getters.getProfile; }
  },
  validations() {
    return {
      reason: { required },
      comments: { 
        requiredIf: helpers.withMessage(
          'If other, please specify',
          requiredIf(this.reason === 'Other (Please specify)')
        )
      }
    }
  }
};
</script>

<template>
  <div class="p-12">
    <!-- Page title-->
    <div>
      <div>
        <div class="mb-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Approve {{formData.docId}}
          </h3>
          <p class="mt-4 text-sm text-gray-500">
            <b
              >* Please ensure that all mandatory fields are completed and the
              Job Description has been attached before submitting for
              approval</b
            >
          </p>
        </div>
      </div>
    </div>
    <!-- End of page title-->

    <!-- ATR Information section -->
    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">ATR Information</h3>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Employee Name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.formOne.employeeName}}</dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Hiring manager</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.formOne.hiringManagerName}}</dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Hiring manager email</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.formOne.hiringManagerEmail}}</dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Status</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.status}}</dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Job title</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.formThree.jobTitle}}</dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Attachments</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul v-if="formData.formEleven.files?.length" role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li v-for="(file, index) in formData.formEleven.files" :key="file.name" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-2 flex-1 w-0 truncate"> {{ file.name }} </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <button class="font-medium text-indigo-600 hover:text-indigo-500" @click.prevent="handleDownload(index)"> Download </button>
                </div>
              </li>
            </ul>
            <span v-else>-</span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
    </div>
    <!-- End ATR Information section -->

    <!-- Form section ID: 1 Approve ID: 2 More Info ID: 3 Reject-->
    <div
      class="p-5 my-8 bg-slate-200"
      style="border-radius: 20px"
    >
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <approval-form v-if="this.form_to_show === '1'" :sla="secondApprover"></approval-form>
    <reject-form v-else-if="this.form_to_show === '2'" :atr="formData"></reject-form>
    </div>
    </div>
    </div>
    <!-- End Form section-->
  </div>
</template>

<script>
import { useStore } from "vuex";
import { PaperClipIcon } from '@heroicons/vue/solid'
import ApprovalForm from './approval-forms/approval-form.vue'
import RejectForm from './approval-forms/reject-form.vue'
import httpClient from '@/httpClient';
import { getStorage } from '@/storageSave';

export default {
  name: "approval-page",
  data() {
      return {
          store: useStore(),
          form_to_show: "1",
          secondApprover: ''
      }
  },
  components: {
    PaperClipIcon,
    ApprovalForm,
    RejectForm,
  },
  computed: {
    formData() {
      return JSON.parse(getStorage('formData'));
    },
  },
  methods: {
    async handleDownload(index) {
      const file = this.formData.formEleven.files[index];
      const shortId = this.shortId;
      const fileName = file.name.replace(/\s/g, '_')
      try {
        const response = await httpClient.post(
          '/docs/download',
          { key: `${shortId}/${fileName}` }
        );
        const url = response.data.body.signedUrl;
        window.open(url)
      } catch (err) {
        this.store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'Could not download file'
        });
        console.warn(err)
      }
    },
  },
  created() {
    this.form_to_show = this.$route.params.action
    if(this.formData?.starter_notifyBudgetApproverApproval?.secondLineApproverEmail) {
      this.secondApprover = (this.formData?.starter_notifyBudgetApproverApproval.secondLineApproverEmail.toString() ?? null);
    }
  }
};
</script>

import axios from 'axios';
import { Auth } from 'aws-amplify';

const httpClient = axios.create(
  { baseURL: process.env.VUE_APP_API_BASE_URL }
);

httpClient.interceptors.request.use(
  config => {
    const idToken = Auth.user.signInUserSession.idToken.jwtToken
    config.headers.Authorization = idToken;
    return config;
  }, async error => Promise.reject(error));

httpClient.interceptors.response.use(response => {
  return response;
}, async error => {
    if (error.response.status === 401) {
      await Auth.signOut();
      await Auth.federatedSignIn();
    }
    return Promise.reject(error);
});

export default httpClient;

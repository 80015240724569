<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Generate Offers
          </h3>
        </div>
        <!-- end title header -->
        <p class="text-gray-700">Approved number of candidates: {{ approvedRoles }}</p>
        <div class="col-span-6 mt-4">
          <label
            for="offering_to"
            class="block text-sm font-medium text-gray-700"
          >Please provide the names of the Candidates you are offering to:</label>
          <div class="relative" v-for="(n, index) in numberOfOffers + cancelledOffers?.length || 0" :key="index">
            <input
              :value="data.selection?.offering_to[index]"
              type="text"
              name="offering_to"
              id="offering_to-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
              :disabled="disableForm || (offeringToCopy && (index + 1) <= offeringToCopyArray.length)"
              @input="handleNameInput($event, index)"
            >
            <span v-if="data?.offersArray && data?.offersArray[index]?.active === 'REJECTED'" class="absolute text-red-600 top-2 right-2">Rejected</span>
            <span v-if="data?.offersArray && data?.offersArray[index]?.active === 'CANCELLED'" class="absolute text-red-600 top-2 right-2">Cancelled</span>
          </div>
        </div>
      </div>
      <div v-if="(profile?.userID?.toUpperCase() === data.requestedBy?.toUpperCase() || profile?.ADMIN || profile?.RESOURCING) && (data.currentStageName === 'starter.notifyCVsCollected' || data.currentStageName === 'starter.notifyCandidateSelected' || data.currentStageName === 'starter.complete')" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Generate Offers
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core";

export default {
  props: ['data', 'disableForm'],
  emits: ['update:formData', 'generate-additional-offers'],
  data() {
    return {
      model: this.data,
      offeringToCopy: null,
      offeringToCopyArray: [],
      v$: useVuelidate(),
      showModal: false,
      tempSelection: []
    }
  },
  computed: {
    approvedRoles() {
      return this.data?.formThree?.numberOfPositions
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    numberOfOffers() {
      return this.data?.selection?.offer_stage ? parseInt(this.data.selection.offer_stage) : 0
    },
    cancelledOffers() {
      return this.data?.offersArray?.filter((offer) => offer.active === 'CANCELLED' || offer.active === 'REJECTED')
    }
  },
  methods: {
    handleNameInput(event, index) {
      const value = event.target.value;
      this.tempSelection[index] = value;
    },
    async submitForm () {
      const newOffers = [...this.tempSelection].splice(this.model.selection.offering_to.length);
      this.model.selection.offering_to_new = newOffers;
      this.model.selection.offering_to = this.tempSelection;
      await this.$emit('update:formData', this.model.selection)
      this.$emit('generate-additional-offers', { name: 'generateOffers', data: this.data.selection });
    }
  },
  mounted() {
    if (this.data?.selection?.offering_to) {
      this.offeringToCopy = JSON.stringify(this.data?.selection?.offering_to);
      this.offeringToCopyArray = [...this.data?.selection?.offering_to];
      this.tempSelection = [...this.data?.selection?.offering_to];
    }
    if (this.data.selection?.saved) {
      this.model.selection = this.data.selection
    }
    this.model.selection.offer_stage = this.approvedRoles
  },
  validations() {
    return {
      data: {
        selection: {
          offer_stage: { required },
          regret_letters: { required },
          withdrawing_roles: { required },
          re_advertising: { required }
        }
      }
    }
  }
}
</script>

<template>
  <div class="w-full">
    <div class="xl:hidden bg-white grow shadow overflow-hidden sm:rounded-md m-2" style="max-height: 435px">
      <activity-feed></activity-feed>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md m-2 xl:grow">
      <approval-messages :key="keyIndex" :docType="docType" :formData="formData" @reply="reloadApprovalMessages"></approval-messages>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import ActivityFeed from "@/components/activityFeed.vue";
import ApprovalMessages from "@/components/approvalMessages.vue";

export default {
  props: ['formData', 'docType'],
  components: {
    ApprovalMessages,
    ActivityFeed
  },
  setup(props, { emit }) {
    const approvalSteps = [
      "starter.notifyBudgetApprover",
      "starter.notifyMoreInfoBudgetApproval",
      "starter.notifyHROps",
      "starter.notifyMoreNotifyHROps",
      "starter.notifyRecruitment",
      "starter.notifyRoleAdvertised",
      "starter.notifyCVsCollected",
      "starter.notifyCandidateSelected",
      "starter.notifyBudgetReApproval",
      "starter.notifyMoreInfoBudgetReApproval",
      "starter.notifyHROpsReApproval",
      "starter.notifyMoreInfoHROpsReApproval",
      "starter.notifyMakeOffer",
      "starter.notifyOfferSent",
      "starter.notifyDocsReturned",
      "starter.notifyInternalTeams",
    ];

    const keyIndex = ref(0);
    const showModal = ref(false);
    const currentState = computed(() => props.formData?.currentStageName);
    const currentStep = computed(() =>
      approvalSteps.indexOf(currentState.value)
    );

    const reloadApprovalMessages = () => {
      keyIndex.value++;
      emit('comment')
    }

    return {
      keyIndex,
      showModal,
      approvalSteps,
      currentState,
      currentStep,
      reloadApprovalMessages
    };
  },
};
</script>

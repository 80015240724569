<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 overflow-hidden mb-2" style="padding: 10px !important;">
    <li v-for="document in props.allDocuments" :key="document.createdDate" class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <div class="flex-1 flex flex-col p-8">
        <DocumentTextIcon
          class="w-24 h-24 flex-shrink-0 mx-auto"
          :class="{
            'text-gray-400' : document.docType === 'STARTER',
            'text-orange-400' : document.docType === 'AMEND',
            'text-red-400' : document.docType === 'LEAVER',
            'text-green-500' : document.docType === 'OFFER',

          }"
        />
        <h3 v-if="document.shortId" class="mt-6 text-gray-900 text-sm font-medium font-bold">{{ document.shortId }}</h3>
        <h3 v-else class="mt-6 text-gray-900 text-sm font-medium">N/A</h3>

        <dl class="mt-1 flex-grow flex flex-col justify-between">
          <dt class="sr-only">Title</dt>
          <dt class="sr-only">Role</dt>
          <dd class="mt-3">
            <span class="px-2 py-1 text-xs font-medium">Created: {{ formatDate(document.createdDate, 'DD/MM/YYYY') }}</span>
            <br>
            <span class="px-2 py-1 text-xs font-medium">Doc Type: {{ document.docType }}</span>
            <br>
            <span class="px-2 py-1 text-xs font-medium">Assignee: 
              <span v-if="document.currentAssignee">{{document.currentAssigneeEmail}}</span> 
              <span v-else>N/A</span>
            </span>
          </dd>
        </dl>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div class="w-0 flex-1 flex">
            <div @click="navigateToDocument(document.docId, document.requestedBy)" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <EyeIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3" style="cursor:pointer;">View Document</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps, inject } from "vue";
import { EyeIcon, DocumentTextIcon } from '@heroicons/vue/solid'
import { useRouter } from "vue-router";

const router = useRouter()
const formatDate = inject('formatDate')
const props = defineProps(['allDocuments', 'userprofile'])

const routes = {
  STARTER: 'view-starter',
  LEAVER: 'view-leaver',
  AMEND: 'view-amend',
  OFFER: 'view-offer'
}

const navigateToDocument = async (id, docOwner) => {
  // find document
  const document = props.allDocuments.find((atr) => atr.docId === id);
  if (document && document.currentStageName === 'starter.notifyRecruitment') {
    router.push({ path: `/recruitment-team/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRecruitmentUpdated') {
    router.push({ path: `/recruitment-update/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRoleAdvertised') {
    router.push({ path: `/advert-raised/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyCVsCollected') {
    router.push({ path: `/ready-for-selection/${id}/${docOwner}` });
  } else if (document && (document.currentStageName === 'starter.notifyCandidateSelected' || document.currentStageName === 'starter.complete')) {
    router.push({ path: `/selection-complete/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyMakeOffer') {
    router.push({ path: `/offer-hr/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyPrepOffer') {
    router.push({ path: `/confirm/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyTermsHaveChanged') {
    router.push({ path: `/amend-terms/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyH2RAdmin') {
    router.push({ path: `/assign-admin-owner/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyOfferSent') {
    router.push({ path: `/sap-confirmation/${id}/${docOwner}` });
  } else {
    router.push({ path: `/${routes[document.docType]}/${id}/${docOwner}` });
  }
}

</script>
<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Resignation
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <p class="text-sm text-red-700">Please attach a copy of the employees resignation letter in the attachments & addtional information section.</p>
            
            <div>
              <label for="leaving_date" class="block text-sm font-medium text-gray-700">Leaving date *</label>
              <div class="flex mt-1 rounded-md shadow-sm">
                <input
                  :value="formData.leaving_date"
                  :disabled="disableForm"
                  type="date"
                  name="leaving_date"
                  id="leaving-date-input"
                  class="
                    flex-1
                    block
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    min-w-0
                    rounded-md
                    sm:text-sm
                    border-gray-300
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                    disabled:text-gray-700
                  "
                  @input="handleInput"
                  @blur="v$.formData.leaving_date.$touch()"
                />
              </div>
              <span v-if="v$.formData.leaving_date.$errors.length" class="text-red-500">
                * {{ v$.formData.leaving_date.$errors[0].$message }}
              </span>
            </div>
            <p class="text-sm">*The leaving date is the last day that the employee should be paid for working. For example Joe provides his one month's notice to leave on the last day of March,
                however he has the last week in April booked as holiday. The leave date to enter on the form should be the last day in April, the number of days holiday taken in the
                final week should be entered in the 'Holidays used' field on the form.
            </p>
            <p class="text-sm text-red-700">
                The SSC will process the leaver action and release the P45 to the employee on the next available pay date following receipt of the
                Leaver Form.
            </p>
            <p class="text-sm text-red-700th">If there are further payments to be made to the employee please notify us via Part 2 of the Leaver Form as soon as possible so that
                these can be made before their final pay is processed and before their P45 is issued, otherwise the employee will be taxed at a
                higher rate.
            </p>
        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: ['disableForm'],
  emits: ['submit-form'],
  data() {
    return {
      v$: useVuelidate()
    }
  },
  computed: {
    formData() {
      return this.$store.getters.getLeaverFormData('formSix')
    }
  },
  methods: {
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.formData[name] = value;
      // const copy = this.formData;
      // copy[name] = value;
      this.$store.dispatch('setLeaverFormState', { formName: 'formSix', formData: this.formData })
    },
    submitForm() {
      this.$emit('submit-form', { name: 'formSix', formData: this.formData })
    }
  },
  validations() {
    return {
      formData: {
        leaving_date: { required }
      }
    }
  }
}

</script>

<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            TALEO & Recruitment Details 
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div class="col-span-6 mt-4">
              <label
                for="offer_stage"
                class="block text-sm font-medium text-gray-700"
              >TALEO Vacancy ID: *</label>
              <input
                :value="data?.resourcing?.taleo_vacancy_id"
                type="text"
                name="taleo_vacancy_id"
                id="taleo_vacancy_id-input"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                :disabled="disableForm"
                @input="handleInput"
                @blur="v$.data.resourcing.taleo_vacancy_id.$touch()"
              >
              <span v-if="v$.data?.resourcing?.taleo_vacancy_id.$error" class="text-red-500">
                * {{ v$.data?.resourcing?.taleo_vacancy_id.$errors[0].$message }}
              </span>
            </div>
            <div v-if="data?.formSeven?.recruitmentTeam !== 'No'">
              <p class="text-sm text-blue-700">The hiring manager has specified that they would like the role to be advertised, please provide the following details:</p>
              <div class="col-span-6 mt-4">
                <label
                  for="offer_stage"
                  class="block text-sm font-medium text-gray-700"
                >URL link to advert: *</label>
                <input
                  :value="data?.resourcing?.url_link"
                  type="text"
                  name="url_link"
                  id="url_link-input"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.data?.resourcing.url_link.$touch()"
                >
                <span v-if="v$.data?.resourcing?.url_link.$error" class="text-red-500">
                  * {{ v$.data?.resourcing?.url_link.$errors[0].$message }}
                </span>
              </div>
              <div class="col-span-6 mt-4">
                <label
                  for="offer_stage"
                  class="block text-sm font-medium text-gray-700"
                >Advert close date: *</label>
                <input
                  :value="data?.resourcing?.advert_close"
                  type="date"
                  name="advert_close"
                  id="advert_close-input"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
                  :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                  :disabled="disableForm"
                  @input="handleInput"
                  @blur="v$.data?.resourcing.advert_close.$touch()"
                >
                <span v-if="v$.data?.resourcing?.advert_close.$error" class="text-red-500">
                  * {{ v$.data?.resourcing?.advert_close.$errors[0].$message }}
                </span>
              </div>
            </div>
            
        <!-- End Form -->
      </div>
      <div v-if="profile?.RESOURCING && !disableForm && data.currentStageName === 'starter.notifyRecruitmentUpdated'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="saveForLater"
        >
          Save
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            ml-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Complete
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from '@vuelidate/validators' 

export default {
  props: ['data', 'disableForm'],
  emits: ['update:formData', 'submit-form'],
  data() {
    return {
      model: this.data,
      v$: useVuelidate()
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile;
    }
  },
  methods: {
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model.resourcing[name] = value;
      this.$emit('update:formData', { formName: 'resourcing', data: this.model.resourcing });
    },
    submitForm() {
      this.$emit('submit-form', { name: "resourcing", data: { ...this.data.resourcing, completed: true } })
    },
    saveForLater() {
      this.$emit('submit-form', { name: "resourcing", data: this.data.resourcing })
    }
  },
  validations() {
    return {
      data: {
        resourcing: {
          taleo_vacancy_id: { required },
          url_link: {
            requiredIf: helpers.withMessage('Value is required', requiredIf(this.data?.formSeven?.recruitmentTeam !== 'No'))
          },
          advert_close: {
            requiredIf: helpers.withMessage('Value is required', requiredIf(this.data?.formSeven?.recruitmentTeam !== 'No'))
          }
        }
      }
    }
  }
}

</script>

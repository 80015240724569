import DOMPurify from 'dompurify';

// Standard Input
export function Input(title, name, type, disabled = false, value = '') {
  const data = `
    <div class="grid grid-cols-3 gap-6">
        <div class="col-span-6">
        <label
            for="${name}"
            class="block text-sm font-medium text-gray-700"
            > ${title} *</label
        >
        <div class="mt-1">
            <input
            type="${type}"
            name="${name}"
            id="${name}"
            value="${DOMPurify.sanitize(value)}"
            class="
                shadow-sm
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
                ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
            "
            required
            ${disabled ? 'disabled' : ''}
            @change="handleInputChange"
            />
        </div>
        </div>
    </div>
    `
  return data
}

export function smallInput(title, name, type, disabled = false, value = ''){
  const data = `
  <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="${name}" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> ${title} </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
            type="${type}"
            name="${name}"
            id="${name}"
            value="${value}"
            class="
                shadow-sm
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
                ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
            "
            required
            ${disabled ? 'disabled' : ''}
            @change="handleInputChange"
            />
            </div>
          </div>
  `
  return data
}

// Yes No Radio 
export function Radio(title, name, value1 = 'Yes', value2 = 'No', disabled = false, value = '') {
  const data = `
    <div>
    <p>${title} *</p>
  </div>
  <div class="mt-4 space-y-4">
    <div class="flex items-start">
      <div class="h-5 flex items-center">
        <input
          value="${value1}"
          id="radio_button_${name}_${value1}"
          name="${name}"
          type="radio"
          ${value === value1 ? 'checked' : ''}
          class="
            focus:ring-indigo-500
            h-4
            w-4
            text-indigo-600
            border-gray-300
            rounded
            cursor-pointer
            ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
          "
          ${disabled ? 'disabled' : ''}
        />
      </div>
      <div class="ml-3 text-sm">
        <label
          for="${name}-yes"
          class="font-medium text-gray-700 cursor-pointer"
          >${value1}</label
        >
      </div>
    </div>
    <div class="flex items-start">
      <div class="h-5 flex items-center">
        <input
        value="${value2}"
        id="radio_button_${name}_${value2}"
        name="${name}"
        ${value === value2 ? 'checked' : ''}
          type="radio"
          class="
            focus:ring-indigo-500
            h-4
            w-4
            text-indigo-600
            border-gray-300
            rounded
            cursor-pointer
            ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
          "
          ${disabled ? 'disabled' : ''}
        />
      </div>
      <div class="ml-3 text-sm">
        <label
          for="${name}-no"
          class="font-medium text-gray-700 cursor-pointer"
          >${value2}</label
        >
      </div>
    </div>
  </div>
    `
  return data
}


//Dropdown 
export function Dropdown(title, name, values = [true, false], disabled = false, value = '') {
  const options = []
  values.forEach(element => {
    const slugLower = element.toLowerCase()
    const slug = slugLower.replace(/\s/g, "-")

    options.push(
      `
      <option ${value === slug ? 'selected' : ''} value="${slug}">${element}</option>
      `
    )
  });
  const data = `
      <div class="grid grid-cols-3 gap-6">
      <div class="col-span-6">
        <label for="${name}" class="block text-sm font-medium text-gray-70"
          >${title}</label
        >
        <select
          id="${name}"
          name="${name}"
          autocomplete="${name}"
          value="${value}"
          class="
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
            ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
          "
          ${disabled ? 'disabled' : ''}
        >
          ${options.join()}
        </select>
      </div>
    </div>
  `
  return data
}


//TextArea
export function Textarea(title, name, disabled = false, value = '') {
  const data = `
    <div class="grid grid-cols-3 gap-6">
          <div class="col-span-6">
            <label
              for="${name}"
              class="block text-sm font-medium text-gray-700"
              >${title}</label
            >
            <div class="mt-1">
              <textarea
                rows="4"
                name="${name}"
                id="${name}"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
                "
                ${disabled ? 'disabled' : ''}
                required
              >${value}</textarea>
          `
  return data;
}


// Dynamic Checklist
export function CheckList(title, values = [true, false], disabled = false, value = []) {
  const checkboxes = []
  
  values.forEach(element => {
    let slug = element.toLowerCase();
    slug = slug.replace(/\s/g, "-");
    slug = slug.replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'');

    checkboxes.push(`
    <div class="flex items-start">
    <div class="h-5 flex items-center">
      <input
        id="${slug}-check"
        name="${slug}-check"
        type="checkbox"
        value="${slug}"
        ${value.includes(slug) ? 'checked' : ''}
        class="
          focus:ring-indigo-500
          h-4
          w-4
          text-indigo-600
          border-gray-300
          rounded
          cursor-pointer
          ${disabled ? 'bg-gray-200 border-none cursor-not-allowed' : ''}
        "
        ${disabled ? 'disabled' : ''}
      />
    </div>
    <div class="ml-3 text-sm">
      <label
        for="${slug}-check"
        class="font-medium text-gray-700 cursor-pointer"
        >${element}</label
      >
    </div>
  </div>
    `)
  });
  
  const data = `
  <fieldset>
  <div>
    <p>${title}</p>
  </div>
  <div class="mt-4 space-y-4">
    ${checkboxes.join(' ')}
  <div>
</fieldset>
  `
  return data
}
<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Working Hours
          </h3>
        </div>

        <!-- form start -->
        <div>
          <div class="my-2">
            <p class="text-red-600 italic">
              * If you are not uploading a complex shift pattern, please make sure all of the below inputs have been filled out. If an employee will not be working a specific day, please select "off".
            </p>
            <WorkingWeek :data="model.workingWeek" @update="handleUpdateWorkingHours" :disableForm="disableForm" @mark-complete="handleMarkComplete"/>
            <div class="mt-4">
              <p class="text-red-600 italic">
                * If there is a complex work pattern for this role please update the matrix <a href="https://equans.sharepoint.com/:x:/r/sites/ukbuems/_layouts/15/Doc.aspx?sourcedoc=%7B2479141E-51B8-4E48-8467-32167E8BD5C8%7D&file=Shift%20Rotation%20Template.xlsm&action=default&mobileredirect=true" target="_blank" class="text-blue-600 hover:underline">here</a> and attach it to this offer form.
              </p>
              <!-- TODO: Add in link -->
              <p class="text-red-600 italic">
                * If the time Interval you require isn't available above, please provide additional comments at the end of the form.
              </p>
            </div>
            
            <div class="my-4">
              <p>Upload complex shift pattern:</p>
              <FileUploader
                name="complex-shift-pattern"
                :documentShortId="formData?.shortId"
                :files="formData?.files"
                :disabled="disableForm"
                @updateFiles="handleUpdateFiles"
                :uploading="uploading"
                @fileUploading="uploading = $event"
              />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Is the employee a shift worker? *
              </legend>
              <span v-if="v$.formData.shiftWorker.$error" class="text-red-500">
                * {{ v$.formData.shiftWorker.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="Yes"
                        id="shiftWorker-yes"
                        name="shiftWorker"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        "
                        :checked="formData?.shiftWorker === 'Yes'"
                        :disabled="disableForm"
                        @change="handleInput"
                        @blur="v$.formData.shiftWorker.$touch()"
                    />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftWorker" class="font-medium text-gray-700"
                        >Yes</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="No"
                        id="shiftWorker-no"
                        name="shiftWorker"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                          disabled:bg-gray-200
                          disabled:cursor-not-allowed
                        "
                        :checked="formData?.shiftWorker === 'No'"
                        :disabled="disableForm"
                        @change="handleInput"
                        @blur="v$.formData.shiftWorker.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="shiftWorker" class="font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div v-if="formData?.shiftWorker === 'Yes'">
            <div class="col-span-6 mt-4">
              <label for="approvedWeeklyHours" class="block text-sm font-medium text-gray-700"
                >Number of shifts in a shift pattern (i.e. 4, if they will be working 4 on and 4 off) *</label
              >
              <input
                :value="formData?.numberOfShiftsPerPattern"
                type="text"
                name="numberOfShiftsPerPattern"
                id="numberOfShiftsPerPattern-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:border-none
                  disabled:cursor-not-allowed
                "
                :disabled="disableForm"
                @input="handleInput"
                @blur="v$.formData.numberOfShiftsPerPattern.$touch()"
              />
              <span v-if="v$.formData.numberOfShiftsPerPattern.$error" class="text-red-500">
                * {{ v$.formData.numberOfShiftsPerPattern.$errors[0].$message }}
              </span>
            </div>
            <div class="col-span-6 mt-4">
              <label for="approvedWeeklyHours" class="block text-sm font-medium text-gray-700"
                >Number of days in a shift pattern (i.e. 8, if they will be working 4 on and 4 off) *</label
              >
              <input
                :value="formData?.numberOfDaysPerPattern"
                type="number"
                min="0"
                name="numberOfDaysPerPattern"
                id="numberOfDaysPerPattern-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:border-none
                  disabled:cursor-not-allowed
                "
                :disabled="disableForm"
                @input="handleInput"
                @blur="v$.formData.numberOfDaysPerPattern.$touch()"
              />
              <span v-if="v$.formData.numberOfDaysPerPattern.$error" class="text-red-500">
                * {{ v$.formData.numberOfDaysPerPattern.$errors[0].$message }}
              </span>
            </div>
            <p class="italic">
              * Shift calculation is based on: Number of shifts in a shift pattern divided by te number of days in a shift pattern (including non working days) 
              multiplied by 7 by a Government Leave Factor.
            </p>
            <div class="col-span-6 mt-4">
              <label for="approvedWeeklyHours" class="block text-sm font-medium text-gray-700"
                >Number of shifts per annum: *</label
              >
              <input
                :value="formData?.shiftsPerAnnum"
                type="number"
                name="shiftsPerAnnum"
                id="shiftsPerAnnum-input"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  disabled:bg-gray-200
                  disabled:border-none
                  disabled:cursor-not-allowed
                "
                :disabled="disableForm"
                @input="handleInput"
                @blur="v$.formData.shiftsPerAnnum.$touch()"
              />
              <span v-if="v$.formData.shiftsPerAnnum.$error" class="text-red-500">
                * {{ v$.formData.shiftsPerAnnum.$errors[0].$message }}
              </span>
            </div>
            <p>* As the employee is a shift worker we need to note the number of shifts per annum.</p>
          </div>
          <div class="col-span-6 mt-4">
            <label for="fullTimeHours" class="block text-sm font-medium text-gray-700"
              >How many hours would be worked by a full time employee in this role? (per week) *</label
            >
            <input
              :value="formData?.fullTimeHours"
              type="text"
              name="fullTimeHours"
              id="fullTimeHours-input"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.fullTimeHours.$touch()"
            />
            <span v-if="v$.formData.fullTimeHours.$error" class="text-red-500">
              * {{ v$.formData.fullTimeHours.$errors[0].$message }}
            </span>
          </div>
          <div class="col-span-6 mt-4">
            <label for="approvedWeeklyHours" class="block text-sm font-medium text-gray-700"
              >Approved weekly working hours for this contract:</label
            >
            <input
              :value="formData?.approvedWeeklyHours"
              type="number"
              min="0"
              name="approvedWeeklyHours"
              id="approvedWeeklyHours-input"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:border-none
                disabled:cursor-not-allowed
              "
              disabled
              @input="handleInput"
            />
          </div>
          <p class="text-red-600 italic">
            * The proposed weekly working hours total must match the Monday to Friday hours shown above, otherwise 
            the contract will be delayed.
          </p>
          <div class="col-span-6 mt-4">
            <label for="weeksPerYear" class="block text-sm font-medium text-gray-700"
              >How many weeks will be worked per year?</label
            >
            <input
              :value="formData?.weeksPerYear ?? atr?.formFive.weeksPerYear"
              type="number"
              name="weeksPerYear"
              id="weeksPerYear-input"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:border-none
                disabled:cursor-not-allowed
              "
              disabled
              @input="handleInput"
            />
          </div>
          <div class="col-span-6 mt-4">
            <label for="proposedWeeklyHours" class="block text-sm font-medium text-gray-700"
              >Proposed weekly working hours for this contract: *</label
            >
            <input
              :value="formData?.proposedWeeklyHours"
              type="number"
              min="0"
              name="proposedWeeklyHours"
              id="proposedWeeklyHours-input"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.proposedWeeklyHours.$touch()"
            />
            <span v-if="v$.formData.proposedWeeklyHours.$error" class="text-red-500">
              * {{ v$.formData.proposedWeeklyHours.$errors[0].$message }}
            </span>
          </div>
          <p v-if="formData?.proposedWeeklyHours !== formData?.approvedWeeklyHours" class="text-red-600 italic">
            * Any differences between Approved and Proposed weekly working hours will result in first and second line re-approval
          </p>


        </div>
        <div v-if="hasChanged" class="p-3 w-full flex flex-row items-center">
          <ExclamationIcon class="text-red-600 h-16 w-16" />
          <p class="text-red-600 ml-2">
            Once submitted, this document will go for re-approval because the terms have changed.
          </p>
        </div>
        <p class="text-md text-red-600 italic">* Please complete the full work pattern schedule or upload complex work pattern to proceed</p>
      </div>
      <div v-if="editable || document.currentStageName === 'offer.notifyPrepOffer'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid || !shiftPatternPresent || uploading"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import FileUploader from '@/components/FileUploader.vue';
import WorkingWeek from '@/components/WorkingWeek.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { getStorage } from '@/storageSave';
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  props: ['formData', 'disableForm', 'atr', 'document', 'editable'],
  emits: ['update:formData', 'submit-form', 'complete-offer'],
  components: { ExclamationIcon, FileUploader, WorkingWeek },
  data() {
    return {
      model: this.formData,
      v$: useVuelidate(),
      inputtedShiftPattern: false,
      uploading: false,
    }
  },
  computed: {
    hasChanged() {
      return this.model.proposedWeeklyHours !== this.model.approvedWeeklyHours
    },
    shiftPatternPresent() {
      return this.inputtedShiftPattern || this.formData.files?.length
    }
  },
  methods: {
    handleMarkComplete(val) {
      this.inputtedShiftPattern = val;
    },
    handleUpdateWorkingHours({ data }) {
      this.model.workingWeek = data;
      this.$emit('update:formData', { formName: 'workingHours', formData: this.model });
    },

    handleUpdateFiles(e) {
      this.model.files = e.model
      this.$emit('update:formData', this.model)
    },

    handleInput(event) {
      const { name, value } = event.target;
      this.model[name] = value;
      if (this.hasChanged) {
        this.model.hasChanged = this.hasChanged;
      } else {
        this.model.hasChanged = undefined;
      }
      this.$emit('update:formData', { formName: 'workingHours', formData: this.model });
    },

    submitForm() {
      this.$emit('submit-form', { name: 'workingHours', formData: {
        ...this.model,
        hasChanged: this.hasChanged
      }});
    } 
  },
  mounted() {
    const atr = JSON.parse(getStorage('formData'));
    this.model.approvedWeeklyHours = atr.atr.formFive.hoursPerWeek;
    this.$emit('update:formData', { formName: 'workingHours', formData: this.model });
  },
  validations() {
    return {
      formData: {
        shiftWorker: { required },
        numberOfShiftsPerPattern: {
          requiredif: helpers.withMessage('Value is required', requiredIf(this.formData?.shiftWorker === 'Yes'))
        },
        numberOfDaysPerPattern: {
          requiredif: helpers.withMessage('Value is required', requiredIf(this.formData?.shiftWorker === 'Yes'))
        },
        shiftsPerAnnum: {
          requiredif: helpers.withMessage('Value is required', requiredIf(this.formData?.shiftWorker === 'Yes'))
        },
        fullTimeHours: { required },
        proposedWeeklyHours: { required },
      }
    }
  }
}
</script>

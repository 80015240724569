<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Collecting Applications
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <p class="text-sm text-blue-700">Please attach any relevant documentation including applications and the shortlisting matrix.</p>
        <!-- Document upload -->
          <FileUploader
            name="collecting-applications"
            :disabled="disableForm"
            :files="data?.collectingApplications?.files"
            :documentShortId="data.shortId"
            @updateFiles="handleUpdateFiles"
            :uploading="uploading"
            @fileUploading="uploading = $event"
          />
        <!-- End Document upload -->

        <div class="grid grid-cols-3 gap-6 sm:pt-5 mt-4">
            <fieldset>
              <div>
                <p>All applications attached? *</p>
                <span
                  v-if="v$.data?.collectingApplications.applications_attached.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.data?.collectingApplications.applications_attached.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-applications_attached_yes"
                      name="applications_attached"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.applications_attached === 'Yes'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.applications_attached.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="applications_attached"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-applications_attached_no"
                      name="applications_attached"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.applications_attached === 'No'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.applications_attached.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="applications_attached"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:pt-5 mt-4">
            <fieldset>
              <div>
                <p>Shortlisting matrix attached? *</p>
                <span
                  v-if="v$.data?.collectingApplications.matrix_attached.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.data?.collectingApplications.matrix_attached.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-matrix_attached_yes"
                      name="matrix_attached"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.matrix_attached === 'Yes'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.matrix_attached.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="matrix_attached"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-matrix_attached_no"
                      name="matrix_attached"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.matrix_attached === 'No'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.matrix_attached.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="matrix_attached"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:pt-5 mt-4">
            <fieldset>
              <div>
                <p>Shortlisting complete? *</p>
                <span
                  v-if="v$.data?.collectingApplications.shortlisting_complete.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.data?.collectingApplications.shortlisting_complete.$errors[0].$message }}
                </span>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-shortlisting_complete_yes"
                      name="shortlisting_complete"
                      type="radio"
                      value="Yes"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.shortlisting_complete === 'Yes'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.shortlisting_complete.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="shortlisting_complete"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-shortlisting_complete_no"
                      name="shortlisting_complete"
                      type="radio"
                      value="No"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed': disableForm }"
                      :checked="data.collectingApplications?.shortlisting_complete === 'No'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.data?.collectingApplications.shortlisting_complete.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="shortlisting_complete"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        <!-- End Form -->
      </div>
      <div v-if="profile?.RESOURCING && data.currentStageName === 'starter.notifyRoleAdvertised'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          :disabled="v$.$invalid || uploading"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import useVuelidate from "@vuelidate/core";
import { required } from '@vuelidate/validators'

export default {
  props: ['data', 'disableForm'],
  emits: ['update:formData', 'submit-form'],
  components: { FileUploader },
  data() {
    return {
      modelValue: this.data,
      v$: useVuelidate(),
      uploading: false,
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    }
  },
  methods: {
    handleUpdateFiles({ model }) {
      this.modelValue.collectingApplications.files = model
      this.$emit('update:formData', this.modelValue.collectingApplications)
    },

    handleRadioChange(event) {
      const { name, value } = event.target;
      this.modelValue.collectingApplications[name] = value;
      this.$emit('update:formData', { formName: 'collectingApplications', data: this.modelValue.collectingApplications });
    },

    submitForm () {
      this.$emit('submit-form', { name: 'collectingApplications', data: this.data.collectingApplications });
    }
  },
  validations() {
    return {
      data: {
        collectingApplications: {
          applications_attached: { required },
          matrix_attached: { required },
          shortlisting_complete: { required }
        }
      }
    }
  }
}

</script>

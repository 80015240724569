<template>
    <div class="m-4 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Advanced Search</h1>
        <p class="mt-2 text-sm text-gray-700">Search for a Document</p>
      </div>
    </div>
    <div class="mt-8">
      <div class="flex justify-end flex-col sm:flex-row">
        <label class="self-center" for="search-select">Search by:</label>
        <select
          v-model="selectedSearch"
          id="search-options-select"
          name="search-select"
          class="
            mt-1
            ml-2
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option v-for="option in searchOptions" :key="option.id" :value="option">
            {{ option.displayName }}
          </option>
        </select>
        <div v-if="!hideDatePicker" class="flex justify-end ml-2 flex-col sm:flex-row">
          <label class="self-center">Date range:</label>
          <div class="ml-2">
            <DatePicker
              range 
              maxRange="31" 
              :maxDate="new Date()"
              :timePicker="false"
              :value="searchRange"
              @change="handleDateChange"
            />
          </div>
        </div>
      </div>
      <div class="flex mt-2">
        <div class="relative ml-2 w-full">
          <UserSearch v-if="showUserSearch" :group="selectedSearch.group" @updated-user="handleUser($event, 'user')" />

          <div v-else-if="showUserSearchAndStage" class="flex flex-col">
            <UserSearch v-if="!searchUser" :group="selectedSearch.group" @updated-user="handleUser($event, 'stage')" />
            <div v-else class="flex flex-col">
              <div class="flex">
                <input
                  class="
                    mt-1
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    disabled:bg-gray-200
                    disabled:cursor-not-allowed
                    disabled:text-gray-600
                  "
                  type="text"
                  :value="searchUserName"
                  disabled>
                <XIcon class="text-gray-400 h-5 w-5 hover:text-gray-800 self-center ml-2 cursor-pointer" @click="clearUser" />
              </div>
              <div class="flex mt-2">
                <select
                  v-model="searchQuery"
                  id="stage-options-select"
                  name="search-select"
                  class="
                    mt-1
                    w-full
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    shadow-sm
                    sm:text-sm
                    border-gray-300
                    rounded-l-md
                  "
                >
                  <option v-for="stage in stages" :key="stage.stageName" :value="stage.stageName">
                    {{ stage.displayName }}
                  </option>
                </select>
                <button type="button" class="-ml-px mt-1 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-200" @click="fetchAtrs('next')">
                  <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Search
                </button>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="flex">
              <input
                v-model="searchQuery"
                placeholder="Search..."
                type="text"
                class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-l-md"
              >
              <button type="button" class="-ml-px mt-1 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-200" @click="fetchAtrs('next')">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="searched" class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Short ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigned to</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Current stage</th>
                </tr>
              </thead>
              <tbody v-if="atrs.length" class="bg-white">
                <tr v-for="(atr, index) in atrs" :key="atr.docId" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-blue-600 hover:underline cursor-pointer" @click="navigateToDocument(atr.docId, atr.requestedBy)">{{ atr.shortId }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ atr.formThree?.jobTitle ? atr.formThree.jobTitle : 'No role specified' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ currentAssigneeEmail(atr) }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ findStage(atr.currentStageName, atr.status) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="!atrs.length" class="text-gray-400 text-center p-8"> No Requests to show... </p>
          </div>
        </div>
        <div class="flex justify-center md:justify-end mx-1 md:mx-8 my-4 px-4 md:px-8 py-4 shadow rounded ring-1 ring-black ring-opacity-5">
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" :disabled="pageNumber === 1" @click="prevPage">Prev</button>
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" :disabled="atEnd" @click="nextPage">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, watch, computed, inject } from 'vue';
import { SearchIcon, XIcon } from '@heroicons/vue/solid'
import DatePicker from '@/components/DatePicker.vue'
import UserSearch from '@/components/userDropdownSearch.vue'

const store = useStore();
const router = useRouter();
const atrs = ref([]);
const pageNumber = ref(1);
const atEnd = ref(false);
const tokens = ref(['']);
const searched = ref(false);
const searchQuery = ref('');
const searchRange = ref([]);
const searchUser = ref('');
const searchUserName = ref('');
const searchOptions = [
  {
    id: 0,
    displayName: 'Hiring Manager',
    value: 'HIRING-MANAGER',
    group: 'HM'
  },
  {
    id: 1,
    displayName: 'First Line Approver',
    value: 'FIRST-LINE-APPROVER',
    group: 'HM'
  },
  {
    id: 2,
    displayName: 'Second Line Approver',
    value: 'SECOND-LINE-APPROVER',
    group: 'HROPS'
  },
  {
    id: 3,
    displayName: 'Admin Owner',
    value: 'ADMIN-OWNER',
    group: 'H2R'
  },
  {
    id: 4,
    displayName: 'Resourcing Owner',
    value: 'RESOURCING-OWNER',
    group: 'RESOURCING'
  },
  {
    id: 5,
    displayName: 'Job Title',
    value: 'JOB-TITLE',
    group: null
  },
  {
    id: 6,
    displayName: 'Current Stage',
    value: 'USER-BY-STAGE',
    group: null
  }
]
const selectedSearch = ref(searchOptions[0]);


const routes = {
  STARTER: 'view-starter',
  LEAVER: 'view-leaver',
  AMEND: 'view-amend',
  OFFER: 'view-offer'
}

const stages = inject('stages');

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
}

const currentAssigneeEmail = (atr) => atr.currentAssigneeEmail ? 
  atr.currentAssigneeEmail.includes('@') ? 
    atr.currentAssigneeEmail : atr.currentAssignee.includes('@') ? 
      atr.currentAssignee : atr.currentAssigneeEmail 
  : 'Unassigned'

const navigateToDocument = async (id, docOwner) => {
  // find document
  const document = atrs.value.find((atr) => atr.docId === id);
  if (document && document.currentStageName === 'starter.notifyRecruitment') {
    router.push({ path: `/recruitment-team/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRoleAdvertised') {
    router.push({ path: `/advert-raised/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRecruitmentUpdated') {
    router.push({ path: `/recruitment-update/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyCVsCollected') {
    router.push({ path: `/ready-for-selection/${id}/${docOwner}` });
  } else if (document && (document.currentStageName === 'starter.notifyCandidateSelected' || document.currentStageName === 'starter.complete')) {
    router.push({ path: `/selection-complete/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyMakeOffer') {
    router.push({ path: `/offer-hr/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyPrepOffer') {
    router.push({ path: `/confirm/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyTermsHaveChanged') {
    router.push({ path: `/amend-terms/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyH2RAdmin') {
    router.push({ path: `/assign-admin-owner/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyOfferSent') {
    router.push({ path: `/sap-confirmation/${id}/${docOwner}` });
  } else {
    router.push({ path: `/${routes[document.docType]}/${id}/${docOwner}` });
  }
}

const handleDateChange = (dates) => searchRange.value = dates;

const handleUser = (event, type) => {
  const { userID, Title } = event;
  searchUser.value = userID;
  searchUserName.value = Title
  if (type === 'user') fetchAtrs();
}

const clearUser = () => {
  searchUser.value = '';
  searchUserName.value = '';
  resetSearchSettings();
}

const fetchAtrs = async (type='next') => {
  store.dispatch('setIsLoading', true);
  const token = type === 'next' ? tokens.value[pageNumber.value - 1] : tokens.value[pageNumber.value - 3]
  const response = await httpClient.post('/bff/advanced-search', {
    requestedBy: '12345678910',
    searchType: selectedSearch.value.value,
    searchTerm: searchQuery.value,
    searchRange: searchRange.value,
    user: searchUser.value,
    next: token ? token : undefined,
  });
  if (!response.data.body.next) {
    atEnd.value = true;
  } else {
    atEnd.value = false;
    tokens.value.push(response.data.body.next);
  }
  atrs.value = response.data.body.Items;
  searched.value = true
  store.dispatch('setIsLoading', false);
};

const hideDatePicker = computed(() =>
  selectedSearch.value.value === 'JOB-TITLE' || 
  selectedSearch.value.value === 'USER-BY-STAGE'
);
const showUserSearch = computed(() => [
  'Hiring Manager',
  'First Line Approver',
  'Second Line Approver'
].includes(selectedSearch.value.displayName));

const showUserSearchAndStage = computed(() => 
  selectedSearch.value.value === 'RESOURCING-OWNER' || 
  selectedSearch.value.value === 'USER-BY-STAGE' ||
  selectedSearch.value.value === 'ADMIN-OWNER'
);

const nextPage = async() => {
  if (atEnd.value) return;
  pageNumber.value++;
  await fetchAtrs();
}

const prevPage = async() => {
  if (pageNumber.value === 1) return;
  pageNumber.value--;
  await fetchAtrs('prev');
}

const resetSearchSettings = () => {
  atrs.value = [];
  searched.value = false;
  searchQuery.value = '';
  searchRange.value = [];
  searchUser.value = '';
  searchUserName.value = '';
  pageNumber.value = 1;
  atEnd.value = false;
  tokens.value = [''];
}

watch(selectedSearch, () => resetSearchSettings())
</script>

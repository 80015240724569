<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">Contract Details</h3>
        </div>
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Active</label>
            <fieldset class="mt-4">
              <legend class="sr-only">Contract Active Status</legend>
              <div class="mr-2 flex flex-row align-center">
                <div v-for="item in [true, false]" :key="item" class="flex items-center mr-6">
                  <input v-model="formData.LKStatus" :value="item" :id="`LKStatus-${item.toString()}`" :name="`LKStatus-${item.toString()}`" type="radio" :checked="formData.LKStatus === item" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                  <label :for="`LKStatus-${item.toString()}`" class="ml-3 block text-sm font-medium text-gray-700">{{ item.toString() }}</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div v-for="field in fields" :key="field.id" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{{ field.label }}</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData[field.key]" :type="field.type" :name="field.name" :id="field.id" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:text-gray-800 disabled:bg-gray-200 disabled:cursor-not-allowed" :disabled="selectedContract" @blur="v$.formData[field.key].$touch()" />
              <div v-if="v$.formData[field.key]?.$error">
                <p class="text-red-600">* {{ v$.formData[field.key].$errors[0].$message }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:underline" @click="$emit('cancel')">Cancel</button>
        <button :disabled="v$.$invalid" type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="handleCreateContract">Save</button>
      </div>
    </div>
  </form>
</template>
<script>
import httpClient from '@/httpClient';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators'

export default {
  props: ['contractTypeId', 'contractType', 'selectedContract'],
  data() {
    return {
      v$: useVuelidate(),
      formData: {
        LKStatus: true,
        ID: '',
        Contract: '',
        ContractName: '',
        ContractLevel: '',
        ContractSubLevel: '',
        PotentialPositionCategories: '',
        CoveringLetter: '',
        ContractPosition: '',
        GDFSUEZWorkerType: '',
        PensionScheme: '',
        PensionMaxEE: '',
        PensionMaxER: '',
        PensionRule: '',
        PensionMaxEarningsCap: '',
        LifeCover: '',
        PMI: '',
        Car: '',
        CarAllowance: '',
        Bonus: '',
        OvertimeEligibility: '',
        OvertimeNote: '',
        Holidays: '',
        HolidaysCarryOver: '',
        ProbationPeriod: '',
        ASRReviewDate: '',
        HoursofWork: '',
        Sickness: '',
        NoticePeriod: '',
        PostEmploymentRestrictionsNotwithin3monthofleaving: '',
        PostEmploymentRestrictionsNotwithin6mthofleaving: '',
        PostEmploymentRestrictionsNotwithin9mthofleaving: '',
        ExampleTitles1: '',
        ContractTitle: '',
        Security: '',
        GeneralContracts: '',
        ContractType: this.contractType
      },
      fields: [
        {
          key: 'ID',
          label: 'ID',
          type: 'text',
          name: 'id-field',
          id: 'id-field'
        },
        {
          key: 'ContractTitle',
          label: 'Contract Title',
          type: 'text',
          name: 'ContractTitle-field',
          id: 'ContractTitle-field'
        },
        {
          key: 'Contract',
          label: 'Contract',
          type: 'text',
          name: 'contract-field',
          id: 'contract-field'
        },
        {
          key: 'ContractName',
          label: 'Contract Name',
          type: 'text',
          name: 'contract-name-field',
          id: 'contract-name-field'
        },
        {
          key: 'ContractLevel',
          label: 'Contract Level',
          type: 'text',
          name: 'contract-level-field',
          id: 'contract-level-field'
        },
        {
          key: 'ContractSubLevel',
          label: 'Contract Sub Level',
          type: 'text',
          name: 'contract-sublevel-field',
          id: 'contract-sublevel-field'
        },
        {
          key: 'PotentialPositionCategories',
          label: 'Potential Position Categories',
          type: 'text',
          name: 'ppg-field',
          id: 'ppg-field'
        },
        {
          key: 'CoveringLetter',
          label: 'Covering Letter',
          type: 'text',
          name: 'CoveringLetter-field',
          id: 'CoveringLetter-field'
        },
        {
          key: 'ContractPosition',
          label: 'Contract Position',
          type: 'text',
          name: 'CoveringLetter-field',
          id: 'CoveringLetter-field'
        },
        {
          key: 'GDFSUEZWorkerType',
          label: 'GDF SUEZ Worker Type',
          type: 'text',
          name: 'GDFSUEZWorkerType-field',
          id: 'GDFSUEZWorkerType-field'
        },
        {
          key: 'PensionScheme',
          label: 'Pension Scheme',
          type: 'text',
          name: 'PensionScheme-field',
          id: 'PensionScheme-field'
        },
        {
          key: 'PensionMaxEE',
          label: 'Pension Max EE',
          type: 'text',
          name: 'PensionMaxEE-field',
          id: 'PensionMaxEE-field'
        },
        {
          key: 'PensionMaxER',
          label: 'Pension Max ER',
          type: 'text',
          name: 'PensionMaxER-field',
          id: 'PensionMaxER-field'
        
        },
        {
          key: 'PensionRule',
          label: 'Pension Rule',
          type: 'text',
          name: 'PensionRule-field',
          id: 'PensionRule-field'
        },
        {
          key: 'PensionMaxEarningsCap',
          label: 'Pension Max Earnings Cap',
          type: 'text',
          name: 'PensionMaxEarningsCap-field',
          id: 'PensionMaxEarningsCap-field',
        },
        {
          key: 'LifeCover',
          label: 'Life Cover',
          type: 'text',
          name: 'LifeCover-field',
          id: 'LifeCover-field'
        },
        {
          key: 'PMI',
          label: 'Private Medical Insurance',
          type: 'text',
          name: 'PMI-field',
          id: 'PMI-field'
        },
        {
          key: 'Car',
          label: 'Car',
          type: 'text',
          name: 'Car-field',
          id: 'Car-field'
        },
        {
          key: 'CarAllowance',
          label: 'Car Allowance',
          type: 'text',
          name: 'CarAllowance-field',
          id: 'CarAllowance-field'
        },
        {
          key: 'Bonus',
          label: 'Bonus',
          type: 'text',
          name: 'Bonus-field',
          id: 'Bonus-field'
        },
        {
          key: 'OvertimeEligibility',
          label: 'Overtime Eligibility',
          type: 'text',
          name: 'OvertimeEligibility-field',
          id: 'OvertimeEligibility-field'
        },
        {
          key: 'OvertimeNote',
          label: 'Car',
          type: 'text',
          name: 'Car-field',
          id: 'Car-field'
        },
        {
          key: 'Holidays',
          label: 'Holidays',
          type: 'text',
          name: 'Holidays-field',
          id: 'Holidays-field'
        },
        {
          key: 'HolidaysCarryOver',
          label: 'Holidays Carry Over',
          type: 'text',
          name: 'HolidaysCarryOver-field',
          id: 'HolidaysCarryOver-field'
        },
        {
          key: 'ProbationPeriod',
          label: 'Probation Period',
          type: 'text',
          name: 'ProbationPeriod-field',
          id: 'ProbationPeriod-field'
        },
        {
          key: 'ASRReviewDate',
          label: 'ASR Review Date',
          type: 'text',
          name: 'ASRReviewDate-field',
          id: 'ASRReviewDate-field'
        },
        {
          key: 'HoursofWork',
          label: 'Hours of Work',
          type: 'text',
          name: 'HoursofWork-field',
          id: 'HoursofWork-field'
        },
        {
          key: 'Sickness',
          label: 'Sickness',
          type: 'text',
          name: 'Sickness-field',
          id: 'Sickness-field'
        },
        {
          key: 'NoticePeriod',
          label: 'Notice Period',
          type: 'text',
          name: 'NoticePeriod-field',
          id: 'NoticePeriod-field'
        },
        {
          key: 'PostEmploymentRestrictionsNotwithin3monthofleaving',
          label: 'Post Employment Restrictions Not within 3 months of leaving',
          type: 'text',
          name: 'PostEmploymentRestrictionsNotwithin3monthofleaving-field',
          id: 'PostEmploymentRestrictionsNotwithin3monthofleaving-field'
        },
        {
          key: 'PostEmploymentRestrictionsNotwithin6mthofleaving',
          label: 'Post Employment Restrictions Not within 6 months of leaving',
          type: 'text',
          name: 'PostEmploymentRestrictionsNotwithin6mthofleaving-field',
          id: 'PostEmploymentRestrictionsNotwithin6mthofleaving-field'
        },
        {
          key: 'PostEmploymentRestrictionsNotwithin9mthofleaving',
          label: 'Post Employment Restrictions Not within 9 months of leaving',
          type: 'text',
          name: 'PostEmploymentRestrictionsNotwithin9mthofleaving-field',
          id: 'PostEmploymentRestrictionsNotwithin9mthofleaving-field'
        },
        {
          key: 'ExampleTitles1',
          label: 'Example Titles',
          type: 'text',
          name: 'ExampleTitles1-field',
          id: 'ExampleTitles1-field'
        },
        {
          key: 'Security',
          label: 'Security',
          type: 'text',
          name: 'Security-field',
          id: 'Security-field'
        },
        {
          key: 'GeneralContracts',
          label: 'General Contracts',
          type: 'text',
          name: 'GeneralContracts-field',
          id: 'GeneralContracts-field'
        },
      ]
    }
  },
  methods: {
    async handleCreateContract() {
      this.$store.dispatch('setIsLoading', true);
      try {
        const payload = { type: 'contract', ContractTypeId: this.contractTypeId, ...this.formData };
        const response = await httpClient.post('/config/create', payload);
        if (response.data.statusCode === 500) throw new Error();
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: this.selectedContract ? 'Changes saved' : 'Successfully created new contract'
        });
        this.$emit('cancel', { refresh: true });
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: this.selectedContract ? 'Error saving changes' : 'Could not create contract'
        });
      }
      this.$store.dispatch('setIsLoading', false);
    }
  },
  mounted() {
    this.formData.ContractType = this.contractType;
    if (this.selectedContract) {
      this.formData = this.selectedContract;
    }
  },  
  validations() {
    return {
      formData: {
        LKStatus: { required },
        ID: { required },
        Contract: { required },
        ContractName: { required },
        ContractLevel: { required },
        ContractSubLevel: { required },
        PotentialPositionCategories: { required },
        ContractPosition: { required },
        PensionScheme: { required },
        PensionMaxEE: { required },
        PensionMaxER: { required },
        PensionRule: { required },
        PensionMaxEarningsCap: { required },
        LifeCover: { required },
        PMI: { required },
        Car: { required },
        CarAllowance: { required },
        Bonus: { required },
        OvertimeEligibility: { required },
        OvertimeNote: { required },
        Holidays: { required },
        HolidaysCarryOver: { required },
        ProbationPeriod: { required },
        ASRReviewDate: { required },
        HoursofWork: { required },
        Sickness: { required },
        NoticePeriod: { required },
        PostEmploymentRestrictionsNotwithin3monthofleaving: { required },
        PostEmploymentRestrictionsNotwithin6mthofleaving: { required },
        PostEmploymentRestrictionsNotwithin9mthofleaving: { required },
        ExampleTitles1: { required },
        ContractTitle: { required },
        Security: { required },
        GeneralContracts: { required },
        ContractType: { required }
      }
    }
  }
}
</script>
<template>
  <div>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Next Steps
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <p>Who will be providing first line approval for this employee amend? *</p>
        <div class="col-span-6">
          <user-search 
            group="HM" 
            v-model:selectedEmail="model.firstLineApproverEmail"
            :read-only="isFirstLineSelected"
            @updated-user="handleUser($event)">
          </user-search>
        </div>

 
        

        <button
          class="
            mr-2
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          :disabled="!formData.firstLineApproverEmail"
          @click.prevent="handleSaveForm"
        >
          Save
        </button>

        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          :disabled="!canComplete"
          @click.prevent="handleSubmit"
        >
          Complete Amend
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserSearch from '@/components/userDropdownSearch.vue'
export default {
    components: {
        UserSearch,
    },
    props: ['formData', 'canComplete'],
    data() {
        return {
            model: this.formData,
            hiddenInputs: true
        }
    },
    computed: {
      isFirstLineSelected() {
        return this.model?.firstLineApproverEmail ? true : false;
      }
    },
    methods: {
      handleUser(event) {
        this.model.firstLineApproverName = event.Title;
        this.model.firstLineApproverEmail = event.email.trim();
        this.hiddenInputs = false,
        this.$emit('update:formData', { name: "formFifteen", formData: this.formData })
      },
      selectAnotherUser() {
        this.model.firstLineApproverName = '';
        this.model.firstLineApproverEmail = '';
        this.$emit('update:formData', { formName: 'formFifteen', formData: this.model });
      },
      handleSaveForm() {
        this.$emit('submit-form', { name: "formFifteen", formData: this.formData })
      },
      handleSubmit() {
        this.$emit('complete-amend');
      },
    }
};
</script>

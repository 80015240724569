<template>
<form-layout
  :navItemConfig="navItemConfig"
  :currentNavItem="currentNavItem"
  :formPoint="formPoint"
  :displayHeader="true"
  formName="leaverForm"
  @set-current-nav-item="setCurrentNavItem"
>
  <template #pageTitle>Advert Raised</template>
      <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">ATR Short ID:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.shortId }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Job Title:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.formThree.jobTitle }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{findStage(formData.currentStageName, formData.status)}}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.formOne?.hiringManagerEmail ?? formData.currentAssigneeEmail }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Doctype:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{formData.docType}}</dd>
      </div>
      <div class="flex justify-end" v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING">
        <button
          class="
            text-center
            px-4
            py-2
            rounded-md
            text-white
            bg-green-700
            hover:underline
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-cyan-500
            bg-no-repeat
          "
          @click.prevent="handleReassign"
        >Re-assign</button>
      </div>
    </template>
  <template #pageSubtitle>
    <b>*Please ensure that all mandatory fields are completed and the Job Description has been attached before submitting for approval</b><br>
    <b>*For compatible browsers please review this link. If your browser is not compatible it may effect the text you see within multi line text boxes and /or the speed of the people search.</b>
  </template>
  <template #main>
    <component :is="currentNavItem?.component" @submit-form="handleSubmitForm" />
  </template>
</form-layout>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import formLayout from '@/layouts/formLayout.vue'
import formOne from '@/components/approvalWorkflowForms/taleoRecruitment.vue'
import formTwo from '@/components/approvalWorkflowForms/hiringSecondaryDetails.vue'
import formThree from '@/components/approvalWorkflowForms/recruitmentReason.vue'
import formFour from '@/components/approvalWorkflowForms/jobDetails.vue'
import formFive from '@/components/approvalWorkflowForms/organisationDetails.vue'
import formSix from '@/components/approvalWorkflowForms/workingHours.vue'
import formSeven from '@/components/approvalWorkflowForms/contractDetails.vue'
import formEight from '@/components/approvalWorkflowForms/advertisingRequirements.vue'
import formNine from '@/components/approvalWorkflowForms/preEmployment.vue'
import formTen from '@/components/approvalWorkflowForms/sercurityForm.vue'
import formEleven from '@/components/approvalWorkflowForms/specialistRoles.vue'
import formTwelve from '@/components/approvalWorkflowForms/firstSecondApproval.vue'
import formThirteen from '@/components/approvalWorkflowForms/candidateSelection.vue'
const store = useStore();
const formPoint = ref (0);
const stages = inject('stages');
const navItemConfig = [
  {
    formPoint: 0,
    name: "formOne",
    text: "Taleo Recruitment",
    component: formOne,
    icon: "UserCircleIcon"
  },
  {
    formPoint: 1,
    name: "formThirteen",
    text: "Candidate Selection",
    component: formThirteen,
    icon: "UserIcon"
  },
  {
    formPoint: 2,
    name: "formTwo",
    text: "Hiring Manager & Secondary Contact Details",
    component: formTwo,
    icon: "UserIcon"
  },
  {
    formPoint: 3,
    name: "formThree",
    text: "Recruitment Reason",
    component: formThree,
    icon: "LogoutIcon"
  },
  {
    formPoint: 4,
    name: "formFour",
    text: "Job Details",
    component: formFour,
    icon: "EmojiHappyIcon"
  },
  {
    formPoint: 5,
    name: "formFive",
    text: "Organisation Details",
    component: formFive,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 6,
    name: "formSix",
    text: "Working Hours",
    component: formSix,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 7,
    name: "formSeven",
    text: "Contract Details",
    component: formSeven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 8,
    name: "formEight",
    text: "Advertising Requirements",
    component: formEight,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 9,
    name: "formNine",
    text: "Pre Employment Checks",
    component: formNine,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 10,
    name: "formTen",
    text: "Security",
    component: formTen,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 11,
    name: "formEleven",
    text: "Specialist Roles",
    component: formEleven,
    icon: "DocumentTextIcon"
  },
  {
    formPoint: 12,
    name: "formTwelve",
    text: "First & Second Line Approval",
    component: formTwelve,
    icon: "DocumentTextIcon"
  }
];
const profile = computed(() => store.getters.getProfile);

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
};


const currentNavItem = computed(() => navItemConfig.find((item) => item.formPoint === formPoint.value));
const setCurrentNavItem = (value) => formPoint.value = value;
const handleSubmitForm = async (event) => {
  const res = await store.dispatch('saveLeaverFormStep', event);
  if (res) formPoint.value++;
};

</script>
<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Pre-Contractual Checks
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div v-html="security_check"></div>
            <div v-html="security_clearance"></div>
            <p class="text-sm text-red-700">Please note a start date cannot be confirmed unlessthe preliminary checks are complete and we may needto return the ATR to you whilst the checks are beingcompleted.</p>
            <div v-html="security_sia"></div>

            <p class="text-sm text-blue-500">
            If the employee starts before BACS processing day, and so long as the completed ATR and supporting documentation is
            received by the HR deadline they can go on for the current month's/week's pay day.
        </p>
            <div v-html="hr_deadlines"></div>
        <!-- End Form -->
      </div>
       <div v-if="profile?.H2R && document.currentStageName === 'offer.notifyMakeOffer'" class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="submitForm"
        >
          Confirm offer has been sent
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useStore } from "vuex";
import { Input, Radio, CheckList } from "@/form";
import { defineProps, defineEmits, toRef, computed } from 'vue';

const store = useStore();
const props = defineProps(['formData', 'disableForm', 'document']);
const emit = defineEmits(['update:formData', 'submit-form'])

const model = toRef(props, 'formData');

const profile = computed(() => store.getters.getProfile);

const security_check = Input(
  "Is a Security Check (BS7858) Required?",
  "security_check",
  "text",
  props.disableForm,
  model.value?.security_check
);

const security_clearance = Input(
  "Have the preliminary checks been carried out forBS7858 Security Clearance? ",
  "security_clearance",
  "text",
  props.disableForm,
  model.value?.security_clearance
);
const security_sia = Radio("Is a Security Industry Authority Licence (SIA) Required?", "security-lisense", undefined, undefined, props.disableForm, model.value?.security_sia);

const hr_deadlines = CheckList(
  "",
  ["Please be sure to check the HR deadlines to manage the employee's expectations for their first pay day."],
  props.disableForm,
  model.value?.confirm
);

const submitForm = async () => {
  // Get input elements
  const securtyCheckInput = document.getElementById("security_check");
  const securityClearanceInput = document.getElementById("security_clearance");
  const securityLisenseYesInput = document.getElementById("radio_button_security-lisense_Yes");
  const securityLisenseNoInput = document.getElementById("radio_button_security-lisense_No");
  const confirm = document.getElementById("please-be-sure-to-check-the-hr-deadlines-to-manage-the-employees-expectations-for-their-first-pay-day-check");

  // Set formData with input values from fields
  model.value.security_check = securtyCheckInput.value;
  model.value.security_clearance = securityClearanceInput.value;
  if (securityLisenseYesInput.checked) model.value.security_sia = securityLisenseYesInput.value;
  else if (securityLisenseNoInput.checked) model.value.security_sia = securityLisenseNoInput.value

  if (confirm.checked) model.value.confirm = "TRUE"

  await emit('update:formData', { formName: 'checks', formData: model.value })
  emit('submit-form', { name: 'checks', data: model.value });
};
</script>

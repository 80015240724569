<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Specialist Roles
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div v-html="highway_role"></div>
            <div v-html="mobile_role"></div>
            <p class="text-sm text-red-700">*If yes, the word mobile should be included in the job title.</p>
            <div v-html="hybrid_role"></div>
            <p class="text-sm text-red-700">*Please check with your HR Manager whether Hybrid Working applies; if so, this will be noted in the job offer.</p>
            <div v-html="collective_bargaining"></div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { Input } from "@/form";
import { defineProps } from 'vue'

const props = defineProps(['formData', 'disableForm']);

const highway_role = Input("Is this a Highway Worker Role? * ", "highway_role", "text", props.disableForm, props.formData.highwayWorker);
const mobile_role = Input("Is this a Mobile Worker Role? * ", "mobile_role", "text", props.disableForm, props.formData.mobileWorker);
const hybrid_role = Input("Will the employee be a Hybrid Worker? * ", "hybrid_role", 'text', props.disableForm, props.formData.hybridWorker);
const collective_bargaining = Input("Will the employee be subject to Collective Bargaining, if so please provide the name of the Collective Bargaining Arrangement as this will be included in their Contract of Employment:", "hybrid_role", 'text', props.disableForm, props.formData.collectiveBargaining);

</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left w-full">
    <div class="relative">
      <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        {{ currentNavItem?.text }}
        <ChevronDownIcon class="absolute right-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="w-full z-40 absolute right-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="w-full">
          <MenuItem
            as="div"
            v-for="item in items"
            :key="item"
            class="flex justify-between items-center"
            :class="[
              currentNavItem.name === item.name ? 
              'bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold' : 
              'text-gray-700', 'block px-4 py-2 text-sm'
            ]"
            @click="handleMenuItemClick(item.formPoint)"
          >
            <button>{{ item.text }}</button>
            <CheckCircleIcon 
              v-if="getFormSavedStatus(item.name)"
              class="h-5 w-5 mr-2 "
              :class="currentNavItem.name === item.name ? 'text-white' : 'text-green-400'"
            />
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, CheckCircleIcon } from '@heroicons/vue/solid'
import { getStorage } from '@/storageSave'
  
export default {
  props: ['items', 'currentNavItem'],
  components: {
    CheckCircleIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  methods: {
    handleMenuItemClick(formPoint) {
      this.$emit('menu-item-click', formPoint);
    },
    getFormSavedStatus(formName) {
      const cache = getStorage('formData');
      if (cache) {
        const cachedData = JSON.parse(cache);
        return cachedData[formName].saved;
      }
      const data = JSON.parse(getStorage('formData'))
      return data[formName].saved;
    },
  }
}

</script>

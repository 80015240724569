<template>
    <section>
        <div class="grid grid-flow-col gap-x-5 gap-y-5 lg:grid-rows-3 xl:grid-rows-1">
            <template v-if="overall">
                <OutstandingRequests 
                    v-for="document in [{title: 'Offers', type: 'OFFER'}, {title: 'Amends', type: 'AMEND'}, {title: 'Leavers', type: 'LEAVER'}]"
                    :title='document.title'
                    :data="overall[document.type]"
                    :key='document.title'>
                </OutstandingRequests>
            </template>
        </div>
    </section>        
    <hr class="mt-6 border-0 h-px bg-gray-300" />
    <section class="pt-4">
        <OutstandingWork
            :users="users">
        </OutstandingWork>
    </section>
    <hr class="mt-6 border-0 h-px bg-gray-300" />
    <section class="pt-4">
        <ProcessedWork
            :users="processedUsers"
            :overall="processedOverall"
            :formData="dates"
            @update:form-data="handleDateChange"
            @searchProcessed="handleSearchProcessed">
        </ProcessedWork>
    </section>
</template>

<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import OutstandingRequests from './elements/OutstandingRequests.vue';
import OutstandingWork from './elements/OustandingWork.vue';
import ProcessedWork from './elements/ProcessedWork.vue';

const store = useStore();
const user = computed(() => store.getters.getProfile);
const overall = ref({
        OFFER: [0, 0, 0, 0, 0, 0],
        LEAVER: [0, 0, 0, 0, 0, 0],
        AMEND: [0, 0, 0, 0, 0, 0]
      });
const users = ref([]);

const processedOverall = ref({
        OFFER: [0, 0, 0, 0, 0, 0],
        LEAVER: [0, 0, 0, 0, 0, 0],
        AMEND: [0, 0, 0, 0, 0, 0]
      });
const processedUsers = ref({});
const dates = ref({
    startDate: null,
    endDate: null
})
const handleDateChange = ({formData}) => {
    dates.value = formData
}

const handleSearchProcessed = async() => {
    const processedResponse = await httpClient.post(
        `/dashboard/total-processed`,
        {requestedBy: user.value.userID,
        startDate: dates.value.startDate,
        endDate: dates.value.endDate,
        }
    );
    const processedBody = processedResponse.data.body;
    processedOverall.value = processedBody.overall
    processedUsers.value = processedBody.users ?? []
}

onMounted(async() => {
    const response = await httpClient.post(
        `/dashboard/total-outstanding`,
        {requestedBy: user.value.userID}
    );
    const body = response.data.body;
    overall.value = body.overall
    users.value = body.users ?? []
    
    const processedResponse = await httpClient.post(
        `/dashboard/total-processed`,
        {requestedBy: user.value.userID}
    );
    const processedBody = processedResponse.data.body;
    processedOverall.value = processedBody.overall
    processedUsers.value = processedBody.users ?? []
})
</script>
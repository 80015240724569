<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Advertising Requirements
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
            <div v-html="recruitment"></div>
            <p class="text-sm">*If you would like to review the full recruitment policy please click here</p>
            <p class="text-sm text-red-700">*All vacancies should be advertised in line with European Social Agreement and approvers are instructed to challenge this decision.</p>
            <div v-html="advertise_salary"></div>
            <div v-html="shortlist"></div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { Input } from "@/form";
import { defineProps } from 'vue'

const props = defineProps(['formData', 'disableForm']);

const recruitment = Input(
  "Do you want the recruitment team to advertise the role? ",
  "recruitment",
  'text',
  props.disableForm,
  props.formData.recruitmentTeam
);

const advertise_salary = Input(
  "Which wording would you like to use to advertise the salary? ",
  "advertise_salary",
  "text",
  props.disableForm,
  props.formData.advertise
);

const shortlist = Input(
  "Do you want the recruitment team to shortlist the applications? ",
  "shortlist",
  "text",
  props.disableForm,
  props.formData.recruitmentShortlist
);

</script>

<template>
  <form v-if="formData" action="#" method="POST">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Employee Details
          </h3>
          <p>
            If you are unsure of any of the information below, please contact your local HR advisor/manager in order to
            obtain the correct information.
          </p>
        </div>
        <div class="grid grid-cols-3 gap-6">
          <!-- Agency Temp  -->
          <fieldset class="col-span-3">
            <legend class="text-base font-medium text-gray-900">
              Is the leaver a Contractor or Agency Temp? *
            </legend>
            <span v-if="v$.formData.is_temp.$error" class="text-red-500">
              * {{ v$.formData.is_temp.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.is_temp"
                    value="Yes"
                    id="radio-button-is_leaver"
                    name="is_temp_yes"
                    type="radio"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                      cursor-pointer
                    "
                    :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                    :disabled="disableForm"
                    @blur="v$.formData.is_temp.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="is_temp_yes" class="font-medium text-gray-700">Yes</label>
                </div>
              </div>
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.is_temp"
                      value="No"
                      id="radio-button-it-leaver"
                      name="is_temp_no"
                      type="radio"
                      class="
                        focus:ring-indigo-500
                        h-4
                        w-4
                        text-indigo-600
                        border-gray-300
                        rounded
                        cursor-pointer
                      "
                      :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
                      :disabled="disableForm"
                      @blur="v$.formData.is_temp.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="is_temp_no" class="font-medium text-gray-700">No</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- End of Agency Temp  -->
            
          <!-- Employee Name-->
          <div class="col-span-6">
            <label for="employee-name" class="block text-sm font-medium text-gray-700"
              >Employee's full name *</label
            >
            <input
              v-model="formData.employee_name"
              type="text"
              name="employee-name"
              id="name-input"
              autocomplete="employee-name"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @blur="v$.formData.employee_name.$touch()"
            />
            <span v-if="v$.formData.employee_name.$error" class="text-red-500">
              * {{ v$.formData.employee_name.$errors[0].$message }}
            </span>
          </div>
          <!-- End of Employee Name-->

          <!-- Employee Number -->
          <div class="col-span-6">
            <label for="employee-name" class="block text-sm font-medium text-gray-700"
              >Employee Number *</label
            >
            <input
              v-model="formData.employee_number"
              type="text"
              name="employee-number"
              id="name-input"
              autocomplete="employee-number"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
              @blur="v$.formData.employee_number.$touch()"
            />
            <p class="mt-1 text-sm text-gray-500">
              <b>
                *Please enter the 6 digit employee number for the leaver.
              </b>
            </p>
            <span v-if="v$.formData.employee_number.$error" class="text-red-500">
              * {{ v$.formData.employee_number.$errors[0].$message }}
            </span>
          </div>
          <!-- End of Employee Number -->

          <!-- Division -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="division" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Division * </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="division" name="divison" autocomplete="division-name" v-model="formData.division" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" @blur="v$.formData.division.$touch()">
                <template v-if="disableForm">
                  <option
                    v-for="item in configItems.DIVISIONS"
                    :key="item.ID"
                    :value="item.NAME"
                  >{{ item.NAME }}</option>
                </template>
                <template v-else>
                  <option
                    v-for="item in activeDivisions"
                    :key="item.ID"
                    :value="item.NAME"
                  >{{ item.NAME }}</option>
                </template>
              </select>
            </div>
            <span v-if="v$.formData.division.$error" class="text-red-500">
              * {{ v$.formData.division.$errors[0].$message }}
            </span>
          </div>
          <!-- End of  Division -->

          <!-- Sector -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="sector" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Sector * </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select v-if="!disableForm" id="sector" name="sector" autocomplete="sector-name" v-model="formData.sector" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" @blur="v$.formData.sector.$touch()">
                  <option
                    v-for="item in activeSectors"
                    :key="item.ID"
                    :value="item.NAME"
                  >{{ item.NAME }}</option>
              </select>
              <input
                v-else
                disabled
                v-model="formData.sector"
                type="text"
                name="sector-name"
                id="sector-name"
                autocomplete="sector-name"
                class="
                  mt-1
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'bg-gray-200 border-none cursor-not-allowed text-gray-700' : disableForm }"
                @blur="v$.formData.sector.$touch()"
              />
              <template >
                  <option
                    v-for="item in currentDivision?.SECTORS"
                    :key="item.ID"
                    :value="item.NAME"
                  >{{ item.NAME }}</option>
                </template>

            </div>
            <span v-if="v$.formData.sector.$error" class="text-red-500">
              * {{ v$.formData.sector.$errors[0].$message }}
            </span>
          </div>
          <!-- End of Sector -->

          <!-- Company Code -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="company_code" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Company Code * </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="sector" name="company_code" autocomplete="company_code-name" v-model="formData.company_code" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" @blur="v$.formData.company_code.$touch()">
                <template v-if="disableForm">
                  <option
                    v-for="item in configItems?.ENTITIES"
                    :key="item.entity"
                    :value="item.entity"
                  >{{ item.entity }}</option>
                </template>
                <template v-else>
                  <option
                    v-for="item in activeEntities"
                    :key="item.entity"
                    :value="item.entity"
                  >{{ item.entity }}</option>
                </template>
              </select>
            </div>
            <span v-if="v$.formData.company_code.$error" class="text-red-500">
              * {{ v$.formData.company_code.$errors[0].$message }}
            </span>
          </div>
          <!-- End of Company Code -->

          <!-- personal email -->
          <div class="col-span-6">
            <label for="personal-name" class="block text-sm font-medium text-gray-700"
              >Personal/home email address of the employee leaving:</label
            >
            <input
              v-model="formData.personal_email"
              type="text"
              name="personal-name"
              id="personal-name"
              autocomplete="employee-name"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }"
              :disabled="disableForm"
            />
          </div>
          <!-- End of personal email-->

          <!-- Work Location -->
          <div class="col-span-6">
            <label for="location" class="block text-sm font-medium text-gray-700">Work Location *</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="location" name="location" :value="formData.location" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" :class="{ 'bg-gray-200 border-none cursor-not-allowed' : disableForm }" :disabled="disableForm" @change="setLocation" @blur="v$.formData.location.$touch()">
                <template v-if="disableForm">
                  <option
                    v-for="item in configItems.LOCATIONS"
                    :key="item.LOCATIONCODE"
                    :value="item.LOCATIONLOOKUP"
                  >{{ item.DISPLAYNAME }}</option>
                </template>
                <template v-else>
                  <option
                    v-for="item in activeLocations"
                    :key="item.LOCATIONCODE"
                    :value="item.LOCATIONLOOKUP"
                  >{{ item.DISPLAYNAME }}</option>
                </template>
              </select>
            </div>
            <span v-if="v$.formData.location.$error" class="text-red-500">
              * {{ v$.formData.location.$errors[0].$message }}
            </span>
          </div>
          <!-- Work Location end-->

          <!-- Work Location Postcode -->
          <div class="col-span-6">
            <label for="location-postcode" class="block text-sm font-medium text-gray-700"
              >Work Location postcode</label
            >
            <input
              :value="formData.location_postcode"
              type="text"
              name="location-postcode"
              id="name-input"
              autocomplete="location-postcode"
              class="
                mt-1
                focus:ring-indigo-500 focus:border-indigo-500
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
                disabled:bg-gray-200
                disabled:border-none
                disabled:cursor-not-allowed
              "
              disabled
            />
          </div>
          <!-- End of Work Location postcode -->
            
        </div>
        <p class="mt-1 text-sm text-gray-500"><b>
            *Please enter a personal / home email address for the employee. This will be used to send them a confidential exit interview questionnaire that they can
            complete anonymously. Ensure that the employee knows that providing a personal email address is optional. Please encourage completion of the
            questionnaire. £1 is paid to Charity for every completed questionnaire. A copy of the questionnaire is also available here.
        </b></p>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="
            bg-green-500
            border border-transparent
            rounded-md
            shadow-sm
            py-2
            px-4
            inline-flex
            justify-center
            text-sm
            font-medium
            text-white
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            disabled:cursor-not-allowed
            disabled:bg-gray-200
            disabled:text-gray-400
          "
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  props: ['disableForm', 'configItems'],
  data() {
    return {
      v$: useVuelidate()
    }
  },
  computed: {
    activeDivisions() {
      return this.configItems.DIVISIONS.filter((div) => div.LKStatus);
    },
    activeEntities() {
      return this.configItems.ENTITIES.filter((entity) => entity.LKStatus);
    },
    activeLocations() {
      return this.configItems.LOCATIONS.filter((loc) => loc.LKStatus)
    },
    formData() {
      return this.$store.getters.getLeaverFormData('formTwo')
    },
    currentDivision() {
      return this.configItems.DIVISIONS.find((div) => div.NAME === this.formData.division)
    },
    currentLocation() {
      return this.configItems.LOCATIONS.find((loc) => loc.DISPLAYNAME === this.formData.location)
    },
    activeSectors() {
      return this.currentDivision?.SECTORS.filter((sector) => sector.LKStatus);
    }
  },
  methods: {
    handleSave() {
      this.$emit('submit-form', { name: 'formTwo', formData: this.formData })
    },
    setLocation(event) {
      this.formData.location = event.target.value;
      this.formData.location_postcode = this.currentLocation.POSTCODE;
    }
  },
  validations() {
    return {
      formData: {
        is_temp: { required },
        employee_name: { required },
        division: { required },
        sector: { required },
        company_code: { required },
        employee_number: { required },
        location: { required }
      }
    }
  },
}
</script>

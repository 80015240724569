<template>
    <Datepicker
      :modelValue="value"
      :startDate="startDate"
      :range="range"
      :max-range="maxRange"
      :minDate="minDate"
      :maxDate="maxDate"
      :enableTimePicker="timePicker"
      @update:modelValue="handleUpdate"
    />
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { Datepicker },
  props: ['range', 'maxRange', 'minDate', 'maxDate', 'timePicker', 'value'],
  data() {
    return {
      startDate: new Date()
    };
  },
  methods: {
    handleUpdate(dates) {
      this.$emit('change', dates);
    }
  }
}
</script>
<style>
.dp__theme_light {
  --dp-primary-color: #15803d !important;
}
</style>